import React, { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'

import { Meeting } from 'services/store/meetings/types'
import { addMeeting, updateMeeting, removeMeeting } from 'services/store/meetings/actions'

interface StoreMeetingsContextValue {
  storeMeetings: Meeting[],
  addStoreMeeting: (meeting: Meeting) => Promise<void>
  updateStoreMeeting: (meeting: Meeting) => Promise<void>
  removeStoreMeeting: (meeting: Meeting) => Promise<void>
}

const initialValue: StoreMeetingsContextValue = {
  storeMeetings: [],
  addStoreMeeting: async (meeting: Meeting) => {
    console.log('addStoreMeeting: ', meeting)

    return undefined
  },
  updateStoreMeeting: async (meeting: Meeting) => {
    console.log('updateStoreMeeting: ', meeting)

    return undefined
  },
  removeStoreMeeting: async (meeting: Meeting) => {
    console.log('removeStoreMeeting: ', meeting)

    return undefined
  },
}

// create and initialize context
export const StoreMeetingsContext = React.createContext<StoreMeetingsContextValue>(initialValue)

export function useStoreMeetings (): StoreMeetingsContextValue {
  return useContext(StoreMeetingsContext)
}

export type StoreMeetingsMockContextValue = Partial<StoreMeetingsContextValue>

type MockProps = {
  value?: Partial<StoreMeetingsContextValue>
}

export const StoreMeetingsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <StoreMeetingsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </StoreMeetingsContext.Provider>
  )
}

const StoreMeetingsContextProvider: React.FC = ({ children }) => {
  const storeMeetings = useSelector((state: RootState) => state.meetings)
  const dispatch = useDispatch()

  async function addStoreMeeting (meeting: Meeting): Promise<void> {
    dispatch(addMeeting(meeting))
  }

  async function updateStoreMeeting (meeting: Meeting): Promise<void> {
    dispatch(updateMeeting(meeting))
  }

  async function removeStoreMeeting (meeting: Meeting): Promise<void> {
    dispatch(removeMeeting(meeting))
  }

  return (
    <StoreMeetingsContext.Provider
      value={{
        storeMeetings,
        addStoreMeeting,
        updateStoreMeeting,
        removeStoreMeeting,
      }}>
      {children}
    </StoreMeetingsContext.Provider>
  )
}

export default StoreMeetingsContextProvider
