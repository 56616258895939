import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'services/store'
import 'screens/Settings.css'
import paths from 'navigation/paths'
import Meetings from './Meetings'
import ProfilePhotoProvider from 'context/ProfilePhotoContext/ProfilePhotoContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import { updateScreens } from 'services/store/screens/actions'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import ConnectionsProvider from 'context/Connections/ConnectionsContext'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'

type ScreenProps = RouteComponentProps<{
  id: string;
}>

const MeetingsScreen: React.FC<ScreenProps> = ({ history, match }) => {
  const { meetingsList } = useSelector((state: RootState) => state.screens)
  const meetingId = match.params.id

  const storeTips = useSelector((state: RootState) => state.tips)

  const dispatch = useDispatch()

  function onChooseAvailability (meeting: string, connectCalendar: boolean, time?: string, autoBook?: boolean): void {
    if (time) {
      dispatch(updateScreens({ dayAvailability: { meeting, selectedTime: time }, meetingsList: {} }))
      history.push('/tabs/meetings/dayAvailability')
    } else {
      // if (allScreens?.newInvitee && !autoBook) {
      //   dispatch(updateScreens({
      //     askConnectCalendars: {
      //       meeting,
      //       previous: paths.meetingsList,
      //       nextScreen: '/tabs/meetings/chooseAvailability',
      //     },
      //     meetingsList: {},
      //   }))
      //   history.push('/tabs/meetings/askConnectCalendars')
      // } else {
      if (autoBook) {
        if (!storeTips?.tipsAvailabilityCalendarAuto?.completed) {
          dispatch(updateScreens({
            chooseAvailability: { meeting },
            meetingsList: {},
            tipsAvailabilityCalendarAuto: {
              nextScreen: paths.chooseAvailability,
            },
          }))
          history.push(paths.tipsAvailabilityCalendarAuto)

          return
        }
      } else {
        if (!storeTips?.tipsAvailabilityCalendar?.completed) {
          dispatch(updateScreens({
            chooseAvailability: { meeting },
            meetingsList: {},
            tipsAvailabilityCalendar: {
              nextScreen: paths.chooseAvailability,
            },
          }))
          history.push(paths.tipsAvailabilityCalendar)

          return
        }

        if (connectCalendar) {
          if (!storeTips?.tipsConnectCalendars?.skipped ||
            storeTips?.tipsConnectCalendars?.skipped < 2) {
            dispatch(updateScreens({
              askConnectCalendars: {
                meeting,
                previous: paths.meetingsList,
                nextScreen: paths.addParticipants,
              },
            }))
            history.push(paths.askConnectCalendar)

            return
          }
        }
      }

      dispatch(updateScreens({ chooseAvailability: { meeting }, meetingsList: {} }))
      history.push(paths.chooseAvailability)
    }
  }

  function onInvitations (): void {
    history.push(paths.invitationsList)
  }

  function onProfile (): void {
    history.push('/tabs/settings/account')
  }
  function onAddCalendar (meeting: string): void {
    dispatch(updateScreens({
      askConnectCalendars: {
        meeting,
        previous: paths.meetingsList,
        nextScreen: paths.chooseAvailability,
      },
      meetingsList: {},
    }))
    history.push(paths.askConnectCalendar)
  }
  function onEdit (meeting: string, connectCalendar?: boolean): void {
    console.log('ON EDIT connectCalendar: ', connectCalendar)
    dispatch(updateScreens({ meetingSettings: { meeting, connectCalendar }, meetingsList: {} }))
    history.push(paths.meetingSettings)
  }
  function onParticipants (meeting: string): void {
    dispatch(updateScreens({ meetingParticipants: { meeting }, meetingsList: {} }))
    history.push('/tabs/meetings/meetingParticipants')
  }
  function onShareMeeting (meeting: string, byLink: boolean): void {
    if (byLink) {
      dispatch(updateScreens({
        shareMeetingInvite: {
          meeting,
        },
        meetingsList: {},
      }))
      history.push('/tabs/meetings/shareMeetingInvite')
    } else {
      dispatch(updateScreens({
        addParticipants: {
          meeting,
        },
        meetingsList: {},
      }))
      history.push(paths.addParticipants)
    }
  }
  function onCalendarSettings (meeting: string): void {
    dispatch(updateScreens({ meetingCalendarSettings: { meeting, editOnly: true } }))
    history.push(paths.meetingCalendarSettings)
  }
  function onDescription (meeting: string): void {
    dispatch(updateScreens({ meetingDescription: { meeting } }))
    history.push(paths.meetingDescription)
  }
  function onShowPastMeetings (): void {
    history.push(paths.pastMeetings)
  }
  function onResetParams (): void {
    dispatch(updateScreens({ meetingsList: {} }))
  }

  function goBack (): void {
    history.replace(paths.meetingsList)
  }

  return (
    <PreferencesProvider>
      <ContactsProvider>
        <ConnectionsProvider>
          <ProfilePhotoProvider>
            <CalendarsProvider>
              <MeetingsProvider>
                <InviteesProvider>
                  <ParticipantsProvider>
                    <StoreParticipantsProvider>
                      <Meetings
                        meetingId={meetingId}
                        firstJoinedMeeting={meetingsList?.firstJoinedMeeting}
                        onEdit={onEdit}
                        onChooseAvailability={onChooseAvailability}
                        onShareMeeting={onShareMeeting}
                        onProfile={onProfile}
                        onInvitations={onInvitations}
                        onParticipants={onParticipants}
                        onShowPastMeetings={onShowPastMeetings}
                        onAddCalendar={onAddCalendar}
                        onCalendarSettings={onCalendarSettings}
                        onDescription={onDescription}
                        onResetParams={onResetParams}
                        goBack={goBack} />
                    </StoreParticipantsProvider>
                  </ParticipantsProvider>
                </InviteesProvider>
              </MeetingsProvider>
            </CalendarsProvider>
          </ProfilePhotoProvider>
        </ConnectionsProvider>
      </ContactsProvider>
    </PreferencesProvider>
  )
}

export default MeetingsScreen
