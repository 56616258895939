import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import paths from 'navigation/paths'
import 'screens/Settings.css'
import PastMeetings from './PastMeetings'
import ProfilePhotoProvider from 'context/ProfilePhotoContext/ProfilePhotoContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import { updateScreens } from 'services/store/screens/actions'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'

const PastMeetingsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()

  function onChooseAvailability (meeting: string, time?: string, showList?: boolean): void {
    if (time) {
      dispatch(updateScreens({ dayAvailability: { meeting, selectedTime: time } }))
      history.push('/tabs/meetings/dayAvailability')
    } else {
      dispatch(updateScreens({ chooseAvailability: { meeting, showList } }))
      history.push(paths.chooseAvailability)
    }
  }
  function onEdit (meeting: string): void {
    dispatch(updateScreens({ meetingSettings: { meeting } }))
    history.push(paths.meetingSettings)
  }
  function onParticipants (meeting: string): void {
    dispatch(updateScreens({ meetingParticipants: { meeting } }))
    history.push('/tabs/meetings/meetingParticipants')
  }
  function onShareMeeting (meeting: string): void {
    dispatch(updateScreens({ addParticipants: { meeting } }))
    history.push(paths.addParticipants)
  }

  function goBack (): void {
    dispatch(updateScreens({ meetingSettings: { meeting: '' } }))
    history.goBack()
  }

  return (
    <ProfilePhotoProvider>
      <CalendarsProvider>
        <MeetingsProvider>
          <InviteesProvider>
            <PastMeetings
              onEdit={onEdit}
              onChooseAvailability={onChooseAvailability}
              onShareMeeting={onShareMeeting}
              onParticipants={onParticipants}
              goBack={goBack} />
          </InviteesProvider>
        </MeetingsProvider>
      </CalendarsProvider>
    </ProfilePhotoProvider>
  )
}

export default PastMeetingsScreen
