import React, { CSSProperties } from 'react'
import { IonIcon, IonLabel, IonSpinner, IonText } from '@ionic/react'

import { helpCircle } from 'ionicons/icons'

import { Participant, InviteeStatus, Meeting, Invitee } from 'types'

import Avatar from '@material-ui/core/Avatar'

import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'

const container: CSSProperties = {
  display: 'flex',
  overflowX: 'hidden',
  flexWrap: 'nowrap',
}

const avatarColor = '#92949C'

const avatarStyle = {
  width: 32,
  height: 32,
  backgroundColor: avatarColor,
  marginBottom: 5,
}

// const expectingPeopleIcon: CSSProperties = {
//   fontSize: 50,
//   color: avatarColor,
// }

const MAX_NAME_LENGTH = 8

interface ComponentProps {
  meeting?: Meeting
}

const ParticipantScheduleTitleBar: React.FC<ComponentProps> = ({ meeting }) => {
  const { user } = useUser()
  const { loading, participants } = useParticipants()
  const { meetingInvitees } = useInvitees()

  const invitees = meetingInvitees?.filter(invitee => {
    // when an invitee is marked accepted  go ahead and render it as an invitee
    // until it's added to participant list as there might be a delay between
    // being marked as accepted and added to participants list
    // so to hide that delay show accepted invitee. Once it's added to participant
    // list we fill filter it out below.
    if (invitee.status === InviteeStatus.declined) {
      return false
    }

    if (participants && participants.find(p => p.user === invitee.user)) {
      return false
    }

    return true
  })

  function renderParticipants (): JSX.Element[] | undefined {
    if (participants?.length && user?.id) {
      return participants.filter(p => p.user !== user?.id).map((participant: Participant, i: number) => {
        const { name, photo } = participant
        let displayName = name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
        const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

        // if initials is > 2 characters then just pick first and last
        const displayInitials = initials.length > 2
          ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

        if (participant.user === user?.id) {
          displayName = 'You'
        }

        return (
          <ProfileBox
            key={i}>
            <Avatar
              src={photo}
              style={avatarStyle}>
              <IonText style={{ fontSize: 18 }}>
                {displayInitials}
              </IonText>
            </Avatar>
            <IonLabel>{displayName}</IonLabel>
          </ProfileBox>
        )
      })
    }
  }

  function renderInvitees (): JSX.Element[] | undefined {
    if (invitees?.length && user?.id) {
      return invitees?.map((invitee: Invitee, i: number) => {
        const { name, photo } = invitee
        const displayName = name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
        const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

        // if initials is > 2 characters then just pick first and last
        const displayInitials = initials.length > 2
          ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

        return (
          <ProfileBox
            key={i}>
            <Avatar
              src={photo}
              style={avatarStyle}>
              <IonText style={{ fontSize: 18 }}>
                {displayInitials}
              </IonText>
            </Avatar>
            <IonLabel>{displayName}</IonLabel>
            <WaitingSelectTime />
          </ProfileBox>
        )
      })
    }
  }

  function renderExpectingMore (): JSX.Element | undefined {
    if (participants?.length && meeting?.participantsExpected && meetingInvitees) {
      if (meeting?.participantsExpected > participants?.length) {
        let count = meeting.participantsExpected - participants.length

        if (invitees && invitees.length <= count) {
          count = count - invitees.length
        }

        const expectingAvatarStyle: CSSProperties = {
          ...avatarStyle,
          // border: '2px dashed',
          // borderColor: prefersDark ? 'white' : '#92949C',
          // borderColor: '#92949C',
          backgroundColor: avatarColor,
          // backgroundColor: prefersDark ? '#555555' : '#c0c0c0',
        }

        // const expectingPeopleIcon: CSSProperties = {
        //   fontSize: 50,
        //   color: prefersDark ? 'black' : 'white',
        // }

        if (count) {
          return (
            <ProfileBox>
              <Avatar
                style={expectingAvatarStyle}>
                <IonText style={{ fontSize: 18 }}>
                  {count}
                </IonText>
              </Avatar>
              <IonLabel color='medium'>Awaiting</IonLabel>
              <WaitingSelectTime />
              {/* <ExpectingBadge count={count} /> */}
            </ProfileBox>
          )
        }
      }
    }
  }

  if (participants?.length) {
    return (
      <div
        id='participantScheduleBar'
        style={container}>
        {renderParticipants()}
        {renderInvitees()}
        {renderExpectingMore()}
        {loading &&
          <ProfileBox>
            <IonSpinner name='dots' />
          </ProfileBox>}
      </div>
    )
  } else if (loading) {
    return (
      <div
        style={container}>
        <ProfileBox>
          <IonSpinner name='dots' />
        </ProfileBox>
      </div>
    )
  }

  return null
}

const ProfileBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: 70,
    width: 70,
    height: 70,
    position: 'relative',
  }

  return (
    <div
      style={container}
      className='CalendarABorderRight'>
      {children}
    </div>
  )
}

interface ExpectingProps {
  count: number
}

// const ExpectingBadge: React.FC<ExpectingProps> = ({ count }) => {
//   const container: CSSProperties = {
//     top: 18,
//     right: 10,
//     position: 'absolute',
//     zIndex: 1,
//   }

//   const circle: CSSProperties = {
//     height: 24,
//     width: 24,
//     borderRadius: 12,
//     border: '2px solid white',
//     backgroundColor: avatarColor,
//     // display: 'flex',
//     // alignItems: 'center',
//     // justifyContent: 'center',
//     textAlign: 'center',
//   }

//   const badge: CSSProperties = {
//     color: 'white',
//     // fontWeight: 'bold',
//     fontSize: 13,
//   }

//   return (
//     <div
//       style={container}>
//       <div
//         style={circle}>
//         <IonText style={badge}>
//           {count}
//         </IonText>
//       </div>
//     </div>
//   )
// }

const WaitingSelectTime: React.FC = () => {
  const icon: CSSProperties = {
    top: 20,
    right: 10,
    position: 'absolute',
    zIndex: 1,
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: 'white',
    color: avatarColor,
  }

  return (
    <IonIcon
      icon={helpCircle}
      style={icon} />
  )
}

export default ParticipantScheduleTitleBar
