import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { useSpring } from 'react-spring'
import moment from 'moment'

import { compareTimes } from 'services/time'
import { CalendarEvent, MeetingTime, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import { useUser } from 'context/UserContext/UserContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import AnimatedBell from 'components/atoms/AnimatedBell/AnimatedBell'
import MeetingTimeListItem from 'components/molecules/MeetingTimeListItem/MeetingTimeListItem'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  onClose: () => void;
  onGetNotified: () => void;
  presentingElement: HTMLElement | undefined;
}

const GetNotifiedModal: React.FC<ContainerProps> = ({ onGetNotified, onClose }) => {
  const [showCard] = useState(true)

  const { user } = useUser()
  const { meeting } = useMeetings()

  // function renderTrendingTime (): JSX.Element | undefined {
  //   if (user?.id && meeting?.meetingTimes?.length) {
  //     const currentValue = moment().valueOf()
  //     const sortedTimes = meeting.meetingTimes.slice().filter(meetingTime => {
  //       if (moment(meetingTime.endTime).valueOf() > currentValue) {
  //         return true
  //       }
  //     }).sort((a: MeetingTime, b: MeetingTime) => {
  //       const timeA = a.startTime
  //       const timeB = b.startTime

  //       return compareTimes(timeA, timeB)
  //     })

  //     if (sortedTimes?.length) {
  //       const meetingTime = sortedTimes[0]
  //       const { participants } = meetingTime

  //       const active = !!participants?.find(u => u === user?.id)

  //       return (
  //         <div>
  //           <div
  //             style={messageBox}>
  //             <IonText
  //               color='medium'
  //               style={messageStyle}>
  //               You can be notified when everyone has selected their times and Moica finds a time that works for everybody. <br />
  //               Below is currently the earliest trending common time.
  //             </IonText>
  //           </div>
  //           <div style={{ paddingRight: 20 }}>
  //             <MeetingTimeListItem
  //               meetingTime={meetingTime}
  //               active={active}
  //               lines='none' />
  //           </div>
  //         </div>
  //       )
  //     }
  //   }

  //   return (
  //     <div
  //       style={messageBox}>
  //       <IonText
  //         color='medium'
  //         style={messageStyle}>
  //         You can be notified when everyone has selected their times and Moica finds a time that works for everybody. <br />
  //       </IonText>
  //     </div>
  //   )
  // }

  function renderStatus (): JSX.Element | undefined {
    const confirmed = meeting?.meetingTimes?.find(mt => mt.status === 'confirmed')

    if (confirmed) {
      const { participants } = confirmed

      const active = !!participants?.find(u => u === user?.id)

      return (
        <div
          className='ion-text-start'>
          <div style={iconBox}>
            <AnimatedBell
              style={{ width: 60, height: 60 }} />
            <IonText
              style={titleStyle}>
              You&apos;re all set!
            </IonText>
          </div>
          <div
            style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Moica can notify you if anyone&apos;s availability changes.
            </IonText>
          </div>
          <div style={{ paddingRight: 20 }}>
            <MeetingTimeListItem
              meetingTime={confirmed}
              active={active}
              lines='none' />
          </div>
        </div>
      )
    }

    const currentValue = moment().valueOf()
    const sortedTimes = meeting?.meetingTimes?.slice().filter(meetingTime => {
      if (moment(meetingTime.endTime).valueOf() > currentValue) {
        return true
      }
    }).sort((a: MeetingTime, b: MeetingTime) => {
      const timeA = a.startTime
      const timeB = b.startTime

      return compareTimes(timeA, timeB)
    })

    let topTrendingTime: JSX.Element | undefined

    if (sortedTimes?.length) {
      const meetingTime = sortedTimes[0]
      const { participants } = meetingTime
      const active = !!participants?.find(u => u === user?.id)

      topTrendingTime = (
        <div
          style={{
            ...messageStyle,
            ...messageBox,
            paddingRight: 20,
          }}>
          Currently, this is the earliest trending common time:
          <MeetingTimeListItem
            meetingTime={meetingTime}
            active={active}
            lines='none' />
        </div>
      )
    }

    return (
      <div
        className='ion-text-start'>
        <div style={iconBox}>
          <AnimatedBell
            style={{ width: 60, height: 60 }} />
          <IonText
            style={titleStyle}>
            Great, you&apos;re done for now!
          </IonText>
        </div>
        <div
          style={messageBox}>
          <IonText
            color='medium'
            style={messageStyle}>
            Moica can notify you when everyone has selected their availability.<br />
            Do you want to set up notifications to stay up to date on the meeting?<br /><br />
          </IonText>
        </div>
        {topTrendingTime}
      </div>
    )
  }

  function renderActionButtons (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          testId='get-notified-setup'
          onClick={onGetNotified}>
          Setup Notification
        </FooterButton>
      </IonFooter>
    )
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard (): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title={meeting?.title}
          icon=''
          animatedProps={popoverProps}
          onClose={onClose}>
          {renderStatus()}
          {renderActionButtons()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      data-ci-id='get-notified-modal'
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          data-ci-id='get-notified-backdrop'
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default GetNotifiedModal
