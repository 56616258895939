import { UPDATE_TIP, Tips, TipActions, ENABLE_ALL_TIPS } from './types'
import { APP_RESET } from '../types'

export default function screensReducer (
  state: Tips = { enabled: true, startMinTip: true },
  action: TipActions,
): Tips {
  switch (action.type) {
    case UPDATE_TIP:
      return { ...state, ...action.tips }
    case ENABLE_ALL_TIPS:
      return { enabled: true, startMinTip: true }
    case APP_RESET:
      return { enabled: true, startMinTip: true }
    default:
      return state
  }
}
