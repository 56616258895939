import React from 'react'

import { CalendarAccountDetailsTips } from 'types/componentTips'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'

import { useTips } from 'context/TipsContext/TipsContext'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'
import { CalendarAccountDetails } from 'services/store/tips/types'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'

interface ScreenTipsProps {
  minTip?: boolean;
  showScreenTip?: CalendarAccountDetailsTips;
  onClose?: () => void;
  onButton?: () => void;
  onMinTip?: () => void;
}

export const ScreenTips: React.FC<ScreenTipsProps> = ({ minTip, showScreenTip, onMinTip, onButton, onClose }) => {
  // console.log('ScreenTips showScreenTip', showScreenTip)

  const { storeTips, updateStoreTips } = useTips()
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { 
        screen: 'CalendarAccounts',
        ...eventData },
    })
  }

  function updateTips (tip: CalendarAccountDetails): void {
    if (updateStoreTips) {
      updateStoreTips({
        calendarAccountDetails: {
          ...storeTips?.calendarAccountDetails, ...tip,
        },
      })
    }
  }

  return (
    <div>
      <TipMin
        show={minTip}
        onButton={() => {
          logTap({ component: 'TipMin', button: 'MinTip' })
          onMinTip && onMinTip()}} />
      <TipSelectCalendar
        show={!minTip && showScreenTip === CalendarAccountDetailsTips.selectCalendar}
        onClose={() => {
          logTap({ component: 'TipSelectCalendar', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipSelectCalendar', button: 'GotIt'})
          updateTips({ selectCalendar: true })
          onButton && onButton()
        }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onClose?: () => void;
  onButton?: () => void;
}

export const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      onClick={onButton} />
  )
}

export const TipSelectCalendar: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = `Select which of these calendars you want Moica to use to read when you are free or busy when scheduling.
  Selecting View Only allows you to see appointments on this calendar in Moica,
  but will not mark you as busy for those times.
  Disable means you won't see the appointments from this calendar, nor will meetings on this calendar mark you as busy in Moica`

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}
