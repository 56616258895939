import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonLabel, IonItem, IonItemGroup, IonToggle,
} from '@ionic/react'
import 'screens/Settings.css'
import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useProfilePhoto } from 'context/ProfilePhotoContext/ProfilePhotoContext'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { useUser } from 'context/UserContext/UserContext'
import { useDevices } from 'context/DevicesContext/DevicesContext'
import { useTips } from 'context/TipsContext/TipsContext'
import ListItem from 'components/atoms/ListItem/ListItem'
import { useTabs } from 'navigation/TabsContext'
import { ScreenTips } from './ScreenTips'
import { SettingsScreenTips } from 'types/componentTips'
import { SettingsScreen as StoreTips } from 'services/store/tips/types'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Settings'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const nameStyle: CSSProperties = {
  fontSize: 20,
}

interface SettingsProps {
  onCalendar: () => void
  onDevices: () => void
  onAbout: () => void
}

const Settings: React.FC<SettingsProps> = ({ onCalendar, onDevices, onAbout }) => {
  const [showScreenTip, setShowScreenTip] = useState(SettingsScreenTips.noTip)
  const [minTip, setMinTip] = useState(false)

  const { user } = useUser()
  const { devices = [] } = useDevices()
  const { initials, photo } = useProfilePhoto()
  const { calendars } = useCalendars()
  const { storeTips, updateStoreTips, enableAllTips } = useTips()
  const { setShowTabs } = useTabs()
  const { logEvent } = useAnalytics()
  // const [tipsEnabled, setTipsEnabled] = useState(tips ? !Object.keys(tips).length : true)

  useEffect(() => {
    startTips()
    setShowTabs && setShowTabs(true)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Settings' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Settings',
      },
    })
  }

  const componentStoreTips = storeTips?.settingsScreen

  function startTips (): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(SettingsScreenTips.noTip)
      } else {
        setNextTip(SettingsScreenTips.sequence)
      }
    }, 1000)
  }

  // useEffect(() => {
  //   if (Array.isArray(calendars)) {
  //     if (!calendars.length) {
  //       setShowTip(TipTypes.addCalendar)
  //     } else {
  //       setShowTip(TipTypes.noTip)
  //     }
  //   }
  // }, [calendars])

  function setNextTip (tip: SettingsScreenTips, restartTips?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      case SettingsScreenTips.calendars:
      case SettingsScreenTips.sequence:
        if (restartTips || !componentStoreTips?.calendars) {
          setShowScreenTip(SettingsScreenTips.calendars)

          break
        }
      // eslint-disable-next-line no-fallthrough
      case SettingsScreenTips.devices:
        if (!componentStoreTips?.devices) {
          setShowScreenTip(SettingsScreenTips.devices)

          break
        }
      // eslint-disable-next-line no-fallthrough
      case SettingsScreenTips.helpTips:
      case SettingsScreenTips.endTips:
        if (!componentStoreTips?.endTips) {
          setShowScreenTip(SettingsScreenTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case SettingsScreenTips.noTip:
      default:
        setShowScreenTip(SettingsScreenTips.endTips)
        setMinTip(true)
        break
    }
  }

  function updateTip (tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        settingsScreen: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  function renderTipsToggle (): JSX.Element {
    return (
      <IonItemGroup style={{ marginTop: 20 }}>
        <IonItem lines='full'>
          <IonLabel>Help Tips</IonLabel>
          <IonToggle
            data-ci-id='tips-toggle'
            color='success'
            checked={storeTips?.enabled}
            onIonChange={e => {
              const value = e.detail.checked

              logTap({ component: 'TipsToggle', button: value.toString() })
              updateStoreTips && updateStoreTips({ enabled: value, startMinTip: value })

              if (e.detail.checked) {
                enableAllTips && enableAllTips()
              } else {
                setNextTip(SettingsScreenTips.noTip)
              }
            }} />
        </IonItem>
      </IonItemGroup>
    )
  }

  function renderScreenTips (): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)

          // if we've not shown endTips then go ahead and start the sequence
          if (!componentStoreTips?.endTips) {
            setNextTip(SettingsScreenTips.sequence, true)
          }
        }}
        onButton={(restartTips) => {
          if (showScreenTip === SettingsScreenTips.endTips) {
            if (restartTips) {
              console.log('Restart TIPS!')
              setNextTip(SettingsScreenTips.sequence, true)
            } else {
              setNextTip(SettingsScreenTips.noTip)
              setMinTip(true)
            }
          } else {
            setNextTip(showScreenTip + 1)
          }
        }} />
    )
  }

  return (
    <IonPage>
      <ScreenHeader title={title} />
      <IonContent scrollY={false}>
        <div
          style={container}
          className='titleIconBox'>
          <IonHeader collapse='condense'>
            <IonToolbar>
              <IonTitle size='large'>{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          {user?.id && user?.name &&
            <IonItemGroup style={{ marginTop: 20 }}>
              <IonItem
                data-ci-id='account'
                routerLink='/tabs/settings/account'
                detail
                lines='none'>
                <ProfilePhoto
                  initials={initials}
                  photo={photo} />
                <IonLabel style={nameStyle}>
                  {user.name}
                </IonLabel>
              </IonItem>
            </IonItemGroup>}

          {renderTipsToggle()}

          <IonItemGroup style={{ marginTop: 20 }}>
            <ListItem
              data-ci-id='calendar-accounts'
              tip={!minTip && showScreenTip === SettingsScreenTips.calendars}
              detail
              label='Calendar Accounts'
              endLabel={(calendars && calendars.length > 0) ? `${calendars.length}` : undefined}
              onClick={() => {
                logTap({ component: 'ListItem', button: 'CalendarAccounts' })
                updateTip({ calendars: true })
                onCalendar()
              }} />

            <ListItem
              data-ci-id='authorized-devices'
              label='Authorized Devices'
              detail
              tip={!minTip && showScreenTip === SettingsScreenTips.devices}
              endLabel={(devices?.length > 0) ? `${devices.length}` : undefined}
              onClick={() => {
                logTap({ component: 'ListItem', button: 'AuthorizedDevices' })
                updateTip({ devices: true })
                onDevices()
              }} />

          </IonItemGroup>
          <IonItemGroup style={{ marginTop: 20 }}>
            <ListItem
              data-ci-id='about'
              detail
              label='About This App'
              onClick={() => {
                logTap({ component: 'ListItem', button: 'About' })
                onAbout()
              }} />
          </IonItemGroup>

        </div>
        {renderScreenTips()}
      </IonContent>
    </IonPage>
  )
}

export default Settings
