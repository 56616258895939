import moment from 'moment'
import { TimeSlot as TimeSlotType, TimeRange as TimeRangeType, User, MeetingTime } from 'types'

export type TimeMarker = {
  time: string;
  type: string; // start or end
  user?: string;
  timeSlot?: string;
}

function sortTimeMarkes (markers: TimeMarker[]): TimeMarker[] {
  return markers.slice().sort((a: TimeMarker, b: TimeMarker) => {
    const timeA = moment(a.time).valueOf()
    const timeB = moment(b.time).valueOf()

    if (timeA === timeB) {
      // if tow times are same then put end before start
      if (a.type === 'end') {
        return -1
      } else {
        return 1
      }
    } else {
      return timeA - timeB
    }
  })
}

export function getUpdatedSlots (
  timeSlots: TimeSlotType[], editSlot?: TimeSlotType):
  TimeSlotType[] | undefined {
  if (timeSlots.length) {
    // make a copy of the timeSlots for the day
    let slots = timeSlots.slice()

    if (editSlot) {
      // if we are editing a slot then replace the original slot with the one being
      // edited to calculate the time ranges
      if (editSlot.localId) {
        slots = slots.map(timeSlot => {
          if (timeSlot.localId === editSlot.localId) {
            return editSlot
          }

          return timeSlot
        })
      } else {
        slots = slots.map(timeSlot => {
          if (timeSlot.id === editSlot.id) {
            return editSlot
          }

          return timeSlot
        })
      }
    }

    return slots
  }
}

// the meeting timeRanges apply to multiple days so
// adjust the timeRanges for a specific day
export function adjustTimeRangesForDay (dayStartTime: string, timeRanges: TimeRangeType[]): TimeRangeType[] {
  return timeRanges.map(range => {
    const dayStart = moment(range.startTime).startOf('day')
    // calculate start and end minute markers
    const startMinutes = moment(range.startTime).diff(dayStart, 'minutes')
    const endMinutes = moment(range.endTime).diff(dayStart, 'minutes')

    // calculate start and end time relative to the dayStartTime
    return {
      startTime: moment(dayStartTime).add(startMinutes, 'minutes').toISOString(),
      endTime: moment(dayStartTime).add(endMinutes, 'minutes').toISOString(),
    }
  })
}

export function withinTimeRanges (time: string, timeRanges?: TimeRangeType[]):
TimeRangeType | undefined {
  if (timeRanges?.length) {
    const timeValue = moment(time).valueOf()
    const dayStartTime = moment(time).startOf('day').toISOString()

    const adjustedTimeRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
    const withinRange = adjustedTimeRanges.find(range => {
      const startRange = moment(range.startTime).valueOf()
      const endRange = moment(range.endTime).valueOf()

      // NOTE: don't include endRange exact time
      if (timeValue >= startRange && timeValue < endRange) {
        return true
      }
    })

    return withinRange
  }
}

export function withinDayRanges (startTime: string, endTime: string, dayRanges?: TimeRangeType[]): boolean {
  const startValue = moment(startTime).valueOf()
  const endValue = moment(endTime).valueOf()

  if (dayRanges?.length) {
    const withinRange = dayRanges.find(range => {
      const rangeStart = moment(range.startTime).valueOf()
      const rangeEnd = moment(range.endTime).valueOf()

      if ((startValue >= rangeStart && startValue < rangeEnd) ||
      (endValue > rangeStart && endValue < rangeEnd)) {
        return true
      }
    })

    return !!withinRange
  }

  return true
}

export function includesTimeRange (dayStartTime: string,
  startTime: string, endTime: string, timeRanges: TimeRangeType[]):
TimeRangeType | undefined {
  const startValue = moment(startTime).valueOf()
  const endValue = moment(endTime).valueOf()

  const adjustedTimeRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
  const includesRange = adjustedTimeRanges.find(range => {
    const startRange = moment(range.startTime).valueOf()
    const endRange = moment(range.endTime).valueOf()

    if (startValue <= startRange && endValue >= endRange) {
      return true
    }
  })

  return includesRange
}

export function getTimeRanges (args: {
  timeSlots: TimeSlotType[], user?: string; editSlot?: TimeSlotType, allowedTimeRanges?: TimeRangeType[]
}):TimeRangeType[] {
  const { user, timeSlots, editSlot, allowedTimeRanges } = args
  const timeRanges: TimeRangeType[] = []
  let timeMarkers: TimeMarker[] = []
  const dayMarkers: string[] = []

  const slots = getUpdatedSlots(timeSlots, editSlot)

  if (slots && slots.length) {
    // create a linear array of time markers

    slots.forEach(slot => {
      const dayStartTime = moment(slot.startTime).startOf('day').toISOString()

      if (dayMarkers.indexOf(dayStartTime) < 0) {
        dayMarkers.push(dayStartTime)
      }

      timeMarkers.push({
        time: slot.startTime,
        type: 'start',
        user: slot.user ? slot.user : 'default',
        timeSlot: (user && slot.user === user) ? slot.id : undefined,
      })

      // NOTE we can handle endTime's spanning midnight across multiple days
      // so don't restrict endTime
      timeMarkers.push({
        time: slot.endTime,
        type: 'end',
        user: slot.user ? slot.user : 'default',
      })
    })

    if (allowedTimeRanges?.length) {
      dayMarkers.forEach(dayStartTime => {
        const adjustedTimeRanges = adjustTimeRangesForDay(dayStartTime, allowedTimeRanges)

        adjustedTimeRanges.forEach(range => {
          timeMarkers.push({
            time: range.startTime,
            type: 'start',
            user: 'meeting',
          })
          timeMarkers.push({
            time: range.endTime,
            type: 'end',
            user: 'meeting',
          })
        })
      })
    }

    // sort the markers
    timeMarkers = sortTimeMarkes(timeMarkers)

    let range: TimeRangeType | undefined
    let startCount = 0

    // console.log('TIME MARKERS: ', timeMarkers)

    timeMarkers.forEach((marker) => {
      // console.log('Time Marker: ', marker)
      if (!range) {
        // can we assume first marker is start? what if it's across different time zones?
        // this is first marker so start a new range
        range = {
          startTime: marker.time,
          endTime: '',
          timeSlot: marker.timeSlot,
        }

        if (marker.user) range.participants = [marker.user]

        startCount = startCount + 1
      } else {
        // if the next slot is start
        if (marker.type === 'start') {
          if (!range.timeSlot) {
            range.timeSlot = marker.timeSlot
          }

          startCount = startCount + 1

          // if the next start time is same and current range start time
          // then don't end range just add the user to current range
          if (marker.time === range.startTime) {
            if (marker.user && range.participants) {
              range.participants.push(marker.user)
            }
          } else {
            // close the previous range at this marker
            range.endTime = marker.time
            timeRanges.push(range)

            // start a new range and carry forward participants
            const newRange: TimeRangeType = {
              startTime: marker.time,
              endTime: '',
              timeSlot: marker.timeSlot,
            }

            if (range.participants && marker.user) {
              newRange.participants = range.participants.concat([marker.user])
            }

            range = newRange
          }
        } else {
          // next slot is end
          startCount = startCount - 1

          if (marker.time !== range.startTime) {
            // if the next marker end time is different from start time
            // then make a new range
            // close the previous range at this marker
            range.endTime = marker.time

            timeRanges.push(range)

            if (range.participants && range.participants.length > 1 &&
            marker.user) {
            // start a new range and carry forward participants
            // minus the user from this marker
              const newRange: TimeRangeType = {
                startTime: marker.time,
                endTime: '',
                timeSlot: marker.timeSlot,
              }

              // just remove one instance of this user. Since we support overlapped
              // time ranges for the same user. We will dedupe the users at the end
              newRange.participants = range.participants.slice()
              // splice mutates the array in place
              newRange.participants.splice(range.participants.indexOf(marker.user), 1)
              range = newRange
            } else {
              if (startCount === 0) {
                range = undefined
              }
            }
          } else {
            // remove the user of this time marker from the current range
            if (range.participants && range.participants.length > 1 &&
              marker.user) {
              // just remove one instance of this user. Since we support overlapped
              // time ranges for the same user. We will dedupe the users at the end
              range.participants.splice(range.participants.indexOf(marker.user), 1)
            } else {
              if (startCount === 0) {
                range = undefined
              }
            }
          }
        }
      }
    })

    // console.log('RANGES WITH TIME RANGES: ', timeRanges)

    timeRanges.forEach(range => {
      if (range.participants) {
        // dedupe participant names in the ranges
        range.participants =
        range.participants.filter((p, index) => range.participants?.indexOf(p) === index)
      }
    })

    if (allowedTimeRanges?.length) {
      return timeRanges.filter(range => {
        // allow only those time ranges that have overlapping slot with 'meeting'
        if (range.participants?.length) {
          // dedupe participant names in the ranges
          const index = range.participants.indexOf('meeting')

          // remove the meeting user and use this timeSlot
          if (index > -1) {
            range.participants.splice(index, 1)

            // if after removing meeting timeRange we still have a participant left then
            // keep this time slot
            if (range.participants.length) {
              return true
            }
          }
        }
      })
    }
  }

  return timeRanges
}

export function findUserTimeRange (
  time: string,
  timeSlots: TimeSlotType[], editSlot?: TimeSlotType, user?: User):
  TimeRangeType | undefined {
  const selectedTime = moment(time).valueOf()
  let ranges = getTimeRanges({ timeSlots, editSlot })

  if (ranges.length && user && user.id) {
    ranges = ranges.filter(range => {
      const { participants } = range

      if (participants) {
        return participants.find(p => p === user.id)
      }
    })
  }

  return ranges.find(range => {
    const startTime = moment(range.startTime).valueOf()
    const endTime = moment(range.endTime).valueOf()

    // if the time is at the cusp of two ranges then prefer the range where the time
    // is inside the endTime
    if (selectedTime >= startTime && selectedTime < endTime) {
      return true
    }
  })
}

export function getUserTimeSlot (
  time: string, user: string, timeSlots: TimeSlotType[], editSlot?: TimeSlotType): TimeSlotType | undefined {
  // find if this user has a range from this time
  const selectedTime = moment(time).valueOf()

  // find timeSlot for this timeRange
  let timeSlot

  if (editSlot) {
    const startTime = moment(editSlot.startTime).valueOf()
    const endTime = moment(editSlot.endTime).valueOf()

    if (selectedTime >= startTime && selectedTime <= endTime) {
      timeSlot = editSlot
    }
  }

  if (!timeSlot && timeSlots && timeSlots.length) {
    timeSlot = timeSlots.find(t => {
      if (!user || t.user === user) {
        const startTime = moment(t.startTime).valueOf()
        const endTime = moment(t.endTime).valueOf()

        if (selectedTime >= startTime && selectedTime <= endTime) {
          if (user && t.user !== user) {
            return false
          }

          return true
        }
      }
    })
  }

  // console.log('Found user time slot ', timeSlot)

  return timeSlot
}

export function getUserMeetingTime (args: {
  startTime: string, endTime?: string; user: string, meetingTimes?: MeetingTime[]
}): MeetingTime | undefined {
  // find if this user has a meeting time from this time
  const { startTime, endTime, user, meetingTimes } = args

  const selectedStartTime = moment(startTime).valueOf()
  const selectedEndTime = endTime ? moment(endTime).valueOf() : undefined

  let meetingTime

  if (user && Array.isArray(meetingTimes) && meetingTimes.length) {
    const times = meetingTimes.filter(t => {
      if (t.participants.find(p => p === user)) {
        return true
      }
    })

    if (times.length) {
      meetingTime = times.find(t => {
        const start = moment(t.startTime).valueOf()
        const end = moment(t.endTime).valueOf()

        if (selectedEndTime) {
          if (selectedStartTime >= start && selectedStartTime <= end &&
            selectedEndTime >= start && selectedEndTime <= end) {
            return true
          }
        } else if (selectedStartTime >= start && selectedStartTime <= end) {
          return true
        }
      })
    }
  }

  // console.log('Found user time slot ', timeSlot)

  return meetingTime
}

export function getDisabledRanges (dayStartTime: string, timeRanges: TimeRangeType[]):
TimeRangeType[] {
  if (timeRanges && timeRanges.length) {
    const dayEndTime = moment(dayStartTime).add(24, 'hours').toISOString()
    const disabledRanges: TimeRangeType[] = []

    let startRange = dayStartTime

    const sortedTimeRanges = timeRanges.slice().sort((a, b) => {
      const timeA = moment(a.startTime).valueOf()
      const timeB = moment(b.startTime).valueOf()

      return timeA - timeB
    })

    sortedTimeRanges.forEach(range => {
      const dayStart = moment(range.startTime).startOf('day')
      // calculate start and end minute markers
      const startMinutes = moment(range.startTime).diff(dayStart, 'minutes')
      const endMinutes = moment(range.endTime).diff(dayStart, 'minutes')
      // calculate start and end time relative to the dayStartTime
      const startTime = moment(dayStartTime).add(startMinutes, 'minutes').toISOString()
      const endTime = moment(dayStartTime).add(endMinutes, 'minutes').toISOString()
      const startRangeTime = moment(startRange).valueOf()

      if (moment(startTime).valueOf() <= startRangeTime) {
        startRange = endTime
      } else {
        disabledRanges.push({
          startTime: startRange,
          endTime: startTime,
        })
        startRange = endTime
      }
    })

    if (moment(startRange).valueOf() < moment(dayEndTime).valueOf()) {
      disabledRanges.push({
        startTime: startRange,
        endTime: dayEndTime,
      })
    }

    return disabledRanges
  }

  return []
}

/*
// sort the slots chronologically
function sortSlots (slots: TimeSlotType[]): TimeSlotType[] {
  return slots.slice().sort((a: TimeSlotType, b: TimeSlotType) => {
    const timeA = a.startTime
    const timeB = b.startTime

    return compareTimes(timeA, timeB)
  })
}

// this is the core of the logic: find all overlapping time ranges
function getTimeRangesB (
  timeSlots: TimeSlotType[], editSlot: TimeSlotType):
  TimeRangeType[] {
  const timeRanges: TimeRangeType[] = []

  if (timeSlots.length) {
    // make a copy of the timeSlots for the day
    let slots = timeSlots.slice()

    if (editSlot) {
      // if we are editing a slot then replace the original slot with the one being
      // edited to calculate the time ranges
      if (editSlot.localId) {
        slots = slots.filter(timeSlot => timeSlot.localId !== editSlot.localId)
      } else {
        slots = slots.filter(timeSlot => timeSlot.id !== editSlot.id)
      }

      // console.log('Using edited slot: ', editSlot)
      slots.push(editSlot)
    }

    // sort the slots chronologically
    const sortedTimeSlots = sortSlots(slots)

    console.log(`Time Slots for day ${day}`)
    console.log(sortedTimeSlots)

    let startRange = sortedTimeSlots[0].startTime
    const lastSlot = sortedTimeSlots[sortedTimeSlots.length - 1]
    let lastChecked = -1

    // build all ranges recursively until end of the last slot
    while (moment(startRange).valueOf() < moment(lastSlot.endTime).valueOf()) {
      sortedTimeSlots.forEach((slotA, index) => {
        if (index > lastChecked) {
          while (moment(startRange).valueOf() < moment(slotA.endTime).valueOf()) {
            let endRange = slotA.endTime
            const participants = slotA.user ? [slotA.user] : []

            sortedTimeSlots.every((slotB, i) => {
              const startA = moment(startRange).valueOf()
              const endA = moment(endRange).valueOf()
              const startB = moment(slotB.startTime).valueOf()
              const endB = moment(slotB.endTime).valueOf()

              if (i > index && slotB.user && endB > startA) {
                if (startB < endA) {
                  if (startB <= startA) {
                    participants.push(slotB.user)

                    if (endB <= endA) {
                      endRange = slotB.endTime
                      lastChecked = i
                    }

                    return true
                  } else {
                    endRange = slotB.startTime
                    console.log('Setting endRange to slotB.startTime', endRange)
                  }
                }

                // done with this range
                return false
              }

              // continue checking
              return true
            })
            timeRanges.push({
              startTime: startRange,
              endTime: endRange,
              participants,
            })
            // move the startRange to the end of this range
            startRange = endRange

            // if we are at the end of the time slot start at the next time slot
            if (startRange === slotA.endTime && index < sortedTimeSlots.length - 1) {
              const nextIndex = lastChecked > 0 ? lastChecked + 1 : index + 1

              if (nextIndex < sortedTimeSlots.length) {
                if (startRange < sortedTimeSlots[nextIndex].startTime) {
                  startRange = sortedTimeSlots[nextIndex].startTime
                }
              }
            }
          }
        }
      })
    }
  }

  // console.log('TIME RANGES: ', timeRanges)

  return timeRanges
}
*/
