import React, { CSSProperties } from 'react'
import { IonLabel, IonText } from '@ionic/react'
import Avatar from '@material-ui/core/Avatar'

import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'

const container: CSSProperties = {
  display: 'flex',
  overflowX: 'hidden',
  flexWrap: 'nowrap',
  minWidth: 70,
  height: 70,
}

const avatarColor = '#92949C'

const avatarStyle = {
  width: 32,
  height: 32,
  backgroundColor: avatarColor,
  marginBottom: 5,
}

const MAX_NAME_LENGTH = 8

const UserDayScheduleTitle: React.FC = () => {
  const { user } = useUser()
  const { participants } = useParticipants()

  function renderUser (): JSX.Element | undefined {
    if (participants?.length && user?.id) {
      const participant = participants.find(p => p?.user === user.id)

      if (participant) {
        const { name, photo } = participant
        let displayName = name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
        const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

        // if initials is > 2 characters then just pick first and last
        const displayInitials = initials.length > 2
          ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

        if (participant.user === user?.id) {
          displayName = 'You'
        }

        return (
          <ProfileBox>
            <Avatar
              src={photo}
              style={avatarStyle}>
              <IonText style={{ fontSize: 18 }}>
                {displayInitials}
              </IonText>
            </Avatar>
            <IonLabel>{displayName}</IonLabel>
          </ProfileBox>
        )
      }
    }
  }

  if (participants?.length) {
    return (
      <div
        style={container}
        className='CalendarABorderRight'>
        {renderUser()}
      </div>
    )
  }

  return null
}

const ProfileBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: 70,
    width: 70,
    height: 70,
    position: 'relative',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

export default UserDayScheduleTitle
