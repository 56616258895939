import React, { CSSProperties, useState, useEffect } from 'react'
import {
  IonLabel, IonItem, IonIcon, IonText, IonItemGroup, IonSpinner,
} from '@ionic/react'

import 'screens/Settings.css'
import { CalendarAccount, SubCalendar } from 'types'
import { ellipse, checkmarkCircle, eye, eyeOff, star } from 'ionicons/icons'
import { CalendarService } from 'services/api'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { logoColor } from 'theme/styles'

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: 20,
}

export interface CalendarItemProps {
  calendar: CalendarAccount;
  selected?: SubCalendar[];
  writable?: boolean;
  selectBookCalendar?: boolean;
  onSelect: (cal: SubCalendar) => void;
}

const CalendarItem: React.FC<CalendarItemProps> = ({ calendar, selected, writable, selectBookCalendar, onSelect }) => {
  const { service, account } = calendar
  const [loading, setLoading] = useState(false)
  const [subCalendars, setSubCalendars] = useState<SubCalendar[]>()

  const { getCalendarDetails } = useCalendars()

  async function getCalendars (): Promise<void> {
    let calendars = await getCalendarDetails(calendar, writable)

    if (writable) {
      // if writable then only use ones that have been marked freeBusy
      calendars = calendars.filter(cal => cal.enabled && cal.freeBusy)
    }

    const primaryIndex = calendars.findIndex(c => c.id === account)

    if (primaryIndex > -1) {
      calendars.unshift(calendars.splice(primaryIndex, 1)[0])
    }

    setSubCalendars(calendars)
    setLoading(false)
  }
  useEffect(() => {
    if (service === CalendarService.Google) {
      setLoading(true)
      getCalendars()
    }
  }, [])

  function renderSubCalendar (subCalendar: SubCalendar, last: boolean): JSX.Element {
    const cal = selected?.find(cal => cal.id === subCalendar.id && cal.account === subCalendar.account)

    function note (): string | undefined {
      if (cal && !selectBookCalendar) {
        const { enabled, freeBusy } = cal

        if (enabled && freeBusy) {
          return 'free-busy'
        } else if (enabled && !freeBusy) {
          return 'view-only'
        } else {
          return 'ignore'
        }
      }
    }

    function icons (): JSX.Element | undefined {
      if (cal) {
        const { enabled, freeBusy } = cal

        if (selectBookCalendar) {
          if (enabled) {
            return (
              <IonIcon
                slot='end'
                style={{ fontSize: 22, color: logoColor }}
                icon={star} />
            )
          }
        } else {
          if (enabled && freeBusy) {
            return (
              <IonIcon
                slot='end'
                color='primary'
                style={{ fontSize: 22 }}
                icon={checkmarkCircle} />
            )
          } else if (enabled && !freeBusy) {
            return (
              <IonIcon
                slot='end'
                color='primary'
                style={{ fontSize: 22 }}
                icon={eye} />
            )
          } else {
            return (
              <IonIcon
                slot='end'
                color='medium'
                style={{ fontSize: 22 }}
                icon={eyeOff} />
            )
          }
        }
      }
    }

    return (
      <IonItem
        key={subCalendar.id}
        button
        detail={false}
        lines={last ? 'full' : 'inset'}
        onClick={() => onSelect(subCalendar)}>
        <IonIcon
          slot='start'
          size='small'
          style={{ color: subCalendar.backgroundColor }}
          icon={ellipse} />
        <IonLabel>
          {subCalendar.name}
        </IonLabel>
        <IonText
          color='medium'
          slot='end'>
          {note()}
        </IonText>
        {icons()}
      </IonItem>
    )
  }

  function renderSubCalendars (): JSX.Element[] | undefined {
    if (subCalendars && subCalendars.length) {
      const items = subCalendars.filter(subCalendar => {
        const cal = selected?.find(cal => cal.id === subCalendar.id && cal.account === subCalendar.account)

        // first check if the meeting specific calendar has been enabled by the participant
        if (cal && (cal.enabled || cal.freeBusy)) {
          return true
        }

        // if not per meeting then check if the calendar has been enabled globally  or
        // if it's not hidden and selected
        return subCalendar.enabled || subCalendar.freeBusy || (!subCalendar.hidden && subCalendar.selected)
      })

      return items.map((subCalendar, i) => renderSubCalendar(subCalendar, i === items.length - 1))
    }
  }

  const title = account.split('@')[1].split('.')[0].toUpperCase()

  // console.log('CalendarItem: selected: ', selected)

  if (subCalendars?.length) {
    return (
      <div>
        <IonText
          color='medium'
          style={titleStyle}>
          {title}
        </IonText>
        {loading &&
          <IonItem
            lines='full'>
            <IonLabel color='secondary'>
              <IonSpinner
                name='dots' />
            </IonLabel>

          </IonItem>}
        <IonItemGroup style={{ marginTop: 10, marginBottom: 20 }}>
          {renderSubCalendars()}
        </IonItemGroup>
      </div>
    )
  }

  return null
}

export default CalendarItem
