import React, { CSSProperties } from 'react'
import { IonText, IonItem, IonLabel, isPlatform, IonSpinner, IonIcon, IonButton } from '@ionic/react'
import TipTarget from '../TipTarget/TipTarget'
import { useTips } from 'context/TipsContext/TipsContext'
import Icon from '@mdi/react'
// https://materialdesignicons.com/

const titleStyle: CSSProperties = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

// const labelStyle: CSSProperties = {
//   fontSize: 18,
// }

const container: CSSProperties = {
  marginTop: 20,
}

const labelStyle: CSSProperties = {
  fontSize: 16,
  overflow: 'visble',
}

const labelTextStyle: CSSProperties = {
  whiteSpace: 'pre-wrap',
}

export type ItemIcon = {
  name: string
  color?: string
  slot: string
  activity?: boolean
  style?: CSSProperties
  size?: string
  type?: string
  onClickIcon?: () => void
}

interface ContainerProps {
  style?: CSSProperties
  testId?: string
  title?: string
  label?: string
  subLabel?: string
  endLabel?: string
  icon?: ItemIcon
  lines?: 'full' | 'inset' | 'none'
  detail?: boolean
  tip?: boolean
  textWrap?: boolean
  onClick?: () => void
}

const ListItem: React.FC<ContainerProps> = (
  { style, testId, title, label, icon, lines = 'full', subLabel, endLabel, tip, detail, textWrap, onClick }) => {
  const { storeTips } = useTips()

  function renderIcon (): JSX.Element | undefined {
    if (icon) {
      const { type, name, color, slot, activity = false, style, size, onClickIcon } = icon

      if (activity) {
        return (
          <IonSpinner
            slot={slot}
            color='medium'
            name='dots' />
        )
      }

      if (type === 'mdi') {
        return (
          <Icon
            path={name}
            size={1}
            color={color} />
        )
      }

      return (
        <IonButton
          fill='clear'
          onClick={onClickIcon}>
          <IonIcon
            style={style}
            size={size}
            slot={slot}
            color={color}
            icon={name} />
        </IonButton>
      )
    }
  }

  const boxStyle = style || (title ? container : {})
  const itemStyle: CSSProperties = title ? { marginTop: 5 } : {}

  if (tip && storeTips?.enabled) {
    itemStyle.overflow = 'visible'
    itemStyle.border = '2px solid #3880ff'
  }

  return (
    <div style={boxStyle}>
      {title &&
        <ItemTitle title={title} />}
      <IonItem
        {...(testId && { 'data-ci-id': testId })}
        lines={lines}
        detail={detail}
        onClick={onClick}
        style={itemStyle}>
        {tip &&
          <TipTarget
            style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
        <IonLabel
          style={labelStyle}
          className={textWrap ? 'ion-text-wrap' : undefined}>
          <IonText style={labelTextStyle}>
            {label}
          </IonText>
          <p>
            <IonText
              color='medium'
              style={labelTextStyle}>
              {subLabel}
            </IonText>
          </p>
        </IonLabel>
        {endLabel &&
          <IonText
            slot='end'
            color='medium'>
            {endLabel}
          </IonText>}
        {/* {tip &&
          <IonText slot='end'>
            <TipTarget style={{ marginRight: 10 }} />
          </IonText>} */}
        {renderIcon()}
      </IonItem>
    </div>
  )
}

interface TitleProps {
  title?: string;
}

export const ItemTitle: React.FC<TitleProps> = ({ title }) => {
  return (
    <IonText
      style={titleStyle}
      color='medium'>
      {title?.toUpperCase()}
    </IonText>
  )
}
export default ListItem
