import React, { useState } from 'react'
import { IonText, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { Meeting, MeetingTime, TimeRange } from 'types'
import { trendingUp, checkmarkCircleOutline, checkmarkCircle, removeCircle } from 'ionicons/icons'
import moment from 'moment'

import { ItemTitle } from 'components/atoms/ListItem/ListItem'

export type ItemIcon = {
  name: string;
  color: string;
  slot: string;
  activity?: boolean;
}

interface ContainerProps {
  user: string;
  meeting: Meeting;
  meetingTime?: MeetingTime;
  timeRange?: TimeRange;
  busy?: boolean;
  onAction?: () => void;
}

const MeetingTimeItemDetails: React.FC<ContainerProps> = (
  { user, meetingTime, timeRange, busy }) => {
  const startTime = meetingTime?.startTime || timeRange?.startTime || ''
  const endTime = meetingTime?.endTime || timeRange?.endTime || ''
  const status = meetingTime?.status

  // Note: on android the list has a small padding at the bottom
  // and since we are using a slight shaded background for the select button
  // get rid of the bottom padding
  const active = meetingTime?.participants ? !!meetingTime?.participants?.find(u => u === user)
    : !!timeRange?.participants?.find(u => u === user)
  const tentative = status === 'tentative'
  const trending = status === 'trending'

  const [confirmed, setConfirmed] = useState(status === 'confirmed')

  function renderLabel (): JSX.Element | undefined {
    const day = moment(startTime).format('ddd, MMM D')
    const start = moment(startTime).format('h:mm a')
    const end = moment(endTime).format('h:mm a')

    return (
      <IonLabel>
        <h2 style={{ fontWeight: 'bold' }}>{day}</h2>
        <p>
          {start} to {end}
        </p>
      </IonLabel>
    )
  }

  function renderChoice (): JSX.Element | undefined {
    if (timeRange) {
      if (busy) {
        return (
          <IonItem
            color='transparent'
            lines='none'>
            <IonIcon
              icon={removeCircle}
              color='danger'
              slot='start' />
            {renderLabel()}
          </IonItem>
        )
      }

      return (
        <IonItem
          color='transparent'
          lines='none'>
          {/* {active &&
            <IonIcon
              color='primary'
              icon={checkmarkCircle}
              slot='start' />} */}
          {renderLabel()}
        </IonItem>
      )
    }
  }

  function renderTrending (): JSX.Element | undefined {
    if (trending) {
      return (
        <IonItem
          color='transparent'
          lines='none'>
          <IonIcon
            icon={trendingUp}
            color={active ? 'secondary' : 'medium'}
            slot='start' />
          {renderLabel()}
          <IonText
            slot='end'
            color='secondary'>
            trending
          </IonText>
        </IonItem>
      )
    }
  }

  function renderTentative (): JSX.Element | undefined {
    if (tentative) {
      return (
        <IonItem
          color='transparent'
          lines='none'>
          <IonIcon
            icon={trendingUp}
            color={active ? 'warning' : 'medium'}
            slot='start' />
          {renderLabel()}
          <IonText
            slot='end'
            color='warning'>
            trending
          </IonText>
        </IonItem>
      )
    }
  }

  function renderConfirmed (): JSX.Element | undefined {
    if (confirmed) {
      return (
        <IonItem
          color='transparent'
          lines='none'>
          <IonIcon
            icon={active ? checkmarkCircle : checkmarkCircleOutline}
            color={active ? 'success' : 'medium'}
            slot='start' />
          {renderLabel()}
          <IonText
            slot='end'
            color='success'>
            confirmed
          </IonText>
        </IonItem>
      )
    }
  }

  // function renderConfirmTime (): JSX.Element | undefined {
  //   if (meeting?.createdBy === user) {
  //     return (
  //       <IonItem
  //         lines='full'>
  //         <IonLabel>confirm this time</IonLabel>
  //         <IonToggle
  //           color='success'
  //           slot='end'
  //           checked={confirmed}
  //           onIonChange={e => {
  //             console.log('set confirm time ', e.detail.checked)
  //             setConfirmed(e.detail.checked)
  //             onConfirm(e.detail.checked)
  //           }} />
  //       </IonItem>
  //     )
  //   }
  // }

  // async function onConfirm (confirm: boolean): Promise<void> {
  //   if (updateMeetingTimes && meeting?.id) {
  //     let input: UpdateMeetingTimesInput

  //     if (confirm) {
  //       const participants = meetingTime?.participants || timeRange?.participants || []

  //       input = {
  //         id: meeting.id,
  //         meetingTimes: [
  //           {
  //             startTime,
  //             endTime,
  //             participants,
  //             status: 'confirmed',
  //             updatedBy: user,
  //           },
  //         ],
  //       }
  //       updateMeetingTimes(input)

  //       if (!active) {
  //         onAvailable()
  //       }
  //     } else {
  //       input = {
  //         id: meeting.id,
  //         meetingTimes: [],
  //       }
  //       updateMeetingTimes(input)
  //     }

  //     onAction && onAction()
  //     // console.log('Creating time slots with server: ', input)
  //   }
  // }

  // async function onAvailable (): Promise<void> {
  //   if (meetingTime && timeSlots && deleteTimeSlot) {
  //     const startValue = moment(startTime).valueOf()
  //     const endValue = moment(endTime).valueOf()

  //     // find out if there are any blocked timeSlots for this time range
  //     const timeSlot = timeSlots.find(slot => {
  //       if (slot.user === user && slot.id) {
  //         const start = moment(slot.startTime).valueOf()
  //         const end = moment(slot.endTime).valueOf()

  //         // valid overlap

  //         if (start < endValue && end > startValue) {
  //           return true
  //         }
  //       }
  //     })

  //     if (timeSlot?.id && !timeSlot.available) {
  //       const input: DeleteTimeSlotInput = {
  //         meeting: meeting.id,
  //         id: timeSlot.id,
  //       }

  //       // console.log('Delete time slots with server: ', input)

  //       // console.log('Creating time slots with server: ', input)
  //       // setAvailableActivity(true)
  //       await deleteTimeSlot(input)
  //       // setAvailableActivity(false)
  //       onAction && onAction()

  //       return
  //     }

  //     // if not found then drop down and create a timeSlot
  //   }

  //   if (busy && deleteTimeSlot && timeSlots) {
  //     // they are the same so just go ahead and delete the slot
  //     if (timeRange?.timeSlot) {
  //       const input: DeleteTimeSlotInput = {
  //         meeting: meeting.id,
  //         id: timeRange?.timeSlot,
  //       }

  //       // console.log('Delete time slots with server: ', input)

  //       // console.log('Creating time slots with server: ', input)
  //       // setAvailableActivity(true)
  //       await deleteTimeSlot(input)
  //       // setAvailableActivity(false)
  //       onAction && onAction()
  //     }
  //   } else if (createTimeSlot) {
  //     const input: CreateTimeSlotInput = {
  //       meeting: meeting.id,
  //       startTime,
  //       endTime,
  //       available: true,
  //     }

  //     // console.log('Creating time slots with server: ', input)
  //     // setAvailableActivity(true)
  //     await createTimeSlot(input)
  //     // setAvailableActivity(false)
  //     onAction && onAction()
  //   }
  // }

  return (
    <div>
      <div style={{ textAlign: 'left', marginTop: 5 }}>
        <ItemTitle title='availability' />
      </div>
      {renderChoice()}
      {renderTrending()}
      {renderTentative()}
      {renderConfirmed()}
    </div>
  )
}

export default MeetingTimeItemDetails
