import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'
import { updateScreens } from 'services/store/screens/actions'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import AddParticipants from './AddParticipants'
import ConnectionsProvider from 'context/Connections/ConnectionsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'

const AddParticipantsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { addParticipants } = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  function onInvite (): void {
    if (addParticipants?.meeting) {
      dispatch(updateScreens({ shareMeetingInvite: { meeting: addParticipants?.meeting } }))
      history.push('/tabs/meetings/shareMeetingInvite')
    }
  }

  function goBack (): void {
    history.goBack()
  }

  function onAdd (): void {
    if (addParticipants?.nextScreen) {
      history.push(addParticipants?.nextScreen)
    } else {
      history.goBack()
    }
  }

  return (
    <ConnectionsProvider>
      <InviteesProvider meeting={addParticipants?.meeting}>
        <MeetingsProvider meeting={addParticipants?.meeting}>
          <ParticipantsProvider meeting={addParticipants?.meeting}>
            <StoreParticipantsProvider>
              {addParticipants?.meeting &&
                <AddParticipants
                  onInvite={onInvite}
                  onAdd={onAdd}
                  goBack={goBack}
                  goBackTo={addParticipants?.previous} />}
            </StoreParticipantsProvider>
          </ParticipantsProvider>
        </MeetingsProvider>
      </InviteesProvider>
    </ConnectionsProvider>
  )
}

export default AddParticipantsScreen
