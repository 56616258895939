import React, { useState, CSSProperties } from 'react'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { bulb, home, cog } from 'ionicons/icons'
import { Redirect, Route, Switch } from 'react-router'

import Meetings from 'screens/Meetings'
import Connections from 'screens/Connections'
import Settings from 'screens/Settings'
import Account from 'screens/Account'
import CalendarAccounts from 'screens/CalendarAccounts'
import ConnectCalendar from 'screens/ConnectCalendar'
import FirstMeeting from 'screens/FirstMeeting'
import AddContactFirebase from 'screens/AddContactFirebase'
import LoginFirebase from 'screens/LoginFirebase'
import MeetingSettings from 'screens/MeetingSettings'
import Invitations from 'screens/Invitations'
import MeetingParticipants from 'screens/MeetingParticipants'
import MeetingInvite from 'screens/MeetingInvite'
import ChooseAvailability from 'screens/ChooseAvailability'
import DayAvailability from 'screens/DayAvailability'
import Devices from 'screens/Devices'
import AddParticipants from 'screens/AddParticipants'
import MeetWithUser from 'screens/MeetWithUser'
import About from 'screens/About'

import TabsContext from './TabsContext'
import PastMeetingsScreen from 'screens/PastMeetings'
import AskConnectCalendars from 'screens/AskConnectCalendars'
import NewUserAddContact from 'screens/NewUserAddContact'
import paths from './paths'
import TipsList from 'screens/TipsList'
import TipsAvailabilityCalendar from 'screens/TipsAvailabilityCalendar'
import TipsConnectCalendars from 'screens/TipsConnectCalendars'
import TipsGooglePermissions from 'screens/TipsGooglePermissions'
import CalendarSettingsFreeBusy from 'screens/CalendarSettingsFreeBusy'
import CalendarSettingsBookCalendar from 'screens/CalendarSettingsBookCalendar'
import CalendarSettingsAutoTimes from 'screens/CalendarSettingsAutoTimes'
import CalendarSettingsDoneAddAnother from 'screens/CalendarSettingsDoneAddAnother'
import TipsConfigureCalendar from 'screens/TipsConfigureCalendar'
import TipsAvailabilityCalendarAuto from 'screens/TipsAvailabilityCalendarAuto'
import MeetingCalendarSettings from 'screens/MeetingCalendarSettings'
import MeetingDescription from 'screens/MeetingDescription'
import { EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

type AppTabDef = {
  tab: string;
  href: string;
  icon: string;
  label: string;
  show: boolean;
  style?: CSSProperties
}

export const AppTabs: React.FC = () => {
  const [showTabs, setShowTabs] = useState(true)
  const { logEvent } = useAnalytics()

  console.log('AppTabs: SHOW TABS', showTabs)

  const tabDefs = (): AppTabDef[] => [
    {
      tab: 'meetings',
      href: paths.meetingsList,
      icon: home,
      label: 'Meetings',
      show: true,
    },
    // {
    //   tab: 'connections',
    //   href: paths.connections,
    //   icon: peopleCircleOutline,
    //   label: 'People',
    //   show: true,
    // },
    {
      tab: 'tips',
      href: paths.tipsList,
      icon: bulb,
      label: 'Tips',
      show: true,
    },
    {
      tab: 'settings',
      href: '/settings',
      icon: cog,
      label: 'Settings',
      show: true,
    },
  ]
  const tabs = tabDefs()
    .filter(tabDef => tabDef.show)
    .map((tabDef) => (
      <IonTabButton
        key={tabDef.tab}
        tab={tabDef.tab}
        href={tabDef.href}>
        <IonIcon
          icon={tabDef.icon}
          size='medium'
          style={tabDef.style} />
        <IonLabel>{tabDef.label}</IonLabel>
      </IonTabButton>
    ))

  return (
    <TabsContext.Provider value={{ showTabs, setShowTabs }}>
      <IonTabs
        onIonTabsDidChange={(event) => {
          const tab = tabDefs().find(t => t.tab === event.detail.tab)

          if (tab) {
            logEvent({
              eventName: EventName.buttonTap,
              eventData: {
                screen: 'AppTabs',
                button: tab.label,
              },
            })
          }
        }}>
        <IonRouterOutlet>
          <Switch>
            <Route
              path={paths.login}
              component={LoginFirebase}
              exact />
            <Route
              path={paths.newUserAddContact}
              component={NewUserAddContact}
              exact />
            <Route
              path={paths.addContact}
              component={AddContactFirebase}
              exact />
            <Route
              path={paths.startFirstMeeting}
              component={FirstMeeting}
              exact />
            <Route
              path={paths.meetingsList}
              component={Meetings}
              exact />
            <Route
              path={paths.meeting}
              component={Meetings}
              exact />
            <Route
              path={paths.pastMeetings}
              component={PastMeetingsScreen}
              exact />
            <Route
              path={paths.connections}
              component={Connections}
              exact />
            <Route
              path={paths.settings}
              component={Settings}
              exact />
            <Route
              path={paths.meetingSettings}
              component={MeetingSettings}
              exact />
            <Route
              path={paths.meetingDescription}
              component={MeetingDescription}
              exact />
            <Route
              path={paths.meetingCalendarSettings}
              component={MeetingCalendarSettings}
              exact />
            <Route
              path={paths.invitationsList}
              component={Invitations}
              exact />
            <Route
              path={paths.tipsList}
              component={TipsList}
              exact />
            <Route
              path={paths.tipsAvailabilityCalendar}
              component={TipsAvailabilityCalendar}
              exact />
            <Route
              path={paths.tipsConnectCalendars}
              component={TipsConnectCalendars}
              exact />
            <Route
              path={paths.tipsGooglePermissions}
              component={TipsGooglePermissions}
              exact />
            <Route
              path={paths.tipsConfigureCalendar}
              component={TipsConfigureCalendar}
              exact />
            <Route
              path={paths.tipsAvailabilityCalendarAuto}
              component={TipsAvailabilityCalendarAuto}
              exact />
            <Route
              path='/tabs/meetings/meetingParticipants'
              component={MeetingParticipants}
              exact />
            <Route
              path={paths.addParticipants}
              component={AddParticipants}
              exact />
            <Route
              path={paths.askConnectCalendar}
              component={AskConnectCalendars}
              exact />
            <Route
              path='/tabs/meetings/shareMeetingInvite'
              component={MeetingInvite}
              exact />
            <Route
              path={paths.inviteAuthenticated}
              component={Invitations}
              exact />
            <Route
              path='/tabs/meetings/meet/:moicaID'
              component={MeetWithUser}
              exact />
            <Route
              path={paths.chooseAvailability}
              component={ChooseAvailability}
              exact />
            <Route
              path='/tabs/meetings/dayAvailability'
              component={DayAvailability}
              exact />
            <Route
              path='/tabs/settings/account'
              component={Account}
              exact />
            <Route
              path='/tabs/settings/about'
              component={About}
              exact />
            <Route
              path='/tabs/settings/calendarAccounts'
              component={CalendarAccounts}
              exact />
            <Route
              path={paths.connectCalendar}
              component={ConnectCalendar}
              exact />
            <Route
              path={paths.calendarSettingsFreeBusy}
              component={CalendarSettingsFreeBusy}
              exact />
            <Route
              path={paths.calendarSettingsBookCalendar}
              component={CalendarSettingsBookCalendar}
              exact />
            <Route
              path={paths.calendarSettingsAutoTimes}
              component={CalendarSettingsAutoTimes}
              exact />
            <Route
              path={paths.calendarSettingsDoneAddAnother}
              component={CalendarSettingsDoneAddAnother}
              exact />
            <Route
              path='/tabs/settings/devices'
              component={Devices}
              exact />
            <Route path='*'>
              <Redirect to={paths.meetingsList} />
            </Route>
          </Switch>
          <Redirect
            exact
            from='/'
            to={paths.meetingsList} />
          <Redirect
            exact
            from={paths.invite}
            push
            to={paths.inviteAuthenticated} />
          <Redirect
            exact
            from='/meet/:moicaID'
            push
            to='/tabs/meetings/meet/:moicaID' />
          <Redirect
            from='/start'
            to={paths.meetingsList} />
        </IonRouterOutlet>
        <IonTabBar
          slot='bottom'
          style={showTabs ? {} : { display: 'none' }}>
          {tabs}
        </IonTabBar>
      </IonTabs>
    </TabsContext.Provider>
  )
}
