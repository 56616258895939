import React, { CSSProperties } from 'react'
import { IonIcon, IonLabel, IonText } from '@ionic/react'

import { closeOutline } from 'ionicons/icons'

import { Connection } from 'types'

import Avatar from '@material-ui/core/Avatar'

const avatarColor = '#92949C'
const container: CSSProperties = {
  display: 'flex',
  overflowX: 'auto',
  flexWrap: 'nowrap',
  paddingLeft: 10,
  borderBottom: '0.5px solid gray',
}

const avatarStyle = {
  width: 44,
  height: 44,
  backgroundColor: avatarColor,
  marginBottom: 5,
}

const MAX_NAME_LENGTH = 8

interface ComponentProps {
  connections: Connection[]
  onRemove: (connection: Connection) => void
}

const AddParticipantsRow: React.FC<ComponentProps> = ({ connections, onRemove }) => {
  if (connections?.length) {
    return (
      <div
        style={container}>
        {connections.map((connection: Connection, i: number) => {
          const { name, photo } = connection
          const displayName = name.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH)
          const initials = name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

          // if initials is > 2 characters then just pick first and last
          const displayInitials = initials.length > 2
            ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials

          return (
            <ProfileBox key={i}>
              <Avatar
                src={photo}
                style={avatarStyle}>
                <IonText style={{ fontSize: 18 }}>
                  {displayInitials}
                </IonText>
              </Avatar>
              <IonLabel>{displayName}</IonLabel>
              <RemoveIcon onClick={() => onRemove(connection)} />
            </ProfileBox>
          )
        })}
      </div>
    )
  }

  return null
}

const ProfileBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    marginRight: 15,
    width: 60,
    height: 70,
    position: 'relative',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

interface RemoveProps {
  onClick: () => void
}

const RemoveIcon: React.FC<RemoveProps> = ({ onClick }) => {
  const container: CSSProperties = {
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 1,
  }

  return (
    <div
      style={container}
      onClick={onClick}>
      <IconCircle />
    </div>
  )
}

const IconCircle: React.FC = () => {
  const container: CSSProperties = {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: 'gray',
    border: '1px solid',
    borderColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const icon: CSSProperties = {
    color: 'white',
    fontSize: 16,
  }

  return (
    <div
      style={container}>
      <IonIcon
        icon={closeOutline}
        style={icon} />
    </div>
  )
}

export default AddParticipantsRow
