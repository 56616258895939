import React from 'react'

import 'screens/Settings.css'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import MeetingActionsModal from './MeetingActionsModal'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'

interface ContainerProps {
  meeting: string;
  onEdit: (meeting: string) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingActionsModalScreen: React.FC<ContainerProps> = ({ meeting, onEdit, onClose, presentingElement }) => {
  console.log('MeetingActionsModal for meeting: ', meeting)

  return (
    <MeetingsProvider meeting={meeting}>
      <ParticipantsProvider meeting={meeting}>
        <TimeSlotsProvider meeting={meeting}>
          <MeetingActionsModal
            onEdit={onEdit}
            onClose={onClose}
            presentingElement={presentingElement} />
        </TimeSlotsProvider>
      </ParticipantsProvider>
    </MeetingsProvider>
  )
}

export default MeetingActionsModalScreen
