import React, { CSSProperties, useRef, useEffect, useState } from 'react'
import {
  IonActionSheet,
  IonBackdrop,
  IonContent, IonFooter, IonPage, IonText,
} from '@ionic/react'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import FirstInviteCard from 'components/organisms/FirstInviteCard/FirstInviteCard'
import { useMeetingInvite } from 'context/MeetingInviteContext/MeetingInviteContext'
import { InvitationStatus, UpdateInvitationInput } from 'services/api'
import LoginModal from 'components/organisms/LoginModal'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'
import { getAccounts } from 'services/accounts'

const titleBox: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

const welcomeText: CSSProperties = {
  fontSize: 20,
  textAlign: 'center',
}

interface ComponentProps {
  invitation: string;
  onNewUser: () => void;
  onLogin: (verifyToken: string) => void;
  onDecline: () => void;
  onSelectAccounts: () => void
}

const StartInvite: React.FC<ComponentProps> = ({ invitation, onNewUser, onLogin, onSelectAccounts, onDecline }) => {
  const [showTip, setShowTip] = useState('')
  const [showLogin, setShowLogin] = useState(false)
  const [showDeclineActions, setShowDeclineActions] = useState(false)

  const { meetingInvite, updateInvitation, error } = useMeetingInvite()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  function updateInvitationStatus (status: InvitationStatus): void {
    if (invitation) {
      const input: UpdateInvitationInput = {
        id: invitation,
        status,
      }

      updateInvitation(input)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      updateInvitationStatus(InvitationStatus.seen)
      setShowTip('start')
    }, 1000)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'StartInvite' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'StartInvite',
      },
    })
  }

  function onAccept (): void {
    setShowTip('')
    setShowLogin(true)

    const storeAccounts = getAccounts()?.filter(a => a.moicaID)

    if (storeAccounts?.length) {
      onSelectAccounts()
    } else {
      setShowLogin(true)
    }
  }

  function onDeclineInvitation (): void {
    updateInvitationStatus(InvitationStatus.declined)
    onDecline()
  }

  // async function startLogin (): Promise<void> {
  //   if (getVerifyToken) {
  //     const verifyToken = await getVerifyToken()

  //     if (verifyToken) {
  //       console.log('GOT VERIFY TOKEN: ', verifyToken)
  //       onLogin(verifyToken)
  //     }
  //   }
  // }

  function renderInvite (): JSX.Element | undefined {
    if (meetingInvite) {
      return (
        <FirstInviteCard
          invite={meetingInvite}
          onAccept={() => {
            logTap({ component: 'FirstInviteCard', button: 'Accept' })
            onAccept()
          }}
          onDecline={() => {
            logTap({ component: 'FirstInviteCard', button: 'Decline' })
            setShowDeclineActions(true)
          }}
          tip={showTip} />
      )
    }
  }

  function renderError (): JSX.Element | undefined {
    if (error) {
      return (
        <ScreenCenter height='80%'>
          <IonText style={{ fontSize: 32 }}>
            🙁 <br />
          </IonText>
          {error}
        </ScreenCenter>
      )
    }
  }

  function renderMinTip (): JSX.Element | undefined {
    return (
      <TipMinButton
        show={showTip === 'minTip'}
        onClick={() => setShowTip('start')} />
    )
  }

  function renderTitle (): JSX.Element {
    return (
      <div style={titleBox}>
        <IonText
          color='dark'
          style={welcomeText}>
          <span style={{ fontWeight: 'bold', fontSize: 22 }}>Moica</span><br />
        </IonText>
      </div>
    )
  }

  if (error) {
    const showError = error === 'inviteNotFound' ? 'Invite not found' : 'Error!'

    return (
      <IonPage>
        <IonContent>
          {renderTitle()}
          <ScreenCenter height='80%'>
            <IonText style={{ fontSize: 32 }}>
              🙁 <br />
            </IonText>
            {showError}
          </ScreenCenter>
        </IonContent>
      </IonPage>
    )
  }

  function renderLoginModal (): JSX.Element | undefined {
    if (showLogin) {
      return (
        <LoginModal
          onNewUser={() => {
            logTap({ component: 'LoginModal', button: 'NewUser' })
            setShowLogin(false)
            onNewUser()
          }}
          onLogin={verifyToken => {
            logTap({ component: 'LoginModal', button: 'Login' })
            setShowLogin(false)
            onLogin(verifyToken)
          }}
          presentingElement={pageRef.current}
          onClose={() => {
            logTap({ component: 'LoginModal', button: 'Close' })
            setShowLogin(false)
          }} />
      )
    }
  }

  function renderDeclineActions (): JSX.Element | undefined {
    return (
      <IonActionSheet
        isOpen={showDeclineActions}
        onDidDismiss={() => {
          logTap({ component: 'ActionSheet', button: 'GestureClose' })
          setShowDeclineActions(false)
        }}
        buttons={[{
          text: 'Decline Meeting?',
          role: 'destructive',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'Decline' })
            setShowDeclineActions(false)
            onDeclineInvitation()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'Cancel' })
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  function renderFooter (): JSX.Element | undefined {
    const txt = 'Let\'s find a time'

    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          testId='start-invite-accept'
          onClick={() => {
            logTap({ component: 'FooterButton', button: txt })
            onAccept()
          }}>
          {txt}
        </FooterButton>
        <FooterButton
          testId='start-invite-decline'
          fill='clear'
          color='danger'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'Decline' })
            setShowDeclineActions(true)
          }}>
          Decline
        </FooterButton>
      </IonFooter>
    )
  }

  return (
    <IonPage ref={pageRef}>
      <ScreenHeader
        title='Moica' />
      <IonContent>
        {renderInvite()}
        {showLogin && <IonBackdrop visible />}
        {renderError()}
      </IonContent>
      {/* {renderStartTip()} */}
      {renderFooter()}
      {renderMinTip()}
      {renderLoginModal()}
      {renderDeclineActions()}
    </IonPage>
  )
}

export default StartInvite
