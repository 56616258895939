
import React, { CSSProperties } from 'react'
import { IonCard, IonIcon, IonText } from '@ionic/react'
import { bulb, close } from 'ionicons/icons'
import { animated } from 'react-spring'
import { logoColor } from 'theme/styles'

import './ActionCard.css'

const tipBox: CSSProperties = {
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  zIndex: 10,
}

const bulbIcon: CSSProperties = {
  fontSize: 20,
  color: logoColor,
  // position: 'absolute',
  // right: 4,
  // top: 8,
}

const closeButton: CSSProperties = {
  fontSize: 20,
  color: logoColor,
  // position: 'absolute',
  // right: 4,
  // bottom: 8,
}

const header: CSSProperties = {
  height: 40,
  display: 'flex',
  // borderBottom: '1px solid gray',
  // border: '1px solid gold',
}

const iconBox: CSSProperties = {
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // border: '1px solid gray',
}

const titleBox: CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const titleStyle = {
  fontSize: 12,
}

const defaultCardStyle: CSSProperties = {
  margin: 0,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  paddingBottom: 20,
}

interface ContainerProps {
  title: string
  boxStyle?: CSSProperties,
  cardStyle?: CSSProperties,
  className?: string;
  icon?: string,
  arrowUp?: boolean,
  arrowDown?: boolean,
  onClose?: () => void,
  animatedProps?: CSSProperties,
}

const ActionCard: React.FC<ContainerProps> = (
  { title, boxStyle, className, cardStyle = defaultCardStyle, icon = bulb, onClose, animatedProps, children }) => {
  function renderIcon (): JSX.Element | undefined {
    // const icon = bulb

    // if (arrowUp) {
    //   icon = arrowUpOutline
    // } else if (arrowDown) {
    //   icon = arrowDownOutline
    // }

    return (
      <IonIcon
        icon={icon}
        slot='start'
        size='medium'
        style={bulbIcon} />
    )
  }

  function renderClose (): JSX.Element | undefined {
    if (onClose) {
      return (
        <IonIcon
          onClick={() => onClose()}
          slot='end'
          icon={close}
          style={closeButton} />
      )
    }
  }

  // function renderHeader (): JSX.Element | undefined {
  //   return (
  //     <IonItem
  //       color='medium'
  //       lines='full'
  //       class='ion-no-padding'>
  //       {renderIcon()}
  //       <IonLabel>
  //         <IonCardSubtitle
  //           color='secondary'
  //           mode='ios'
  //           style={{ textAlign: 'center' }}>{title || 'Tip'}
  //         </IonCardSubtitle>
  //       </IonLabel>
  //     </IonItem>
  //   )
  // }

  function renderHeader (): JSX.Element | undefined {
    return (
      <div
        style={header}
        className='tipCardHeader'>
        <div style={iconBox}>
          {renderIcon()}
        </div>
        <div
          style={titleBox}>
          <IonText
            style={titleStyle}
            color='dark'>
            {title?.toUpperCase() || 'TIP'}
          </IonText>
        </div>
        <div style={iconBox}>
          {renderClose()}
        </div>
      </div>
    )
  }

  return (
    <animated.div
      style={{ ...tipBox, ...boxStyle, ...animatedProps }}>
      <IonCard
        color='light'
        className={className}
        style={{ ...cardStyle }}>
        {renderHeader()}
        <div>
          {children}
        </div>
      </IonCard>
    </animated.div>
  )
}

export default ActionCard
