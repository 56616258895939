import React, { useContext, useEffect, useState } from 'react'
import { ApolloError, useMutation, useQuery, useSubscription } from '@apollo/client'

import { CreateUserInput, CREATE_USER, DELETE_USER, GET_USER, LOGOUT_USER, UpdateUserInput, UPDATE_USER, USER_SUBSCRIPTION, VERIFY_SUBSCRIPTION } from 'services/api'
import { User } from 'types'
import { browserName, platformName } from 'services/platform'
import { fingerprint, logoutAccount } from 'services/accounts'
import { storeAccessToken } from 'services/security/accessToken'
import { useDispatch } from 'react-redux'
import { resetApp } from 'services/store'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface UserContextValue {
  loading?: boolean
  user?: User
  loginCompleted?: string
  createUser: (name: string, invitation?: string) => Promise<void>
  logoutUser: () => Promise<void>
  deleteUser: () => Promise<void>
  updateUser: (input: UpdateUserInput) => Promise<User | undefined>
}

export const initialValue: UserContextValue = {
  loading: false,
  createUser: async (name: string, invitation?: string) => {
    console.log('createUser: ', name, invitation)

    return undefined
  },
  updateUser: async (input: UpdateUserInput) => {
    console.log('updateUser: ', input)

    return undefined
  },
  logoutUser: async () => {
    console.log('logoutUser: ')

    return undefined
  },
  deleteUser: async () => {
    console.log('deleteUser: ')

    return undefined
  },
}

// create and initialize context
const UserContext = React.createContext<UserContextValue>(initialValue)

export function useUser (): UserContextValue {
  return useContext(UserContext)
}

export type UserMockContextValue = Partial<UserContextValue>

type MockProps = {
  value?: Partial<UserContextValue>
}

export const UserMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <UserContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </UserContext.Provider>
  )
}

const UserProvider: React.FC = ({ children }) => {
  const [loginCompleted, setLoginCompleted] = useState('')

  const dispatch = useDispatch()

  const { logEvent } = useAnalytics()

  function onError (error: ApolloError): void {
    if (error.graphQLErrors.find(err => err.extensions?.code === 'UNAUTHENTICATED')) {
      console.log('USER CONTEXT: USER UNAUTHENTICATED')
    }
  }

  const { loading, data, refetch } = useQuery(GET_USER, {
    onError,
    // fetchPolicy: 'cache-and-network',
  })

  const { data: verifyCompletedData } = useSubscription(VERIFY_SUBSCRIPTION)

  // Only re-run the effect if subscriptionData changes
  useEffect(() => {
    if (verifyCompletedData?.verifyCompleted) {
      console.log('USER CONTEXT: VERIFY COMPLETED:  ', verifyCompletedData.verifyCompleted)

      // we could get verify completed for a different deviceId for instance if launched
      // in a new tab to verify a contact for login
      const { accessToken, device, action, verifyToken } = verifyCompletedData.verifyCompleted

      if (accessToken && action && action === 'verifyContact') {
        storeAccessToken(device, accessToken)
        setLoginCompleted(verifyToken)
        refetch()
      }
    }
  }, [verifyCompletedData])

  const { data: userSubscriptionData } = useSubscription(USER_SUBSCRIPTION)

  // Only re-run the effect if subscriptionData changes
  useEffect(() => {
    if (userSubscriptionData && userSubscriptionData.userUpdated) {
      console.log('USER CONTEXT: USER UPDATED: ', userSubscriptionData.userUpdated)
      refetch()
    }
  }, [userSubscriptionData])

  const [createUserMutation] = useMutation(CREATE_USER)
  const [logoutUserMutation] = useMutation(LOGOUT_USER)
  const [deleteUserMutation] = useMutation(DELETE_USER)
  const [updateUserMutation] = useMutation(UPDATE_USER)

  async function createUser (name: string, invitation?: string): Promise<void> {
    if (name && fingerprint) {
      console.log('Create User ', name)
      console.log('device:  ', fingerprint)

      const browser = browserName()
      const platform = platformName()

      console.log('browser:  ', browser)
      console.log('platform:  ', platform)

      const input: CreateUserInput = {
        name,
        deviceDetails: {
          browser: navigator.userAgent,
        },
      }

      if (invitation) input.invitation = invitation

      const { data: userData } = await createUserMutation({ variables: { input } })
      // const { user: { id, createTime, moicaID }, accessToken } = userData.createUser
      const { user: { id, moicaID }, accessToken } = userData.createUser

      if (id && moicaID && accessToken) {
        logEvent({
          eventName: EventName.mutation,
          eventData: {
            mutation: 'createUser',
            user: id,
            moicaID,
          },
        })
        storeAccessToken(fingerprint, accessToken)
        refetch()
      }
    }
  }

  async function logoutUser (): Promise<void> {
    await logoutUserMutation()
    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'logoutUser',
      },
    })
    logoutAccount()
    dispatch(resetApp())
    refetch()
  }

  async function updateUser (input: UpdateUserInput): Promise<User | undefined> {
    const { data } = await updateUserMutation(
      { variables: { input } },
    )

    if (data?.updateUser?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateUser',
        },
      })

      const user = data.updateUser as User

      return user
    }
  }

  async function deleteUser (): Promise<void> {
    console.log('USER CONTEXT: delete user')

    await deleteUserMutation()
    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'deleteUser',
      },
    })
    logoutAccount()
    dispatch(resetApp())
    refetch()
  }

  return (
    <UserContext.Provider
      value={{
        loading,
        user: data?.user,
        loginCompleted,
        createUser,
        logoutUser,
        deleteUser,
        updateUser,
      }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
