import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonText,
  IonPage, isPlatform, IonSpinner, IonFooter,
} from '@ionic/react'
import { useKeyboardState } from '@ionic/react-hooks/keyboard'

import { CreateInviteeInput, InviteeStatus, JoinMeetingInput } from 'services/api'

import { Spring } from 'react-spring/renderprops'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useUser } from 'context/UserContext/UserContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import ListItemInput from 'components/atoms/ListItemInput/ListItemInput'
import { useTabs } from 'navigation/TabsContext'
import { useRoutes, AuthTypes } from 'navigation/RouteContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import AnimatedSmily from 'components/atoms/AnimatedSmily/AnimatedSmily'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

const messageBox: CSSProperties = {
  paddingTop: '5%',
  paddingLeft: isPlatform('ios') ? '5%' : '4%',
  paddingRight: '5%',
  marginBottom: '5%',
}

const smilyBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: -'10%',
}

const nameBox: CSSProperties = {
  marginTop: -'10%',
  textAlign: 'center',
}

const nameText: CSSProperties = {
  fontSize: 22,
  textAlign: 'center',
}

interface ComponentProps {
  invitation?: string;
  goBack: () => void;
  onNewMeeting: () => void;
  onJoinMeeting: (meeting: string) => void;
}

const NewUserName: React.FC<ComponentProps> = ({ invitation, goBack, onNewMeeting, onJoinMeeting }) => {
  const [name, setName] = useState<string>()
  const [showName, setShowName] = useState(false)
  const [creatingUser, setCreatingUser] = useState(false)
  const [showWelcome, setShowWelcome] = useState(false)
  const { createUser } = useUser()
  const { createInvitee } = useInvitees()
  const { joinMeeting } = useMeetings()
  const { setShowTabs } = useTabs()
  const { setAuthenticated } = useRoutes()
  const { logEvent } = useAnalytics()

  const { isOpen: keyboardIsOpen } = useKeyboardState()

  useEffect(() => {
    setTimeout(() => {
      setShowName(true)
    }, 1000)
    setShowTabs && setShowTabs(false)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'NewUserName' },
    })
  }, [])

  async function onCreateUser (name: string): Promise<void> {
    if (name && joinMeeting) {
      await createUser(name, invitation)

      if (invitation) {
        console.log('JOIN FIRST MEETING')

        const input: CreateInviteeInput = {
          invitation,
          status: InviteeStatus.accepted,
        }

        const data = await createInvitee(input)

        if (Array.isArray(data)) {
          const invitee = data[0]

          console.log('CreateUser: joining meeting')

          if (invitee.id) {
            const input: JoinMeetingInput = {
              invitee: invitee.id,
            }

            const meeting = await joinMeeting(input)

            if (meeting) {
              onShowWelcome(meeting.id)
            }
          }
        }
      } else {
        onShowWelcome()
      }
    }
  }

  function renderMessage (): JSX.Element | undefined {
    if (!showWelcome) {
      return (
        <div style={messageBox}>
          <IonText
            color='medium'>
            Let&apos;s start with a display name for your meetings.
          </IonText>
        </div>
      )
    }
  }
  function renderNameInput (): JSX.Element | undefined {
    if (!showWelcome) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={showName ? { opacity: 1 } : { opacity: 0 }}>
          {props =>
            <div style={props}>
              <ListItemInput
                testId='name-input'
                tip
                title='display name'
                placeholder='Enter Your Name'
                capitalizeWords
                value={name}
                onChange={(n) => {
                  setName(n)
                }}
                onDone={() => {
                  logEvent({
                    eventName: EventName.buttonTap,
                    eventData: {
                      component: 'ListItemInput',
                      screen: 'NewUserName',
                      button: 'Done',
                    },
                  })
                  onNext()
                }} />
              {renderTip()}
            </div>}
        </Spring>
      )
    }
  }

  function onNext (): void {
    if (name?.length) {
      setCreatingUser(true)
      onCreateUser(name)
    }
  }

  function onShowWelcome (meetingId?: string): void {
    setShowWelcome(true)
    setTimeout(() => {
      setAuthenticated && setAuthenticated(AuthTypes.authenticated)

      if (invitation && meetingId) {
        onJoinMeeting(meetingId)
      } else {
        onNewMeeting()
      }
    }, 4000)
  }

  function renderFooter (): JSX.Element | undefined {
    if (name?.length && !keyboardIsOpen && !showWelcome) {
      return (
        <IonFooter className='screenFooterButton'>
          {!creatingUser &&
            <FooterButton
              testId='next-button'
              onClick={() => {
                logEvent({
                  eventName: EventName.buttonTap,
                  eventData: {
                    component: 'FooterButton',
                    screen: 'NewUserName',
                    button: 'Next',
                  },
                })
                onNext()
              }}>
              Next
            </FooterButton>}
          {creatingUser &&
            <FooterButton
              data-ci-id='next-button'
              color='primary'>
              <IonSpinner name='dots' />
            </FooterButton>}
        </IonFooter>
      )
    }
  }

  function renderTip (): JSX.Element | undefined {
    if (!showWelcome) {
      return (
        <div style={messageBox}>
          <IonText
            color='medium'
            style={{ fontSize: 14 }}>
            {'Your Display Name will be visibile to people you meet with, so be sure it\'s recognizable! You can change it at any time.'}
          </IonText>
        </div>
      )
    }
  }

  function renderWelcome (): JSX.Element | undefined {
    if (showWelcome) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={(showWelcome) ? { opacity: 1 } : { opacity: 0 }}>
          {props =>
            <div style={props}>
              <div style={smilyBox}>
                <AnimatedSmily />
              </div>
              <div style={nameBox}>
                <IonText
                  color='dark'
                  style={nameText}>
                  Hi {name}! <br />
                </IonText>
                <div
                  style={{
                    marginTop: 80,
                    padding: 20,
                    textAlign: 'center',
                  }}>
                  <IonText
                    color='medium'
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                    }}>
                    You&apos;re all set! <br />
                    Let&apos;s get started <br />
                  </IonText>
                </div>
              </div>
            </div>}
        </Spring>
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader
        title='Moica'
        onBack={showWelcome ? undefined : goBack} />
      <IonContent>
        {renderMessage()}
        {renderNameInput()}
        {renderWelcome()}
      </IonContent>
      {renderFooter()}
    </IonPage>
  )
}

export default NewUserName
