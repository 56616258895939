import React, { useState, useRef, useEffect } from 'react'
import {
  IonContent, IonHeader, IonPage, IonTitle,
  IonToolbar, IonButtons, IonButton, IonItem, IonLabel, IonBadge, IonBackdrop, IonIcon, IonFooter,
} from '@ionic/react'
import { RefresherEventDetail } from '@ionic/core'
import { addCircle, addCircleOutline } from 'ionicons/icons'

import './Meetings.css'
import { InviteeStatus, MeetingTime } from 'types'
import MeetingsList from 'components/organisms/MeetingsList/MeetingsList'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { useUser } from 'context/UserContext/UserContext'
import { useProfilePhoto } from 'context/ProfilePhotoContext/ProfilePhotoContext'
import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useTips } from 'context/TipsContext/TipsContext'
import MeetingTimeModal from 'components/organisms/MeetingTimeModal'
import MeetingActionsModal from 'components/organisms/MeetingActionsModal'
import { useConnections } from 'context/Connections/ConnectionsContext'
import { useTabs } from 'navigation/TabsContext'
import { ScreenTips } from './ScreenTips'
import { ComponentTips, MeetingsScreenTips } from 'types/componentTips'
import ScreenTipsContext from 'context/ScreenTipsContext/ScreenTipsContextNew'
import { MeetingsScreen as StoreTips } from 'services/store/tips/types'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import paths from 'navigation/paths'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useStoreParticipants } from 'context/StoreParticipantsContext/StoreParticipantsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { UpdateParticipantInput } from 'services/api'
import { EventData, EventName, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'
import ScreenLogEventContext from 'context/ScreenLogEventContext/ScreenLogEventContext'
import MeetingNotFoundModal from 'components/organisms/MeetingNotFoundModal/MeetingNotFoundModal'

const title = 'Meetings'

type MeetingTimeInfo = {
  meeting: string;
  meetingTime: MeetingTime;
}

interface ComponentProps {
  meetingId?: string;
  firstJoinedMeeting?: boolean;
  onEdit: (meeting: string, connectCalendar: boolean) => void;
  onParticipants: (meeting: string) => void;
  onShareMeeting: (meeting: string, byLink: boolean) => void;
  onChooseAvailability: (meeting: string, connectCalendar: boolean, time?: string, autoBook?: boolean) => void;
  onInvitations: () => void;
  onAddCalendar: (meeting: string) => void;
  onCalendarSettings: (meeting: string) => void;
  onDescription: (meeting: string) => void;
  onShowPastMeetings: () => void;
  onProfile: () => void;
  onResetParams: () => void;
  renderWithProviders?: (card: JSX.Element) => JSX.Element
  goBack: () => void
}

const Meetings: React.FC<ComponentProps> = ({
  meetingId,
  firstJoinedMeeting,
  onEdit, onChooseAvailability,
  onShareMeeting, onInvitations, onProfile, onParticipants, onShowPastMeetings,
  onResetParams, onAddCalendar, onCalendarSettings, onDescription,
  renderWithProviders, goBack,
}) => {
  const [reload] = useState(0)
  const [showMeetingTime, setShowMeetingTime] = useState<MeetingTimeInfo>()
  const [showMeetingActions, setShowMeetingActions] = useState('')
  const [reloadComplete] = useState<RefresherEventDetail>()
  const [showScreenTip, setShowScreenTip] = useState<ComponentTips>({})
  const [minTip, setMinTip] = useState(false)
  const [tipCounter, setTipCounter] = useState(0)
  const [disableMinTip, setDisableMinTip] = useState(false)
  const [showMeetingNotFound, setShowMeetingNotFound] = useState(false)

  const pageRef = useRef()

  const contentRef = useRef<HTMLIonContentElement>(null)

  const { user } = useUser()
  const { initials, photo } = useProfilePhoto()
  const { invitees } = useInvitees()
  const { loading, meetings = [] } = useMeetings()
  const { storeTips, updateStoreTips } = useTips()
  const { connections } = useConnections()
  const { setShowTabs } = useTabs()
  const { updateParticipant } = useParticipants()
  const { storeParticipants, updateStoreParticipants } = useStoreParticipants()
  const { logEvent } = useAnalytics()

  const componentStoreTips = storeTips?.meetingsScreen

  useEffect(() => {
    setShowTabs && setShowTabs(true)
    checkReadyToSchedule()
    logEvent({
      eventName: EventName.screenView,
      eventData: {
        screen: 'Meetings',
      },
    })
  }, [])

  useEffect(() => {
    if (!loading && meetings && meetingId) {
      if (!meetings.find(meeting => meeting.id === meetingId)) {
        setShowMeetingNotFound(true)
      }
    }
  }, [loading, meetings])

  // function doRefresh (event: CustomEvent<RefresherEventDetail>): void {
  //   console.log('Begin async operation')

  //   if (user?.id) {
  //     setReloadComplete(event.detail)
  //     setReload(reload + 1)
  //   } else {
  //     // if the user is not set no need to refetch
  //     setTimeout(() => {
  //       event.detail.complete()
  //     }, 1000)
  //   }
  // }

  // useEffect(() => {
  //   if (!componentStoreTips?.endTips) {
  //     if (showScreenTip?.meetingCard) {
  //       setShowNextTip(tipCounter + 1)
  //     } else if (showScreenTip?.meetingsScreen) {
  //       setNextTip(showScreenTip?.meetingsScreen)
  //     }
  //   }
  // }, [storeTips])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Meetings',
      },
    })
  }

  function checkReadyToSchedule (): void {
    if (storeParticipants?.notReadyToSchedule) {
      storeParticipants.notReadyToSchedule.forEach(id => {
        setReadyToSchedule(id)
      })
    }
  }

  async function setReadyToSchedule (participant: string): Promise<void> {
    if (storeParticipants?.notReadyToSchedule && updateStoreParticipants && updateParticipant) {
      const input: UpdateParticipantInput = {
        id: participant,
        readyToSchedule: true,
      }

      await updateParticipant(input)

      updateStoreParticipants({
        notReadyToSchedule: storeParticipants.notReadyToSchedule.filter(id => id !== participant),
      })
    }
  }

  function refreshDone (): void {
    if (reloadComplete) {
      console.log('refresh done')
      reloadComplete.complete()
    }
  }

  function updateTip (tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingsScreen: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  function renderInvitations (): JSX.Element | undefined {
    if (invitees?.length) {
      const newInvites = invitees.filter(i =>
        i.status === InviteeStatus.invited || i.status === InviteeStatus.seen || i.status === InviteeStatus.received)
      const declined = invitees.filter(i => i.status === InviteeStatus.declined)

      if (invitees.length) {
        if (newInvites.length) {
          return (
            <IonItem
              detail
              mode='ios'
              lines='full'
              onClick={() => {
                logTap({ component: 'IonItem', button: 'Invitations' })
                updateTip({ newInvitations: true })
                // setNextTip(MeetingsScreenTips.noTip)
                onInvitations()
              }}>
              {(!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newInvitations) &&
                <TipTarget
                  style={{ position: 'absolute', bottom: 24, left: '42%' }} />}
              <IonBadge
                slot='start'
                mode='ios'
                color='primary'>
                {newInvites.length}
              </IonBadge>
              <IonLabel style={{ fontSize: 17, fontWeight: 600 }}>
                Invitations
              </IonLabel>
            </IonItem>
          )
        }

        if (declined.length) {
          return (
            <IonItem
              detail
              lines='full'
              onClick={() => {
                logTap({ component: 'IonItem', button: 'DeclinedInvitations' })
                onInvitations()
              }}>
              <IonLabel>
                Invitations
              </IonLabel>
            </IonItem>
          )
        }
      }
    }
  }

  function renderMeetings (): JSX.Element {
    // show users meetings
    return (
      <MeetingsList
        meetingId={meetingId}
        reload={reload}
        firstJoinedMeeting={firstJoinedMeeting}
        onChooseAvailability={(meeting, time, autoBook) => onChooseAvailability(meeting.id, meetings?.length > 2, time, autoBook)}
        onMeetingTime={(meeting, meetingTime) => {
          logTap({ component: 'MeetingList', button: 'MeetingTime', meeting: meeting.id })
          setShowMeetingTime({ meeting: meeting.id, meetingTime })
          firstJoinedMeeting && onResetParams()
        }}
        onInvite={(meeting, byLink) => {
          logTap({ component: 'MeetingList', button: 'Invite', meeting: meeting.id })
          onShareMeeting(meeting.id, (byLink || !connections?.length))
        }}
        onEdit={(meeting) => {
          logTap({ component: 'MeetingList', button: 'Edit', meeting: meeting.id })
          onEdit(meeting.id, false)
        }}
        onMoreActions={(meeting) => {
          logTap({ component: 'MeetingList', button: 'MoreActions', meeting: meeting.id })
          setShowMeetingActions(meeting.id)
        }}
        onParticipants={(meeting) => {
          logTap({ component: 'MeetingList', button: 'MeetingTime', meeting: meeting.id })
          onParticipants(meeting.id)
        }}
        onShowPastMeetings={() => {
          logTap({ component: 'MeetingList', button: 'ShowPast' })
          onShowPastMeetings()
        }}
        onReloadDone={refreshDone}
        onAddCalendar={(meeting) => {
          logTap({ component: 'MeetingList', button: 'AddCalendar', meeting: meeting.id })
          onAddCalendar(meeting.id)
        }}
        onCalendarSettings={(meeting) => {
          logTap({ component: 'MeetingList', button: 'CalendarSettings', meeting: meeting.id })
          onCalendarSettings(meeting.id)
        }}
        onDescription={(meeting) => {
          logTap({ component: 'MeetingList', button: 'Description', meeting: meeting.id })
          onDescription(meeting.id)
        }}
        renderWithProviders={renderWithProviders} />
    )
  }

  function renderProfilePhoto (): JSX.Element | undefined {
    if (user?.id && user.name) {
      return (
        <IonButtons slot='start'>
          <IonButton
            onClick={() => {
              logTap({ component: 'ProfilePhoto', button: 'UserProfile' })
              updateTip({ profile: true })
              // setNextTip(MeetingsScreenTips.noTip)
              onProfile()
            }}>
            <ProfilePhoto
              style={{ height: 32, width: 32 }}
              fontSize={16}
              initials={initials}
              photo={photo} />
          </IonButton>
          {(!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.profile) &&
            <TipTarget style={{ bottom: 0, marginLeft: 5, zIndex: -1 }} />}
        </IonButtons>
      )
    }
  }

  function renderNewMeetingButton (): JSX.Element | undefined {
    if (user?.id && meetings?.length) {
      return (
        <IonButtons slot='end'>
          {!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newMeeting &&
            <TipTarget style={{ bottom: 0, marginRight: 5 }} />}
          <IonButton
            color='primary'
            onClick={() => {
              logTap({ button: 'NewMeeting' })
              updateTip({ newMeeting: true })
              // setNextTip(MeetingsScreenTips.profile)
              // if (screens?.allScreens?.newInvitee && updateScreens) {
              //   updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
              // }

              // on third meeting ask connect calendar
              onEdit('', meetings.length > 1)
            }}>
            <IonIcon
              slot='icon-only'
              color='primary'
              icon={addCircleOutline} />
          </IonButton>
        </IonButtons>
      )
    }
  }

  function renderHeader (): JSX.Element {
    if (meetingId) {
      return (
        <ScreenHeader
          title='Meeting'
          goBackTo={paths.meetingsList} />
      )
    }

    return (
      <IonHeader>
        <IonToolbar>
          {renderProfilePhoto()}
          <IonTitle>{title}</IonTitle>
          {renderNewMeetingButton()}
        </IonToolbar>
      </IonHeader>
    )
  }

  function renderModals (): JSX.Element | undefined {
    if (showMeetingTime) {
      const { meeting, meetingTime } = showMeetingTime

      return (
        <MeetingTimeModal
          meeting={meeting}
          meetingTime={meetingTime}
          presentingElement={pageRef.current}
          onMore={() => {
            logTap({ button: 'ChooseAvailability', component: 'MeetingTimeModal' })
            setShowMeetingTime(undefined)
            // give it some time for the modal to get dismissed and then navigate to next screen
            // else we get can't update state when component is unmounted warning
            setTimeout(() => {
              onChooseAvailability && onChooseAvailability(meeting, meetings?.length > 2, meetingTime.startTime)
            }, 1000)
          }}
          onClose={() => {
            logTap({ button: 'Close', component: 'MeetingTimeModal' })
            setShowMeetingTime(undefined)
          }} />
      )
    }

    if (showMeetingActions) {
      return (
        <MeetingActionsModal
          meeting={showMeetingActions}
          presentingElement={pageRef.current}
          onEdit={(meeting) => {
            logTap({ button: 'Edit', component: 'MeetingActionsModal' })
            onEdit(meeting, false)
            setTimeout(() => {
              setShowMeetingActions('')
            }, 1000)
          }}
          onClose={() => {
            logTap({ button: 'Close', component: 'MeetingActionsModal' })
            setShowMeetingActions('')
          }} />
      )
    }

    if (showMeetingNotFound && meetingId) {
      return (
        <MeetingNotFoundModal
          presentingElement={pageRef.current}
          meeting={meetingId}
          onUpdateCalendar={() => {
            logTap({ button: 'UpdateCalendar', component: 'MeetingNotFoundModal' })
            setShowMeetingNotFound(false)
            // give it some time for the modal to get dismissed and then navigate to next screen
            // else we get can't update state when component is unmounted warning
            setTimeout(() => {
              goBack()
            }, 1000)
          }}
          onClose={() => {
            logTap({ button: 'Close', component: 'MeetingNotFoundModal' })
            setShowMeetingNotFound(false)
            goBack()
          }} />
      )
    }
  }

  // function renderNewMeetingButton (): JSX.Element | undefined {
  //   if (showTip === TipTypes.noTip) {
  //     return (
  //       <IonFab
  //         vertical='bottom'
  //         horizontal='end'
  //         slot='fixed'>
  //         <IonFabButton
  //           color='primary'
  //           onClick={() => {
  //             onEdit('')
  //           }}>
  //           <IonIcon
  //             icon={add} />
  //         </IonFabButton>
  //       </IonFab>
  //     )
  //   }
  // }

  async function onScroll (): Promise<void> {
    if (contentRef.current) {
      const el = await contentRef.current.getScrollElement()
      const { scrollTop, scrollHeight, clientHeight } = el

      // console.log('scroll: ', scrollTop, scrollHeight, clientHeight)

      const bottomMarker = scrollHeight - (scrollTop + clientHeight)

      // const text = `${scrollHeight} ${clientHeight} ${bottomMarker}`

      // alert(text)

      // console.log('bottomMarker: ', bottomMarker)

      // if (bottomMarker < 150) {
      if (scrollHeight > (clientHeight + 100) && bottomMarker < 150) {
        !disableMinTip && setDisableMinTip(true)
      } else {
        disableMinTip && setDisableMinTip(false)
      }
    }
  }

  function renderStartMeetingButton (): JSX.Element | undefined {
    if (!loading && !meetings?.length && !firstJoinedMeeting) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            testId='start-meeting'
            onClick={() => {
              logTap({ button: 'NewMeeting', component: 'FooterButton' })
              onEdit('', false)
            }}>
            Schedule a Meeting
          </FooterButton>
        </IonFooter>

      )
    }
  }

  function renderScreenTips (): JSX.Element | undefined {
    if (meetings?.length) {
      return (
        <ScreenTips />
      )
    }
  }

  function renderLargeTitle (): JSX.Element | undefined {
    if (!meetingId) {
      return (
        <IonHeader collapse='condense'>
          <IonToolbar>
            <IonTitle size='large'>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
      )
    }
  }

  return (
    <ScreenTipsContext.Provider
      value={{ showScreenTip, minTip, tipCounter, disableMinTip, setShowScreenTip, setMinTip, setTipCounter }}>
      <ScreenLogEventContext.Provider
        value={{ logTapEvent: logTap }}>
        <IonPage ref={pageRef}>
          {renderHeader()}
          <IonContent
            ref={contentRef}
            scrollEvents
            onIonScroll={onScroll}>
            {renderLargeTitle()}
            {/* <IonRefresher
          slot='fixed'
          onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher> */}
            {renderInvitations()}
            {renderMeetings()}
            {showMeetingTime && <IonBackdrop visible />}
            {renderModals()}
          </IonContent>
          {renderStartMeetingButton()}
          {renderScreenTips()}
          {/* {renderProfileTip()} */}
        </IonPage>
      </ScreenLogEventContext.Provider>
    </ScreenTipsContext.Provider>
  )
}

export default Meetings
