import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { useSpring } from 'react-spring'
import { CalendarEvent, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { deleteFromDeviceCalendar } from 'services/deviceCalendar'
import AnimatedError from 'components/atoms/AnimatedError/AnimatedError'

import { useCalendars } from 'context/CalendarsContext/CalendarsContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  textAlign: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  meeting: string;
  onClose: () => void;
  onUpdateCalendar: () => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingNotFoundModal: React.FC<ContainerProps> = ({ meeting, onUpdateCalendar, onClose }) => {
  const [showCard] = useState(true)
  const { loading: calendarsLoading, defaultCalendar } = useCalendars()

  function renderStatus (): JSX.Element | undefined {
    const message = 'The meeting was probably deleted'

    return (
      <div
        className='ion-text-start'>
        <div style={iconBox}>
          <AnimatedError
            style={{ width: 60, height: 60 }}
            loop={4} />
          <IonText
            style={titleStyle}>
            Meeting not found!
          </IonText>
          <div
            style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              {message}
            </IonText>
          </div>
        </div>
      </div>
    )
  }

  function updateCalendar (): void {
    if (meeting) {
      deleteFromDeviceCalendar(meeting)
      onUpdateCalendar()
    }
  }

  function renderActionButtons (): JSX.Element | undefined {
    const label = 'Update your Calendar'

    if (!calendarsLoading && !defaultCalendar) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton onClick={updateCalendar}>
            {label}
          </FooterButton>
        </IonFooter>
      )
    }
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard (): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title='Oops'
          icon=''
          onClose={onClose}
          animatedProps={popoverProps}>
          {renderStatus()}
          {renderActionButtons()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default MeetingNotFoundModal
