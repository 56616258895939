import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'
import 'screens/Settings.css'
import MeetingSettings from './MeetingSettings'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import { updateScreens } from 'services/store/screens/actions'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import paths from 'navigation/paths'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'

const MeetingSettingsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { meetingSettings } = useSelector((state: RootState) => state.screens)
  const storeTips = useSelector((state: RootState) => state.tips)

  const dispatch = useDispatch()

  function onChooseAvailability (meeting: string, startEdit?: boolean): void {
    if (storeTips?.tipsAvailabilityCalendar?.completed) {
      dispatch(updateScreens({
        chooseAvailability: {
          meeting,
          previous: paths.meetingsList,
          startEdit,
        },
        meetingSettings: { meeting: '' },
      }))
      history.replace(paths.chooseAvailability)
    } else {
      dispatch(updateScreens({
        chooseAvailability: {
          meeting,
          startEdit,
          newUser: meetingSettings?.newUser,
        },
        tipsAvailabilityCalendar: {
          nextScreen: paths.chooseAvailability,
          newUser: meetingSettings?.newUser,
        },
      }))
      history.replace(paths.tipsAvailabilityCalendar)
    }
  }

  // we can't use meetingSettings.meeting as it may be a newly created meeting
  function onAskConnectCalendar (meeting: string): void {
    dispatch(updateScreens({
      askConnectCalendars: {
        meeting,
        previous: paths.meetingsList,
        nextScreen: paths.addParticipants,
      },
    }))
    history.push(paths.askConnectCalendar)
  }

  function onMeetingCalendarSettings (meeting: string): void {
    dispatch(updateScreens({
      meetingCalendarSettings: {
        meeting,
        previous: paths.meetingsList,
        initiator: !meetingSettings?.meetMeInitiator,
        meetMeInitiator: meetingSettings?.meetMeInitiator,
      },
    }))
    history.push(paths.meetingCalendarSettings)
  }

  function onAddParticipants (meeting: string): void {
    dispatch(updateScreens({
      addParticipants: {
        meeting,
        previous: paths.meetingsList,
        nextScreen: paths.meetingsList,
      },
    }))
    history.push(paths.addParticipants)
  }

  function goBack (): void {
    history.goBack()
    // NOTE the reason we clear the screen params state after a delay is
    // that if we clear it sooner it re-renders the current screen with the new params and it causes flicker
    // before switching back to previous screen
    setTimeout(() => {
      dispatch(updateScreens({ meetingSettings: { meeting: '' } }))
    }, 1000)
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <InviteesProvider>
          <MeetingsProvider meeting={meetingSettings?.meeting}>
            <ParticipantsProvider meeting={meetingSettings?.meeting}>
              <StoreParticipantsProvider>
                <MeetingSettings
                  meetingId={meetingSettings?.meeting || ''}
                  tips={meetingSettings?.tips || false}
                  addParticipants={meetingSettings?.addParticipants}
                  meetMeInitiator={meetingSettings?.meetMeInitiator}
                  meetMeInvitee={meetingSettings?.meetMeInvitee}
                  newUser={meetingSettings?.newUser}
                  onChooseAvailability={onChooseAvailability}
                  onAskConnectCalendar={onAskConnectCalendar}
                  onAddParticipants={onAddParticipants}
                  onMeetingCalendarSettings={onMeetingCalendarSettings}
                  goBack={goBack} />
              </StoreParticipantsProvider>
            </ParticipantsProvider>
          </MeetingsProvider>
        </InviteesProvider>
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default MeetingSettingsScreen
