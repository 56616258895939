import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'services/store'
import 'screens/Settings.css'
import { updateScreens } from 'services/store/screens/actions'
import AskConnectCalendars from './AskConnectCalendars'
import paths from 'navigation/paths'

const AskConnectCalendarsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { askConnectCalendars } = useSelector((state: RootState) => state.screens)
  const storeTips = useSelector((state: RootState) => state.tips)

  const dispatch = useDispatch()

  function onAddCalendar (): void {
    if (askConnectCalendars?.meeting) {
      dispatch(updateScreens({
        chooseAvailability: {
          meeting: askConnectCalendars.meeting,
          previous: paths.meetingsList,
        },
        tipsGooglePermissions: {
          addCalendar: true,
        },
      }))
      history.push(paths.tipsGooglePermissions)
    }
  }

  function onChooseAvailability (): void {
    if (askConnectCalendars?.meeting) {
      dispatch(updateScreens({
        chooseAvailability: {
          meeting: askConnectCalendars.meeting,
          previous: paths.meetingsList,
        },
        meetingSettings: {
          meeting: '',
        },
      }))

      if (storeTips?.tipsAvailabilityCalendar?.completed) {
        history.replace(paths.chooseAvailability)
      } else {
        dispatch(updateScreens({
          tipsAvailabilityCalendar: {
            nextScreen: paths.chooseAvailability,
          },
        }))
        history.push(paths.tipsAvailabilityCalendar)
      }
    }
  }

  return (
    <AskConnectCalendars
      onAddCalendar={onAddCalendar}
      onChooseAvailability={onChooseAvailability}
      goBackTo={askConnectCalendars?.previous} />
  )
}

export default AskConnectCalendarsScreen
