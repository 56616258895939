import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'

import { updateScreens } from 'services/store/screens/actions'

import 'screens/Settings.css'
import { encryptedAccessToken, storeEncryptedAccessToken } from 'services/security/accessToken'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import ConnectCalendar from './ConnectCalendar'
import paths from 'navigation/paths'
import { encryptedDeviceFingerPrint, storeEncryptedDeviceFingerPrint } from 'services/accounts'
import { useRoutes, AuthTypes } from 'navigation/RouteContext'
import { IonAlert, IonPage, IonSpinner } from '@ionic/react'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'

const ConnectCalendarScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [authCode, setAuthCode] = useState('')
  const [showAlert, setShowAlert] = useState('')
  const dispatch = useDispatch()
  const { setAuthenticated } = useRoutes()

  useEffect(() => {
    if (location.search) {
      console.log('GOOGLE AUTH URL: ', location.search)

      const params = queryString.parse(location.search)

      if (params.code && params.code !== authCode && params.state) {
        const { code } = params
        const state = JSON.parse(params.state as string)

        if (state.token && state.device) {
        // add the code only once
          const { token, device } = state

          console.log('Auth Code: ', code)
          console.log('Access Token: ', token)

          if (token !== encryptedAccessToken() || device !== encryptedDeviceFingerPrint()) {
            console.log('INVALID ACCESS TOKEN OR DEVICE... reload app with new context')
            storeEncryptedAccessToken(token)
            storeEncryptedDeviceFingerPrint(device)
            setAuthenticated && setAuthenticated(AuthTypes.authenticated)
            window.location.reload()
          } else {
            setAuthCode(code as string)
          }
        } else if (!state.token) {
          setShowAlert('Error connecting your calendar: invalid token id')
        } else if (!state.device) {
          setShowAlert('Error connecting your calendar: invalid device id ')
        }

        console.log('GOOGLE AUTH STATE: ', state)

        if (state.steps && state.meeting) {
          dispatch(updateScreens({
            addParticipants: {
              meeting: state.meeting,
              nextScreen: paths.meetingsList,
            },
            shareMeetingInvite: {
              meeting: state.meeting,
              nextScreen: paths.meetingsList,
            },
            calendarAccounts: {
              previous: paths.meetingsList,
              nextScreen: paths.addParticipants,
            },
          }))
        }
      } else {
        console.log('ignore auth url params ', params)
      }
    }
  }, [location.search])

  function onDone (): void {
    history.replace(paths.connectCalendar)
  }

  function goBack (): void {
    history.goBack()
  }

  function onSelectFreeBusy (calendar: string): void {
    dispatch(updateScreens({
      calendarSettings: {
        calendar,
      },
    }))
    history.replace(paths.calendarSettingsFreeBusy)
  }

  function onSelectAutoTimes (calendar: string): void {
    dispatch(updateScreens({
      calendarSettings: {
        calendar,
      },
    }))
    history.replace(paths.calendarSettingsAutoTimes)
  }

  if (!authCode) {
    return (
      <IonPage>
        <ScreenCenter height='80%'>
          <IonSpinner
            name='dots'
            color='medium' />
        </ScreenCenter>
        <IonAlert
          isOpen={!!showAlert}
          onDidDismiss={() => setShowAlert('')}
          header='Error'
          message={showAlert}
          buttons={[
            {
              text: 'OK',
              handler: () => {
                setShowAlert('')
                onDone()
              },
            },
          ]} />
      </IonPage>
    )
  }

  return (
    <CalendarsProvider>
      <ConnectCalendar
        authCode={authCode}
        onSelectFreeBusy={onSelectFreeBusy}
        onSelectAutoTimes={onSelectAutoTimes}
        goBack={goBack}
        onDone={onDone} />
    </CalendarsProvider>
  )
}

export default ConnectCalendarScreen
