import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateScreens } from 'services/store/screens/actions'

import 'screens/Settings.css'
import MeetWithUser from './MeetWithUser'
import MeetUserProvider from 'context/MeetUserContext/MeetUserContext'
import UserProvider from 'context/UserContext/UserContext'
import paths from 'navigation/paths'

type ScreenProps = RouteComponentProps<{
  moicaID: string;
}>

const MeetWithUserScreen: React.FC<ScreenProps> = ({ history, match }) => {
  const moicaID = match.params.moicaID
  const dispatch = useDispatch()

  function onMeet (meetUser: string): void {
    if (meetUser) {
      dispatch(updateScreens({ meetingSettings: { addParticipants: [meetUser], meetMeInitiator: true } }))
      history.push(paths.meetingSettings)
    }
  }

  function goBack (): void {
    history.replace(paths.meetingsList)
  }

  return (
    <UserProvider>
      <MeetUserProvider moicaID={moicaID}>
        <MeetWithUser
          onMeet={onMeet}
          goBack={goBack} />
      </MeetUserProvider>
    </UserProvider>
  )
}

export default MeetWithUserScreen
