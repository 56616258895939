import React, { useContext } from 'react'
import firebase from 'firebase/app'
import { logEvent as firebaseLogEvent } from 'services/firebase'
import { useUser } from 'context/UserContext/UserContext'
import { fingerprint as device } from 'services/accounts'

export enum EventName {
  buttonTap = 'button_tap',
  screenView = 'screen_view',
  mutation = 'mutation',
}

export interface EventData extends firebase.analytics.CustomParams {
  screen?: string;
  button?: string;
  component?: string;
  user?: string;
  meeting?: string;
  mutation?: string;
}

export interface Event {
  eventName?: EventName;
  eventData?: EventData
}

interface AnalyticsContextValue {
  logEvent: (event: Event) => void
}

const initialValue: AnalyticsContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logEvent: () => {},
}
// create and initialize context
export const AnalyticsContext = React.createContext<AnalyticsContextValue>(initialValue)

export function useAnalytics (): AnalyticsContextValue {
  return useContext(AnalyticsContext)
}

// https://firebase.google.com/docs/analytics/screenviews#web-version-9

const AnalyticsProvider: React.FC = ({ children }) => {
  const { user } = useUser()

  const eventUser = user?.id || 'New User'

  function logEvent (event: Event): void {
    const { eventName, eventData } = event

    // console.log('LOG EVENT: ', event)

    const data: firebase.analytics.CustomParams = {
      ...eventData,
      user: eventUser,
      device,
    }

    if (eventName === EventName.screenView && eventData) {
      const { screen } = eventData

      data.firebase_screen = screen
      firebaseLogEvent(firebase.analytics.EventName.SCREEN_VIEW, data)
    } else if (eventName === EventName.buttonTap && eventData) {
      const { screen, component } = eventData
      // create button full path "Screen/Component/Button"

      let button = `${screen}/`

      if (component) button += `${component}/`

      button += eventData.button

      data.button = button
      firebaseLogEvent(eventName, data)
    } else if (eventName) {
      firebaseLogEvent(eventName, data)
    }
  }

  return (
    <AnalyticsContext.Provider
      value={{
        logEvent,
      }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
