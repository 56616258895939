import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { GET_MEET_USER } from 'services/api'
import { MeetUser } from 'types'

interface MeetUserContextValue {
  loading: boolean
  meetUser?: MeetUser
  error?: string,
}

const initialValue: MeetUserContextValue = {
  loading: false,
}

// create and initialize context
export const MeetUserContext = React.createContext<MeetUserContextValue>(initialValue)

export function useMeetUser (): MeetUserContextValue {
  return useContext(MeetUserContext)
}

export type MeetingsMockContextValue = Partial<MeetUserContextValue>

type MockProps = {
  value?: Partial<MeetUserContextValue>
}

export const MeetUserMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <MeetUserContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </MeetUserContext.Provider>
  )
}

interface ProviderProps {
  moicaID?: string,
}

const MeetUserProvider: React.FC<ProviderProps> = ({ moicaID, children }) => {
  const { loading, data, error } = useQuery(GET_MEET_USER, {
    variables: { moicaID },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <MeetUserContext.Provider
      value={{
        loading,
        meetUser: data?.meetUser,
        error: error?.message,
      }}>
      {children}
    </MeetUserContext.Provider>
  )
}

export default MeetUserProvider
