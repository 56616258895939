import React from 'react'
import { IonText, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { Participant } from 'types'
import { ban, checkmark } from 'ionicons/icons'
import { Avatar } from '@material-ui/core'

const avatarStyle = {
  width: 30,
  height: 30,
  backgroundColor: '#92949C',
  // marginLeft: -5,
  marginRight: 10,
}

export type ItemIcon = {
  name: string;
  color: string;
  slot: string;
  activity?: boolean;
}

interface ContainerProps {
  user: string;
  busy?: boolean;
  participants: Participant[];
}

const MeetingTimeParticipants: React.FC<ContainerProps> = (
  { user, participants, busy }) => {
  return (
    <div>
      {participants.map((participant, i) => {
        const initials = participant.name.split(' ').map(t => t.charAt(0) && t.charAt(0).toUpperCase()).join('')

        // if initials is > 2 characters then just pick first and last
        const displayInitials = initials.length > 2
          ? (initials.charAt(0) + initials.charAt(initials.length - 1)) : initials
        const name = user === participant.user ? 'You' : participant.name

        return (
          <IonItem
            color='transparent'
            key={i}
            lines={(i < participants.length - 1) ? 'inset' : 'none'}>
            <Avatar
              slot='start'
              src={participant.photo}
              style={avatarStyle}>
              <IonText style={{ fontSize: 12, fontWeight: 'bold' }}>
                {displayInitials}
              </IonText>
            </Avatar>
            <IonLabel>
              {name}
            </IonLabel>
            {(!busy || user !== participant.user) &&
              <IonIcon
                slot='end'
                color='secondary'
                icon={checkmark} />}
            {busy && user === participant.user &&
              <IonIcon
                slot='end'
                color='medium'
                icon={ban} />}
          </IonItem>
        )
      })}
    </div>
  )
}

export default MeetingTimeParticipants
