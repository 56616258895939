import {
  ApolloClient,
  InMemoryCache,
  split,
} from '@apollo/client'
import { HttpLink } from '@apollo/client/link/http'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import moment from 'moment-timezone'

import { fingerprint } from 'services/accounts'
import { getAccessToken } from 'services/security/accessToken'

function getAuth (): string | undefined {
  if (fingerprint) {
    const authorization = {
      device: fingerprint,
      accessToken: '',
      timeZone: moment.tz.guess(),
    }
    const token = getAccessToken()

    if (token) {
      authorization.accessToken = token
    }

    return JSON.stringify(authorization)
  }
}

export async function initApollo (): Promise<ApolloClient<Record<string, unknown>> | undefined> {
  const { REACT_APP_MEETINGS_SERVER, REACT_APP_MEETINGS_WS } = process.env

  if (REACT_APP_MEETINGS_SERVER && REACT_APP_MEETINGS_WS) {
    // Create a WebSocket link:

    const httpLink = new HttpLink({
      uri: REACT_APP_MEETINGS_SERVER + '/meetings',
    })

    const wsLink = new WebSocketLink({
      uri: REACT_APP_MEETINGS_WS + '/meetings',
      options: {
        reconnect: true,
        connectionParams: () => {
          return {
            authorization: getAuth(),
          }
        },
        lazy: true,
      },
    })
    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const authorization = getAuth()

      if (authorization) {
        return {
          headers: {
            ...headers,
            authorization,
          },
        }
      }

      // return the headers to the context so httpLink can read them
      return headers
    })

    // const errorLink = onError(({ graphQLErrors, networkError }) => {
    //   if (graphQLErrors) {
    //     graphQLErrors.map(({ message, locations, path }) =>
    //       console.log(
    //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //       ),
    //     )
    //   }

    //   if (networkError) console.log(`[Network error]: ${networkError}`)
    // })

    // const links = ApolloLink.from([
    //   errorLink,
    //   authLink,
    //   link,
    // ])

    console.log('APOLLO INIT COMPLETE')

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
    })
  }
}
