import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonModal, IonText, IonSpinner, IonAlert, IonFooter,
} from '@ionic/react'

import 'screens/Settings.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'
import { useUser } from 'context/UserContext/UserContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'
import ListItemInput from 'components/atoms/ListItemInput/ListItemInput'

const title = 'Display Name'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

interface ContainerProps {
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const ChangeUserNameModal: React.FC<ContainerProps> = ({ onClose, presentingElement }) => {
  const [showActivity, setShowActivity] = useState(false)
  const [showAlert, setShowAlert] = useState('')
  const [name, setUserName] = useState('')
  const { user, updateUser } = useUser()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    if (user?.name) {
      setUserName(user.name)
    }
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'ChangeUserNameModal',
      },
    })
  }

  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function renderInput (): JSX.Element | undefined {
    return (
      <div style={{ paddingTop: 10 }}>
        <ListItemInput
          testId='name-input'
          tip
          title='display name'
          placeholder='Enter new display name'
          capitalizeWords
          value={name}
          onChange={(n) => {
            setUserName(n)
          }} />
      </div>
    )
  }

  async function updateUserName (): Promise<void> {
    setShowActivity(true)
    await updateUser({ name })
    setShowActivity(false)
    onClose()
  }

  function renderFooter (): JSX.Element | undefined {
    if (showActivity) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    if (user?.name && name && name !== user.name) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'ChangeDisplayName' })
              updateUserName()
            }}>
            Change Display Name
          </FooterButton>
          <FooterButton
            fill='clear'
            onClick={() => {
              logTap({ button: 'Cancel' })
              onClose()
            }}>
            Cancel
          </FooterButton>
        </IonFooter>
      )
    } else {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={onClose}>
            Done
          </FooterButton>
        </IonFooter>
      )
    }
  }

  return (
    <IonModal
      isOpen
      swipeToClose
      presentingElement={presentingElement}
      onDidDismiss={() => {
        logTap({ button: 'GestureClose' })
        onClose()
      }}>
      {renderHeader()}
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          {renderInput()}
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              {'Your Display Name will be visibile to people you meet with, so be sure it\'s recognizable! You can change it at any time.'}
            </IonText>
          </div>
          {/* <div style={{ marginTop: 40, padding: 20 }}>
            {renderUpdateButton()}
          </div> */}
        </div>
      </IonContent>
      {renderFooter()}
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert('')}
        header='Error'
        message={showAlert}
        buttons={[
          {
            text: 'OK',
            handler: () => {
              setShowAlert('')
              setShowActivity(false)

              if (user?.name) {
                setUserName(user.name)
              }
            },
          },
        ]} />
    </IonModal>
  )
}

export default ChangeUserNameModal
