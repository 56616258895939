import React, { CSSProperties, useState, useEffect, useRef } from 'react'
import {
  IonContent,
  IonSpinner,
  IonPage,
  IonFooter,
  IonText,
  IonChip,
  IonIcon,
  IonLabel,
  IonItem,
  IonToggle,
} from '@ionic/react'

import 'theme/styles.css'

import { TimeRange } from 'types'
import { Spring } from 'react-spring/renderprops'
import moment from 'moment'
import { useKeyboardState } from '@ionic/react-hooks/keyboard'

import { UpdateParticipantInput } from 'services/api'
import MeetingCalendar from '../../components/molecules/MeetingBookCalendar/MeetingBookCalendar'
import SelectMeetingCalendar from '../../components/organisms/SelectMeetingBookCalendar/SelectMeetingCalendar'
import MeetingFreeBusyCalendars from '../../components/molecules/MeetingFreeBusyCalendars/MeetingFreeBusyCalendars'
import SelectFreeBusyCalendars from '../../components/organisms/SelectFreeBusyCalendars/SelectFreeBusyCalendars'
import MeetingTimes from '../../components/molecules/MeetingTimes/MeetingTimes'
import AutoBookSettingsModal from 'components/organisms/SelectMeetingAutobookSchedule/AutoBookSettingsModal'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useCalendars, MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'
import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { adjustTimeRangesForDay } from 'components/organisms/ScheduleCalendar/utils'
import { flash, flashOffOutline, warning } from 'ionicons/icons'
import ScheduleActionsModal, { SelectedOptions } from 'components/organisms/ScheduleActionsModal/ScheduleActionsModal'
import { useStoreParticipants } from 'context/StoreParticipantsContext/StoreParticipantsContext'
import { logoColor } from 'theme/styles'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'
import InvitationsSentModal from 'components/organisms/InvitationsSentModal/InvitationsSentModal'
import { useInvitees } from 'context/InviteesContext/InviteesContext'

const messageBox: CSSProperties = {
  marginTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
  alignItems: 'center',
}

const messageStyle = {
  fontSize: 14,
}

const warningChipStyle: CSSProperties = {
  marginTop: 8,
  marginLeft: 10,
  // border: `1px solid ${blue}`,
}

type ScheduleActionsInfo = {
  adjustedAutoTimes?: TimeRange[];
  selectedAutoTimes?: TimeRange[];
  selectBusyTimes?: boolean;
}

interface ContainerProps {
  tips?: boolean;
  editOnly?: boolean;
  step?: number;
  initiator?: boolean;
  meetMeInitiator?: boolean;
  goBackTo?: string;
  goBack: () => void;
  onDone: () => void;
  onChooseAvailability: () => void;
  onAddParticipants: () => void;
}

const MeetingCalendarSettings: React.FC<ContainerProps> = (
  { goBackTo, editOnly, initiator, meetMeInitiator, goBack, onDone, onChooseAvailability, onAddParticipants }) => {
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [showActivity, setShowActivity] = useState('')
  const [selectViewCalendars, setSelectViewCalendars] = useState(false)
  const [selectMeetingCalendar, setSelectMeetingCalendar] = useState(false)
  const [selectAutoTimeRanges, setSelectAutoTimeRanges] = useState(false)
  const [adjustedAutoTimes, setAudjustedAutoTimes] = useState<TimeRange[]>()
  const [showScheduleActionsModal, setShowScheduleActionsModal] = useState(false)
  const [showInviteSentModal, setShowInviteSentModal] = useState(false)
  const [edited, setEdited] = useState(false)
  const [autoEnabled, setAutoEnabled] = useState(false)

  const { isOpen } = useKeyboardState()

  const pageRef = useRef()

  const { meeting } = useMeetings()
  const { participant, participants, updateParticipant } = useParticipants()
  const { calendars, getMeetingCalendar } = useCalendars()
  const { preferences } = usePreferences()
  const { storeParticipants, updateStoreParticipants } = useStoreParticipants()
  const { logEvent } = useAnalytics()
  const { meetingInvitees } = useInvitees()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'MeetingCalendarSettings', meeting: meeting?.id },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'MeetingCalendarSettings',
        meeting: meeting?.id,
      },
    })
  }

  async function getBookCalendar (): Promise<void> {
    if (meeting && calendars && participant && getMeetingCalendar) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)
        checkAutoTimes(calendar)

        if (calendar.autoBook) {
          setAutoEnabled(true)
        }
      }
    }
  }

  useEffect(() => {
    if (participant?.readyToSchedule) {
      if (!participant.readyToSchedule.ready && storeParticipants && updateStoreParticipants) {
        if (!storeParticipants.notReadyToSchedule?.find(id => participant.id === id)) {
          updateStoreParticipants({
            notReadyToSchedule: storeParticipants.notReadyToSchedule
              ? [...storeParticipants.notReadyToSchedule, participant.id] : [participant.id],
          })
        }
      }
    }
  }, [participant])

  useEffect(() => {
    getBookCalendar()
  }, [meeting, calendars, participant])

  function checkAutoTimes (calendar: MeetingBookCalendar): void {
    if (calendar?.autoTimes && meeting?.timeRanges) {
      const { autoTimes } = calendar
      const { timeRanges } = meeting

      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are outside the autoRanges
      const outsideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if (startRange >= startAuto && endRange <= endAuto) {
            return false
          }

          return true
        })
      })

      if (outsideAutoRange) {
        const updateAutoTimes = [
          {
            startTime: timeRanges[0].startTime,
            endTime: timeRanges[timeRanges.length - 1].endTime,
          },
        ]

        setAudjustedAutoTimes(updateAutoTimes)
        console.log('ADJUSTED AUTO TIMES: ', updateAutoTimes)
      } else {
        setAudjustedAutoTimes(undefined)
      }
    }
  }

  function checkOverlapAutoTimes (calendar: MeetingBookCalendar): boolean {
    if (calendar?.autoTimes && meeting?.timeRanges) {
      const { autoTimes } = calendar
      const { timeRanges } = meeting

      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are inside the autoRanges
      const insideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if ((startRange >= startAuto && startRange < endAuto) ||
          (endRange > startAuto && endRange <= endAuto)) {
            return true
          }
        })
      })

      return !!insideAutoRange
    }

    return false
  }

  async function saveParticipant (done?: boolean, selectedOptions?: SelectedOptions): Promise<void> {
    if (participant && bookCalendar && updateParticipant) {
      const { calendar, autoTimes, autoDays } = bookCalendar

      const timeRanges = selectedOptions?.adjustedAutoTimes ? adjustedAutoTimes : autoTimes

      console.log('UPDATING PARTICIPANT')

      const input: UpdateParticipantInput = {
        id: participant.id,
        bookCalendar: {
          account: calendar.account,
          calendarId: calendar.id,
          timeRanges: Array.isArray(timeRanges) ? timeRanges.map(r => {
            return {
              startTime: r.startTime,
              endTime: r.endTime,
            }
          }) : [],
          autoDays,
        },
        autoBookEnabled: autoEnabled,
      }

      if (!participant.readyToSchedule || !participant.readyToSchedule.ready) {
        input.readyToSchedule = initiator || (!selectedOptions?.busyTimes && !selectedOptions?.addParticipants)
      }

      setShowActivity('update')

      const updated = await updateParticipant(input)

      setShowActivity('')

      if (input.readyToSchedule && storeParticipants?.notReadyToSchedule && updateStoreParticipants) {
        updateStoreParticipants({
          notReadyToSchedule: storeParticipants.notReadyToSchedule.filter(id => id !== participant.id),
        })
      }

      if (selectedOptions?.busyTimes) {
        onChooseAvailability()
      } else if (selectedOptions?.addParticipants) {
        onAddParticipants()
      } else if (done) {
        if (!autoEnabled && !editOnly) {
          onChooseAvailability()
        } else if (meetMeInitiator && meetingInvitees?.length) {
          setShowInviteSentModal(true)
          setTimeout(() => {
            setShowInviteSentModal(false)
            setTimeout(() => {
              onDone()
            }, 500)
          }, 3000)
        } else {
          onDone()
        }
      } else if (goBack) {
        goBack()
      }

      console.log('Update participant response: ', updated)
    }
  }

  function renderFooter (): JSX.Element | undefined {
    if (showActivity === 'update') {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    if (editOnly && edited && !isOpen) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'Update' })
                    saveParticipant(true)
                  }}>
                  Update
                </FooterButton>
                <FooterButton
                  fill='clear'
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'Cancel' })
                    goBack && goBack()
                  }}>
                  Cancel
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }

    if (!editOnly && !isOpen) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'Schedule' })

                    if (bookCalendar?.autoBook) {
                      setShowScheduleActionsModal(true)
                    } else {
                      saveParticipant(true)
                    }
                  }}>
                  Schedule
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }
  }

  function renderHeader (): JSX.Element {
    const title = 'Meeting Calendar Settings'

    return (
      <ScreenHeader
        title={meeting?.title || title}
        onBack={goBackTo ? undefined : goBack}
        goBackTo={goBackTo} />
    )
  }

  function renderModals (): JSX.Element | undefined {
    if (meeting) {
      if (selectMeetingCalendar && calendars && bookCalendar?.calendar) {
        return (
          <SelectMeetingCalendar
            calendars={calendars}
            selectedCalendar={bookCalendar.calendar}
            presentingElement={pageRef.current}
            onClose={() => setSelectMeetingCalendar(false)}
            onSelect={(selected) => {
              const updateBookCalendar: MeetingBookCalendar = {
                ...bookCalendar,
                calendar: selected,
              }

              console.log('UPDATED CALENDAR: ', updateBookCalendar)

              setBookCalendar(updateBookCalendar)
              checkAutoTimes(updateBookCalendar)
              setSelectMeetingCalendar(false)
              setEdited(true)
            }} />
        )
      }

      if (selectViewCalendars && calendars?.length && participant) {
        return (
          <SelectFreeBusyCalendars
            participant={participant}
            accounts={calendars}
            presentingElement={pageRef.current}
            onClose={() => setSelectViewCalendars(false)}
            onUpdated={() => {
              setSelectViewCalendars(false)
              setEdited(true)
              // refetchParticipant && refetchParticipant({ meeting: meeting.id })
            }} />
        )
      }

      if (selectAutoTimeRanges && bookCalendar) {
        const { autoTimes, autoDays } = bookCalendar

        return (
          <AutoBookSettingsModal
            enabled={autoEnabled}
            timeRanges={autoTimes}
            days={autoDays}
            timeZone={preferences?.timeZone}
            presentingElement={pageRef.current}
            onClose={() => setSelectAutoTimeRanges(false)}
            onUpdated={(ranges, days, enabled) => {
              const updateBookCalendar: MeetingBookCalendar = {
                ...bookCalendar,
                autoDays: days,
                autoTimes: ranges,
                autoBook: enabled,
              }

              console.log('UPDATED CALENDAR: ', updateBookCalendar)
              setBookCalendar(updateBookCalendar)
              checkAutoTimes(updateBookCalendar)
              setSelectAutoTimeRanges(false)
              setEdited(true)
            }} />
        )
      }

      if (showScheduleActionsModal && bookCalendar?.autoTimes) {
        const selectedAutoTimes = bookCalendar?.autoTimes

        return (
          <ScheduleActionsModal
            meeting={meeting}
            selectedAutoTimes={checkOverlapAutoTimes(bookCalendar) ? selectedAutoTimes : undefined}
            adjustedAutoTimes={adjustedAutoTimes}
            inviteParticipants={initiator}
            addParticipants={participant?.meetMe}
            presentingElement={pageRef.current}
            onSelectedOptions={(selectedOptions?: SelectedOptions) => {
              setShowScheduleActionsModal(false)
              saveParticipant(true, selectedOptions)
            }}
            onClose={() => {
              setShowScheduleActionsModal(false)
            }} />
        )
      }

      console.log('MEET ME INVITEES: ', meetingInvitees)

      if (showInviteSentModal) {
        const names = meetingInvitees?.filter(p => p.user !== participant?.user).map(p => p.name)

        if (names?.length) {
          return (
            <InvitationsSentModal
              names={names}
              presentingElement={pageRef.current}
              onClose={() => {
                setShowInviteSentModal(false)
              }} />
          )
        }
      }
    }
  }

  function renderBookCalendar (): JSX.Element | undefined {
    if (autoEnabled && bookCalendar?.calendar) {
      return (
        <MeetingCalendar
          calendar={bookCalendar.calendar}
          onClick={() => {
            logTap({ component: 'MeetingCalendar', button: 'SelectCalendar' })
            setSelectMeetingCalendar(true)
          }} />
      )
    }
  }

  function renderAutoBookTimes (): JSX.Element | undefined {
    if (autoEnabled && bookCalendar?.autoTimes) {
      return (
        <MeetingTimes
          title='automagic schedule times'
          timeRanges={bookCalendar.autoTimes}
          timeZone={preferences?.timeZone}
          disabled={!autoEnabled}
          onClick={() => {
            logTap({ component: 'MeetingTimes', button: 'SelectAutoTimes' })
            setSelectAutoTimeRanges(true)
          }} />
      )
    }
  }

  function renderWarning (): JSX.Element | undefined {
    if (autoEnabled && adjustedAutoTimes) {
      const message = 'Your Automagic scheduling preferences are not set to handle meetings for this time range.'

      return (
        <div>
          <div style={messageBox}>
            <IonIcon
              icon={warning}
              style={{ fontSize: 20, marginRight: 5 }}
              color='danger' />
            <IonText
              color='danger'
              style={messageStyle}>
              {message}
            </IonText>
          </div>
          <IonChip
            color='primary'
            style={warningChipStyle}
            onClick={() => {
              if (bookCalendar) {
                logTap({ component: 'IonChip', button: 'ResetToCover' })

                const update = {
                  ...bookCalendar,
                  autoTimes: adjustedAutoTimes,
                }

                setBookCalendar(update)
                checkAutoTimes(update)
                setEdited(true)
              }
            }}>
            <IonLabel>Set to cover meeting time range</IonLabel>
          </IonChip>
        </div>
      )
    }
  }

  function renderViewCalendars (): JSX.Element | undefined {
    if (autoEnabled && calendars?.length && participant) {
      return (
        <MeetingFreeBusyCalendars
          participant={participant}
          accounts={calendars}
          onClick={() => {
            logTap({ component: 'MeetingFreeBusyCalendars', button: 'SelectViewCalendars' })
            setSelectViewCalendars(true)
          }} />
      )
    }
  }

  function renderAutoSwitch (): JSX.Element {
    // const label = timeZone ? `${timeZone} (${moment.tz(timeZone).format('z')})` : ''

    return (
      <div style={{ paddingTop: 20 }}>
        <IonItem
          lines='full'>
          <IonIcon
            icon={autoEnabled ? flash : flashOffOutline}
            style={{ color: autoEnabled ? logoColor : 'gray' }}
            slot='start' />
          <IonLabel>
            <h2>Automagic Scheduling </h2>
            {/* <p>{label} </p> */}
          </IonLabel>
          <IonToggle
            slot='end'
            checked={autoEnabled}
            onIonChange={e => {
              logTap({ component: 'AutoSwitch', button: autoEnabled.toString() })
              console.log('automatic scheduling ', e.detail.checked)

              if (bookCalendar && bookCalendar?.autoBook !== e.detail.checked) {
                const updateBookCalendar: MeetingBookCalendar = {
                  ...bookCalendar,
                  autoBook: e.detail.checked,
                }

                setAutoEnabled(e.detail.checked)
                setBookCalendar(updateBookCalendar)
                setEdited(true)
              }
            }} />
        </IonItem>
      </div>
    )
  }

  return (
    <IonPage
      ref={pageRef}>
      {renderHeader()}
      <IonContent>
        <div
          style={{ flex: 1, height: '100%' }}
          className='titleIconBox'>
          {/* <div
            style={iconBox}>
            <AnimatedMeeting
              style={{ height: 60, width: 60 }} />
          </div> */}
          {renderAutoSwitch()}
          {renderBookCalendar()}
          {renderViewCalendars()}
          {renderAutoBookTimes()}
          {renderWarning()}
        </div>
      </IonContent>
      {renderFooter()}
      {renderModals()}
    </IonPage>
  )
}

export default MeetingCalendarSettings
