import React, { useContext, useEffect } from 'react'
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client'

import {
  GET_MEETINGS,
  GET_MEETING,
  MEETING_SUBSCRIPTION,
  CREATE_MEETING,
  DELETE_MEETING,
  EXIT_MEETING,
  UPDATE_MEETING,
  CreateMeetingInput,
  UpdateMeetingInput,
  JOIN_MEETING,
  GET_PARTICIPANTS,
  UpdateMeetingTimesInput,
  UPDATE_MEETING_TIMES,
  JoinMeetingInput,
} from 'services/api'
import { Meeting } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface MeetingsContextValue {
  loading?: boolean
  meetings?: Meeting[]
  meeting?: Meeting
  createMeeting: (input: CreateMeetingInput) => Promise<Meeting | undefined>
  updateMeeting: (input: UpdateMeetingInput) => Promise<Meeting | undefined>
  updateMeetingTimes: (input: UpdateMeetingTimesInput) => Promise<Meeting | undefined>
  joinMeeting: (input: JoinMeetingInput) => Promise<Meeting | undefined>
  deleteMeeting: (meeting: string) => Promise<string | undefined>
  exitMeeting: (meeting: string) => Promise<string | undefined>
}

const initialValue: MeetingsContextValue = {
  loading: false,
  meetings: [],
  createMeeting: async (input: CreateMeetingInput) => {
    console.log('createMeeting: ', input)

    return undefined
  },
  updateMeeting: async (input: UpdateMeetingInput) => {
    console.log('updateMeeting: ', input)

    return undefined
  },
  updateMeetingTimes: async (input: UpdateMeetingTimesInput) => {
    console.log('updateMeetingTimes: ', input)

    return undefined
  },
  joinMeeting: async (input: JoinMeetingInput) => {
    console.log('joinMeeting: ', input)

    return undefined
  },
  deleteMeeting: async (meeting: string) => {
    console.log('deleteMeeting: ', meeting)

    return undefined
  },
  exitMeeting: async (meeting: string) => {
    console.log('exitMeeting: ', meeting)

    return undefined
  },
}

// create and initialize context
export const MeetingsContext = React.createContext<MeetingsContextValue>(initialValue)

export function useMeetings ():MeetingsContextValue {
  return useContext(MeetingsContext)
}

export type MeetingsMockContextValue = Partial<MeetingsContextValue>

type MockProps = {
  value?: Partial<MeetingsContextValue>
}

export const MeetingsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <MeetingsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </MeetingsContext.Provider>
  )
}

interface ProviderProps {
  meeting?: string
}

const MeetingsProvider: React.FC<ProviderProps> = ({ meeting, children }) => {
  const { logEvent } = useAnalytics()

  const { loading, data: meetingsData, refetch: refetchMeetings } = useQuery(GET_MEETINGS, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: meetingData } = useQuery(GET_MEETING, {
    variables: { id: meeting || '' },
    fetchPolicy: 'cache-and-network',
  })
  const [getParticipants, { refetch: refetchParticipants }] = useLazyQuery(GET_PARTICIPANTS)

  const { data: subscriptionData } = useSubscription(MEETING_SUBSCRIPTION)

  // Only re-run the effect if subscriptionData changes
  useEffect(() => {
    if (subscriptionData && subscriptionData.meetingUpdated) {
      console.log('MeetingsProvider: meeting updated: ', subscriptionData)
      refetchMeetings()

      if (refetchParticipants) {
        refetchParticipants({
          variables: { meeting: subscriptionData.meetingUpdated.id },
        })
      } else {
        getParticipants({ variables: { meeting: subscriptionData.meetingUpdated.id } })
      }
    }
  }, [subscriptionData])

  const [updateMeetingMutation] = useMutation(UPDATE_MEETING)
  const [updateMeetingTimesMutation] = useMutation(UPDATE_MEETING_TIMES)
  const [joinMeetingMutation] = useMutation(JOIN_MEETING)
  const [createMeetingMutation] = useMutation(CREATE_MEETING, {
    update (cache, { data: { createMeeting } }) {
      // console.log('create timeslot update cache')

      try {
        const query = cache.readQuery({ query: GET_MEETINGS }) as {meetings: [Meeting]}

        if (query && query.meetings) {
          const { meetings } = query
          // console.log('cached meetings: ', meetings)
          // console.log('mutation data: ', createTimeSlot)

          // console.log('Cached timeSlots length: ', timeSlots.length)
          if (!meetings.find((m) => m.id === createMeeting.id)) {
            cache.writeQuery({
              query: GET_MEETINGS,
              data: { meetings: meetings.concat([createMeeting]) },
            })
          } else {
            // console.log('probably already got added by')
          }
        }
      } catch {
        console.log('createMeetingMutation: cache update error')
      }
    },
  })

  const [deleteMeetingMutation] = useMutation(DELETE_MEETING, {
    update (cache, { data: { deleteMeeting } }) {
      console.log('Update cache')

      try {
        const query = cache.readQuery({ query: GET_MEETINGS }) as {meetings: [Meeting]}

        if (query && query.meetings) {
          const { meetings } = query

          console.log('cached meetings: ', meetings)
          console.log('mutation data: ', deleteMeeting)
          cache.writeQuery({
            query: GET_MEETINGS,
            data: { meetings: meetings.filter((meeting) => meeting.id !== deleteMeeting.id) },
          })
        }
      } catch {
        console.log('deleteMeetingMutation: cache update error')
      }
    },
  })

  const [exitMeetingMutation] = useMutation(EXIT_MEETING, {
    update (cache, { data: { exitMeeting } }) {
      console.log('Update cache')

      try {
        const query = cache.readQuery({ query: GET_MEETINGS }) as {meetings: [Meeting]}

        if (query && query.meetings) {
          const { meetings } = query

          console.log('cached meetings: ', meetings)
          console.log('mutation data: ', exitMeeting)
          cache.writeQuery({
            query: GET_MEETINGS,
            data: { meetings: meetings.filter((meeting) => meeting.id !== exitMeeting.id) },
          })
        }
      } catch {
        console.log('exitMeetingMutation: cache update error')
      }
    },
  })

  async function createMeeting (input: CreateMeetingInput): Promise<Meeting | undefined> {
    const { data } = await createMeetingMutation({ variables: { input } })

    if (data?.createMeeting?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createMeeting',
          meeting: data?.createMeeting?.id,
        },
      })

      const meeting = data.createMeeting as Meeting

      return meeting
    }
  }

  async function updateMeeting (input: UpdateMeetingInput): Promise<Meeting | undefined> {
    const { data } = await updateMeetingMutation({ variables: { input } })

    if (data?.updateMeeting?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateMeeting',
          meeting: data?.updateMeeting?.id,
        },
      })

      const meeting = data.updateMeeting as Meeting

      return meeting
    }
  }

  async function updateMeetingTimes (
    input: UpdateMeetingTimesInput,
  ): Promise<Meeting | undefined> {
    const { data } = await updateMeetingTimesMutation({ variables: { input } })

    if (data?.updateMeetingTimes?.id) {
      let meetingTime = input.meetingTimes?.length ? 'tentative' : ''

      if (input.meetingTimes?.find(mt => mt.status === 'confirmed')) {
        meetingTime = 'confirmed'
      }

      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateMeetingTimes',
          meeting: data?.updateMeetingTimes?.id,
          meetingTime,
        },
      })

      const meeting = data.updateMeetingTimes as Meeting

      return meeting
    }
  }

  async function joinMeeting (input: JoinMeetingInput): Promise<Meeting | undefined> {
    const { data } = await joinMeetingMutation({ variables: { input } })

    if (data?.joinMeeting?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateMeetingTimes',
          meeting: data?.joinMeeting?.id,
        },
      })

      const meeting = data.joinMeeting as Meeting

      return meeting
    }
  }

  async function exitMeeting (meeting: string): Promise<string | undefined> {
    const { data } = await exitMeetingMutation({ variables: { meeting } })

    if (data?.exitMeeting?.meeting) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'exitMeeting',
          meeting: data?.exitMeeting?.id,
        },
      })

      return meeting
    }
  }

  async function deleteMeeting (meeting: string): Promise<string | undefined> {
    const { data } = await deleteMeetingMutation({ variables: { meeting } })

    if (data?.deleteMeeting?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteMeeting',
          meeting: data?.deleteMeeting?.id,
        },
      })

      return data.deleteMeeting.id
    }
  }

  return (
    <MeetingsContext.Provider
      value={{
        loading,
        meetings: meetingsData?.meetings,
        meeting: meetingData?.meeting,
        createMeeting,
        updateMeeting,
        joinMeeting,
        exitMeeting,
        deleteMeeting,
        updateMeetingTimes,
      }}>
      {children}
    </MeetingsContext.Provider>
  )
}

export default MeetingsProvider
