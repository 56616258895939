import React, { CSSProperties, useState, useEffect } from 'react'
import {
  IonContent, IonLabel, IonItem, IonIcon, IonActionSheet, IonSpinner, IonText, IonFooter, IonPage,
} from '@ionic/react'

import { Avatar } from '@material-ui/core'
import { SubCalendar } from 'types'
import { ellipse, eye, eyeOff, checkmarkCircle } from 'ionicons/icons'
import { ActivateCalendarInput, UpdateCalendarInput } from 'services/api'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Calendar Preferences'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const avatarStyle = {
  width: '15%',
  height: '15%',
  backgroundColor: '#92949C',
}

const iconBox: CSSProperties = {
  padding: '5%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}
const messageBox: CSSProperties = {
  padding: '5%',
  paddingTop: 0,
}

const messageStyle = {
  fontSize: 14,
}

interface ContainerProps {
  calendarId?: string;
  onDone: () => void;
  onSelectBookCalendar: () => void;
  onSelectAutoTimes: () => void;
}

const CalendarSettingsFreeBusy: React.FC<ContainerProps> = ({ calendarId, onSelectBookCalendar, onSelectAutoTimes, onDone }) => {
  const [subCalendars, setSubCalendars] = useState<SubCalendar[]>()
  const [showCalendarActions, setCalendarActions] = useState<SubCalendar>()

  const [loading, setLoading] = useState(true)

  const { calendars, getCalendarDetails, activateCalendar, updateCalendar } = useCalendars()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarSettingsFreeBusy' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'CalendarSettingsFreeBusy',
      },
    })
  }

  async function getCalendars (): Promise<void> {
    if (calendarId && calendars?.length && getCalendarDetails) {
      const calendar = calendars.find(cal => cal.id === calendarId)

      if (calendar) {
        const calendars = await getCalendarDetails(calendar)

        setSubCalendars(calendars)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (calendars?.length && !subCalendars) {
      getCalendars()
    }
  }, [calendars])

  console.log('ALL Calendars: ', calendars)

  function updateUserCalendar (enabled: boolean, freeBusy: boolean): void {
    console.log('update calendar: ', showCalendarActions)

    if (calendarId && showCalendarActions && subCalendars && updateCalendar) {
      const input: UpdateCalendarInput = {
        id: calendarId,
        calendarId: showCalendarActions.id,
        enabled,
        freeBusy,
      }

      updateCalendar(input)

      setSubCalendars(subCalendars.map(c => {
        if (c.id === showCalendarActions.id) {
          return {
            ...c,
            enabled,
            freeBusy,
          }
        }

        return c
      }))
    }
  }

  function renderSubCalendar (subCalendar: SubCalendar, last: boolean): JSX.Element {
    const { primary, name, backgroundColor, enabled, freeBusy } = subCalendar

    function note (): string {
      if (enabled && freeBusy) {
        return 'free-busy'
      } else if (enabled && !freeBusy) {
        return 'view-only'
      } else {
        return 'ignore'
      }
    }

    function icons (): JSX.Element {
      if (enabled && freeBusy) {
        return (
          <IonIcon
            slot='end'
            color='primary'
            style={{ fontSize: 22 }}
            icon={checkmarkCircle} />
        )
      } else if (enabled && !freeBusy) {
        return (
          <IonIcon
            slot='end'
            color='primary'
            style={{ fontSize: 22 }}
            icon={eye} />
        )
      } else {
        return (
          <IonIcon
            slot='end'
            color='medium'
            style={{ fontSize: 22 }}
            icon={eyeOff} />
        )
      }
    }

    return (
      <IonItem
        key={subCalendar.id}
        lines={last ? 'none' : 'inset'}
        onClick={() => {
          logTap({ component: 'IonItem', button: 'SubCalendar' })
          setCalendarActions(subCalendar)
        }}>
        <IonIcon
          slot='start'
          size='small'
          style={{ color: backgroundColor }}
          icon={ellipse} />
        <IonLabel>
          {primary ? 'Primary' : name}
        </IonLabel>
        <IonText
          color='medium'
          slot='end'>
          {note()}
        </IonText>
        {icons()}
      </IonItem>
    )
  }

  function renderSubCalendars (): JSX.Element[] | undefined {
    if (subCalendars && subCalendars.length) {
      const items = subCalendars.filter(c => c.enabled || (!c.hidden && c.selected))

      return items.map((subCalendar, i) => renderSubCalendar(subCalendar, i === items.length - 1))
    }
  }

  async function onActivateCalendar (): Promise<void> {
    if (calendarId && activateCalendar) {
      console.log('onActivateCalendar: ')

      const input: ActivateCalendarInput = {
        id: calendarId,
        active: true,
      }

      try {
        const calendar = await activateCalendar(input)

        console.log('ACTIVATE GOOGLE CALENDAR', calendar)
      } catch (error) {
        if (error instanceof Error) {
          console.log('ACTIVATE CALENDAR ERROR: ', error.message)
        }
      }
    }
  }

  async function onNext (): Promise<void> {
    if (calendars?.length === 1) {
      // if there are multiple free-busy then go to book calendar
      // else go directly to auto times
      const calendar = calendars.find(cal => cal.id === calendarId)

      if (calendar) {
        const freeBusy = calendar.calendars.filter(cal => cal.enabled && cal.freeBusy)

        if (freeBusy?.length > 1) {
          onSelectBookCalendar()
        } else {
          onSelectAutoTimes()
        }
      }
    } else {
      await onActivateCalendar()
      onDone()
    }
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'Next' })
            onNext()
          }}>
          Next
        </FooterButton>
      </IonFooter>
    )
  }

  function renderCalendarAccount (): JSX.Element | undefined {
    if (calendars?.length) {
      const calendar = calendars.find(cal => cal.id === calendarId)

      if (calendar) {
        const { service, account, photo } = calendar

        return (
          <div
            style={iconBox}
            className='titleIconBox'>
            <Avatar
              src={photo}
              style={avatarStyle} />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{account}</h2>
              <p>{service}</p>
            </IonLabel>
          </div>
        )
      }
    }
  }

  function renderLoading (): JSX.Element | undefined {
    if (loading) {
      return (
        <IonItem
          lines='full'
          style={{ textAlign: 'center' }}>
          <IonLabel color='secondary'>
            <IonSpinner
              name='dots' />
          </IonLabel>
        </IonItem>
      )
    }
  }

  function renderMessage (): JSX.Element {
    const text = `Select which of these calendars you want Moica to use to determine when you are free or busy when scheduling.
    Selecting View Only allows you to see appointments on this calendar
    but Moica will not mark you as busy for those times.`

    return (
      <div style={messageBox}>
        <IonText
          color='medium'
          style={messageStyle}>
          {text}
        </IonText>
      </div>
    )
  }

  console.log('CalendarSettingsFreeBusy: subCalendars: ', subCalendars)

  return (
    <IonPage>
      <ScreenHeader title={title} />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          {renderCalendarAccount()}
          {renderMessage()}
          {renderLoading()}
          {renderSubCalendars()}
        </div>
      </IonContent>
      {renderFooter()}
      <IonActionSheet
        isOpen={!!showCalendarActions}
        onDidDismiss={() => setCalendarActions(undefined)}
        buttons={[
          {
            text: 'Use for free-busy',
            handler: () => {
              logTap({ component: 'ActionSheet', button: 'FreeBusy' })
              updateUserCalendar(true, true)
            },
          },
          {
            text: 'View only',
            handler: () => {
              logTap({ component: 'ActionSheet', button: 'ViewOnly' })
              updateUserCalendar(true, false)
            },
          },
          {
            text: 'Ignore',
            role: 'destructive',
            handler: () => {
              logTap({ component: 'ActionSheet', button: 'Ignore' })
              updateUserCalendar(false, false)
            },
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              logTap({ component: 'ActionSheet', button: 'Cancel' })
              console.log('Cancel clicked')
            },
          }]} />
    </IonPage>
  )
}

export default CalendarSettingsFreeBusy
