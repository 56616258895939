import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { useSpring } from 'react-spring'
import { CalendarEvent, Participant } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import { useUser } from 'context/UserContext/UserContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import MeetingTimeListItem from 'components/molecules/MeetingTimeListItem/MeetingTimeListItem'
import AnimatedCheckmark from 'components/atoms/AnimatedCheckmark/AnimatedCheckmark'
import { addToDeviceCalendar } from 'services/deviceCalendar'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { DeviceCalendarAction } from 'services/api'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  textAlign: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

interface ContainerProps {
  onClose: () => void;
  onAddToCalendar: () => void;
  presentingElement: HTMLElement | undefined;
}

const AddToDeviceCalendarModal: React.FC<ContainerProps> = ({ onAddToCalendar, onClose }) => {
  const [showCard] = useState(true)

  const { user } = useUser()
  const { meeting } = useMeetings()
  const { participants, participant, updateParticipant } = useParticipants()

  function renderStatus (): JSX.Element | undefined {
    const confirmed = meeting?.meetingTimes?.find(mt => mt.status === 'confirmed')

    if (confirmed && participant) {
      const { participants } = confirmed
      const { deviceCalendar } = participant

      const active = !!participants?.find(u => u === user?.id)
      let message

      if (deviceCalendar?.updateTime) {
        message = 'There have been changes to the meeting. Please update your Calendar.'
      } else {
        message = 'Would you like to add this meeting to your Calendar?'
      }

      return (
        <div
          className='ion-text-start'>
          <div style={iconBox}>
            <AnimatedCheckmark
              style={{ width: 60, height: 60 }}
              loop={2} />
            <IonText
              style={titleStyle}>
              Meeting is confirmed!
            </IonText>
          </div>
          <div
            style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              {message}
            </IonText>
          </div>
          <div style={{ paddingRight: 20 }}>
            <MeetingTimeListItem
              meetingTime={confirmed}
              active={active}
              lines='none' />
          </div>
        </div>
      )
    }
  }

  function addToCalendar (): void {
    if (meeting && participants && participant) {
      addToDeviceCalendar(meeting, participants)
      updateParticipant({
        id: participant.id,
        deviceCalendar: {
          action: DeviceCalendarAction.updated,
        },
      })
      onAddToCalendar()
    }
  }

  function renderActionButtons (): JSX.Element | undefined {
    if (participant) {
      const { deviceCalendar } = participant

      const label = deviceCalendar ? 'Update your Calendar' : 'Add to your Calendar'

      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton onClick={addToCalendar}>
            {label}
          </FooterButton>
        </IonFooter>
      )
    }
  }

  const popoverProps = useSpring({
    ...(showCard ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderActionsCard (): JSX.Element | undefined {
    if (meeting) {
      return (
        <ActionCard
          title={meeting?.title}
          icon=''
          onClose={onClose}
          animatedProps={popoverProps}>
          {renderStatus()}
          {renderActionButtons()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderActionsCard()}
      </IonContent>
    </IonModal>
  )
}

export default AddToDeviceCalendarModal
