import { DeviceCalendarAction } from 'services/api'

export interface User {
  name?: string;
  id?: string;
  createTime?: string;
  moicaID?: string;
  __typename?: 'User';
}

export type MeetingTime = {
  id?: string;
  startTime: string;
  endTime: string;
  participants: string[];
  status: string;
  updatedBy?: string;
}

export interface Meeting {
  id: string;
  title: string;
  duration: string;
  description?: string;
  createTime?: string;
  createdBy?: string;
  updateTime?: string;
  participants?: string[];
  dayRanges?: TimeRange[];
  timeRanges?: TimeRange[];
  meetingTimes?: MeetingTime[];
  participantsExpected?: number;
  tentativeThreshold?: number;
  videoConference?: VideoConference;
  __typename?: 'Meeting';
}

export interface Invitation {
  id: string;
  meeting: string;
  user: string;
  createTime: string;
}

export interface MeetingInvite {
  id: string;
  meeting: string;
  title: string;
  duration: string;
  participants: number;
  dayRanges?: TimeRange[];
  timeRanges?: TimeRange[];
  invitation: string;
  name: string;
  photo?: string;
}

export enum InviteeStatus {
  invited = 'invited',
  received = 'received',
  seen = 'seen',
  declined = 'declined',
  accepted = 'accepted',
}

export interface Invitee {
  id: string;
  meeting: string;
  user: string;
  name: string;
  moicaID: string;
  photo?: string;
  invitation?: string;
  invitedBy?: string;
  meetMe?: boolean;
  status: InviteeStatus
  createTime: string;
  updateTime: string;
}

export interface Participant {
  id: string;
  user: string;
  moicaID: string;
  name: string;
  admin: boolean;
  creator: boolean;
  createTime: string;
  // this is the moicaID of the invitation creator
  invitedBy: string;
  photo?: string;
  calendars?: ParticipantCalendar[];
  bookCalendar: {
    account: string;
    calendarId: string;
    timeRanges?: TimeRange[];
    autoDays?: string[];
  };
  autoBook: {
    enabled: boolean;
    active: boolean;
  }
  readyToSchedule?: {
    ready: boolean;
    configureStartTime?: string;
    configureEndTime?: string;
  }
  meetMe?: boolean;
  deviceCalendar?: {
    action: DeviceCalendarAction,
    updateTime: string;
  }
}

export interface UserContact {
  id: string;
  email?: string;
  phone?: string;
  photo?: string;
  provider?: string;
  createTime: string;
}

export enum ContactType {
  email = 'email',
  phone = 'phone',
  google = 'google',
}
export interface TimeSlot {
  localId?: string;
  id?: string;
  user?: string;
  meeting: string;
  startTime: string;
  endTime: string;
  available?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  autoCreated?: boolean;
  __typename?: 'TimeSlot';
}

export interface TimeRange {
  startTime: string;
  endTime: string;
  participants?: string[];
  timeSlot?: string;
  __typename?: 'TimeRange';
}

export interface CalendarAccount {
  id: string;
  user: string;
  service: string;
  account: string;
  photo: string;
  calendars: Calendar[];
  accessTokens: {
    accessToken: string;
    refreshToken: string;
    expireTime: string;
  };
  createTime: string;
}

interface Calendar {
  calendarId: string;
  enabled: boolean;
  freeBusy: boolean;
  primary?: boolean;
  timeZone?: string;
  timeRanges?: TimeRange[];
  autoDays?: string[]
}

export interface CalendarEvent {
  id: string;
  summary: string;
  startTime: string;
  endTime: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

export interface SubCalendar {
  id: string;
  account: string;
  name?: string;
  primary?: boolean;
  enabled?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  timeZone?: string;
  freeBusy?: boolean;
  hidden?: boolean;
  selected?: boolean;
}

export interface ParticipantCalendar {
  calendarId: string;
  account: string;
  enabled?: boolean;
  freeBusy?: boolean;
}

export interface UserDevice {
  id: string;
  device: string;
  createTime: string;
  accessTime: string;
  browser: string;
  ip: string;
}

export interface Preferences {
  id: string;
  bookCalendar: {
    account: string;
    calendarId: string;
  };
  timeZone: string
}

export interface VideoConference {
  link: string;
}

export interface Connection {
  id: string;
  user: string;
  connectedTo: string;
  name: string;
  moicaID: string;
  photo?: string;
  createTime: string;
}

export interface MeetUser {
  user: string;
  name: string;
  moicaID: string;
  photo?: string;
}
