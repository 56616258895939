
import CryptoJS from 'crypto-js'
import { account, findAccount, initSession } from '../accounts'
import { getLocalStore } from 'services/store/configure'
import { updateAccount } from 'services/store/accounts/actions'

const key = process.env.REACT_APP_TOKEN_KEY || 'tokenKey123'

// AccessTokens are stores in localStorage so they can be accessed by differt tabs
// if the user wants to swith between different accounts
export function storeAccessToken (device: string, token: string): void {
  if (device && token) {
    const account = findAccount(device)

    if (account) {
      const ciphertext = CryptoJS.AES.encrypt(token, key)

      getLocalStore().dispatch(updateAccount({
        ...account,
        accessToken: ciphertext.toString(),
      }))
      // if we are reseting access token then we also need to initialize this session again
      initSession(account.id)
    }
  }
}

export function getAccessToken (): string | undefined {
  if (account?.accessToken) {
    const token = account.accessToken
    const bytes = CryptoJS.AES.decrypt(token, key)
    const plaintext = bytes.toString(CryptoJS.enc.Utf8)

    return plaintext
  }
}

export function encryptedAccessToken (): string | undefined {
  if (account?.accessToken) {
    return account.accessToken
  }
}

export function storeEncryptedAccessToken (token: string): void {
  if (account) {
    getLocalStore().dispatch(updateAccount({
      ...account,
      accessToken: token,
    }))
  }
}
