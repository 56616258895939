import React, { useContext, useEffect } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'

import { CreateInviteeInput, CREATE_INVITEE, DELETE_INVITEE, GET_MEETING_INVITEES, GET_USER_INVITATIONS, GET_USER_INVITEES, INVITEE_SUBSCRIPTION, UpdateInviteeInput, UPDATE_INVITEE } from 'services/api'
import { Invitee, MeetingInvite } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface InviteesContextValue {
  loading?: boolean
  invitees?: Invitee[]
  meetingInvitees?: Invitee[]
  invitations?: MeetingInvite[]
  createInvitee: (input: CreateInviteeInput) => Promise<Invitee[] | undefined>
  updateInvitee: (input: UpdateInviteeInput) => Promise<Invitee | undefined>
  deleteInvitee: (id: string) => Promise<string | undefined>
  refetch: () => void
}

export const initialValue: InviteesContextValue = {
  loading: false,
  invitees: [],
  meetingInvitees: [],
  invitations: [],
  createInvitee: async (input: CreateInviteeInput) => {
    console.log('createInvitee: ', input)

    return undefined
  },
  updateInvitee: async (input: UpdateInviteeInput) => {
    console.log('updateInvitee: ', input)

    return undefined
  },
  deleteInvitee: async (id: string) => {
    console.log('deleteInvitee: ', id)

    return undefined
  },
  refetch: () => { return undefined },
}

// create and initialize context
export const InviteesContext = React.createContext<InviteesContextValue>(initialValue)

export function useInvitees (): InviteesContextValue {
  return useContext(InviteesContext)
}

export type InviteesMockContextValue = Partial<InviteesContextValue>

type MockProps = {
  value?: Partial<InviteesContextValue>
}

export const InviteesMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <InviteesContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </InviteesContext.Provider>
  )
}

interface ProviderProps {
  meeting?: string,
}

const InviteesProvider: React.FC<ProviderProps> = ({ meeting, children }) => {
  const { logEvent } = useAnalytics()

  const { loading, data: invitationsData, refetch: refetchInvitations } = useQuery(GET_USER_INVITATIONS, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: inviteesData, refetch: refetchInvitees } = useQuery(GET_USER_INVITEES, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: meetingInviteesData, refetch: refetchMeetingInvitees } = useQuery(GET_MEETING_INVITEES, {
    variables: { meeting: meeting || '' },
    fetchPolicy: 'cache-and-network',
  })
  const { data: inviteeSubscriptionData } = useSubscription(INVITEE_SUBSCRIPTION)

  const [updateInviteeMutation] = useMutation(UPDATE_INVITEE)
  const [createInviteeMutation] = useMutation(CREATE_INVITEE,
    {
      update (cache, { data: { createInvitee } }) {
        console.log('createInviteeMutation: createInvitee: ', createInvitee)

        // const meeting = createInvitee[0].meeting

        // create invitee only happens in the meeting context so update the GET_MEETING_INVITEES query
        try {
          let query

          if (meeting) {
            query = cache.readQuery({
              query: GET_MEETING_INVITEES,
              variables: { meeting },
            }) as { invitees: [Invitee]}
          } else {
            query = cache.readQuery({
              query: GET_USER_INVITEES,
            }) as { invitees: [Invitee]}
          }

          // const query = cache.readQuery({ query: GET_MEETING_INVITEES, variables: { meeting } }) as { invitees: [Invitee]}

          if (query && query.invitees) {
            const { invitees } = query

            console.log('cached invitees: ', invitees)
            // console.log('mutation data: ', createTimeSlot)

            // console.log('Cached timeSlots length: ', timeSlots.length)
            const newInvitees = createInvitee.filter((invitee: Invitee) => !invitees.find(i => i.id === invitee.id))

            if (newInvitees.length) {
              cache.writeQuery({
                query: meeting ? GET_MEETING_INVITEES : GET_USER_INVITEES,
                data: { invitees: invitees.concat(newInvitees) },
              })
              console.log('Added invitee to cache ', newInvitees)
            } else {
              // console.log('probably already got added by')
            }
          }
        } catch (error) {
          console.log('createInviteeMutation: cache update error: ', error)
        }
      },
    })

  const [deleteInviteeMutation] = useMutation(DELETE_INVITEE,
    {
      update (cache, { data: { deleteInvitee } }) {
        console.log('deleteInvitee: Update cache')

        let query

        if (meeting) {
          query = cache.readQuery({
            query: GET_MEETING_INVITEES,
            variables: { meeting },
          }) as { invitees: [Invitee]}
        } else {
          query = cache.readQuery({
            query: GET_USER_INVITEES,
          }) as { invitees: [Invitee]}
        }

        if (query && query.invitees) {
          const { invitees } = query

          cache.writeQuery({
            query: meeting ? GET_MEETING_INVITEES : GET_USER_INVITEES,
            data: { invitees: invitees.filter(invitee => invitee.id !== deleteInvitee.id) },
          })
        }
      },
    })

  // Only re-run the effect if subscriptionData changes
  useEffect(() => {
    if (inviteeSubscriptionData && inviteeSubscriptionData.inviteeUpdated) {
      console.log('Meetings screen: invitee updated: ', inviteeSubscriptionData)

      refetch()
    }
  }, [inviteeSubscriptionData])

  function refetch (): void {
    if (refetchInvitees) {
      refetchInvitees()
    }

    if (refetchInvitations) {
      refetchInvitations()
    }

    if (refetchMeetingInvitees) {
      refetchMeetingInvitees()
    }
  }

  async function createInvitee (input: CreateInviteeInput): Promise<Invitee[] | undefined> {
    const { data } = await createInviteeMutation({ variables: { input } })

    const { meeting, invitation, meetMe } = input

    if (Array.isArray(data?.createInvitee)) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'createInvitee',
          meeting,
          invitation,
          meetMe,
        },
      })

      return data.createInvitee
    }
  }

  async function updateInvitee (input: UpdateInviteeInput): Promise<Invitee | undefined> {
    const { data } = await updateInviteeMutation({ variables: { input } })

    if (data?.updateInvitee?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateInvitee',
          invitee: input.id,
          status: input.status,
        },
      })

      return data?.updateInvitee
    }
  }

  async function deleteInvitee (id: string): Promise<string | undefined> {
    const { data } = await deleteInviteeMutation(
      { variables: { input: { id } } })

    if (data?.deleteInvitee?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteInvitee',
          invitee: id,
        },
      })

      return data?.deleteInvitee?.id
    }
  }

  return (
    <InviteesContext.Provider
      value={{
        loading,
        invitees: inviteesData?.invitees,
        meetingInvitees: meetingInviteesData?.invitees,
        invitations: invitationsData?.userInvitations,
        createInvitee,
        updateInvitee,
        deleteInvitee,
        refetch,
      }}>
      {children}
    </InviteesContext.Provider>
  )
}

export default InviteesProvider
