import React, { useEffect } from 'react'
import {
  IonContent, IonFooter, IonPage,
} from '@ionic/react'
// import { useSpring } from 'react-spring'

import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
// import { useUser } from 'context/UserContext/UserContext'
// import TipCard from 'components/organisms/TipCard/TipCard'
import { useTabs } from 'navigation/TabsContext'
import AnimatedMeeting from 'components/atoms/AnimatedMeeting/AnimatedMeeting'
import AnimatedPeople from 'components/atoms/AnimatedPeople/AnimatedPeople'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const title = 'Moica'

interface ScreenProps {
  onCreateMeeting: () => void
}

const FirstMeeting: React.FC<ScreenProps> = ({ onCreateMeeting }) => {
  // const [showTip, setShowTip] = useState('')
  // const { user } = useUser()
  const { setShowTabs } = useTabs()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    // setTimeout(() => {
    //   setShowTip('start')
    // }, 1000)
    setShowTabs && setShowTabs(false)
  }, [])

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'DayAvailability' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'FirstMeeting',
      },
    })
  }

  // function renderName (): JSX.Element | undefined {
  //   if (showName && user?.name) {
  //     return (
  //       <ScreenCenter height='80%'>
  //         <Spring
  //           from={{ opacity: 0 }}
  //           to={showName ? { opacity: 1 } : { opacity: 0 }}>
  //           {props =>
  //             <div style={props}>
  //               <span
  //                 data-ci-id='greeting'
  //                 style={{ fontWeight: 'bold', fontSize: 22 }}>
  //                 Hi {user.name}!
  //               </span>
  //             </div>}
  //         </Spring>
  //       </ScreenCenter>

  //     )
  //   }
  // }

  // function renderMeeting (): JSX.Element | undefined {
  //   if (!showName) {
  //     return (
  //       <Spring
  //         from={{ opacity: 0 }}
  //         to={!showName ? { opacity: 1 } : { opacity: 0 }}>
  //         {props =>
  //           <div style={props}>
  //             <FirstMeetingCard
  //               meeting={{
  //                 id: 'meeting',
  //                 title: 'First Meeting',
  //                 duration: '60',
  //               }}
  //               tip={showTip}
  //               onEdit={onCreateMeeting} />
  //           </div>}
  //       </Spring>

  //     )
  //   }
  // }

  function renderCreateMeeting (): JSX.Element | undefined {
    return (
      <ScreenCenter height='80%'>
        <AnimatedPeople
          loop
          style={{ height: 300, width: 300, marginTop: -200 }} />
        <AnimatedMeeting
          loop
          style={{ height: 100, width: 100, marginTop: -40 }} />

      </ScreenCenter>
    )
  }

  // const nameTipProps = useSpring({
  //   ...(showTip === 'start' ? { opacity: 1, bottom: 40 } : { opacity: 0, bottom: -100 }),
  // })

  // function renderStartTip (): JSX.Element | undefined {
  //   if (showTip === 'start' && user?.name) {
  //     return (
  //       <TipCard
  //         animatedProps={nameTipProps}>
  //         <IonText
  //           data-ci-id='greeting'
  //           style={{ fontWeight: 'bold', fontSize: 22 }}>
  //           Hi {user.name}! <br />
  //         </IonText>
  //         <IonText style={{ fontSize: 16 }}>
  //           Tap Create Meeting to set up your first meeting.
  //         </IonText>
  //       </TipCard>
  //     )
  //   }
  // }

  return (
    <IonPage>
      <ScreenHeader
        title={title} />
      <IonContent>
        {renderCreateMeeting()}
      </IonContent>
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'CreateFirstMeeting' })
            onCreateMeeting()
          }}>
          Create a Meeting
        </FooterButton>
      </IonFooter>
    </IonPage>
  )
}

export default FirstMeeting
