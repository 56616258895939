import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'

import 'screens/Settings.css'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import MeetingDescription from './MeetingDescription'

const MeetingParticipantsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { meetingDescription } = useSelector((state: RootState) => state.screens)

  function goBack (): void {
    history.goBack()
  }

  return (
    <MeetingsProvider meeting={meetingDescription?.meeting}>
      <MeetingDescription
        goBack={goBack} />
    </MeetingsProvider>
  )
}

export default MeetingParticipantsScreen
