import React, { CSSProperties, useState, useRef, useEffect } from 'react'
import {
  IonContent, IonPage, IonText,
} from '@ionic/react'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { useMeetUser } from 'context/MeetUserContext/MeetUserContext'
import LoginModal from 'components/organisms/LoginModal'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import MeetMe from 'components/organisms/MeetMe/MeetMe'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'
import { getAccounts } from 'services/accounts'

const titleBox: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

const welcomeText: CSSProperties = {
  fontSize: 20,
  textAlign: 'center',
}

interface ComponentProps {
  onNewUser: (meetUserId: string) => void;
  onLogin: (verifyToken: string, meetUserId: string) => void;
  onSelectAccounts: (meetUserId: string) => void;
}

const StartMeetUser: React.FC<ComponentProps> = ({ onNewUser, onLogin, onSelectAccounts }) => {
  const [showLogin, setShowLogin] = useState(false)

  const { meetUser, error } = useMeetUser()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'StartMeetUser' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'StartMeetUser',
      },
    })
  }

  function onAccept (): void {
    const storeAccounts = getAccounts()?.filter(a => a.moicaID)

    if (storeAccounts?.length && meetUser) {
      onSelectAccounts(meetUser?.user)
    } else {
      setShowLogin(true)
    }
  }

  function renderError (): JSX.Element | undefined {
    if (error) {
      return (
        <ScreenCenter height='80%'>
          <IonText style={{ fontSize: 32 }}>
            🙁 <br />
          </IonText>
          {error}
        </ScreenCenter>
      )
    }
  }

  function renderTitle (): JSX.Element {
    return (
      <div style={titleBox}>
        <IonText
          color='dark'
          style={welcomeText}>
          <span style={{ fontWeight: 'bold', fontSize: 22 }}>
            {meetUser?.name || 'Welcome to Moica'}
          </span><br />
        </IonText>
      </div>
    )
  }

  if (error) {
    const showError = error === 'inviteNotFound' ? 'Invite not found' : 'Error!'

    return (
      <IonPage>
        <IonContent>
          {renderTitle()}
          <ScreenCenter height='80%'>
            <IonText style={{ fontSize: 32 }}>
              🙁 <br />
            </IonText>
            {showError}
          </ScreenCenter>
        </IonContent>
      </IonPage>
    )
  }

  function renderLoginModal (): JSX.Element | undefined {
    if (showLogin) {
      return (
        <LoginModal
          onNewUser={() => {
            logTap({ component: 'LoginModal', button: 'NewUser' })
            setShowLogin(false)
            meetUser?.user && onNewUser(meetUser.user)
          }}
          onLogin={verifyToken => {
            logTap({ component: 'LoginModal', button: 'Login' })
            setShowLogin(false)
            meetUser?.user && onLogin(verifyToken, meetUser.user)
          }}
          presentingElement={pageRef.current}
          onClose={() => {
            logTap({ component: 'LoginModal', button: 'Close' })
            setShowLogin(false)
          }} />
      )
    }
  }

  function renderMeetUser (): JSX.Element | undefined {
    if (meetUser) {
      return (
        <MeetMe
          meetUser={meetUser}
          onMeet={() => {
            logTap({ component: 'MeetMe', button: 'Meet' })
            onAccept()
          }} />
      )
    }
  }

  return (
    <IonPage ref={pageRef}>
      <ScreenHeader
        title='Meet' />
      <IonContent>
        {renderMeetUser()}
        {renderError()}
        {renderLoginModal()}
      </IonContent>
    </IonPage>
  )
}

export default StartMeetUser
