import React, { useState, CSSProperties, useEffect } from 'react'
import moment from 'moment'
import { IonToast, IonText } from '@ionic/react'

import 'theme/CalendarA.css'
import CalendarBox from '../CalendarBox/CalendarBox'
import TimeBar from '../CalendarTimeBar/TimeBar'
import calendarConfig from './config'
import { Meeting, CalendarAccount, CalendarEvent } from 'types'

import DaysBar from '../CalendarDaysBar/DaysBar'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { MeetingBookCalendar, useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { CalendarViewContext, useCalendarView } from './CalendarViewContext'

const defaultDays = 30

const container: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  WebkitUserSelect: 'none',
}

interface CalendarProps {
  tip?: boolean;
  meeting: Meeting;
  meetings?: Meeting[];
  reload?: number;
  hide?: boolean;
  editCalendar?: boolean;
  onSelectTime?: (time: string) => void;
  onSelectDay?: (time: string) => void;
  onStartEditCalendar: () => void;
}

const ScheduleCalendar: React.FC<CalendarProps> = ({ meeting, meetings, tip, reload, hide, editCalendar, onStartEditCalendar, onSelectTime, onSelectDay }) => {
  const [startDay, setStartDay] = useState(0)
  const [currentDay, setCurrentDay] = useState(0)
  const [endDay, setEndDay] = useState(defaultDays)
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>()
  const [showToast, setShowToast] = useState('')

  const { participant } = useParticipants()
  const { calendars, calendarUpdated, getMeetingCalendar, getCalendarEvents } = useCalendars()

  async function getAllCalendarEvents (calendars?: CalendarAccount[]): Promise<void> {
    let events: CalendarEvent[] = []

    if (calendars) {
      if (meeting && meeting.dayRanges && meeting.dayRanges.length > 0) {
        const sorted = meeting.dayRanges.slice().sort((a, b) => {
          const timeA = moment(a.startTime).valueOf()
          const timeB = moment(b.startTime).valueOf()

          return timeA - timeB
        })

        const range = {
          startTime: sorted[0].startTime,
          endTime: sorted[sorted.length - 1].endTime,
        }

        // NOTE: don't use Promises.all as the googleapi client can't handle multiple accessTokens
        // in parallel
        for (let i = 0; i < calendars.length; i++) {
          const result = await getCalendarEvents({
            account: calendars[i],
            calendars: participant?.calendars,
            range,
          })

          events = events.concat(result)
        }

        console.log('ALL CALENDAR EVENTS: ', events?.length)
      }
    } else if (meetings) {
      // get other confirmed meeting times
      const confirmedMeetings = meetings.filter(m => m.id !== meeting.id && m.meetingTimes?.find(mt => mt.status === 'confirmed'))

      confirmedMeetings.forEach(m => {
        const mt = m.meetingTimes?.find(mt => mt.status === 'confirmed')

        if (mt) {
          events.push({
            id: m.id,
            summary: `✅ ${m.title}`,
            startTime: mt.startTime,
            endTime: mt.endTime,
          })
        }
      })
    }

    setCalendarEvents(events)
  }

  async function getBookCalendar (): Promise<void> {
    if (calendars && participant) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)
      }
    }
  }

  // async function getMeetingCalendar (calendars: CalendarAccount[], account: string, calendarId: string): Promise<void> {
  //   const calendarAccount = calendars.find(cal => cal.id === account)

  //   if (calendarAccount) {
  //     const calendar = calendarAccount.calendars.find(cal => cal.calendarId === calendarId)

  //     if (calendar && participantData?.participant?.autoBook?.active &&
  //       participantData?.participant?.autoBook?.enabled) {
  //       if (participantData?.participant.bookCalendar?.timeRanges) {
  //         const { timeRanges, autoDays: days } = participantData.participant.bookCalendar

  //         setAutoTimeRanges(timeRanges)
  //         setAutoDays(days)
  //         // showAutoBookTimesToast(timeRanges)
  //       } else {
  //         const { timeRanges, autoDays: days } = calendar

  //         if (Array.isArray(timeRanges) && timeRanges.length) {
  //           setAutoTimeRanges(timeRanges)
  //           setAutoDays(days)
  //           // showAutoBookTimesToast(timeRanges)
  //         }
  //       }
  //     } else {
  //       setAutoTimeRanges(undefined)
  //       setAutoDays([])
  //       // showAutoBookTimesToast()
  //     }
  //   }
  // }

  useEffect(() => {
    if (calendars?.length && participant) {
      console.log('CalendarA: GET ALL EVENTS AND BOOK CALENDAR ')
      console.log(calendars)
      console.log(participant)
      getAllCalendarEvents(calendars)
      getBookCalendar()
    } else {
      getAllCalendarEvents()
    }
  }, [calendars, participant])

  useEffect(() => {
    console.log('CalendarBox: calendarUpdated', calendarUpdated)

    if (calendarUpdated && calendars?.length) {
      getAllCalendarEvents(calendars)
    }
  }, [calendarUpdated])

  return (
    <CalendarViewContext.Provider value={calendarConfig}>
      <div style={{ ...container, ...(hide ? { display: 'none' } : {}) }}>
        <TopBox>
          <Month
            startDay={startDay}
            currentDay={currentDay} />
          <DaysBar
            startDay={startDay}
            endDay={endDay}
            autoBook={bookCalendar?.autoBook}
            autoDays={bookCalendar?.autoBook ? bookCalendar?.autoDays : undefined}
            calendarEvents={calendarEvents}
            onSelectDay={onSelectDay} />
        </TopBox>
        <BottomBox>
          <TimeBarBox>
            <TimeBar bookCalendar={bookCalendar} />
          </TimeBarBox>
          <BottomRightBox>
            <CalendarBox
              startDay={startDay}
              currentDay={currentDay}
              endDay={endDay}
              meeting={meeting}
              reload={reload}
              bookCalendar={bookCalendar}
              calendarEvents={calendarEvents}
              tip={tip}
              editCalendar={editCalendar}
              onStartEditCalendar={onStartEditCalendar}
              setStartDay={(day) => setStartDay(day)}
              setCurrentDay={(day) => setCurrentDay(day)}
              setEndDay={(day) => setEndDay(day)}
              onSelectTime={onSelectTime} />
          </BottomRightBox>
        </BottomBox>

        <IonToast
          isOpen={!!showToast}
          color='warning'
          onDidDismiss={() => setShowToast('')}
          message={showToast}
          duration={4000} />
      </div>
    </CalendarViewContext.Provider>
  )
}

const BottomRightBox: React.FC = ({ children }) => {
  const { timeLabelWidth } = useCalendarView()
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: timeLabelWidth,
    right: 0,
    bottom: 0,
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const TopBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    // height: dayLabelHeight,
    display: 'flex',
    marginTop: 10,
    borderBottom: '0.5px solid gray',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const BottomBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    flex: 1,
    display: 'flex',
    position: 'relative',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const TimeBarBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

interface MonthProps {
  startDay: number;
  currentDay: number;
  autoBook?: boolean;
}

const Month: React.FC<MonthProps> = ({ startDay, currentDay, autoBook }) => {
  const { timeLabelWidth, dayLabelHeight, allDayEventsBoxHeight } = useCalendarView()
  const container: CSSProperties = {
    width: timeLabelWidth,
    display: 'flex',
    flexDirection: 'column',
  }
  const monthBox: CSSProperties = {
    width: timeLabelWidth,
    height: dayLabelHeight,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  }
  const allDayBox: CSSProperties = {
    width: timeLabelWidth,
    height: allDayEventsBoxHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const monthLabel: CSSProperties = {
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: 20,
    top: -3,
  }
  const allDayLabel: CSSProperties = {
    fontSize: 12,
  }
  const month = moment().add(startDay + currentDay, 'days').format('MMM')

  return (
    <div style={container}>
      <div style={monthBox}>
        <IonText style={monthLabel}>
          {month}
        </IonText>
      </div>
      {autoBook &&
        <div
          className='CalendarABorderRight'
          style={allDayBox}>
          <IonText
            className='CalendarATimeLabel'
            style={allDayLabel}>
            all-day
          </IonText>
        </div>}
    </div>

  )
}

export default ScheduleCalendar
