import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'services/store'
import 'screens/Settings.css'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import ChooseAvailability from './ChooseAvailability'
import { updateScreens } from 'services/store/screens/actions'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'
import ContactsProvider from 'context/ContactsContext/ContactsContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'
import ConnectionsProvider from 'context/Connections/ConnectionsContext'
import paths from 'navigation/paths'
import MeetingNotifyProvider from 'context/MeetingNotifyContext/MeetingNotifyContext'
import StoreParticipantsProvider from 'context/StoreParticipantsContext/StoreParticipantsContext'
import TokensProvider from 'context/TokensContext/TokensContext'

const ChooseAvailabilityScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { chooseAvailability } = useSelector((state: RootState) => state.screens)

  const dispatch = useDispatch()

  function onMeetingSettings (): void {
    if (chooseAvailability?.meeting) {
      dispatch(updateScreens({ meetingSettings: { meeting: chooseAvailability?.meeting } }))
      history.push(paths.meetingSettings)
    }
  }

  function disableAutoEdit (): void {
    dispatch(updateScreens({
      chooseAvailability: {
        ...chooseAvailability,
        disableAutoEdit: true,
      },
    }))
  }

  function onMeetingInvite (byLink: boolean): void {
    if (chooseAvailability?.meeting) {
      // dispatch(updateScreens({ shareMeetingInvite: { meeting: chooseAvailability?.meeting } }))
      // history.push('/tabs/meetings/shareMeetingInvite')
      if (byLink) {
        dispatch(updateScreens({
          shareMeetingInvite: {
            meeting: chooseAvailability?.meeting,
            nextScreen: paths.meetingsList,
          },
          meetingsList: {
            firstCreatedMeeting: true,
          },
          chooseAvailability: {
            ...chooseAvailability,
          },
        }))
        history.push('/tabs/meetings/shareMeetingInvite')
      } else {
        dispatch(updateScreens({
          addParticipants: {
            meeting: chooseAvailability?.meeting,
            nextScreen: paths.meetingsList,
          },
        }))
        history.push(paths.addParticipants)
      }

      disableAutoEdit()
    }
  }

  function onCalendarAccounts (): void {
    dispatch(updateScreens({ calendarAccounts: { previous: location.pathname } }))
    disableAutoEdit()
    history.push('/tabs/settings/calendarAccounts')
  }

  function onSettings (): void {
    disableAutoEdit()
    history.push('/tabs/settings/account?showTip=notify')
  }

  function onDayAvailability (selectedTime: string): void {
    if (chooseAvailability?.meeting) {
      dispatch(updateScreens({ dayAvailability: { meeting: chooseAvailability?.meeting, selectedTime } }))
      disableAutoEdit()
      history.push('/tabs/meetings/dayAvailability')
    }
  }

  function onParticipants (): void {
    if (chooseAvailability?.meeting) {
      dispatch(updateScreens({ meetingParticipants: { meeting: chooseAvailability?.meeting } }))
      disableAutoEdit()
      history.push('/tabs/meetings/meetingParticipants')
    }
  }

  function goBack (): void {
    dispatch(updateScreens({ chooseAvailability: { meeting: '', previous: '' } }))
    history.goBack()
  }

  function onAddContact (url?: string): void {
    dispatch(updateScreens({
      chooseAvailability: {
        ...chooseAvailability,
        newUser: false,
      },
    }))
    history.push(url || paths.newUserAddContact)
  }

  return (
    <PreferencesProvider>
      <TokensProvider>
        <ContactsProvider>
          <ConnectionsProvider>
            <CalendarsProvider>
              <MeetingsProvider meeting={chooseAvailability?.meeting}>
                <InviteesProvider meeting={chooseAvailability?.meeting}>
                  <ParticipantsProvider meeting={chooseAvailability?.meeting}>
                    <TimeSlotsProvider meeting={chooseAvailability?.meeting}>
                      <StoreParticipantsProvider>
                        <MeetingNotifyProvider>
                          <ChooseAvailability
                            newUser={chooseAvailability?.newUser}
                            onMeetingSettings={onMeetingSettings}
                            onMeetingInvite={onMeetingInvite}
                            onCalendarAccounts={onCalendarAccounts}
                            onSettings={onSettings}
                            onDayAvailability={onDayAvailability}
                            onParticipants={onParticipants}
                            onAddContact={onAddContact}
                            showList={chooseAvailability?.showList}
                            goBackTo={chooseAvailability?.previous}
                            goBack={goBack} />
                        </MeetingNotifyProvider>
                      </StoreParticipantsProvider>
                    </TimeSlotsProvider>
                  </ParticipantsProvider>
                </InviteesProvider>
              </MeetingsProvider>
            </CalendarsProvider>
          </ConnectionsProvider>
        </ContactsProvider>
      </TokensProvider>
    </PreferencesProvider>
  )
}

export default ChooseAvailabilityScreen
