import React, { useContext, useEffect } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'

import {
  DeleteParticipantInput, DELETE_PARTICIPANT,
  GET_PARTICIPANT, GET_PARTICIPANTS, MEETING_SUBSCRIPTION, UpdateParticipantInput, UPDATE_PARTICIPANT,
} from 'services/api'
import { Participant } from 'types'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

export interface ParticipantsContextValue {
  loading?: boolean
  participants?: Participant[]
  participant?: Participant
  // createParticipants?: (input: CreateParticipantsInput) => Promise<Participant[] | undefined>
  updateParticipant: (input: UpdateParticipantInput) => Promise<Participant | undefined>
  deleteParticipant: (input: DeleteParticipantInput) => Promise<string | undefined>
}

const initialValue: ParticipantsContextValue = {
  loading: false,
  participants: [],
  updateParticipant: async (input: UpdateParticipantInput) => {
    console.log('updateParticipant: ', input)

    return undefined
  },
  deleteParticipant: async (input: DeleteParticipantInput) => {
    console.log('deleteParticipant: ', input)

    return undefined
  },
}

// create and initialize context
export const ParticipantsContext = React.createContext<ParticipantsContextValue>(initialValue)

export function useParticipants (): ParticipantsContextValue {
  return useContext(ParticipantsContext)
}

export type ParticipantsMockContextValue = Partial<ParticipantsContextValue>

type MockProps = {
  value?: Partial<ParticipantsContextValue>
}

export const ParticipantsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <ParticipantsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </ParticipantsContext.Provider>
  )
}

interface ProviderProps {
  meeting?: string,
}

const ParticipantsProvider: React.FC<ProviderProps> = ({ meeting, children }) => {
  const { logEvent } = useAnalytics()

  const { loading, data: participantsData, refetch } = useQuery(GET_PARTICIPANTS, {
    variables: { meeting: meeting || '' },
    fetchPolicy: 'cache-and-network',
  })

  const { data: participantData } = useQuery(GET_PARTICIPANT, {
    variables: { meeting: meeting || '' },
    fetchPolicy: 'cache-and-network',
  })

  const { data: subscriptionData } = useSubscription(MEETING_SUBSCRIPTION)

  useEffect(() => {
    if (subscriptionData && subscriptionData.meetingUpdated) {
      console.log('ParticipantsProvider: meeting updated: ', subscriptionData)

      if (subscriptionData.meetingUpdated.id === meeting) {
        console.log('ParticipantsProvider: refetch participants')
        refetch()
      }
    }
  }, [subscriptionData])

  const [updateParticipantMutation] = useMutation(UPDATE_PARTICIPANT)
  // const [createParticipantsMutation] = useMutation(CREATE_PARTICIPANTS,
  //   {
  //     update (cache, { data: { createParticipants } }) {
  //       // console.log('create timeslot update cache')

  //       const { participants } = cache.readQuery({ query: GET_PARTICIPANTS, variables: { meeting: meeting || '' } }) as { participants: [Participant]}

  //       // console.log('cached meetings: ', meetings)
  //       // console.log('mutation data: ', createTimeSlot)

  //       const addParticipants: Participant[] = []

  //       createParticipants.forEach((participant: Participant) => {
  //         if (!participants.find(p => p.id === participant.id)) {
  //           addParticipants.push(participant)
  //         }
  //       })
  //       cache.writeQuery({
  //         query: GET_PARTICIPANTS,
  //         variables: { meeting },
  //         data: { participants: participants.concat(addParticipants) },
  //       })
  //     },
  //   })

  const [deleteParticipantMutation] = useMutation(DELETE_PARTICIPANT,
    {
      update (cache, { data: { deleteParticipant } }) {
        console.log('Update cache')

        const { participants } = cache.readQuery({ query: GET_PARTICIPANTS, variables: { meeting: meeting || '' } }) as { participants: [Participant]}

        console.log('cached participants: ', participants)
        console.log('mutation data: ', deleteParticipant)
        cache.writeQuery({
          query: GET_PARTICIPANTS,
          data: { participants: participants.filter(participant => participant.id !== deleteParticipant.id) },
        })
      },
    })

  // async function createParticipants (input: CreateParticipantsInput): Promise<Participant[] | undefined> {
  //   const { data } = await createParticipantsMutation(
  //     { variables: { input } },
  //   )

  //   if (data?.createParticipants?.length) {
  //     return data.createParticipants as Participant[]
  //   }
  // }

  async function updateParticipant (input: UpdateParticipantInput): Promise<Participant | undefined> {
    const { data } = await updateParticipantMutation(
      { variables: { input } },
    )

    if (data?.updateParticipant?.id) {
      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'updateParticipant',
          participant: data.updateParticipant.id,
        },
      })

      const participant = data.updateMeeting as Participant

      return participant
    }
  }
  async function deleteParticipant (input: DeleteParticipantInput): Promise<string | undefined> {
    const { data } = await deleteParticipantMutation(
      { variables: { input } },
    )

    if (data?.deleteParticipant?.id) {
      console.log('REFETCH PARTICIPANTS')
      refetch()

      logEvent({
        eventName: EventName.mutation,
        eventData: {
          mutation: 'deleteParticipant',
          participant: data.deleteParticipant.id,
        },
      })

      return data.deleteParticipant.id
    }
  }

  return (
    <ParticipantsContext.Provider
      value={{
        loading,
        participants: participantsData?.participants,
        participant: participantData?.participant,
        updateParticipant,
        deleteParticipant,
      }}>
      {children}
    </ParticipantsContext.Provider>
  )
}

export default ParticipantsProvider
