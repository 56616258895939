import React, { CSSProperties, useEffect, useState } from 'react'
import { IonContent, IonFooter, IonItem, IonLabel, IonPage, IonSpinner, IonText } from '@ionic/react'

import 'screens/Settings.css'
import { SubCalendar } from 'types'
import SelectCalendar from 'components/molecules/SelectCalendar/SelectCalendar'
import { UpdatePreferencesInput } from 'services/api'
import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import AnimatedMeeting from 'components/atoms/AnimatedMeeting/AnimatedMeeting'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}
const messageBox: CSSProperties = {
  padding: 20,
}

const messageStyle = {
  fontSize: 14,
}

interface ContainerProps {
  calendarId?: string;
  onDone: () => void;
}

const CalendarSettingsBookCalendar: React.FC<ContainerProps> = ({ calendarId, onDone }) => {
  const [selected, setSelected] = useState<SubCalendar>()

  const { updatePreferences } = usePreferences()
  const { loading, calendars } = useCalendars()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarSettingsBookCalendar' },
    })
  }, [])

  useEffect(() => {
    // pick the primary sub calendar as default
    if (calendarId && calendars?.length) {
      const calendar = calendars.find(cal => cal.id === calendarId)

      if (calendar) {
        const primary = calendar.calendars.find(cal => cal.primary)

        if (primary) {
          const defaultSelected = {
            id: primary.calendarId,
            account: calendar.id,
          }

          setSelected(defaultSelected)
        }
      }
    }
  }, [calendars])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
      },
    })
  }

  async function updateDefaultCalendar (): Promise<void> {
    console.log('Update default calendar to: ', selected)

    if (selected && calendars && updatePreferences) {
      const calendar = calendars.find(account => account.calendars.find(cal => cal.calendarId === selected.id))

      if (calendar) {
        const input: UpdatePreferencesInput = {
          bookCalendar: {
            account: calendar.id,
            calendarId: selected.id,
          },

        }

        await updatePreferences(input)

        console.log('Updated default calendar', input)
      }

      onDone()
    }
  }

  function renderLoading (): JSX.Element | undefined {
    if (loading) {
      return (
        <IonItem
          lines='full'
          style={{ textAlign: 'center' }}>
          <IonLabel color='secondary'>
            <IonSpinner
              name='dots' />
          </IonLabel>
        </IonItem>
      )
    }
  }

  function renderMessage (): JSX.Element {
    const text = `Select which calendar you'd like Moica meetings to be placed on by default.
    You can change this setting for individual meetings.`

    return (
      <div style={messageBox}>
        <IonText
          color='medium'
          style={messageStyle}>
          {text}
        </IonText>
      </div>
    )
  }

  function showSelected (): SubCalendar[] | undefined {
    if (selected) {
      return [
        {
          ...selected,
          enabled: true,
          freeBusy: false,
        },
      ]
    }
  }

  function renderCalendars (): JSX.Element | undefined {
    // we disable freeBusy just for the visual SelectCalendar component
  // so that it doesn't show the freeBusy label

    if (calendars?.length) {
      // const showSelected = selected ? [
      //   {
      //     ...selected,
      //     enabled: true,
      //     freeBusy: false,
      //   },
      // ] : []

      return (
        <SelectCalendar
          calendars={calendars}
          selected={showSelected()}
          writable
          selectBookCalendar
          onSelect={(cal) => {
            logTap({ component: 'SelectCalendar', button: 'SelectCalendar' })
            setSelected(cal)
          }} />
      )
    }
  }

  function renderFooter (): JSX.Element | undefined {
    if (selected) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'Next' })
              updateDefaultCalendar()
            }}>
            Next
          </FooterButton>
        </IonFooter>
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader title='Calendar Preferences' />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <div
            style={iconBox}>
            <AnimatedMeeting
              style={{ height: 60, width: 60 }} />
          </div>
          {renderMessage()}
          {renderLoading()}
          {renderCalendars()}
        </div>
      </IonContent>
      {renderFooter()}
    </IonPage>

  )
}

export default CalendarSettingsBookCalendar
