import React, { CSSProperties, useState } from 'react'
import { TimeSlot, TimeRange, CalendarEvent, Participant, MeetingTime } from 'types'
import moment from 'moment'

import { useSpring } from 'react-spring'

import ActionCard from 'components/organisms/ActionCard/ActionCard'
import { compareTimes } from 'services/time'

import { useUser } from 'context/UserContext/UserContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { IonItemDivider, IonItemGroup, IonList, IonSelect, IonSelectOption } from '@ionic/react'
import { getTimeRanges } from '../ScheduleCalendar/utils'
import TimeRangeListItem from 'components/molecules/TimeRangeListItem/TimeRangeListItem'
import MeetingTimeListItem from 'components/molecules/MeetingTimeListItem/MeetingTimeListItem'
import MeetingTimeItemDetails from '../MeetingTimeItemDetails/MeetingTimeItemDetails'
import MeetingTimeParticipants from '../MeetingTimeParticipants/MeetingTimeParticipants'

type PopoverInfo = {
  participants?: Participant[];
  timeRange?: TimeRange;
  meetingTime?: MeetingTime;
  calendarEvents?: CalendarEvent[];
  busy?: boolean;
}

const dividerStyle: CSSProperties = {
  // borderBottom: '0.5px solid gray',
}

const detailCardStyle: CSSProperties = {
  margin: 0,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
}

interface ComponentProps {
  id?: string;
  calendarEvents?: CalendarEvent[];
  tip?: boolean;
}

const ScheduleCalendarList: React.FC<ComponentProps> = () => {
  const [sortBy, setSortBy] = useState('participants')
  const [showDetails, setShowDetails] = useState<PopoverInfo>()
  const { user } = useUser()
  const { meeting } = useMeetings()
  const { timeSlots } = useTimeSlots()
  const { participants } = useParticipants()

  function getMeetingTimes (): MeetingTime[] {
    if (meeting?.meetingTimes?.length) {
      const currentValue = moment().valueOf()

      return meeting.meetingTimes.slice().filter(meetingTime => {
        if (moment(meetingTime.endTime).valueOf() > currentValue) {
          return true
        }
      }).sort((a: MeetingTime, b: MeetingTime) => {
        const timeA = a.startTime
        const timeB = b.startTime

        return compareTimes(timeA, timeB)
      })
    }

    return []
  }

  const sortedMeetingTimes = getMeetingTimes()

  function renderMeetingTime (meetingTime: MeetingTime): JSX.Element | null {
    const { participants } = meetingTime

    const active = !!participants?.find(u => u === user?.id)

    return (
      <MeetingTimeListItem
        meetingTime={meetingTime}
        active={active}
        lines='full'
        onSelect={() => console.log('select meeting time: ', meetingTime)}
        onDetails={() => getDetails({ meetingTime })} />
    )
  }

  function renderTentative (): JSX.Element | undefined {
    if (sortedMeetingTimes.length) {
      const items = sortedMeetingTimes.filter(mt => mt.status === 'tentative')

      if (items.length) {
        return (
          <IonItemGroup>
            {/* <IonItemDivider
              color='light'
              sticky
              style={dividerStyle}>
              Tentative
            </IonItemDivider> */}
            {items.map((meetingTime, i) => {
              return (
                <div key={i}>
                  {renderMeetingTime(meetingTime)}
                </div>
              )
            })}
          </IonItemGroup>
        )
      }
    }
  }

  function renderTrending (): JSX.Element | undefined {
    if (sortedMeetingTimes.length) {
      const items = sortedMeetingTimes.filter(mt => mt.status === 'trending')

      if (items.length) {
        return (
          <IonItemGroup>
            {/* <IonItemDivider
              color='light'
              sticky
              style={dividerStyle}>
              Trending
            </IonItemDivider> */}
            {items.map((meetingTime, i) => {
              return (
                <div key={i}>
                  {renderMeetingTime(meetingTime)}
                </div>
              )
            })}
          </IonItemGroup>

        )
      }
    }
  }

  function renderConfirmed (): JSX.Element | undefined {
    if (sortedMeetingTimes.length) {
      const items = sortedMeetingTimes.filter(mt => mt.status === 'confirmed')

      if (items.length) {
        return (
          <IonItemGroup>
            {/* <IonItemDivider
              color='light'
              sticky
              style={dividerStyle}>
              Confirmed
            </IonItemDivider> */}
            {items.map((meetingTime, i) => {
              return (
                <div key={i}>
                  {renderMeetingTime(meetingTime)}
                </div>
              )
            })}
          </IonItemGroup>

        )
      }
    }
  }

  function renderTimeRange (range: TimeRange, disableDay?: boolean): JSX.Element | null {
    const { participants } = range

    const active = !!participants?.find(u => u === user?.id)

    let busy = false

    if (range.timeSlot && timeSlots) {
      const slot = timeSlots.find(s => s.id === range.timeSlot)

      if (slot && !slot.available) {
        busy = true
      }
    }

    return (
      <TimeRangeListItem
        timeRange={range}
        active={active}
        busy={busy}
        disableDay={disableDay}
        onSelect={() => {
          return console.log('select time range: ', range)
        }}
        onDetails={() => {
          getDetails({ timeRange: range, busy })
        }} />
    )
  }

  function getSlots (): TimeSlot[] {
    // console.log('TIME SLOTS: ', timeSlots)

    if (timeSlots?.length) {
      return timeSlots
    }

    return []
  }

  function getDaySlots (day?: string): TimeSlot[] {
    // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
    if (day) {
      const dayStart = moment(day).startOf('day').valueOf()
      const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

      // console.log('dayStart: ', moment(selectedTime).startOf('day').toISOString())
      // console.log('dayEnd: ', moment(dayStart).add(24, 'hours').toISOString())

      // console.log('dayStart Value: ', dayStart)
      // console.log('dayEnd value: ', dayEnd)

      // first find local changes
      let slots = getSlots()

      slots = slots.filter((t: TimeSlot) => {
        const start = moment(t.startTime).valueOf()
        const end = moment(t.endTime).valueOf()

        // Note: a timeSlot can span across midnight
        // so include timeSlot if either start or end are within the day range
        if ((start >= dayStart && start < dayEnd) ||
        (end > dayStart && end <= dayEnd)) {
          return true
        }
      })

      return slots
    }

    return getSlots()
  }

  // issue #411 show choices only if the user had made some choices
  function getSortedRanges (day?: string): TimeRange[] {
    if (user?.id && meeting && timeSlots?.length && timeSlots.find(t => t.user === user?.id)) {
      const currentValue = moment().valueOf()
      const slots = getDaySlots(day)
      const ranges = getTimeRanges({
        user: user.id,
        timeSlots: slots.filter(s => !s.disabled),
        allowedTimeRanges: meeting.timeRanges,
      }).filter(range => {
        if (moment(range.endTime).valueOf() > currentValue) {
          return true
        }
      })

      if (sortBy === 'participants') {
        return ranges.slice().sort((a: TimeRange, b: TimeRange) => {
          const countA = a.participants?.length || 0
          const countB = b.participants?.length || 0

          return countB - countA
        })
      }

      return ranges
    }

    return []
  }

  function renderTimeRanges (): JSX.Element | undefined {
    if (sortBy === 'participants') {
      const ranges = getSortedRanges()

      if (ranges.length) {
        return (
          <IonItemGroup>
            {ranges.map((range, i) => {
              return (
                <div key={i}>
                  {renderTimeRange(range)}
                </div>
              )
            })}
          </IonItemGroup>
        )
      }
    }
  }

  function renderDayRanges (day: string): JSX.Element | undefined {
    const items = getSortedRanges(day)

    const dayLabel = moment(day).format('ddd MMM D')

    if (items.length) {
      return (
        <IonItemGroup
          key={day}
          style={dividerStyle}>
          <IonItemDivider
            color='light'
            sticky>
            {dayLabel}
          </IonItemDivider>
          {items.map((range, i) => {
            return (
              <div key={i}>
                {renderTimeRange(range, true)}
              </div>
            )
          })}
        </IonItemGroup>
      )
    }
  }

  function renderDays (): JSX.Element[] | undefined {
    if (sortBy === 'days') {
      const days: JSX.Element[] = []

      if (meeting?.dayRanges?.length) {
        const sortedDayRanges = meeting.dayRanges.slice().sort((a, b) => {
          const timeA = moment(a.startTime).valueOf()
          const timeB = moment(b.startTime).valueOf()

          return timeA - timeB
        })
        const start = sortedDayRanges[0].startTime
        const end = sortedDayRanges[sortedDayRanges.length - 1].endTime
        const endValue = moment(end).valueOf()

        let day = start

        while (moment(day).valueOf() < endValue) {
          const value = moment(day).valueOf()

          if (sortedDayRanges.find(range => {
            if (value >= moment(range.startTime).valueOf() && value < moment(range.endTime).valueOf()) {
              return true
            }
          })) {
            const ranges = renderDayRanges(day)

            ranges && days.push(ranges)
          }

          day = moment(day).add(1, 'day').toISOString()
        }
      }

      return days
    }
  }

  function renderChoicesTitle (): JSX.Element | undefined {
    if (timeSlots?.length) {
      return (
        <IonItemDivider
          color='light'
          sticky={sortBy === 'participants'}
          style={dividerStyle}>
          Choices
          <IonSelect
            slot='end'
            value={sortBy}
            interface='popover'
            onIonChange={e => setSortBy(e.detail.value)}>
            <IonSelectOption
              style={{ fontSize: 14 }}
              value='participants'>
              by participants
            </IonSelectOption>
            <IonSelectOption value='days'>
              by days
            </IonSelectOption>
          </IonSelect>
        </IonItemDivider>
      )
    } else {
      return (
        <IonItemDivider color='light'>
          No Choices
        </IonItemDivider>
      )
    }
  }

  function getDetails (args: { timeRange?: TimeRange, meetingTime?: MeetingTime, busy?: boolean}): void {
    const { timeRange, meetingTime, busy } = args

    if (participants?.length) {
      if (meetingTime) {
        console.log('get details for meetingTime: ', meetingTime)

        const rangeParticipants = meetingTime.participants.map((u: string) => {
          return participants.find((p: Participant) => p.user === u)
        })

        if (rangeParticipants) {
          const info: PopoverInfo = {
            participants: rangeParticipants as Participant[],
            meetingTime,
            busy,
          }

          setShowDetails(info)
        }
      } else if (timeRange?.participants) {
        console.log('get details for range: ', timeRange)

        const rangeParticipants = timeRange?.participants.map((u: string) => {
          return participants.find((p: Participant) => p.user === u)
        })

        if (rangeParticipants) {
          const info: PopoverInfo = {
            participants: rangeParticipants as Participant[],
            timeRange,
            busy,
          }

          setShowDetails(info)
        }
      }
    }
  }

  function renderMeetingTimeDetails (): JSX.Element | undefined {
    if (user?.id && meeting && showDetails) {
      const { meetingTime } = showDetails

      if (meetingTime) {
        return (
          <MeetingTimeItemDetails
            user={user.id}
            meeting={meeting}
            meetingTime={meetingTime}
            onAction={() => {
              setShowDetails(undefined)
            }} />
        )
      }
    }
  }

  function renderTimeRangeDetails (): JSX.Element | undefined {
    if (user?.id && meeting && showDetails) {
      const { timeRange, busy } = showDetails

      if (timeRange) {
        return (
          <MeetingTimeItemDetails
            user={user.id}
            meeting={meeting}
            timeRange={timeRange}
            busy={busy}
            onAction={() => {
              setShowDetails(undefined)
            }} />
        )
      }
    }
  }

  function renderMeetingTimeParticipants (): JSX.Element | undefined {
    if (user?.id && showDetails?.participants) {
      return (
        <MeetingTimeParticipants
          user={user?.id}
          participants={showDetails.participants} />
      )
    }
  }

  const popoverProps = useSpring({
    ...(showDetails ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderDetailsCard (): JSX.Element | undefined {
    if (showDetails) {
      // zIndex of sticky item dividers is apparently 1000
      // we want the cards to show on top of the list and sticky dividers
      // so use zIndex > 1000
      return (
        <ActionCard
          boxStyle={{ zIndex: 10001 }}
          cardStyle={detailCardStyle}
          className='CalendarADetailCardBox'
          title='time range'
          icon=''
          onClose={() => {
            setShowDetails(undefined)
          }}
          animatedProps={popoverProps}>
          {renderMeetingTimeDetails()}
          {renderTimeRangeDetails()}
          {renderMeetingTimeParticipants()}
        </ActionCard>
      )
    }
  }

  return (
    <div>
      <IonList>
        {renderConfirmed()}
        {renderTentative()}
        {renderTrending()}
        {renderChoicesTitle()}
        {renderTimeRanges()}
        {renderDays()}
      </IonList>
      {renderDetailsCard()}
    </div>

  )
}

export default ScheduleCalendarList
