import React, { useEffect, useState, CSSProperties } from 'react'
import moment from 'moment'
import {
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton,
  IonText,
  IonRow,
  IonSpinner,
  IonCol,
  IonLabel,
  IonChip,
  IonIcon,
  IonItem,
} from '@ionic/react'
import TipTarget from 'components/atoms/TipTarget/TipTarget'
import { Invitee, InviteeStatus, MeetingInvite, TimeRange } from 'types'
import { dayRangesToString, minutesToString, timeRangesToString } from 'services/time'

import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import { flashOutline, timeOutline, warning } from 'ionicons/icons'
import ListItem from 'components/atoms/ListItem/ListItem'
import { logoColor } from 'theme/styles'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { adjustTimeRangesForDay } from '../ScheduleCalendar/utils'

const photoBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const msgText: CSSProperties = {
  fontSize: 16,
  textAlign: 'center',
}

const warningChipStyle: CSSProperties = {
  marginLeft: 10,
  border: '1px solid #ffc409',
}

// const photoStyle: CSSProperties = {
//   width: '100%',
//   height: 200,
//   objectFit: 'cover',
//   objectPosition: 'center 30%',
// }

interface ContainerProps {
  invite: MeetingInvite;
  invitee: Invitee;
  showActivity?: string;
  tip?: string;
  onAccept: () => void
  onDecline: () => void
  onCalendarSettings: () => void
  onMeetingSettings: () => void
  onSelectAutoTimeOptions: (adjustedAutotimes: TimeRange[], defaultAutoTimes?: TimeRange[],) => void
  onSelectBusyTimes: () => void
  onExit: () => void
}

function getInitials (name: string): string {
  return name.split(' ').map((t: string) => t.charAt(0) && t.charAt(0).toUpperCase()).join('')
}

const InviteCard: React.FC<ContainerProps> = (
  { invite, invitee, showActivity, onAccept, onDecline, onExit, onCalendarSettings, onMeetingSettings, onSelectAutoTimeOptions, onSelectBusyTimes, tip }) => {
  const { title, dayRanges, timeRanges, name, photo } = invite

  const { defaultCalendar, autoTimes } = useCalendars()
  const [adjustedAutoTimes, setAudjustedAutoTimes] = useState<TimeRange[]>()

  const duration = minutesToString(invite.duration)

  useEffect(() => {
    if (defaultCalendar && autoTimes) {
      checkAutoTimes()
    }
  }, [defaultCalendar, autoTimes])

  function checkAutoTimes (): void {
    if (autoTimes?.length && timeRanges) {
      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are outside the autoRanges
      const outsideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if (startRange >= startAuto && endRange <= endAuto) {
            return false
          }

          return true
        })
      })

      if (outsideAutoRange) {
        const updateAutoTimes = [
          {
            startTime: timeRanges[0].startTime,
            endTime: timeRanges[timeRanges.length - 1].endTime,
          },
        ]

        setAudjustedAutoTimes(updateAutoTimes)
      }
    }
  }

  function checkOverlapAutoTimes (): boolean {
    if (autoTimes?.length && timeRanges) {
      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are inside the autoRanges
      const insideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if ((startRange >= startAuto && startRange < endAuto) ||
          (endRange > startAuto && endRange <= endAuto)) {
            return true
          }
        })
      })

      return !!insideAutoRange
    }

    return false
  }

  function renderPhoto (): JSX.Element | undefined {
    if (name) {
      const initials = getInitials(name)
      const msg = `${name} is inviting you to`

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

      const photoStyle = {
        width: 100,
        height: 100,
        border: '3px solid white',
        boxShadow: prefersDark?.matches ? '' : '0px 4px 8px gray',
      }

      return (
        <div
          style={photoBox}
          className='cardIconBox'>
          <ProfilePhoto
            style={photoStyle}
            fontSize={40}
            initials={initials}
            photo={photo} />
          <IonLabel style={{ textAlign: 'center' }}>

            <IonText
              color='medium'
              style={msgText}>
              {msg}
            </IonText>
          </IonLabel>
        </div>
      )
    }
  }

  function renderAcceptButton (): JSX.Element | undefined {
    if (invitee.status !== InviteeStatus.accepted) {
      if (showActivity === 'accept') {
        return (
          <IonCol>
            <IonButton
              shape='round'
              expand='block'
              fill='solid'>
              <IonSpinner name='dots' />
            </IonButton>
          </IonCol>

        )
      } else {
        return (
          <IonCol>
            <IonButton
              shape='round'
              expand='block'
              fill='solid'
              onClick={() => {
                if (adjustedAutoTimes && autoTimes) {
                  if (checkOverlapAutoTimes()) {
                    onSelectAutoTimeOptions(adjustedAutoTimes, autoTimes)
                  } else {
                    onSelectAutoTimeOptions(adjustedAutoTimes)
                  }
                } else if (autoTimes) {
                  onSelectBusyTimes()
                } else {
                  onAccept()
                }
              }}>
              Schedule
            </IonButton>
          </IonCol>
        )
      }
    }
  }

  function renderDeclineButton (): JSX.Element | undefined {
    if (invitee.status !== InviteeStatus.declined) {
      if (showActivity === 'decline') {
        return (
          <IonCol>
            <IonButton
              shape='round'
              expand='block'
              fill='outline'
              color='danger'>
              <IonSpinner name='dots' />
            </IonButton>
          </IonCol>
        )
      } else if (invitee.status === InviteeStatus.accepted) {
        return (
          <IonCol>
            <IonButton
              shape='round'
              expand='block'
              fill='outline'
              color='danger'
              onClick={onExit}>
              Exit Meeting
            </IonButton>
          </IonCol>
        )
      } else {
        return (
          <IonCol>
            <IonButton
              shape='round'
              expand='block'
              fill='outline'
              color='danger'
              onClick={onDecline}>
              Decline
            </IonButton>
          </IonCol>
        )
      }
    }
  }

  function renderMeetMeBadge (): JSX.Element | undefined {
    if (invitee.meetMe) {
      return (
        <div style={{ marginBottom: 10 }}>
          <IonChip
            color='success'
            style={{ marginLeft: -5 }}>
            From your moica meet link
          </IonChip>
        </div>
      )
    }
  }

  function renderBookCalendar (): JSX.Element | undefined {
    if (defaultCalendar) {
      return (
        <ListItem
          label='Book on Calendar'
          subLabel={defaultCalendar.name}
          lines='none'
          detail
          icon={{ name: flashOutline, slot: 'start', style: { color: logoColor } }}
          onClick={onCalendarSettings} />
      )
    }
  }

  function renderAutoTimes (): JSX.Element | undefined {
    if (autoTimes) {
      // const displayAutoTimes = adjustedAutoTimes || autoTimes
      const displayAutoTimes = autoTimes

      let timeLabel = ''

      displayAutoTimes.forEach((range, i) => {
        const start = moment(range.startTime).format('h:mm a')
        const end = moment(range.endTime).format('h:mm a')

        timeLabel += `${start} - ${end}`

        if (i < displayAutoTimes.length - 1) {
          timeLabel += ', '
        }
      })

      return (
        <ListItem
          label='Automagic schedule between'
          subLabel={timeLabel}
          lines='none'
          detail
          icon={{ name: timeOutline, slot: 'start', color: 'primary' }}
          onClick={onCalendarSettings} />
      )
    }
  }

  function renderAutoTimesWarning (): JSX.Element | undefined {
    if (adjustedAutoTimes) {
      // const message = 'Automagic schedule times will be adjusted to accomodate meeting time restrictions. You can tap below to change the setting'
      const message = 'Your default automagic schedule times don\'t cover the entire meeting time ranges.'

      return (
        <div>
          <IonChip
            style={warningChipStyle}
            color='medium'>
            <IonIcon
              icon={warning}
              color='warning' />
            <IonText
              style={{ fontSize: 12 }}>
              NOTE
            </IonText>
          </IonChip>
          <IonItem
            lines='none'>
            <IonLabel
              className='ion-text-wrap'
              style={{ marginTop: 0 }}>
              <IonText
                style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}
                color='medium'>
                {message}
              </IonText>
            </IonLabel>
          </IonItem>
        </div>
      )
    }
  }

  // function renderAddParticipants (): JSX.Element | undefined {
  //   return (
  //     <ListItem
  //       label='Add Participants'
  //       lines='none'
  //       detail
  //       icon={{ name: peopleOutline, slot: 'start', color: 'medium' }} />
  //   )
  // }

  function renderDetails (): string | undefined {
    const time = invitee.updateTime || invitee.createTime
    const formatted = moment(time).format('ddd D h:mm A')

    if (invitee.status === InviteeStatus.accepted) {
      return `You accepted this invitation on ${formatted}`
    } else if (invitee.status === InviteeStatus.declined) {
      return `You declined this invitation on ${formatted}`
    }
  }

  function renderTimes (): JSX.Element | undefined {
    const timeRangesLabel = timeRanges?.length ? `${timeRangesToString(timeRanges)}` : ''

    return (
      <>
        <IonChip
          color='warning'
          style={{ marginLeft: -8, marginTop: 10 }}>
          <IonLabel>
            Let&apos;s find a time to meet for {duration} between:
          </IonLabel>
        </IonChip>
        <IonCardSubtitle
          mode='md'>
          {dayRangesToString(dayRanges)}
        </IonCardSubtitle>
        {timeRangesLabel &&
          <IonCardSubtitle mode='md'>
            {timeRangesLabel}
          </IonCardSubtitle>}
      </>
    )
  }

  function renderHeader (): JSX.Element | undefined {
    return (
      <IonCardHeader
        onClick={() => {
          if (invitee.meetMe) {
            onMeetingSettings()
          }
        }}>
        {/* {renderBadge()} */}
        {renderMeetMeBadge()}
        <IonCardTitle style={{ marginTop: 5 }}>
          {title}
          {tip === 'title' &&
            <TipTarget />}
        </IonCardTitle>
        {renderTimes()}
      </IonCardHeader>
    )
  }

  return (
    <IonCard>
      {renderPhoto()}
      {renderHeader()}
      {renderAutoTimesWarning()}
      {renderAutoTimes()}
      {renderBookCalendar()}
      {/* {renderAddParticipants()} */}
      <IonCardContent>
        <IonRow>
          {renderAcceptButton()}
          {renderDeclineButton()}
        </IonRow>
        <div style={{ marginTop: 10 }}>
          {renderDetails()}
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default InviteCard
