import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { NotifyParticipantsInput, NOTIFY_PARTICIPANTS } from 'services/api'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'

interface NotifyParticipantsContextValue {
  notifyParticipants: (input: NotifyParticipantsInput) => Promise<void>
}

const initialValue: NotifyParticipantsContextValue = {
  notifyParticipants: async (input: NotifyParticipantsInput) => {
    console.log('notifyParticipants: ', input)
  },

}
// create and initialize context
export const NotifyParticipantsContext = React.createContext<NotifyParticipantsContextValue>(initialValue)

export function useNotifyParticipants (): NotifyParticipantsContextValue {
  return useContext(NotifyParticipantsContext)
}

export type NotifyParticipantsMockContextValue = Partial<NotifyParticipantsContextValue>

type MockProps = {
  value?: Partial<NotifyParticipantsContextValue>
}

export const NotifyParticipantsMockProvider: React.FC<MockProps> = ({ value, children }) => {
  return (
    <NotifyParticipantsContext.Provider
      value={{
        ...initialValue,
        ...value,
      }}>
      {children}
    </NotifyParticipantsContext.Provider>
  )
}

const NotifyParticipantsContextProvider: React.FC = ({ children }) => {
  const { logEvent } = useAnalytics()

  const [notifyParticipantsMutation] = useMutation(NOTIFY_PARTICIPANTS)

  async function notifyParticipants (input: NotifyParticipantsInput): Promise<void> {
    await notifyParticipantsMutation({ variables: { input } })

    const { meeting, notifyType } = input

    logEvent({
      eventName: EventName.mutation,
      eventData: {
        mutation: 'exitMeeting',
        meeting,
        notifyType,
      },
    })
  }

  return (
    <NotifyParticipantsContext.Provider
      value={{
        notifyParticipants,
      }}>
      {children}
    </NotifyParticipantsContext.Provider>
  )
}

export default NotifyParticipantsContextProvider
