import React, { useState, CSSProperties, useEffect } from 'react'
import { IonIcon, IonLabel } from '@ionic/react'
import moment from 'moment'
import { peopleCircleOutline } from 'ionicons/icons'

import 'theme/CalendarA.css'
import TimeBar from '../CalendarTimeBar/TimeBar'
import calendarConfig from './config'
import { CalendarAccount, CalendarEvent } from 'types'

import { NotifyTypes, useMeetingNotify } from 'context/MeetingNotifyContext/MeetingNotifyContext'
import { CalendarViewContext, useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { MeetingBookCalendar, useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import ScreenTipsContext from 'context/ScreenTipsContext/ScreenTipsContext'
import { useTips } from 'context/TipsContext/TipsContext'

import { CombinedDayScheduleTips, ComponentTips, DayScheduleTips } from 'types/componentTips'
import { ScreenTips } from './ScreenTips'
import ParticipantScheduleTitleBar from '../ParticipantScheduleTitleBar/ParticipantScheduleTitleBar'
import ParticipantDaySchedules from '../ParticipantDaySchedules/ParticipantDaySchedules'
import UserDayScheduleTitle from '../UserDayScheduleTitle/UserDayScheduleTitle'
import CombinedDaySchedule from '../CombinedDaySchedule/CombinedDaySchedule'
import DayScheduleWeekBar from '../DayScheduleWeekBar/DayScheduleWeekBar'
import { ConfirmedTimeNotify } from 'screens/ChooseAvailability/Notify'
import SelectDaySchedule from '../SelectDaySchedule/SelectDaySchedule'
import Fireworks from 'components/atoms/Fireworks/Fireworks'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const container: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  WebkitUserSelect: 'none',
}

const avatarColor = '#92949C'

export interface CalendarProps {
  time: string;
  tip?: boolean;
  onSelectTime?: (time: string) => void;
}

let activeScrollTimer: NodeJS.Timeout
let activeScroll = ''

const DaySchedule: React.FC<CalendarProps> = ({ time, onSelectTime }) => {
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>()
  const [showScreenTip, setShowScreenTip] = useState<ComponentTips>({})
  const [minTip, setMinTip] = useState(false)
  const [showNextTip, setShowNextTip] = useState(0)
  const [selectedTime, setSelectedTime] = useState(time)
  const { meeting, meetings } = useMeetings()
  const { participant } = useParticipants()
  const { storeTips } = useTips()
  const { calendars, calendarUpdated, getMeetingCalendar, getCalendarEvents } = useCalendars()
  const { showNotify, notifyMessage, showFireworks, setShowNotify, setShowFireworks } = useMeetingNotify()
  const { logEvent } = useAnalytics()

  async function getAllCalendarEvents (calendars?: CalendarAccount[]): Promise<void> {
    let events: CalendarEvent[] = []

    if (calendars && getCalendarEvents) {
      if (meeting && meeting.dayRanges && meeting.dayRanges.length > 0) {
        const sorted = meeting.dayRanges.slice().sort((a, b) => {
          const timeA = moment(a.startTime).valueOf()
          const timeB = moment(b.startTime).valueOf()

          return timeA - timeB
        })

        const range = {
          startTime: sorted[0].startTime,
          endTime: sorted[sorted.length - 1].endTime,
        }

        // NOTE: don't use Promises.all as the googleapi client can't handle multiple accessTokens
        // in parallel
        for (let i = 0; i < calendars.length; i++) {
          // const result = await getCalendarEvents(calendars[i], dayRange)

          const result = await getCalendarEvents({
            account: calendars[i],
            calendars: participant?.calendars,
            range,
          })

          events = events.concat(result)
        }

        // console.log('ALL CALENDAR EVENTS: ', events?.length)
      }
    } else if (meeting && meetings) {
      // get other confirmed meeting times
      const confirmedMeetings = meetings.filter(m => m.id !== meeting.id && m.meetingTimes?.find(mt => mt.status === 'confirmed'))

      confirmedMeetings.forEach(m => {
        const mt = m.meetingTimes?.find(mt => mt.status === 'confirmed')

        if (mt) {
          events.push({
            id: m.id,
            summary: `✅ ${m.title}`,
            startTime: mt.startTime,
            endTime: mt.endTime,
          })
        }
      })
    }

    setCalendarEvents(events)
  }

  async function getBookCalendar (): Promise<void> {
    if (calendars && participant && getMeetingCalendar) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)
      }
    }
  }

  // async function getMeetingCalendar (calendars: CalendarAccount[], account: string, calendarId: string): Promise<void> {
  //   const calendarAccount = calendars.find(cal => cal.id === account)

  //   if (calendarAccount) {
  //     const calendar = calendarAccount.calendars.find(cal => cal.calendarId === calendarId)

  //     if (calendar && participantData?.participant?.autoBook?.active &&
  //       participantData?.participant?.autoBook?.enabled) {
  //       if (participantData?.participant.bookCalendar?.timeRanges) {
  //         const { timeRanges, autoDays: days } = participantData.participant.bookCalendar

  //         setAutoTimeRanges(timeRanges)
  //         setAutoDays(days)
  //         // showAutoBookTimesToast(timeRanges)
  //       } else {
  //         const { timeRanges, autoDays: days } = calendar

  //         if (Array.isArray(timeRanges) && timeRanges.length) {
  //           setAutoTimeRanges(timeRanges)
  //           setAutoDays(days)
  //           // showAutoBookTimesToast(timeRanges)
  //         }
  //       }
  //     } else {
  //       setAutoTimeRanges(undefined)
  //       setAutoDays([])
  //       // showAutoBookTimesToast()
  //     }
  //   }
  // }

  // useEffect(() => {
  //   // startTips()
  // }, [])

  // function startTips (): void {
  //   setTimeout(() => {
  //     if (storeTips?.startMinTip || storeTips?.daySchedule?.endTips) {
  //       // setNextTip(DayScheduleTips.noTip)
  //       setNextTip(DayScheduleTips.sequence)
  //     } else {
  //       setNextTip(DayScheduleTips.sequence)
  //     }
  //   }, 2000)
  // }

  useEffect(() => {
    // console.log('DaySchedule: calendars:  ', calendars)

    if (calendars?.length && participant) {
      // console.log('CalendarA: GET ALL EVENTS AND BOOK CALENDAR ')
      // console.log(calendars)
      // console.log(participant)
      getAllCalendarEvents(calendars)
      getBookCalendar()
    } else {
      getAllCalendarEvents()
    }
  }, [calendars, participant])

  useEffect(() => {
    // console.log('CalendarBox: calendarUpdated', calendarUpdated)

    if (calendarUpdated && calendars?.length) {
      getAllCalendarEvents(calendars)
    }
  }, [calendarUpdated])

  const componentStoreTips = storeTips?.daySchedule

  // function getSlots (): TimeSlot[] {
  //   // console.log('TIME SLOTS: ', timeSlots)

  //   if (timeSlots?.length) {
  //     return timeSlots
  //   }

  //   return []
  // }

  // function getDaySlots (): TimeSlot[] {
  //   // 12AM start time - 1 minute so that 12AM selection fits in the isAfter range below.
  //   const dayStart = moment(selectedTime).startOf('day').valueOf()
  //   const dayEnd = moment(dayStart).add(24, 'hours').valueOf()

  //   // first find local changes
  //   let slots = getSlots()

  //   slots = slots.filter((t: TimeSlot) => {
  //     const start = moment(t.startTime).valueOf()
  //     const end = moment(t.endTime).valueOf()

  //     // Note: a timeSlot can span across midnight
  //     // so include timeSlot if either start or end are within the day range
  //     if ((start >= dayStart && start < dayEnd) ||
  //       (end > dayStart && end <= dayEnd)) {
  //       return true
  //     }
  //   })

  //   return slots
  // }

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  function setNextTip (tip: DayScheduleTips): void {
    // console.log('DaySchedule setNextTip: ', tip)

    let trending
    let tentative

    if (meeting && meeting.meetingTimes?.length) {
      meeting.meetingTimes.forEach(mt => {
        const dayStart = moment(selectedTime).startOf('day').valueOf()
        const dayEnd = moment(dayStart).add(24, 'hours').valueOf()
        const start = moment(mt.startTime).valueOf()
        const end = moment(mt.endTime).valueOf()

        if (start >= dayStart && end <= dayEnd) {
          if (mt.status === 'trending') {
            trending = true
          } else if (mt.status === 'tentative') {
            tentative = true
          }
        }
      })
    }

    // if (user && timeSlots) {
    //   selectedTimes = getDaySlots().find(timeSlot => timeSlot.user === user.id)
    // }

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.sequence:
      case DayScheduleTips.selectTimes:
        // just disable it for now until we find an elegant solution
        // if (!componentStoreTips?.selectTimes && !selectedTimes && !bookCalendar?.autoBook) {
        //   setShowScreenTip({ daySchedule: DayScheduleTips.selectTimes })
        //   break
        // }
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.othersTimes:
        if (!componentStoreTips?.selectTimes) {
          setShowScreenTip({ daySchedule: DayScheduleTips.othersTimes })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.combinedColumn:
        setShowScreenTip({ combinedDaySchedule: CombinedDayScheduleTips.sequence })
        setShowNextTip(showNextTip + 1)
        break
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.trendingTimes:
        if (trending) {
          setShowScreenTip({ daySchedule: DayScheduleTips.trendingTimes })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.tentativeTimes:
        if (tentative) {
          setShowScreenTip({ daySchedule: DayScheduleTips.tentativeTimes })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.switchDays:
        setShowScreenTip({ daySchedule: DayScheduleTips.switchDays })
        break
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.endTips:
        setShowScreenTip({ daySchedule: DayScheduleTips.endTips })
        // setShowScreenTip(DayScheduleTips.endTips)
        break
      // eslint-disable-next-line no-fallthrough
      case DayScheduleTips.noTip:
      default:
        // in the modal case reset the tips back to sequence
        setShowScreenTip({ daySchedule: DayScheduleTips.endTips })
        setMinTip(true)
        break
    }
  }

  function onDoneTips (): void {
    console.log('DaySchedule screen onDoneTips: ', showScreenTip)

    if (showScreenTip.combinedDaySchedule) {
      setNextTip(DayScheduleTips.trendingTimes)
    }
  }

  // function updateTip (tip: StoreTips): void {
  //   if (updateStoreTips) {
  //     updateStoreTips({
  //       daySchedule: {
  //         ...storeTips?.daySchedule, ...tip,
  //       },
  //     })
  //   }
  // }

  function onScroll (top: number, left: number, id: string): void {
    // get scroll position in px
    // console.log(el.scrollLeft, el.scrollTop)
    // console.log('SCROLL: ', id)
    // console.log('activeScroll: ', activeScroll)

    clearTimeout(activeScrollTimer)

    if (!activeScroll) {
      activeScroll = id
    }

    activeScrollTimer = setTimeout(() => {
      activeScroll = ''
    }, 50)

    // ignore scroll from any other component if activeScroll is ON
    if (id === activeScroll) {
      let el = document.getElementById('dayScheduleTimeBar')

      if (el) {
        el.scrollTop = top
      }

      if (id !== 'combinedDaySchedule') {
        el = document.getElementById('combinedDaySchedule')

        if (el) {
          el.scrollTop = top
        }
      }

      if (id !== 'selectDaySchedule') {
        el = document.getElementById('selectDaySchedule')

        if (el) {
          el.scrollTop = top
        }
      }

      if (id !== 'calendarEventsDaySchedule') {
        el = document.getElementById('calendarEventsDaySchedule')

        if (el) {
          el.scrollTop = top
        }
      }

      if (id !== 'participantDaySchedules') {
        el = document.getElementById('participantDaySchedules')

        if (el) {
          el.scrollTop = top
        }
      }
    }

    if (left) {
      const el = document.getElementById('participantScheduleBar')

      if (el) {
        el.scrollLeft = left
      }
    }
  }

  function renderScreenTips (): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)

          // if (!componentStoreTips?.endTips) {
          //   updateTip({ endTips: false })
          // }
          // if we've not shown endTips then go ahead and start the sequence
          if (!componentStoreTips?.endTips) {
            setNextTip(DayScheduleTips.sequence)
          }
        }}
        onButton={(restartTips) => {
          if (showScreenTip?.daySchedule) {
            if (showScreenTip.daySchedule === DayScheduleTips.endTips) {
              if (restartTips) {
                setNextTip(DayScheduleTips.sequence)
              } else {
                setNextTip(DayScheduleTips.noTip)
                setMinTip(true)
              }
            } else {
              if (showScreenTip.daySchedule === DayScheduleTips.tentativeTimes) {
                // note that redux store update takes time so we can't depend
                // on the tentativeTimes state to change so skip tentativeTimesAuto tip
                setNextTip(showScreenTip.daySchedule + 2)
              } else {
                setNextTip(showScreenTip.daySchedule + 1)
              }
            }
          } else {
            // increment the counter to which ever component is showing tips will detect the increment
            // and show it's next tip
            setShowNextTip(showNextTip + 1)
          }
        }} />
    )
  }

  function renderConfirmedTimeNotify (): JSX.Element | undefined {
    return (
      <ConfirmedTimeNotify
        show={showNotify === NotifyTypes.confirmedTime}
        message={notifyMessage}
        onButton={() => {
          logTap({ component: 'ConfirmedTimeNotify', button: 'ConfirmNotify' })
          setShowNotify && setShowNotify(NotifyTypes.none)
        }} />
    )
  }

  function renderFireworks (): JSX.Element | undefined {
    if (showFireworks) {
      return (
        <div
          style={{
            position: 'absolute',
            bottom: '30%',
            background: 'transparent',
          }}>
          <Fireworks
            onComplete={() => setShowFireworks && setShowFireworks(false)} />
        </div>
      )
    }
  }

  return (
    <ScreenTipsContext.Provider value={{ showScreenTip, minTip, showNextTip, setShowScreenTip, onDoneTips }}>
      <CalendarViewContext.Provider value={{ ...calendarConfig, timeSlotWidth: 70, timeLabelWidth: 55 }}>
        <div
          style={container}>
          <TopBox>
            <DayScheduleWeekBar
              meeting={meeting}
              selectedTime={selectedTime}
              tip={!minTip && showScreenTip?.daySchedule === DayScheduleTips.switchDays}
              onSelectTime={(time) => {
                logTap({ component: 'DayScheduleWeekBar', button: 'SelectTime' })
                setSelectedTime(time)
              }} />
          </TopBox>
          <TopBox>
            <CombinedColumnTitle />
            <TimeBarTitle />
            {/* <CalendarEventsDayScheduleTitle
              selectedTime={selectedTime}
              calendarEvents={calendarEvents}
              tip={!minTip && showScreenTip?.daySchedule === DayScheduleTips.selectTimes} /> */}
            <UserDayScheduleTitle />
            <ParticipantScheduleTitleBar meeting={meeting} />
          </TopBox>
          <BottomBox>
            <CombinedDayBox>
              <CombinedDaySchedule
                id='combinedDaySchedule'
                selectedTime={selectedTime}
                reload={0}
                tip={!minTip && (showScreenTip?.combinedDaySchedule === CombinedDayScheduleTips.combinedSlots ||
                showScreenTip?.daySchedule === DayScheduleTips.trendingTimes ||
                showScreenTip?.daySchedule === DayScheduleTips.tentativeTimes)}
                onSelectTime={(time) => {
                  logTap({ component: 'CombinedDaySchedule', button: 'SelectTime' })
                  onSelectTime && onSelectTime(time)
                }}
                onScroll={onScroll} />
            </CombinedDayBox>
            <TimeBarBox>
              <TimeBar
                id='dayScheduleTimeBar'
                selectedTime={selectedTime}
                bookCalendar={bookCalendar} />
            </TimeBarBox>
            {/* <CalendarBox>
              <CalendarViewContext.Provider value={{ ...calendarConfig, timeSlotWidth: 50 }}>
                <CalendarEventsDaySchedule
                  id='calendarEventsDaySchedule'
                  selectedTime={selectedTime}
                  reload={0}
                  bookCalendar={bookCalendar}
                  calendarEvents={calendarEvents}
                  onScroll={onScroll} />
              </CalendarViewContext.Provider>
            </CalendarBox> */}
            <SelectDayBox>
              <SelectDaySchedule
                id='selectDaySchedule'
                selectedTime={selectedTime}
                reload={0}
                tip={!minTip && showScreenTip?.daySchedule === DayScheduleTips.selectTimes}
                calendarEvents={calendarEvents}
                bookCalendar={bookCalendar}
                onScroll={onScroll} />
            </SelectDayBox>
            <BottomRightBox>
              <CalendarViewContext.Provider value={{ ...calendarConfig, timeSlotWidth: 70 }}>
                <ParticipantDaySchedules
                  id='participantDaySchedules'
                  selectedTime={selectedTime}
                  // tip={!minTip && showScreenTip?.daySchedule === DayScheduleTips.othersTimes}
                  onScroll={onScroll} />
              </CalendarViewContext.Provider>
            </BottomRightBox>
          </BottomBox>
          {renderScreenTips()}
          {renderConfirmedTimeNotify()}
          {renderFireworks()}
        </div>
      </CalendarViewContext.Provider>
    </ScreenTipsContext.Provider>
  )
}

const CombinedDayBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 10,
    right: 0,
    bottom: 0,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const TimeBarBox: React.FC = ({ children }) => {
  const { timeSlotWidth = 90 } = useCalendarView()
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 10 + timeSlotWidth,
    bottom: 0,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

// const CalendarBox: React.FC = ({ children }) => {
//   const { timeLabelWidth = 60, timeSlotWidth = 90 } = useCalendarView()
//   const container: CSSProperties = {
//     position: 'absolute',
//     top: 0,
//     left: 10 + timeSlotWidth + timeLabelWidth,
//     right: 0,
//     bottom: 0,
//     display: 'flex',
//     overflow: 'hidden',
//   }

//   return (
//     <div
//       style={container}>
//       {children}
//     </div>
//   )
// }

const SelectDayBox: React.FC = ({ children }) => {
  const { timeLabelWidth = 60, timeSlotWidth = 90 } = useCalendarView()
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 10 + timeSlotWidth + timeLabelWidth,
    right: 0,
    bottom: 0,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const BottomRightBox: React.FC = ({ children }) => {
  const { timeLabelWidth = 60, timeSlotWidth = 90 } = useCalendarView()
  const container: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 10 + (timeSlotWidth * 2) + timeLabelWidth,
    right: 0,
    bottom: 0,
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const TopBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    // height: dayLabelHeight,
    display: 'flex',
    borderBottom: '0.5px solid gray',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const BottomBox: React.FC = ({ children }) => {
  const container: CSSProperties = {
    flex: 1,
    display: 'flex',
    position: 'relative',
  }

  return (
    <div
      style={container}>
      {children}
    </div>
  )
}

const CombinedColumnTitle: React.FC = () => {
  const { timeSlotWidth = 70 } = useCalendarView()

  const container: CSSProperties = {
    marginLeft: 10,
    minWidth: timeSlotWidth,
    height: 70,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 70,
  }

  const iconStyle = {
    fontSize: 38,
    color: avatarColor,
  }

  // const labelStyle = {
  //   fontSize: 14,
  //   fontWeight: 'bold',
  // }

  return (
    <div
      style={container}
      className='CalendarABorderRight'>
      <IonIcon
        icon={peopleCircleOutline}
        style={iconStyle} />
      <IonLabel>
        All
      </IonLabel>
    </div>
  )
}

const TimeBarTitle: React.FC = () => {
  const { timeLabelWidth, allDayEventsBoxHeight } = useCalendarView()
  const container: CSSProperties = {
    width: timeLabelWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 5,
  }
  const allDayBox: CSSProperties = {
    width: timeLabelWidth,
    height: allDayEventsBoxHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  // const allDayLabel: CSSProperties = {
  //   fontSize: 12,
  // }

  return (
    <div style={container}>
      <div
        style={allDayBox}>
        {/* <IonText
          className='CalendarATimeLabel'
          style={allDayLabel}>
          all-day
        </IonText> */}
      </div>
    </div>
  )
}

export default DaySchedule
