import React, { CSSProperties, useState, useEffect } from 'react'
import {
  IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage,
  IonText, IonTitle, IonToast, IonToolbar, isPlatform,
} from '@ionic/react'
import { warning } from 'ionicons/icons'

import ShareOptions from 'components/molecules/ShareOptions/ShareOptions'
import { useUser } from 'context/UserContext/UserContext'
import { useMeetingShare } from 'context/MeetingShareContext/MeetingShareContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useContacts } from 'context/ContactsContext/ContactsContext'
import ListItemInput from 'components/atoms/ListItemInput/ListItemInput'
import ScreenBackButton from 'components/atoms/ScreenBackButton/ScreenBackButton'
import { MeetingInviteTips } from 'types/componentTips'
import { MeetingInvite as StoreTips } from 'services/store/tips/types'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { InviteeStatus } from 'types'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

type Invitation = {
  id: string;
  user: string;
  meeting: string;
  createTime: string;
}

const messageBox: CSSProperties = {
  padding: 20,
  paddingBottom: 0,
  paddingLeft: isPlatform('ios') ? 20 : 16,
}

const messageBox2: CSSProperties = {
  marginTop: 10,
  paddingLeft: isPlatform('ios') ? 20 : 16,
  paddingRight: 20,
  alignItems: 'center',
}

const confirmationWarning: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  zIndex: 1,
}

const messageStyle = {
  fontSize: 14,
}

interface ContainerProps {
  nextScreen?: string;
  onAddContact: () => void;
  goBack: () => void;
}

const appUrl = process.env.REACT_APP_MEETINGS_APP + '/invitation/'

const MeetingInvite: React.FC<ContainerProps> = (
  { onAddContact, goBack }) => {
  const [expectedParticipants, setExpectedParticipants] = useState(0)
  const [tentative, setTentative] = useState(0)
  const [showShareButtons, setShowShareButtons] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(MeetingInviteTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [inviteesError, setInviteesError] = useState(false)
  const { user } = useUser()
  const { meeting, updateMeeting } = useMeetings()
  const { invitations } = useMeetingShare()
  const { meetingInvitees } = useInvitees()
  const { storeTips, updateStoreTips } = useTips()
  const { contacts } = useContacts()
  const { logEvent } = useAnalytics()

  const componentStoreTips = storeTips?.meetingInvite
  const isValidCount = expectedParticipants % 1 === 0
  // console.log('MeetingInvite user: ', user?.id)
  // console.log('MeetingInvite meeting: ', meeting)

  useEffect(() => {
    startTips()
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'MeetingInvite', meeting: meeting?.id },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'MeetingInvite',
        meeting: meeting?.id,
      },
    })
  }

  function setCounts (): void {
    if (meeting?.id && meeting.participants) {
      if (meeting.participantsExpected) {
        if (meeting.participantsExpected !== expectedParticipants) {
          setExpectedParticipants(meeting.participantsExpected)
        }

        if (meeting.tentativeThreshold !== undefined) {
          setTentative(meeting.tentativeThreshold)
        }
      } else {
        const count = meeting.participants.length || 1

        setExpectedParticipants(count)
        setTentative(Math.ceil((count) / 2))
      }
    }
  }

  function startTips (): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setNextTip(MeetingInviteTips.noTip)
      } else {
        setNextTip(MeetingInviteTips.sequence)
      }
    }, 1000)
  }

  useEffect(() => {
    setCounts()
  }, [meeting])

  function setNextTip (tip: MeetingInviteTips, restart?: boolean): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case MeetingInviteTips.sequence:
      case MeetingInviteTips.inviteesCount:
        if (restart || !componentStoreTips?.inviteesCount) {
          setShowScreenTip(MeetingInviteTips.inviteesCount)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingInviteTips.shareLink:
        if (restart || !componentStoreTips?.shareLink) {
          setShowScreenTip(MeetingInviteTips.shareLink)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingInviteTips.endTips:
        if (restart || !componentStoreTips?.endTips) {
          setShowScreenTip(MeetingInviteTips.endTips)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingInviteTips.noTip:
      default:
        // reset tips to endTips and show minimized button
        setMinTip(true)
        setShowScreenTip(MeetingInviteTips.endTips)
        break
    }
  }

  function updateTip (tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingInvite: {
          ...storeTips?.meetingInvite, ...tip,
        },
      })
    }
  }

  function updateExpectedParticipants (): void {
    if (meeting && meeting.participantsExpected &&
      expectedParticipants !== meeting.participantsExpected && updateMeeting) {
      const update = {
        id: meeting.id,
        participantsExpected: expectedParticipants,
        tentativeThreshold: tentative,
      }

      updateMeeting(update)
    }
  }

  const inviteesCount = meetingInvitees?.filter(invitee => {
    if (invitee.status === InviteeStatus.declined || invitee.status === InviteeStatus.accepted) {
      return false
    }

    return true
  }).length

  function renderUpdateButton (): JSX.Element | undefined {
    if (!showShareButtons && meeting?.participantsExpected) {
      const txt = expectedParticipants !== meeting.participantsExpected ? 'Update' : 'Looks Good'

      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            disabled={!isValidCount}
            testId='update-participant-count'
            onClick={() => {
              logTap({ component: 'FootButton', button: txt })
              updateExpectedParticipants()
              setShowShareButtons(true)
            }}>
            {txt}
          </FooterButton>
        </IonFooter>
      )
    }
  }
  function renderParticipantCount (): JSX.Element | undefined {
    if (meeting && meeting?.participants?.length && expectedParticipants) {
      const minimumCount = meeting.participants.length + (inviteesCount || 0)

      return (
        <div style={{ marginBottom: 20 }}>
          <ListItemInput
            testId='expected-participant-count'
            title='total expected participants (including you)'
            tip={!minTip && showScreenTip === MeetingInviteTips.inviteesCount}
            placeholder='enter number of participants'
            enterkeyhint='done'
            inputMode='numeric'
            value={expectedParticipants.toString()}
            type='tel'
            onFocus={() => {
              setMinTip(true)
            }}
            onChange={(value) => {
              logTap({ component: 'ListItemInput', button: 'EditExpected' })

              if (value) {
                const count = Number(value)

                if (!count || count < minimumCount) {
                  setInviteesError(true)

                  return
                }

                setExpectedParticipants(count)
                setShowShareButtons(false)

                // set it only if its enabled in the first place
                if (tentative) {
                  setTentative(Math.ceil(count / 2))
                }
              } else {
                // fractional values flag validCount as false
                setExpectedParticipants(0.5)
              }

              setInviteesError(false)
            }}
            onDone={() => {
              updateExpectedParticipants()
              setShowShareButtons(true)
            }} />
          <div style={messageBox2}>
            <div style={confirmationWarning}>
              <IonIcon
                slot='start'
                color={isValidCount ? 'warning' : 'danger'}
                icon={warning}
                style={{ fontSize: '3rem', marginRight: 5 }} />
              <IonText
                color='medium'
                style={messageStyle}>
                {isValidCount
                  ? `A meeting time will not be confirmed until ${expectedParticipants.toString()}
                    \nparticipant${expectedParticipants === 1 ? '\nhas' : 's\nhave'} selected their availability`
                  : 'A participant count is required for Moica to automagically make any scheduling decisions'}
              </IonText>
            </div>
          </div>
          {inviteesError && meeting.participants?.length &&
            <div style={messageBox2}>
              <IonText
                color='danger'
                style={messageStyle}>
                Expected participants should be greater than or equal to <b>{minimumCount || '2'}</b>
              </IonText>
            </div>}
          {renderUpdateButton()}
        </div>
      )
    }
  }

  // function renderTentativeToggle (): JSX.Element | undefined {
  //   if (expectedParticipants > 2 && meeting?.participants?.length) {
  //     return (
  //       <div style={{ marginBottom: 40 }}>
  //         <IonItem lines='full'>
  //           <IonLabel>Tentative Times</IonLabel>
  //           <IonToggle
  //             color='success'
  //             checked={!!tentative}
  //             onIonChange={e => {
  //               let value = 0

  //               if (e.detail.checked) {
  //                 value = Math.ceil((expectedParticipants) / 2)
  //                 setTentative(value)
  //               } else {
  //                 setTentative(0)
  //               }

  //               // console.log('new tentativeThreshold: ', value)

  //               if (value !== meeting.tentativeThreshold && updateMeeting) {
  //                 const update = {
  //                   id: meeting.id,
  //                   tentativeThreshold: value,
  //                 }

  //                 updateMeeting(update)
  //               }
  //             }} />
  //         </IonItem>
  //         <div style={messageBox2}>
  //           {tentative > 0 &&
  //             <IonText
  //               color='medium'
  //               style={messageStyle}>
  //               Time ranges that are common for <b>{tentative}</b> or more participants will be marked as Tentative
  //             </IonText>}
  //           {tentative === 0 &&
  //             <IonText
  //               color='medium'
  //               style={messageStyle}>
  //               Time ranges that are common for more than half of the participants will be marked as Tentative
  //             </IonText>}
  //         </div>
  //       </div>
  //     )
  //   }
  // }

  // function renderGetLinkButton (): JSX.Element | undefined {
  //   return (
  //     <div className='screenFooterButton'>
  //       <FooterButton
  //         onClick={() => setShowShareButtons(true)}>
  //         Looks Good
  //       </FooterButton>
  //     </div>
  //   )
  // }

  function renderShareOptions (): JSX.Element | undefined {
    if (meeting && invitations?.length && showShareButtons) {
      const invite = invitations[0]

      let sms = ''

      const smsBody = `Hi! I'm using Moica to invite you to a meeting - ${meeting.title}%0A%0APlease tap here to accept or decline my invitation: ${appUrl}${invite.id}`

      if (isPlatform('android')) {
        sms = `sms:?&body=${smsBody}`
      } else {
        sms = `sms:&body=${smsBody}`
      }

      const mailto = `mailto:?subject=Meeting Invite from ${user?.name}&body=${smsBody}`
      // note looks like %0a doesn't work for new link in clipboard just use \n
      const copy = appUrl + invite.id

      return (
        <ShareOptions
          invitation={invite.id}
          copy={copy}
          sms={sms}
          email={mailto}
          tip={!minTip && showScreenTip === MeetingInviteTips.shareLink}
          onMessage={() => {
            logTap({ component: 'ShareOptions', button: 'Message' })
            updateTip({ shareLink: true })
          }}
          onEmail={() => {
            logTap({ component: 'ShareOptions', button: 'Email' })
            updateTip({ shareLink: true })
          }}
          onCopy={() => {
            logTap({ component: 'ShareOptions', button: 'Copy' })
            setShowToast(true)
            updateTip({ shareLink: true })
          }} />
      )
    }
  }

  function renderHeader (): JSX.Element {
    return (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <ScreenBackButton
              onClick={() => goBack && goBack()} />
          </IonButtons>
          <IonTitle>
            {meeting?.title}
          </IonTitle>
          {/* <IonButtons slot='end'>
            {renderNext()}
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
    )
  }

  function renderFooter (): JSX.Element | undefined {
    if (showShareButtons && !contacts?.length) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ component: 'FooterButton', button: 'SetupNotification' })
              onAddContact()
            }}>
            Setup Notification
          </FooterButton>
        </IonFooter>
      )
    }

    // if (nextScreen) {
    //   return (
    //     <IonFooter className='screenFooterButton'>
    //       <IonButton
    //         shape='round'
    //         fill='solid'
    //         expand='block'
    //         color='primary'
    //         routerLink={nextScreen}
    //         routerDirection='back'>
    //         Next
    //       </IonButton>
    //     </IonFooter>
    //   )
    // }
  }
  // if (loading) {
  //   return (
  //     <IonPage>
  //       <ScreenHeader
  //         title='Invite People'
  //         onBack={goBack} />
  //       <IonContent>
  //         <ScreenCenter height='80%'>
  //           <IonSpinner
  //             name='dots'
  //             color='medium' />
  //         </ScreenCenter>
  //       </IonContent>
  //     </IonPage>
  //   )
  // }
  // function renderScreenTips (): JSX.Element | undefined {
  //   return (
  //     <ScreenTips
  //       minTip={minTip}
  //       showScreenTip={showScreenTip}
  //       onClose={() => {
  //         setMinTip(true)
  //       }}
  //       onMinTip={() => {
  //         setMinTip(false)

  //         // if we've not shown endTips then go ahead and start the sequence immediately
  //         if (!componentStoreTips?.endTips) {
  //           setNextTip(MeetingInviteTips.sequence)
  //         }
  //       }}
  //       onButton={(restartTips) => {
  //         if (showScreenTip === MeetingInviteTips.endTips) {
  //           if (restartTips) {
  //             setNextTip(MeetingInviteTips.sequence, true)
  //           } else {
  //             setNextTip(MeetingInviteTips.noTip)
  //             setMinTip(true)
  //           }
  //         } else {
  //           setNextTip(showScreenTip + 1)
  //         }
  //       }} />
  //   )
  // }

  return (
    <IonPage>
      {renderHeader()}
      <IonContent
        className='titleIconBox'
        inputMode='numeric'>
        <div
          style={{ flex: 1, height: '100%' }}
          className='titleIconBox'>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Including yourself, how many people are expected to participate in this meeting?<br />
            </IonText>
          </div>
          {renderParticipantCount()}

          {/* {renderTentativeToggle()} */}
          {/* {renderGetLinkButton()} */}
          {renderShareOptions()}
        </div>
        {/* {invitationLinkTip()} */}
      </IonContent>
      {renderFooter()}
      {/* {renderScreenTips()} */}
      <IonToast
        isOpen={showToast}
        color='warning'
        onDidDismiss={() => setShowToast(false)}
        message='Meeting link copied to clipboard'
        duration={2000} />
    </IonPage>
  )
}

export default MeetingInvite
