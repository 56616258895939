import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import 'screens/Settings.css'
import { RootState } from 'services/store'
import { updateScreens } from 'services/store/screens/actions'
import TipsGooglePermissions from './TipsGooglePermissions'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'

const TipsGooglePermissionsScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { askConnectCalendars, tipsGooglePermissions } = useSelector((state: RootState) => state.screens)
  const dispatch = useDispatch()

  function onAddCalendar (authUrl: string): void {
    console.log('onAddCalendar: ', authUrl)
    dispatch(updateScreens({
      tipsGooglePermissions: {
        addCalendar: false,
      },
    }))
    window.open(authUrl, '_self')
  }

  function goBack (): void {
    dispatch(updateScreens({
      tipsGooglePermissions: {
        addCalendar: false,
      },
    }))
    history.goBack()
  }

  return (
    <CalendarsProvider>
      <MeetingsProvider meeting={askConnectCalendars?.meeting}>
        <TipsGooglePermissions
          onAddCalendar={tipsGooglePermissions?.addCalendar ? onAddCalendar : undefined}
          goBack={goBack} />
      </MeetingsProvider>
    </CalendarsProvider>
  )
}

export default TipsGooglePermissionsScreen
