export type AppVersion = {
   version: string;
   releaseDate: string;
   whatsNew: string[];
 }
export function getThisVersion (): AppVersion {
  return {
    version: '0.0.20',
    releaseDate: 'December 1, 2021',
    whatsNew: [
      'Multiple accounts support in a single browser. Now you can access a different Moica account in a different tab in the same browser.',
      'Assorted user experience improvements',
    ],
  }
}

export function getPreviousVersions (): AppVersion[] {
  return [
    {
      version: '0.0.19.2',
      releaseDate: 'November 9, 2021',
      whatsNew: [
        'Numerous improvements',
      ],
    },
    {
      version: '0.0.19.1',
      releaseDate: 'November 4, 2021',
      whatsNew: [
        'Now by default meeting time restrictions are not set',
        'Numerous other UX improvements',
      ],
    },
    {
      version: '0.0.19.0',
      releaseDate: 'October 26, 2021',
      whatsNew: [
        'Users who dont have their Google calendars connected can now add confirmed meetings to calendars on their phone or computer',
        'Search for participants in connections list to invite to a meeting',
        'Show recently confirmed meeting at the top of the meetings list',
        'By popular demand now the meeting card in home screen shows fireworks when the meeting gets confirmed',
        'Long tap on meeting calendar will select a time slot',
        'Enhanced meeting participant list screen',
        'Meeting link and description are now set in associated Google calendar events for the meetings',
        'Meeting time restriction less than meeting duration is not allowed',
        'Fixed meeting status message when there is an impasse or if meeting duration criteria is not met',
        'Fixed a case where video conference link was not being setup when a MeetMe link was used to setup the meeting',
        'Fixed issues with scrolling the availability calendar when a time slot is being edited',
        'A number of assorted UI/UX improvements',
      ],
    },
    {
      version: '0.0.18.1',
      releaseDate: 'September 22, 2021',
      whatsNew: [
        'Assorted improvements and bug fixes',
      ],
    },
    {
      version: '0.0.18.0',
      releaseDate: 'August 24, 2021',
      whatsNew: [
        'Users can now change their display name in account settings',
        'Users that don\'t have their calendars connected can see other Moica confirmed meetings in schedule calendar',
        'Organizer can now manually confirm a meeting time ',
        'Users can now add descriptions to meetings',
        'Numerous other bug fixes and enhancements',
      ],
    },
    {
      version: '0.0.17.2',
      releaseDate: 'July 26, 2021',
      whatsNew: [
        'If automagic times and meeting times don\'t match then show warning on meeting card',
        'Directly access edit meeting automagic screen from meeting card',
        'Allow user to disable automagic scheduling in invite flow or in meeting settings',
        'Assorted UI improvements',
      ],
    },
    {
      version: '0.0.17.1',
      releaseDate: 'July 24, 2021',
      whatsNew: [
        'As meet-me invitee you can now add participants before the meeting is scheduled',
        'As meet-me invitee you can also edit base meeting parameters such as title, meeting days etc before the meeting is scheduled',
        'Bug fixes for initiator and invitee flows with automagic settings',
        'Fix for a case where user might abort calendar setting screen while accepting an invite',
        'For a new user, show meeting settings tips along with the steps',
        'Minor UI fixes',
      ],
    },
    {
      version: '0.0.17',
      releaseDate: 'July 21, 2021',
      whatsNew: [
        'Users can now select busy times or add participants before automagic scheduling',
        'Users can now configure automagic or add participants to meet-me meeting before it is scheduled',
        'Number of other bug fixes and improvements',
      ],
    },
    {
      version: '0.0.16.1',
      releaseDate: 'July 8, 2021',
      whatsNew: [
        'New user simplified steps with progressive disclosure',
        'Simplified steps for first meeting invitee and first initiator',
        'New and simplfied add and invite participant screens',
        'Simplified tips screens',
        'Simplified color scheme for meeting calendar',
        'Highlight automagic time ranges on the calendar',
        'Edit calendar mode',
        'Fixed screens for small phones such as iPhone5',
        'Numerous other bug fixes and enhancements based on early user testing and feedback',
      ],
    },
    {
      version: '0.0.15.1',
      releaseDate: 'May 21, 2021',
      whatsNew: [
        'Improve tip slide flows',
        'Show swiper hand on center of screen',
      ],
    },
    {
      version: '0.0.15',
      releaseDate: 'May 12, 2021',
      whatsNew: [
        'Basic notification via sms',
        'Ability to send a friendly nudge to participants to select their times',
        'Send notifications using user timeZone',
      ],
    },
    {
      version: '0.0.14.5',
      releaseDate: 'April 28, 2021',
      whatsNew: [
        'Fix some scenarios where add calendar was not progressing',
      ],
    },
    {
      version: '0.0.14.4',
      releaseDate: 'April 23, 2021',
      whatsNew: [
        'If a user has automagic On, the app will show ALL of their available times to other participants',
        'UI fixes to make it easier for users to block off times when in automagic mode',
      ],
    },
    {
      version: '0.0.14.3',
      releaseDate: 'April 21, 2021',
      whatsNew: [
        'Fix add calendar issues',
      ],
    },
    {
      version: '0.0.14.2',
      releaseDate: 'April 20, 2021',
      whatsNew: [
        'Fix for cases where user is asked to login into the app again while connecting google calendar',
      ],
    },
    {
      version: '0.0.14.1',
      releaseDate: 'April 18, 2021',
      whatsNew: [
        'Footer buttons in various modals',
        'Assorted UX improvements',
      ],
    },
    {
      version: '0.0.14',
      releaseDate: 'April 15, 2021',
      whatsNew: [
        'New google calendar permissions tip slides',
        'New calendar preferences slides',
        'New connect calendar flow with intro slides and success screen',
        'New availability calendar UI with modal details',
        'Automagic availability calendar tips',
        'Activate the calendar only after user has configured it fully',
        'Allow user to connect more calendars after they are done connecting and configuring one',
        'Refined and simplified availability calendar design',
        'Full width meeting card layout',
        'Enable automagic scheduling tip slides',
        'Manual availability calendar tip slides',
        'New Tips tab screen',
      ],
    },
    {
      version: '0.0.13',
      releaseDate: 'April 2, 2021',
      whatsNew: [
        'Disable the People tab',
        'If user\'s autoBook is on then mark them as responded',
        'Make scheduling meeting and upcoming meeting list sections persistent',
        'Fixes to meeting day ranges selection logic',
        'Improvements to participant status color coding',
        'When user connects their calendar skip availability calendar and go straight to add participants',
        'Mark a time range as unavailable',
        'Fixes to day schedule screen tips',
        'Disable user calendar event colors',
        'Add Participant button bolder',
        'Add participant button in the footer',
      ],
    },
    {
      version: '0.0.12',
      releaseDate: 'March 30, 2021',
      whatsNew: [
        'New first-time meeting invite screen',
        'New ask for connecting calendar screen',
        'Status indicators for meeting participants',
        'Meeting status field on meeting cards',
        'Responded/Not-Responsed/Attending/Not-Attending status for participants on meeting cards',
        'Expected invitees avatars',
        'Optimized meeting card when the meeting has been confirmed',
        'Done selecting times button in calendar view',
        'By default tips are minimized',
        'New call-to-action buttons in the footer on various screens',
        'App navigation tab lables',
        'Numerous bug fixes and improvements',
      ],
    },
    {
      version: '0.0.11',
      releaseDate: 'March 17, 2021',
      whatsNew: [
        '💡 More help tip improvements for user testing phase 1',
        'Tip sequence logic fixes',
        'Fixes for Android input fields',
      ],
    },
    {
      version: '0.0.10',
      releaseDate: 'March 12, 2021',
      whatsNew: [
        '💡 More help tip improvements for all screens',
        'Tip sequence logic fixes',
      ],
    },
    {
      version: '0.0.9',
      releaseDate: 'March 9, 2021',
      whatsNew: [
        '💡 More help tip improvements for all screens',
        'Tip sequence logic fixes',
        'Fix meeting time success fireworks logic',
        'When re-enable tips default to minTipButton',
        'Display expected number of invitees on the meeting cards',
        'Default connections screen with tips',
        'Fix for edit meeting where enter key would skip duration',
        'Hide minTipButton on home and account screens when user scrolls to the bottom',
        'Loading meeting card details indicator',
      ],
    },
    {
      version: '0.0.8',
      releaseDate: 'March 3, 2021',
      whatsNew: [
        '💡 Tons of Help Tips improvements',
        'Replay tips on certain screens',
        'Highlight user time selection column in Day detail screen',
        'Make meeting date-range selection easier',
        'Fix single day date-range for a meeting',
        'Fix navigation tab bar issues',
        'Fix navigation from automagic scheduling off button',
        'Support Android keyboard Go button',
        'Dimiss tips on Got-It',
      ],
    },
    {
      version: '0.0.7.1',
      releaseDate: 'Feb 23, 2021',
      whatsNew: [
        'Minimize tip when user taps Got-It',
        'Tip logic fixes',
        'Tip refinements!',
      ],
    },
    {
      version: '0.0.7',
      releaseDate: 'Feb 21, 2021',
      whatsNew: [
        'New account setup steps. Users will be required to enter a contact for notifications and security during initial setup.',
        'Ability to have multiple fragmented day ranges for a meeting',
        '🆘 More help tips!',
      ],
    },
    {
      version: '0.0.6',
      releaseDate: 'Feb 9, 2021',
      whatsNew: [
        '🆘 Help Help Help!',
      ],
    },
    {
      version: '0.0.5',
      releaseDate: 'Feb 1, 2021',
      whatsNew: [
        'Fix a case where calendar event could overlap existing event',
        'Favor tentative times that have participants attending max duration ',
        'Performance improvement with batch-update of auto generated available times',
        'Fix auto schedular for weekends when it was disabled',
        'Auto scheduler ON message was distracting',
        'Show meeting initiator on meeting cards',
        'Show meeting participants and invitees on meeting cards',
        'Show auto-scheduler settings on meeting cards',
        'Meeting actions modal with edit, delete or decline actions',
        'Simplify meetingTime option buttons on meeting cards',
        'Performance optimization of meeting lists',
        'Show join video call option closer to meeting confirmed time',
        'Collapse and expand meeting list sections',
        'Meeting list section enhancements',
        'Set default time limit for a new meeting',
        'Remove recent activity section of meetings list',
        'Choose availability button when auto-scheduling is Off',
        'When user accepts the last invitation switch back to meeting list screen',
        'Invitation profile picture improvements',
      ],
    },
    {
      version: '0.0.4',
      releaseDate: 'Jan 23, 2021',
      whatsNew: [
        'When a participant is added to a meeting an invitation will sent to the user',
        'Show accept/decline options for an invitation to an anonymous user',
        'Updated Meet-with-Me screen',
      ],
    },
    {
      version: '0.0.3',
      releaseDate: 'Jan 14, 2021',
      whatsNew: [
        'About screen',
        'New Meet-with-Me screen',
        'Open video call in a new browser tab',
        'Display invitation photo correctly',
        'Show meeting card with best time options when user first joins a meeting',
      ],
    },
    {
      version: '0.0.2',
      releaseDate: 'Jan 12, 2021',
      whatsNew: [
        'Make logout button more prominent',
        'Dotted green outline of tentative meetings being held',
        'Fix add user button touch area',
        'When in autoScheduling range default to block time instead of creating new available time',
        'Showing three options to users who dont have their calendar connected can be confusing',
        'Show active, pending, upcoming meetings',
        'Remove participant button prominent',
        'Show create a new meeting button when no meetings',
        'Show larger range of overlapping time options to choose from',
        'Out of the list of tentative options prefer the ones with max duration',
        'Can’t expand an unavailable time slot',
        'Show past meetings on a different screen',
      ],
    },
  ]
}
