import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  IonContent, IonFooter, IonIcon, IonPage,
  IonText,
} from '@ionic/react'
import { mailUnreadOutline, phonePortraitOutline } from 'ionicons/icons'
import { Spring } from 'react-spring/renderprops'

import { useTokens } from 'context/TokensContext/TokensContext'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import AnimatedBell from 'components/atoms/AnimatedBell/AnimatedBell'
import AnimatedLock from 'components/atoms/AnimatedLock/AnimatedLock'
import { useTabs } from 'navigation/TabsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '10%',
}

const bellBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const box: CSSProperties = {
  marginTop: '5%',
  marginBottom: '5%',
}

const messageStyle = {
  fontSize: 16,
}

interface ComponentProps {
  showTip?: string;
  newUser: string;
  goBack: () => void;
  onAddContact: (url: string) => void;
}

const NewUserAddContact: React.FC<ComponentProps> = (
  {
    newUser, onAddContact, goBack,
  }) => {
  const [showAddContactsP1, setShowAddContactsP1] = useState(false)
  const [showAddContactsP2, setShowAddContactsP2] = useState(false)

  const { getVerifyToken } = useTokens()
  const { setShowTabs } = useTabs()
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  useEffect(() => {
    setShowAddContactsP1(true)
    setTimeout(() => {
      setShowAddContactsP2(true)
    }, 2000)
    setShowTabs && setShowTabs(true)
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'NewUserAddContact' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'NewUserAddContact',
      },
    })
  }

  async function addContact (contactType: string): Promise<void> {
    if (getVerifyToken) {
      const verifyToken = await getVerifyToken()

      if (verifyToken) {
        const url = `/addContact?contactType=${contactType}&verifyToken=${verifyToken}&newUser=${newUser}`

        onAddContact(url)
      }
    }
  }

  function renderAddContactsP1 (): JSX.Element | undefined {
    if (showAddContactsP1) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={(showAddContactsP1) ? { opacity: 1 } : { opacity: 0 }}>
          {props =>
            <div style={props}>
              <div style={bellBox}>
                <AnimatedBell />
              </div>
              <div style={{ ...box, marginBottom: '20%' }}>
                <IonText
                  style={messageStyle}>
                  How would you like to be notified when changes happen to your meetings?
                </IonText>
              </div>
            </div>}
        </Spring>
      )
    }
  }

  function renderAddContactsP2 (): JSX.Element | undefined {
    if (showAddContactsP2) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={(showAddContactsP2) ? { opacity: 1 } : { opacity: 0 }}>
          {props =>
            <div style={props}>
              <div style={bellBox}>
                <AnimatedLock />
              </div>
              <div style={box}>
                <IonText
                  style={messageStyle}>
                  You can use the same contact to access your account from any device.<br /><br />
                </IonText>
                <IonText
                  color='medium'
                  style={messageStyle}>
                  <span style={{ fontWeight: 'bold' }}>
                    We keep your information private and secure, never to be shared.
                  </span>
                  &nbsp;Past logging in, it is only used to notify you of meeting updates.
                </IonText>
              </div>
            </div>}
        </Spring>
      )
    }
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          fill='solid'
          onClick={() => {
            logTap({ button: 'ByPhone', component: 'FooterButton' })
            addContact('phone')
          }}>
          <IonIcon
            slot='start'
            icon={phonePortraitOutline} />
          By Phone
        </FooterButton>
        <FooterButton
          fill='outline'
          onClick={() => {
            logTap({ button: 'ByEmail', component: 'FooterButton' })
            addContact('email')
          }}>
          <IonIcon
            slot='start'
            icon={mailUnreadOutline} />
          By Email
        </FooterButton>
      </IonFooter>
    )
  }

  return (
    <IonPage ref={pageRef}>
      <ScreenHeader
        title={showAddContactsP1 ? 'Notifications' : 'Welcome to Moica'}
        onBack={goBack} />
      <IonContent scrollY={false}>
        <div style={container}>
          {renderAddContactsP1()}
          {renderAddContactsP2()}
        </div>
      </IonContent>
      {renderFooter()}
      {/* {renderTipContactPrivacy()} */}
      {/* {renderMinTip()} */}
    </IonPage>
  )
}

export default NewUserAddContact
