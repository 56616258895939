import { AppResetAction } from '../types'

export const UPDATE_SCREEN = 'UPDATE_SCREEN'

export interface MeetingsList {
  tips?: boolean;
  firstJoinedMeeting?: boolean;
  firstCreatedMeeting?: boolean;
}

export interface MeetingSettings {
  meeting?: string;
  tips?: boolean;
  steps?: boolean;
  addParticipants?: string[];
  connectCalendar?: boolean;
  meetMeInitiator?: boolean;
  meetMeInvitee?: boolean;
  newUser?: boolean;
}

export interface MeetingCalendarSettings {
  meeting?: string;
  previous?: string;
  initiator?: boolean;
  meetMe?: boolean;
  meetMeInitiator?: boolean;
  editOnly?: boolean;
}

export interface AskConnectCalendars {
  meeting?: string;
  previous?: string;
  nextScreen?: string;
}

export interface CalendarAccounts {
  previous?: string;
  nextScreen?: string;
}

export interface MeetingParticipants {
  meeting?: string;
}

export interface MeetingDescription {
  meeting?: string;
}
export interface ShareMeetingInvite {
  meeting?: string;
  nextScreen?: string;
}

export interface ChooseAvailability {
  meeting?: string;
  tips?: boolean;
  previous?: string;
  showList?: boolean;
  disableAutoEdit?: boolean;
  startEdit?: boolean;
  newUser?: boolean;
}

export interface DayAvailability {
  meeting?: string;
  tips?: boolean;
  selectedTime?: string;
}

export interface AddParticipants {
  meeting?: string;
  previous?: string;
  nextScreen?: string;
}

export interface NewUserName {
  meetUser?: string;
  invitation?: string;
}
export interface LoginFirebase {
  verifyToken?: string;
  meetUser?: string;
  invitation?: string;
}

export interface CalendarSettings {
  calendar?: string;
}

export interface AllScreens {
  newInitiator?: boolean;
  newInvitee?: boolean;
  newUser?: boolean;
}

export interface StartScreen {
  meetUser?: string;
  invitation?: string;
}

export interface TipsAvailabilityCalendar {
  nextScreen?: string;
  newUser?: boolean;
}

export interface TipsGooglePermissions {
  addCalendar?: boolean;
}

export interface Screens {
  allScreens?: AllScreens
  startScreen?: StartScreen
  meetingsList?: MeetingsList
  meetingSettings?: MeetingSettings
  meetingCalendarSettings?: MeetingCalendarSettings
  askConnectCalendars?: AskConnectCalendars
  calendarAccounts?: CalendarAccounts,
  calendarSettings?: CalendarSettings,
  meetingParticipants?: MeetingParticipants
  meetingDescription?: MeetingDescription
  shareMeetingInvite?: ShareMeetingInvite
  chooseAvailability?: ChooseAvailability
  dayAvailability?: DayAvailability
  addParticipants?: AddParticipants
  newUserName?: NewUserName
  loginFirebase?: LoginFirebase
  tipsAvailabilityCalendar?: TipsAvailabilityCalendar,
  tipsAvailabilityCalendarAuto?: TipsAvailabilityCalendar,
  tipsGooglePermissions?: TipsGooglePermissions
}

interface UpdateScreenAction {
  type: typeof UPDATE_SCREEN;
  screens: Screens;
}

export type ScreenActions = UpdateScreenAction | AppResetAction
