import { gql } from '@apollo/client'
import { MeetingTime } from 'types'

// Queries

export const GET_USER = gql`
query getUser{
  user {
    id
    name
    moicaID
    createTime
    invitedBy
  }
}
`

export const GET_MEETINGS = gql`
query getMeetings {
  meetings {
    id
    title
    duration
    description
    createdBy
    createTime
    updatedBy
    updateTime
    participants
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
    meetingTimes {
      id
      startTime
      endTime
      participants
      status
      updatedBy
      timeRanges {
        startTime
        endTime
        participants
      } 
    }
    participantsExpected
    tentativeThreshold
    videoConference {
      link
    }
  }
}
`

export const GET_MEETING = gql`
query getMeeting($id: String!){
  meeting(id: $id) {
    id
    title
    duration
    description
    createdBy
    createTime
    updateTime
    participants
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
    meetingTimes {
      id
      startTime
      endTime
      participants
      status
      updatedBy
      timeRanges {
        startTime
        endTime
        participants
      } 
    }
    participantsExpected
    tentativeThreshold
    videoConference {
      link
    }
  }
}
`

export const GET_MEETING_INVITE = gql`
query getMeetingInvite($invitation: String!){
  meetingInvite(invitation: $invitation) {
    id
    meeting
    title
    duration
    participants
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
    invitation
    name
    photo
  }
}
`

export const GET_INVITATIONS = gql`
 query getInvitations($meeting: String!){
  invitations(meeting: $meeting){
    id
    user
    meeting
    createTime
  }
}
`

export const GET_USER_INVITEES = gql`
 query getInvitees {
  invitees {
    id
    user
    meeting
    invitation
    invitedBy
    status
    meetMe
    createTime
    updateTime
  }
}
`

export const GET_MEETING_INVITEES = gql`
 query getInvitees($meeting: String!) {
  invitees(meeting: $meeting) {
    id
    user
    name
    moicaID
    photo
    meeting
    invitation
    invitedBy
    status
    meetMe
    createTime
    updateTime
  }
}
`

export const GET_USER_INVITATIONS = gql`
 query getUserInvitations {
  userInvitations {
    id
    meeting
    title
    duration
    participants
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
    invitation
    name
    photo
  }
}
`

export const UPDATE_INVITATION = gql`
 mutation UpdateInvitation($input: UpdateInvitationInput){
  updateInvitation( 
    input: $input
  ){
    id
  }
}
`

export type UpdateInvitationInput = {
  id: string;
  status: InvitationStatus
}

export enum InvitationStatus {
  seen = 'seen',
  accepted = 'accepted',
  declined = 'declined',
}

export const GET_TIME_SLOTS = gql`
query getTimeSlots($meeting: String!){
  timeSlots(meeting: $meeting) {
    id
    user
    meeting
    startTime
    endTime
    available
    autoCreated
  }
}
`

export const GET_PARTICIPANTS = gql`
query getParticipants($meeting: String!){
  participants(meeting: $meeting) {
    id
    user
    name
    admin
    creator
    photo
    invitedBy
    moicaID
  }
}
`

export const GET_PARTICIPANT = gql`
query getParticipant($meeting: String!){
  participant(meeting: $meeting) {
    id
    name
    admin
    meeting
    photo
    invitedBy
    moicaID
    meetMe
    bookCalendar {
      calendarId
      account
      timeRanges {
        startTime
        endTime
      }
      autoDays 
    }
    calendars {
      calendarId
      account
      enabled
      freeBusy
    }
    autoBook {
      enabled
      active
    }
    readyToSchedule {
      ready
      configureStartTime
      configureEndTime
    }
    deviceCalendar {
      action
      updateTime
    }
  }
}
`
export enum CalendarService {
  Google = 'Google'
}

export const GET_CALENDARS = gql`
query getCalendars{
  calendars {
    id
    user
    account
    photo
    calendars {
      calendarId
      primary
      enabled
      freeBusy
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    service
    accessTokens {
      accessToken,
      refreshToken,
      expireTime
    }
    createTime
  }
}
`

export const GET_CALENDAR_EVENTS = gql`
query calendarEvents {
  calendarEvents {
    id
    summary
    startTime
    endTime
  }
}
`

export const GET_PREFERENCES = gql`
query preferences {
  preferences {
    id
    bookCalendar {
      account
      calendarId
    }
    timeZone
  }
}
`

export const GET_CONTACTS = gql`
query contacts {
  contacts {
    id
    email
    phone
    photo
    provider
    createTime
  }
}
`

export const GET_DEVICES = gql`
query devices {
  devices {
    id
    device
    browser
    ip
    createTime
    accessTime
  }
}
`

export const GET_CONNECTIONS = gql`
query connections {
  connections {
    id
    user
    connectedTo
    name
    moicaID
    photo
    createTime
  }
}
`
export const GET_MEET_USER = gql`
 query getMeetUser($moicaID: String!){
  meetUser(moicaID: $moicaID){
    user
    name
    moicaID
    photo
  }
}
`

// Mutations
export const CREATE_USER = gql`
 mutation CreateUser($input: CreateUserInput){
  createUser( 
    input: $input
  ){
    user {
      id
      name
      moicaID
      createTime,
      invitedBy,
    }
    accessToken
  }
}
`

export type CreateUserInput = {
  name: string;
  deviceDetails: DeviceDetailsInput
  invitation?: string;
}

type DeviceDetailsInput = {
  browser: string;
}

export const UPDATE_USER = gql`
 mutation UpdateUser($input: UpdateUserInput) {
  updateUser(
    input: $input
  ) {
    id
    name
    moicaID
    createTime,
    invitedBy,
  }
}
`
export type UpdateUserInput = {
  name?: string;
  moicaID?: string;
}

export enum UpdateUserError {
  moicaIDNotAvailable = 'moicaIDNotAvailable',
}

export const DELETE_USER = gql`
 mutation DeleteUser {
  deleteUser {
    success
    message
  }
}
`

export const CREATE_MEETING = gql`
 mutation CreateMeeting($input: CreateMeetingInput){
  createMeeting( 
    input: $input
  ){
    id
    title
    duration
    description
    createdBy
    createTime
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
    participantsExpected
    tentativeThreshold
    videoConference {
      link
    }
  }
}
`

export type CreateMeetingInput = {
  title: string;
  duration: string;
  description?: string;
  dayRanges?: TimeRangeInput[];
  timeRanges?: TimeRangeInput[];
  bookCalendar?: BookCalendar;
  autoBookEnabled?: boolean;
  participantsExpected: number;
  tentativeThreshold: number;
  videoConference?: VideoConferenceInput
}

export const UPDATE_MEETING = gql`
 mutation UpdateMeeting($input: UpdateMeetingInput){
  updateMeeting( 
    input: $input
  ){
    id
    title
    duration
    description
    createdBy
    createTime
    updateTime
    updatedBy
    participants
    dayRanges {
        startTime
        endTime
      }
    timeRanges {
      startTime
      endTime
    }
    meetingTimes {
      id
      startTime
      endTime
      participants
      status
      updatedBy
      timeRanges {
        startTime
        endTime
        participants
      } 
    }
    participantsExpected
    tentativeThreshold
    videoConference {
      link
    }
  }
}
`

export type UpdateMeetingInput = {
  id: string;
  title?: string;
  duration?: string;
  description?: string;
  dayRanges?: TimeRangeInput[];
  timeRanges?: TimeRangeInput[];
  participantsExpected?: number;
  tentativeThreshold?: number;
  videoConference?: VideoConferenceInput
}

export const UPDATE_MEETING_TIMES = gql`
 mutation UpdateMeetingTimes($input: UpdateMeetingTimesInput){
  updateMeetingTimes( 
    input: $input
  ){
    id
    title
    duration
    description
    createdBy
    createTime
    updateTime
    updatedBy
    participants
    dayRanges {
        startTime
        endTime
      }
    timeRanges {
      startTime
      endTime
    }
    meetingTimes {
      id
      startTime
      endTime
      participants
      status
      updatedBy
      timeRanges {
        startTime
        endTime
        participants
      }
    }
    participantsExpected
    tentativeThreshold
    videoConference {
      link
    }
  }
}
`

export type UpdateMeetingTimesInput = {
  id: string;
  meetingTimes?: MeetingTime[];
}

export type TimeRangeInput = {
  startTime: string;
  endTime: string;
}

export type VideoConferenceInput = {
  link: string;
}

export const JOIN_MEETING = gql`
 mutation JoinMeeting($input: JoinMeetingInput){
  joinMeeting( 
    input: $input
  ){
    id
    title
    duration
    createdBy
    createTime
    dayRanges {
      startTime
      endTime
    }
    timeRanges {
      startTime
      endTime
    }
  }
}
`

export type JoinMeetingInput = {
  invitee: string;
  bookCalendar?: BookCalendar
  disableAutoBook?: boolean
  notReadyToSchedule?: boolean
}

export const DELETE_MEETING = gql`
 mutation DeleteMeeting($meeting: String!){
  deleteMeeting( 
    input: { meeting: $meeting }
  ){
    id
  }
}
`

export const EXIT_MEETING = gql`
 mutation ExitMeeting($meeting: String!){
  exitMeeting( 
    input: { meeting: $meeting }
  ){
    id
  }
}
`

export const CREATE_INVITEE = gql`
 mutation CreateInvitee($input: CreateInviteeInput){
  createInvitee( 
    input: $input
  ){
    id
    user
    meeting
    invitation
    status
    createTime
    updateTime
  }   
}
`

export enum CreateInviteeError {
  inviteNotFound = 'inviteNotFound',
  selfInvite = 'selfInvite',
}

export enum InviteeStatus {
  invited = 'invited',
  received = 'received',
  seen = 'seen',
  declined = 'declined',
  accepted = 'accepted',
}

export type CreateInviteeInput = {
  invitation?: string;
  inviteUsers?: string[];
  meeting?: string;
  status?: InviteeStatus
  appUrl?: string;
  meetMe?: boolean;
}

export const UPDATE_INVITEE = gql`
 mutation UpdateInvitee($input: UpdateInviteeInput){
  updateInvitee( 
    input: $input
  ){
    id
    user
    meeting
    invitation
    status
    createTime
    updateTime
  }
}
`

export type UpdateInviteeInput = {
  id: string;
  status?: InviteeStatus
}

export const DELETE_INVITEE = gql`
 mutation DeleteInvitee($input: DeleteInviteeInput){
  deleteInvitee( 
    input: $input
  ){
    id
  }
}
`

export type DeleteInviteeInput = {
  id: string;
}

export const CREATE_TIME_SLOT = gql`
 mutation CreateTimeSlot($input: CreateTimeSlotInput){
  createTimeSlot( 
    input: $input
  ){
    id
    user
    meeting
    startTime
    endTime
    available
    autoCreated
  }
}
`

export type CreateTimeSlotInput = {
  meeting: string;
  startTime: string;
  endTime: string;
  available: boolean;
}

export const UPDATE_TIME_SLOT = gql`
 mutation UpdateTimeSlot($input: UpdateTimeSlotInput){
  updateTimeSlot( 
    input: $input
  ){
    id
    user
    meeting
    startTime
    endTime
    available
    autoCreated
  }
}
`

export type UpdateTimeSlotInput = {
  id: string;
  startTime: string;
  endTime: string;
  available: boolean;
}

export const DELETE_TIME_SLOT = gql`
 mutation DeleteTimeSlot($input: DeleteTimeSlotInput){
  deleteTimeSlot(
    input: $input
  ){
   id
  }
}
`

export type DeleteTimeSlotInput = {
  meeting: string;
  id: string;
}

export const ADD_CALENDAR = gql`
 mutation addCalendar($input: AddCalendarInput){
  addCalendar( 
    input: $input
  ){
    authUrl
  }
}
`

export type AddCalendarInput = {
  service: CalendarService;
  state: string;
}

export const CREATE_CALENDAR = gql`
 mutation createCalendar($input: CreateCalendarInput){
  createCalendar( 
    input: $input
  ){
    id
    user
    service
    account
    photo
    calendars {
      calendarId
      primary
      enabled
      freeBusy
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    accessTokens {
      accessToken,
      refreshToken,
      expireTime
    }
  }
}
`

export type CreateCalendarInput = {
  service: CalendarService;
  authCode: string;
  timeZone: string;
}

export enum CreateCalendarError {
  calendarExists = 'calendarExists'
}

export const ACTIVATE_CALENDAR = gql`
 mutation activateCalendar($input: ActivateCalendarInput){
  activateCalendar( 
    input: $input
  ){
    id
    user
    service
    account
    photo
    calendars {
      calendarId
      primary
      enabled
      freeBusy
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    accessTokens {
      accessToken,
      refreshToken,
      expireTime
    }
    active
  }
}
`

export type ActivateCalendarInput = {
  id: string;
  active: boolean;
}

export const REMOVE_CALENDAR = gql`
 mutation removeCalendar($input: RemoveCalendarInput){
  removeCalendar( 
    input: $input
  ){
   id
  }
}
`

export type RemoveCalendarInput = {
  id: string;
}

export const UPDATE_CALENDAR = gql`
 mutation updateCalendar($input: UpdateCalendarInput){
  updateCalendar( 
    input: $input
  ){
    id
    user
    service
    account
    photo
    calendars {
      calendarId
      primary
      enabled
      freeBusy
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    accessTokens {
      accessToken,
      refreshToken,
      expireTime
    }
  }
}
`

export type UpdateCalendarInput = {
  id: string;
  calendarId: string;
  enabled?: boolean;
  freeBusy?: boolean;
  timeRanges?: TimeRangeInput[];
  autoDays?: string[];
}

export const UPDATE_PREFERENCES = gql`
 mutation updatePreferences($input: UpdatePreferencesInput){
  updatePreferences( input: $input )
  {
    id
    bookCalendar {
      account
      calendarId
    }
    timeZone
  }
}
`

export type UpdatePreferencesInput = {
  bookCalendar?: BookCalendar
}

export const CREATE_PARTICIPANTS = gql`
 mutation createParticipants($input: CreateParticipantsInput){
  createParticipants( 
    input: $input
  ){
    id
    name
    admin
    meeting
    photo
    invitedBy
    moicaID
    bookCalendar {
      calendarId
      account
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    calendars {
      calendarId
      account
      enabled
      freeBusy
    }
    autoBook {
      enabled
      active
    }
  }
}
`

export type CreateParticipantsInput = {
  meeting: string;
  participants: string[];
}

export const UPDATE_PARTICIPANT = gql`
 mutation updateParticipant($input: UpdateParticipantInput){
  updateParticipant( 
    input: $input
  ){
    id
    name
    admin
    meeting
    photo
    invitedBy
    moicaID
    bookCalendar {
      calendarId
      account
      timeRanges {
        startTime
        endTime
      }
      autoDays
    }
    calendars {
      calendarId
      account
      enabled
      freeBusy
    }
    autoBook {
      enabled
      active
    }
    deviceCalendar {
      action
      updateTime
    }
  }
}
`
export type UpdateParticipantInput = {
  id: string; // participant object id
  bookCalendar?: BookCalendar;
  calendars?: ParticipantCalendarInput[];
  autoBookEnabled?: boolean;
  readyToSchedule?: boolean;
  deviceCalendar?: DeviceCalendar
}

export const DELETE_PARTICIPANT = gql`
 mutation deleteParticipant($input: DeleteParticipantInput){
  deleteParticipant( input: $input )
  {
    id
  }
}
`

export type DeleteParticipantInput = {
  id: string
}

export type ParticipantCalendarInput = {
  calendarId: string;
  account: string;
  enabled?: boolean;
  freeBusy: boolean;
}

type BookCalendar = {
  calendarId: string;
  account: string;
  timeRanges?: TimeRangeInput[]
  autoDays?: string[]
}

export enum DeviceCalendarAction {
  updated = 'updated',
  deleted = 'deleted',
}

type DeviceCalendar = {
  action: DeviceCalendarAction
  updateTime?: string;
}

export const CREATE_VERIFY_TOKEN = gql`
 mutation CreateVerifyToken($input: CreateVerifyTokenInput){
  createVerifyToken( 
    input: $input
  ) {
    verifyToken
  }
}
`

export type CreateVerifyTokenInput = {
  deviceDetails: DeviceDetailsInput
}

export const VERIFY_CONTACT = gql`
 mutation VerifyContact($input: VerifyContactInput){
  verifyContact( 
    input: $input
  ){
    verifyToken
    accessToken
  }
}
`
export type VerifyContactInput = {
  email?: string;
  phone?: string;
  verifyFirebase?: {
    uid: string;
    idToken: string;
  };
  verifyToken: string;
  verifyDevice?: string;
}

export enum VerifyContactError {
  contactNotFound = 'contactNotFound',
  verificationFailed = 'verificationFailed',
  expiredToken = 'expiredToken',
  tokenNotFound = 'tokenNotFound'
}

export const CREATE_CONTACT = gql`
 mutation CreateContact($input: CreateContactInput){
  createContact( 
    input: $input
  ){
    id
    email
    phone
    photo
    provider
    createTime
  }
}
`
export type CreateContactInput = {
  email?: string;
  phone?: string;
  photo?: string;
  provider?: string;
  verifyFirebase?: {
    uid: string;
    idToken: string;
  }
  verifyToken: string;
}

export enum CreateContactError {
  contactInUse = 'contactInUse'
}
export const VERIFY_CODE = gql`
 mutation VerifyCode($input: VerifyCodeInput){
  verifyCode(
    input: $input
  ){
    success
    error
    accessToken
  }
}
`
export type VerifyCodeInput = {
  user?: string;
  device: string;
  verifyToken: string;
  code: string;
}

export const LOGOUT_USER = gql`
 mutation LogoutCode {
  logoutUser {
    success
  }
}
`
export const DELETE_CONTACT = gql`
 mutation DeleteContact($input: DeleteContactInput){
  deleteContact( 
    input: $input
  ){
    id
  }
}
`
export type DeleteContactInput = {
  contact: string;
}

export const DELETE_DEVICE = gql`
 mutation DeleteDevice($input: DeleteDeviceInput){
  deleteDevice( 
    input: $input
  ){
    id
  }
}
`
export type DeleteDeviceInput = {
  id: string;
}

export const NOTIFY_PARTICIPANTS = gql`
 mutation NotifyParticipants($input: NotifyParticipantsInput){
  notifyParticipants( 
    input: $input
  ){
    success
  }
}
`
export type NotifyParticipantsInput = {
  meeting: string;
  meetingUsers?: string[];
  notifyType: NotifyParticipantsType;
  allAwaiting?: boolean;
}

export enum NotifyParticipantsType {
  nudge = 'nudge',
}
// Subscriptions
export const MEETING_SUBSCRIPTION = gql`
  subscription {
    meetingUpdated {
      id
      title
      meetingTimes {
        startTime
        endTime
        status
        participants
        updatedBy
        timeRanges {
          startTime
          endTime
          participants
        } 
      }
    }
  }
`

export const TIMESLOTS_SUBSCRIPTION = gql`
  subscription  {
    timeSlotsUpdated {
      action,
      meeting,
      createdTimeSlots {
        id
        user
        meeting
        startTime
        endTime
        available
        autoCreated
      }
      updatedTimeSlots {
        id
        user
        meeting
        startTime
        endTime
        available
        autoCreated
      }
      deletedTimeSlots {
        id
      }
      
    }
  }
`

export const CALENDAR_SUBSCRIPTION = gql`
  subscription {
    calendarUpdated {
      account
      calendarId
    }
  }
`

export const PARTICIPANT_SUBSCRIPTION = gql`
  subscription  {
    participantUpdated {
      id
      name
      admin
      meeting
      photo
      invitedBy
      moicaID
      bookCalendar {
        calendarId
        account
        timeRanges {
          startTime
          endTime
        }
        autoDays
      }
      calendars {
        calendarId
        account
        enabled
        freeBusy
      }
      autoBook {
        enabled
        active
      }
      deviceCalendar {
        action
        updateTime
      }
    }
  }
`

export const INVITEE_SUBSCRIPTION = gql`
  subscription {
    inviteeUpdated {
      id
      status
      updateTime
    }
  }
`

export const VERIFY_SUBSCRIPTION = gql`
  subscription {
    verifyCompleted {
      accessToken
      device
      verifyToken
      email
      phone
      action
    }
  }
`
export const USER_SUBSCRIPTION = gql`
  subscription {
    userUpdated {
      id
      name
      moicaID
    }
  }
`
