
import React, { CSSProperties } from 'react'
import { TimeRange as TimeRangeType } from 'types'
import { IonLabel, IonIcon, IonSpinner, IonText } from '@ionic/react'

import 'theme/CalendarA.css'
import { closeOutline, ban, checkmarkCircleOutline } from 'ionicons/icons'
import moment from 'moment'
import { useCalendarView } from 'components/organisms/ScheduleCalendar/CalendarViewContext'
import { logoColor } from 'theme/styles'
import Icon from '@mdi/react'
import { mdiTrendingUp } from '@mdi/js'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const slotBorderRadius = '3px'
const slot: CSSProperties = {
  flex: 1,
  position: 'relative',
  borderRadius: slotBorderRadius,
  flexDirection: 'column',
  whiteSpace: 'normal',
}
const overlayBox: CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}

const titleBox: CSSProperties = {
  // flex: 1,
  display: 'flex',
  // flexDirection: 'column',
  paddingTop: 5,
  paddingLeft: 2,
  // paddingRight: 2,
  whiteSpace: 'normal',
  overflow: 'hidden',
}

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

const nonactiveLabelColor = '#5D4037'
const activeSlotColor = '#428cff'
// const activeSlotColor = '#5598ff'
// const overlappedTimeColor = '#7974ff'
const overlappedTimeColor = activeSlotColor
// const overlappedTimeColor = '#9692fa'
const confirmedColor = '#37CF4C'
// const busyColor = 'white'
const bestSlotColor = logoColor

const activeSlot: CSSProperties = {
  backgroundColor: activeSlotColor,
}
// const tentativeSlot: CSSProperties = {
//   backgroundColor: '#DEFABB',
//   zIndex: 2,
// }
// const confirmedSlot: CSSProperties = {
//   backgroundColor: confirmedColor,
// }
const editSlot: CSSProperties = {
  backgroundColor: 'transparent',
  border: '2px solid #3dc2ff',
  zIndex: 3,
}

const bestSlot: CSSProperties = {
  backgroundColor: 'transparent',
  border: `3px solid ${bestSlotColor}`,
  zIndex: 3,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
  // fontWeight: 'bold',
  userSelect: 'none',
  WebkitUserSelect: 'none',
}

const iconStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 3,
}

const trendingIcon: CSSProperties = {
  height: 20,
  width: 20,
  minHeight: 20,
  minWidth: 20,
  marginRight: 3,
  fontWeight: 'bold',
}

// const subTitleStyle: CSSProperties = {
//   fontSize: 11,
//   userSelect: 'none',
//   WebkitUserSelect: 'none',
//   overflow: 'hidden',
// }

const MAX_NAME_LENGTH = 8

export interface TimeRangeProps {
  dayStartTime: string;
  scale: number;
  timeRange: TimeRangeType;
  others?: boolean;
  active?: boolean;
  edit?: boolean;
  activity?: boolean;
  disabled?: boolean;
  confirmed?: boolean;
  tentative?: boolean;
  overlapped?: boolean;
  split?: boolean;
  open?: boolean;
  busy?: boolean;
  name?: string;
  consecutive?: boolean;
  trending?: boolean;
  best?: boolean;
  auto?: boolean;
  participants?: string[];
  totalParticipants: number;
  onDelete?: () => void;
  onBusy?: () => void;
}

const TimeRange: React.FC<TimeRangeProps> =
({
  dayStartTime, scale, timeRange, participants,
  others, active, edit, activity, disabled, consecutive,
  confirmed, tentative, split, open, busy, name, best, trending, auto,
  onDelete, onBusy,
}) => {
  const { timeSlotHeight = 50 } = useCalendarView()
  const { logEvent } = useAnalytics()

  const top =
    moment(timeRange.startTime)
      .diff(moment(dayStartTime), 'minutes') * (timeSlotHeight / scale)
  const { startTime, endTime } = timeRange
  const minutes = moment(endTime).diff(moment(startTime), 'minutes')
  const height = minutes * (timeSlotHeight / scale)

  if (disabled) {
    return <DisabledBox
      top={top}
      height={height} />
  }

  if (auto) {
    return <AutoBox
      top={top}
      height={height} />
  }

  const timeSlotBox: CSSProperties = {
    position: 'absolute',
    top: top,
    height,
    width: '100%',
    display: 'flex',
    paddingBottom: '1px',
    paddingRight: '2px',
    // border: '1px solid red',
  }

  const peopleCount = participants?.length || timeRange.participants?.length || 1
  const displayName = name ? name?.split(' ').find(t => t)?.substring(0, MAX_NAME_LENGTH) : ''
  // let count = ''

  // if (totalParticipants === 1 && active) {
  //   count = 'You'
  // } else if (active) {
  //   count = `You, ${peopleCount} of ${totalParticipants}`
  // } else {
  //   count = `${peopleCount} of ${totalParticipants}`
  // }

  // const count = (totalParticipants > 1) ? `${peopleCount} of ${totalParticipants}` : ''

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'TimeRange',
      },
    })
  }

  function renderMyTimeSlot (): JSX.Element | null {
    if (active && !edit && !open) {
      const container: CSSProperties = {
        ...slot,
        ...(busy ? {} : activeSlot),
        overflow: 'hidden',
      }

      if (!busy && others) {
        container.backgroundColor = overlappedTimeColor
      }

      if (!busy && best) {
        // container.backgroundColor = bestSlotColor
        container.backgroundColor = overlappedTimeColor
      }

      if (confirmed && !busy) {
        container.backgroundColor = confirmedColor
      }

      const color = 'white'

      const title = {
        ...titleStyle,
        color,
      }

      // let titleText = others ? (split ? '' : 'You +') : 'You'
      let titleText = 'You'
      let showCount = 0

      if (others && !busy) {
        if (displayName && height > 30) {
          titleText = `You + ${displayName}`
        } else {
          titleText = 'You '
          showCount = (peopleCount - 1)
        }
      }

      if (others && busy) {
        titleText = ''
      }
      // if (busy) {
      //   titleText = 'You'
      // }

      const tileBoxStyle = {
        ...titleBox,
        marginLeft: split ? '45%' : '5px',
      }

      if (height < 45) {
        tileBoxStyle.paddingTop = 3
      }

      if (height < 20) {
        title.fontSize = 8
        tileBoxStyle.paddingTop = 1
      }

      if (best || tentative || trending) {
        container.border = `3px solid ${bestSlotColor}`
        tileBoxStyle.paddingTop = '2px'
      }

      return (
        <div
          style={container}
          className={busy ? 'CalendarABusySlot' : ''}>
          {!consecutive &&
            <div
              style={tileBoxStyle}
              className='ion-text-wrap'>
              {tentative &&
                <Icon
                  path={mdiTrendingUp}
                  style={trendingIcon}
                  color={bestSlotColor} />}
              {trending &&
                <Icon
                  path={mdiTrendingUp}
                  style={trendingIcon}
                  color={bestSlotColor} />}
              {confirmed &&
                <IonIcon
                  style={{ ...iconStyle, color: (active ? color : confirmedColor) }}
                  icon={checkmarkCircleOutline} />}
              {busy &&
                <IonIcon
                  style={{ ...iconStyle, color }}
                  icon={ban} />}
              <IonText
                style={title}>
                {titleText}
                {showCount > 0 &&
                  <strong>
                    + {showCount}
                  </strong>}
              </IonText>
            </div>}
        </div>
      )
    }

    return null
  }

  function renderOthers (): JSX.Element | undefined {
    // if it's others and busy timeRange and show it in split view mode
    if ((!active && others && !busy) || (others && busy)) {
      const container = {
        ...slot,
        overflow: 'hidden',
      }

      const tileBoxStyle = {
        ...titleBox,
        paddingLeft: (split && !active) ? '45%' : '5px',
      }

      const color = nonactiveLabelColor

      if (best || tentative || trending) {
        container.border = `3px solid ${bestSlotColor}`
        tileBoxStyle.paddingTop = '2px'
      }

      if (confirmed) {
        container.border = `3px solid ${confirmedColor}`
        tileBoxStyle.paddingTop = '2px'
      }

      const title = {
        ...titleStyle,
        color,
        marginLeft: 3,
      }

      let titleText = ''
      let showCount = 0

      if (displayName) {
        titleText = displayName
      } else if (active) {
        titleText = (peopleCount - 1).toString()
      } else {
        // titleText = (peopleCount).toString()
        showCount = peopleCount
      }

      // console.log('other range : ', timeRange)
      if (height < 45) {
        tileBoxStyle.paddingTop = 3
      }

      if (height < 20) {
        title.fontSize = 8
        tileBoxStyle.paddingTop = 1
      }

      return (
        <div
          style={container}
          className='CalendarANonActiveSlot'>
          <div style={tileBoxStyle}>
            {tentative &&
              <Icon
                path={mdiTrendingUp}
                style={trendingIcon}
                color={bestSlotColor} />}
            {trending &&
              <Icon
                path={mdiTrendingUp}
                style={trendingIcon}
                color={bestSlotColor} />}
            {confirmed &&
              <IonIcon
                style={{ ...iconStyle, color: (active ? color : confirmedColor) }}
                icon={checkmarkCircleOutline} />}
            <IonText
              style={title}>
              {titleText}
              {showCount > 0 &&
                <strong>
                  {showCount}
                </strong>}
            </IonText>

          </div>
        </div>
      )
    }
  }

  function renderActivity (): JSX.Element | null {
    if (activity) {
      const container = {
        ...slot,
        ...editSlot,
      }

      return (
        <div
          style={container}
          className='CalendarAEditBox'>
          <div style={overlayBox}>
            <IonSpinner
              name='dots'
              style={{ color: 'lightgray' }} />
          </div>
        </div>
      )
    }

    return null
  }

  // function renderConfirmed (): JSX.Element | null {
  //   if (confirmed) {
  //     const container = {
  //       ...slot,
  //       ...confirmedSlot,
  //       overflow: 'hidden',
  //     }

  //     if (overlapped) {
  //       container.marginLeft = '5px'
  //     }

  //     const title = {
  //       ...titleStyle,
  //       color: 'white',
  //     }
  //     const subTitle = {
  //       ...subTitleStyle,
  //       color: 'white',
  //     }
  //     let timeRange

  //     // console.log('render confirmed: height: ', height)

  //     const end = moment(endTime).format('mm') === '00' ? moment(endTime).format('h a') : moment(endTime).format('h:mm a')

  //     if (moment(startTime).format('a') === moment(endTime).format('a')) {
  //       const start = moment(startTime).format('mm') === '00' ? moment(startTime).format('h') : moment(startTime).format('h:mm')

  //       timeRange = `${start} - ${end}`
  //     } else {
  //       const start = moment(startTime).format('mm') === '00' ? moment(startTime).format('h') : moment(startTime).format('h:mm')

  //       timeRange = `${start} - ${end}`
  //     }

  //     const tileBoxStyle = {
  //       ...titleBox,
  //       paddingLeft: split ? '45%' : '5px',
  //     }

  //     return (
  //       <div
  //         style={container}>
  //         {height > 20 &&
  //           <div style={tileBoxStyle}>
  //             <div style={{ display: 'flex', alignItems: 'center' }}>
  //               <IonIcon
  //                 style={{ color: 'white', marginRight: '2px', marginLeft: '-3px' }}
  //                 icon={checkmarkCircle} />
  //               {!split &&
  //                 <IonText
  //                   style={title}>
  //                   Confirmed
  //                 </IonText>}
  //             </div>
  //             {height > 30 &&
  //               <IonText
  //                 style={subTitle}
  //                 className='ion-text-wrap'>
  //                 {timeRange}
  //               </IonText>}
  //           </div>}
  //       </div>
  //     )
  //   }

  //   return null
  // }

  // function renderTentative (): JSX.Element | null {
  //   if (tentative) {
  //     const container = {
  //       ...slot,
  //       ...(active ? activeSlot : {}),
  //     }

  //     if (overlapped) {
  //       container.marginLeft = '5px'
  //     }

  //     const color = (active || busy) ? 'white' : nonactiveLabelColor
  //     const title = {
  //       ...titleStyle,
  //       color,
  //     }
  //     const subTitle = {
  //       ...subTitleStyle,
  //       color,
  //     }

  //     if (height < 40) {
  //       title.fontSize = 11
  //       subTitle.fontSize = 10
  //     }

  //     const tileBoxStyle = {
  //       ...titleBox,
  //       paddingLeft: split ? '45%' : '5px',
  //     }

  //     return (
  //       <div
  //         style={container}
  //         className={busy ? 'CalendarABusySlot' : 'CalendarANonActiveSlot'}>
  //         {height > 20 &&
  //           <div style={tileBoxStyle}>
  //             <div style={{ display: 'flex', alignItems: 'center' }}>
  //               <IonIcon
  //                 style={{ color, marginRight: '2px', marginLeft: '-3px' }}
  //                 icon={time} />
  //               {busy &&
  //                 <IonIcon
  //                   style={{ color, marginRight: '2px' }}
  //                   icon={removeCircleOutline} />}
  //               {!busy &&
  //                 <IonText
  //                   style={title}>
  //                   Tentative
  //                 </IonText>}
  //             </div>
  //             {height > 30 &&
  //               <IonText
  //                 style={subTitle}>
  //                 {count}
  //               </IonText>}
  //           </div>}
  //       </div>
  //     )
  //   }

  //   return null
  // }

  function renderEdit (): JSX.Element | null {
    if (edit || onDelete || onBusy) {
      const container = {
        ...slot,
        ...editSlot,
      }

      return (
        <div
          style={container}
          className='CalendarAEditBox'>
          <div style={overlayBox}>
            <TimeLabelBox
              edit
              time={startTime} />
            {edit && <EditHandle />}
            <TimeLabelBox
              edit
              bottom
              time={endTime} />
            {edit && <EditHandle bottom />}
            {onDelete &&
              <DeleteButton
                onClick={() => {
                  logTap({ button: 'Delete' })
                  onDelete()
                }} />}
            {onBusy &&
              <BusyButton
                onClick={() => {
                  logTap({ button: 'Busy' })
                  onBusy()
                }} />}
          </div>
        </div>
      )
    }

    return null
  }

  function renderBestRange (): JSX.Element | null {
    if (best && !active && !others) {
      const container = {
        ...slot,
        ...bestSlot,
      }

      if (confirmed) {
        container.border = `3px solid ${confirmedColor}`
      }

      // if (tentative) {
      //   container.border = `3px dashed ${confirmedColor}`
      // }

      return (
        <div
          style={container}>
          <div style={overlayBox} />
        </div>
      )
    }

    return null
  }

  // function renderBusy (): JSX.Element | null {
  //   if (busy && !tentative) {
  //     const container = {
  //       ...slot,
  //       overflow: 'hidden',
  //     }

  //     const color = busyColor

  //     const subTitle = {
  //       ...subTitleStyle,
  //       color,
  //     }

  //     const tileBoxStyle = {
  //       ...titleBox,
  //       paddingLeft: split ? '45%' : '5px',
  //     }
  //     const label = (totalParticipants > 1 && peopleCount > 1) ? `${peopleCount - 1} of ${totalParticipants}` : ''

  //     return (
  //       <div
  //         style={container}
  //         className='CalendarABusySlot'>
  //         <div style={tileBoxStyle}>
  //           {height > 20 &&
  //             <div style={{ display: 'flex', alignItems: 'center' }}>
  //               {peopleCount > 1 &&
  //                 <IonIcon
  //                   style={{ color, marginRight: '2px' }}
  //                   icon={peopleOutline} />}
  //               <IonIcon
  //                 style={{ color, marginRight: '2px' }}
  //                 icon={removeCircleOutline} />
  //               {height < 30 &&
  //                 <IonText
  //                   style={subTitle}>
  //                   {label}
  //                 </IonText>}
  //             </div>}
  //           {height > 30 &&
  //             <IonText
  //               style={subTitle}>
  //               {label}
  //             </IonText>}
  //         </div>
  //       </div>
  //     )
  //   }

  //   return null
  // }

  function renderOpenRange (): JSX.Element | null {
    if (open) {
      const container: CSSProperties = {
        flex: 1,
        flexDirection: 'column',
        border: '2px solid #3dc2ff',
        backgroundColor: 'transparent',
      }

      return (
        <div
          style={container} />
      )
    }

    return null
  }

  function renderPastRange (): JSX.Element | null {
    const now = moment()

    if (moment(startTime).isBefore(now)) {
      let height

      if (moment(endTime).isBefore(now)) {
        height = '100%'
      } else {
        const minutes = now.diff(moment(startTime), 'minutes')

        height = minutes * (timeSlotHeight / scale)
      }

      const overlay: CSSProperties = {
        flex: 1,
        height,
        backgroundColor: 'rgba(255,255,255, 0.5)',
      }

      const container: CSSProperties = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        paddingBottom: '1px',
        paddingRight: '2px',
      }

      return (
        <div
          style={container}>
          <div
            style={overlay}
            className='CalendarATimeSlotPast' />
        </div>
      )
    }

    return null
  }

  return (
    <div
      style={timeSlotBox}>
      {renderMyTimeSlot()}
      {renderOthers()}
      {renderBestRange()}
      {/* {renderTentative()} */}
      {/* {renderConfirmed()} */}
      {/* {renderBusy()} */}
      {renderActivity()}
      {renderEdit()}
      {renderPastRange()}
      {renderOpenRange()}
    </div>
  )
}

interface LabelProps {
  time: string;
  bottom?: boolean;
  edit?: boolean;
}

const TimeLabelBox: React.FC<LabelProps> = ({ time, bottom, edit }) => {
  const container: CSSProperties = {
    height: 10,
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // zIndex: 1,
  }

  if (bottom) {
    container.bottom = edit ? -16 : 2
  } else {
    container.top = edit ? -16 : 2
  }

  return (
    <div
      style={container}>
      <TimeLabel
        edit={edit}
        time={time} />
    </div>
  )
}

interface HandleProps {
  bottom?: boolean;
}

const EditHandle: React.FC<HandleProps> = ({ bottom }) => {
  const container: CSSProperties = {
    height: 30,
    width: 30,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  }

  if (bottom) {
    container.top = -15
    container.right = 5
  } else {
    container.bottom = -15
    container.left = 5
  }

  return (
    <div
      style={container}>
      <EditCircle />
    </div>
  )
}

const TimeLabel: React.FC<LabelProps> = ({ time, edit }) => {
  const label: CSSProperties = {
    color: edit ? 'gray' : 'white',
    fontSize: 10,
    fontWeight: 'bold',
    userSelect: 'none',
    WebkitUserSelect: 'none',
  }

  return (
    <IonLabel
      color='medium'
      style={label}>
      {moment(time).format('h:mm A')}
    </IonLabel>
  )
}

const EditCircle: React.FC = () => {
  const container: CSSProperties = {
    height: 12,
    width: 12,
    borderRadius: 6,
    border: '1px solid',
    borderColor: activeSlotColor,
    backgroundColor: 'white',
  }

  return (
    <div
      style={container} />
  )
}

interface ButtonProp {
  onClick: () => void;
}

const DeleteButton: React.FC<ButtonProp> = ({ onClick }) => {
  const container: CSSProperties = {
    height: '100%',
    right: -20,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid red',
  }

  const box: CSSProperties = {
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  }
  const circle: CSSProperties = {
    borderRadius: 10,
    backgroundColor: 'red',
    height: 20,
    width: 20,
    // border: `2px solid ${activeSlotColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const icon: CSSProperties = {
    fontSize: 16,
    color: 'white',
  }

  return (
    <div style={container}>
      <div
        style={box}
        onClick={onClick}>
        <div style={circle}>
          <IonIcon
            style={icon}
            icon={closeOutline} />
        </div>
      </div>

    </div>
  )
}

const BusyButton: React.FC<ButtonProp> = ({ onClick }) => {
  const container: CSSProperties = {
    height: '100%',
    right: -20,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid red',
  }

  const box: CSSProperties = {
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  }
  const circle: CSSProperties = {
    borderRadius: 10,
    height: 20,
    width: 20,
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const icon: CSSProperties = {
    fontSize: 16,
    color: 'white',
  }

  return (
    <div style={container}>
      <div
        style={box}
        onClick={onClick}>
        <div
          style={circle}
          className='CalendarABusySlot'>
          <IonIcon
            style={icon}
            icon={ban} />
        </div>
      </div>

    </div>
  )
}

interface DisabledBoxProps {
  top: number;
  height: number;
}

const DisabledBox: React.FC<DisabledBoxProps> = ({ top, height }) => {
  const container: CSSProperties = {
    position: 'absolute',
    top,
    height,
    width: '100%',
    display: 'flex',
    // borderBottom: '1px solid red',
  }

  return (
    <div
      style={container}
      className='CalendarATimeSlotPast' />
  )
}

const AutoBox: React.FC<DisabledBoxProps> = ({ top, height }) => {
  const container: CSSProperties = {
    position: 'absolute',
    top,
    height,
    width: '100%',
    display: 'flex',
  }

  return (
    <div
      style={container}
      className='CalendarAAutoSlot' />
  )
}

export default TimeRange
