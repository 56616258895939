import React from 'react'
import 'screens/Settings.css'

import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'services/store'

import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import CalendarsProvider from 'context/CalendarsContext/CalendarsContext'
import PreferencesProvider from 'context/PreferencesContext/PreferencesContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'
import DayAvailability from './DayAvailability'
import MeetingNotifyProvider from 'context/MeetingNotifyContext/MeetingNotifyContext'
import InviteesProvider from 'context/InviteesContext/InviteesContext'

const DayAvailabilityScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { dayAvailability } = useSelector((state: RootState) => state.screens)

  function goBack (): void {
    history.goBack()
  }

  return (
    <PreferencesProvider>
      <CalendarsProvider>
        <MeetingsProvider meeting={dayAvailability?.meeting}>
          <InviteesProvider meeting={dayAvailability?.meeting}>
            <ParticipantsProvider meeting={dayAvailability?.meeting}>
              <TimeSlotsProvider meeting={dayAvailability?.meeting}>
                <MeetingNotifyProvider>
                  <DayAvailability
                    selectedTime={dayAvailability?.selectedTime}
                    goBack={goBack} />
                </MeetingNotifyProvider>
              </TimeSlotsProvider>
            </ParticipantsProvider>
          </InviteesProvider>
        </MeetingsProvider>
      </CalendarsProvider>
    </PreferencesProvider>
  )
}

export default DayAvailabilityScreen
