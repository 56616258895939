import React, { useEffect } from 'react'
import { useSpring } from 'react-spring'

import { ComponentTips, MeetingCardTips, MeetingsScreenTips } from 'types/componentTips'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'

import { useTips } from 'context/TipsContext/TipsContext'
import { MeetingCard, MeetingsScreen } from 'services/store/tips/types'
import EndTipsCard from 'components/organisms/EndTipsCard/EndTipsCard'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'
import { IonIcon, IonText } from '@ionic/react'
import { trendingUp } from 'ionicons/icons'
// import { useScreenTips } from 'context/ScreenTipsContext/ScreenTipsContext'
import { useScreenTips } from 'context/ScreenTipsContext/ScreenTipsContextNew'
import { useAnalytics, EventData, EventName } from 'context/Analytics/AnalyticsContext'
import { useScreens } from 'context/ScreensContext/ScreensContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { InviteeStatus } from 'types'
// interface ScreenTipsProps {
// }

export const ScreenTips: React.FC = () => {
  const { showScreenTip, minTip, disableMinTip, tipCounter = 0, setShowScreenTip, setMinTip, setTipCounter } = useScreenTips()
  const { storeTips, updateStoreTips } = useTips()
  const { screens, updateScreens } = useScreens()
  const { invitees } = useInvitees()
  const { logEvent } = useAnalytics()

  
  const componentStoreTips = storeTips?.meetingsScreen
  
  const newInvitee = screens?.allScreens?.newInvitee
  const newInitiator = screens?.allScreens?.newInitiator
  
  useEffect(() => {
    startTips()
  }, [])
  
  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }
  
  function startTips (): void {
    setTimeout(() => {
      if (storeTips?.startMinTip || componentStoreTips?.endTips) {
        setMinTip && setMinTip(false)
      }

      // setNextTip(MeetingsScreenTips.sequence)
      // for now don't show any tips
      setScreenTip({ meetingsScreen: MeetingsScreenTips.noTip })
    }, 1000)
  }

  useEffect(() => {
    // console.log('tipCounter changed: ', tipCounter)

    if (tipCounter && !showScreenTip?.meetingCard && !showScreenTip?.meetingsScreen) {
      // setNextTip(MeetingsScreenTips.meetingCardSequence + 1)
      setScreenTip({ meetingsScreen: MeetingsScreenTips.noTip })
    }
  }, [tipCounter])

  function updateMeetingCardTips (tip: MeetingCard): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingCard: {
          ...storeTips?.meetingCard, ...tip,
        },
      })
    }
  }

  function updateMeetingScreenTips (tip: MeetingsScreen): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingsScreen: {
          ...storeTips?.meetingsScreen, ...tip,
        },
      })
    }
  }

  function setScreenTip (tip: ComponentTips): void {
    if (setShowScreenTip) {
      setShowScreenTip(tip)
    }
  }

  function incrementTipCounter (): void {
    setTipCounter && setTipCounter(tipCounter + 1)
  }

  function newInvitations (): number {
    if (invitees?.length) {
      return invitees.filter(i =>
        i.status === InviteeStatus.invited || i.status === InviteeStatus.seen || i.status === InviteeStatus.received)
        .length
    }

    return 0
  }

  function setNextTip (tip: MeetingsScreenTips): void {
    if (newInvitee) {
      setNextTipNewInvitee(tip)
    } else {
      setNextTipNormal(tip)
    }
  }

  function setNextTipNewInvitee (tip: MeetingsScreenTips): void {
    console.log('setNextTipNewInvitee setNextTip: ', tip)

    switch (tip) {
      case MeetingsScreenTips.sequence:
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.newInviteeJoin:
        if (!componentStoreTips?.newInviteeJoin) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.newInviteeJoin })
          break
        }

      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.meetingCardSequence:
        setScreenTip({ meetingCard: MeetingCardTips.sequence })
        // notify the meeting card that it needs to start the sequence
        incrementTipCounter()
        break

      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.newInviteeSuccess:
        if (!componentStoreTips?.newInviteeSuccess) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.newInviteeSuccess })
          break
        }

      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.endTips:
        if (!componentStoreTips?.endTips) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.endTips })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.noTip:
      default:
        // reset tips to endTips and show minimized button
        setScreenTip({ meetingsScreen: MeetingsScreenTips.endTips })
        // setMinTip(true)
        break
    }
  }

  function setNextTipNormal (tip: MeetingsScreenTips): void {
    console.log('setNextTipNormal setNextTip: ', tip)

    switch (tip) {
      case MeetingsScreenTips.sequence:
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.newInitiatorSuccess:
        if (newInitiator && !componentStoreTips?.newInitiatorSuccess) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.newInitiatorSuccess })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.meetingCardSequence:
        setScreenTip({ meetingCard: MeetingCardTips.sequence })
        // notify the meeting card that it needs to start the sequence
        incrementTipCounter()
        break

      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.newMeeting:
        if (!componentStoreTips?.newMeeting) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.newMeeting })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.profile:
        if (!componentStoreTips?.profile) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.profile })
          break
        }
        // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.newInvitations:
        if (newInvitations() && !componentStoreTips?.newInvitations) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.newInvitations })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.endTips:
        if (!componentStoreTips?.endTips) {
          setScreenTip({ meetingsScreen: MeetingsScreenTips.endTips })
          break
        }
      // eslint-disable-next-line no-fallthrough
      case MeetingsScreenTips.noTip:
      default:
        // reset tips to endTips and show minimized button
        setScreenTip({ meetingsScreen: MeetingsScreenTips.endTips })
        // setMinTip(true)
        break
    }
  }

  function onClose (): void {
    setMinTip && setMinTip(true)
  }

  function onNextTip (restartTips?: boolean): void {
    console.log('onNextTip: showScreenTip: ', showScreenTip)

    if (showScreenTip?.meetingsScreen) {
      const { meetingsScreen } = showScreenTip

      if (meetingsScreen === MeetingsScreenTips.newInitiatorSuccess) {
        setScreenTip({ meetingCard: MeetingCardTips.sequence })
        // notify the meeting card that it needs to start the sequence
        incrementTipCounter()
      } else if (meetingsScreen === MeetingsScreenTips.newInviteeSuccess) {
        setScreenTip({ meetingCard: MeetingCardTips.sequence })
        // notify the meeting card that it needs to start the sequence
        incrementTipCounter()

        // if (screens?.allScreens && updateScreens) {
        //   updateScreens && updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
        // }
      } else if (meetingsScreen === MeetingsScreenTips.endTips) {
        if (restartTips) {
          setNextTip(MeetingsScreenTips.meetingCardSequence)
        }
        // else {
        //   setNextTip(MeetingsScreenTips.noTip)
        //   setMinTip(true)
        // }
      } else {
        // setMinTip(true)
        setNextTip(showScreenTip.meetingsScreen + 1)
      }
    } else {
      // increment the counter to which every component is showing tips will detect the increment
      // and show it's next tip
      // setMinTip(true)
      incrementTipCounter()
    }
  }

  function onMinTip (): void {
    setMinTip && setMinTip(false)

    if (newInvitee) {
      setNextTip(MeetingsScreenTips.meetingCardSequence)
    } else {
      onNextTip()
    }
  }

  function newInviteeJoin (): JSX.Element | undefined {
    const text = `Congrats on participating in your first meeting!
    Let's help you with the next steps.`

    return (
      <ScreenTipCard
        text={text}
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newInviteeJoin}
        buttonLabel='great!'
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingScreenTips({ newInviteeJoin: true })
          setNextTip(MeetingsScreenTips.meetingCardSequence)
        }} />
    )
  }

  function newInviteeSuccess (): JSX.Element | undefined {
    const text = `Congrats on setting up your first meeting!
   Let's check out a few more things.`

    return (
      <ScreenTipCard
        text={text}
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newInviteeSuccess}
        buttonLabel='great!'
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingScreenTips({ newInviteeSuccess: true })

          if (screens?.allScreens && updateScreens) {
            updateScreens && updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
          }

          onNextTip()
        }} />
    )
  }

  function newInitiatorSuccess (): JSX.Element | undefined {
    const text = `Congrats on setting up your first meeting!
    If you've sent the invitation link, we'll let you know when your invitees have responded.
    Meanwhile, let's check out a few things.`

    return (
      <ScreenTipCard
        text={text}
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newInitiatorSuccess}
        buttonLabel='great!'
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingScreenTips({ newInitiatorSuccess: true })

          if (screens?.allScreens && updateScreens) {
            updateScreens && updateScreens({ allScreens: { ...screens.allScreens, newInvitee: false } })
          }

          onNextTip()
        }} />
    )
  }

  function chooseAvailability (): JSX.Element | undefined {
    const text = 'Please choose your preferred times for the meeting and Moica will find a time that works for everyone.'

    const show = !minTip && showScreenTip?.meetingCard === MeetingCardTips.chooseAvailability
    const animatedProps = {
      ...(show ? { opacity: 1, top: 60 } : { opacity: 0, top: -600 }),
    }

    if (newInvitee) {
      return (
        <ScreenTipCard
          show={show}
          animatedProps={animatedProps}
          text={text}
          onClose={() => {
            logTap({ component: 'ScreenTipCard', button: 'Close' })
            onClose && onClose()}}
          buttonLabel='got it'
          onButton={() => {
            logTap({ component: 'ScreenTipCard', button: 'Great' })
            // updateMeetingCardTips({ chooseAvailability: true })
            onClose()
          }} />
      )
    }

    return (
      <ScreenTipCard
        show={show}
        animatedProps={animatedProps}
        text={text}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ chooseAvailability: true })
          onNextTip()
        }} />
    )
  }

  function inviteParticipants (): JSX.Element | undefined {
    const text = 'Invite people to participate in your meeting.'

    return (
      <ScreenTipCard
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.inviteParticipants}
        text={text}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ inviteParticipants: true })
          onNextTip()
        }} />
    )
  }

  function trendingTimes (): JSX.Element | undefined {
    const text = 'Best Time Options: The earliest times that work for most number of participants and labeled as Trending with a trending-up icon'

    return (
      <ScreenTipCard
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.trendingTimes}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ trendingTimes: true })
          onNextTip()
        }}>
        <IonText>
          {text}
          <IonIcon
            style={{ marginLeft: 5, marginBottom: -5, marginRight: 10, fontSize: 20 }}
            icon={trendingUp} />
          Tap on the time to see deails.
        </IonText>
      </ScreenTipCard>
    )
  }

  function tentativeTimes (): JSX.Element | undefined {
    const text = 'Best Time Options: The earliest times that work for most number of participants. If more than half of the participants can make a time it\'s labeled as Tentative with a trending-up icon'

    return (
      <ScreenTipCard
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.tentativeTimes}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ tentativeTimes: true })
          onNextTip()
        }}>
        <IonText>
          {text}
          <IonIcon
            style={{ marginLeft: 5, marginBottom: -5, marginRight: 10, fontSize: 20 }}
            icon={trendingUp} />
          Tap on the time to see deails.
        </IonText>
      </ScreenTipCard>
    )
  }

  function confirmedTime (): JSX.Element | undefined {
    const text = 'Woohooo! Moica found a time that works for everyone. Tap the time to see details.'

    return (
      <ScreenTipCard
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.confirmedTime}
        text={text}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ confirmedTime: true })
          onNextTip()
        }} />
    )
  }

  function participants (): JSX.Element | undefined {
    const text = 'See list of people participating in the meeting.'

    return (
      <ScreenTipCard
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.participants}
        text={text}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ participants: true })
          onNextTip()
        }} />
    )
  }

  function changeAvailability (): JSX.Element | undefined {
    const text = 'Change of plans? Just go into the meeting calendar and pick the new times and Moica will find best times that work for everyone. Easy!'
    const show = !minTip && showScreenTip?.meetingCard === MeetingCardTips.changeAvailability
    const animatedProps = {
      ...(show ? { opacity: 1, top: 60 } : { opacity: 0, top: -600 }),
    }

    return (
      <ScreenTipCard
        text={text}
        animatedProps={animatedProps}
        show={show}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ changeAvailability: true })
          onNextTip()
        }} />
    )
  }

  function turnOnAuto (): JSX.Element | undefined {
    const text = 'Securely connect your Google calendar and Moica will automagically find your availabiliy and handle any change of plans for you.'
    const show = !minTip && showScreenTip?.meetingCard === MeetingCardTips.turnOnAuto
    const animatedProps = {
      ...(show ? { opacity: 1, top: 60 } : { opacity: 0, top: -600 }),
    }

    return (
      <ScreenTipCard
        text={text}
        animatedProps={animatedProps}
        show={show}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ turnOnAuto: true })
          onNextTip()
        }} />
    )
  }

  function changeAuto (): JSX.Element | undefined {
    const text = 'You have automagic scheduling enabled. That\'s awesome! You have full control over the times and days when Moica will autoschedule you for this meeting. Tap below to see the details.'
    const show = !minTip && showScreenTip?.meetingCard === MeetingCardTips.changeAuto
    const animatedProps = {
      ...(show ? { opacity: 1, top: 60 } : { opacity: 0, top: -600 }),
    }

    return (
      <ScreenTipCard
        text={text}
        animatedProps={animatedProps}
        show={show}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ changeAuto: true })
          onNextTip()
        }} />
    )
  }

  function availabilityCalendar (): JSX.Element | undefined {
    const text = 'You can see other participant\'s time choices in the meeting availability calendar'
    const show = !minTip && showScreenTip?.meetingCard === MeetingCardTips.availabilityCalendar
    const animatedProps = {
      ...(show ? { opacity: 1, top: 60 } : { opacity: 0, top: -600 }),
    }

    return (
      <ScreenTipCard
        text={text}
        animatedProps={animatedProps}
        show={show}
        onClose={() => {
          logTap({ component: 'ScreenTipCard', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'ScreenTipCard', button: 'Great' })
          updateMeetingCardTips({ availabilityCalendar: true })
          onNextTip()
        }} />
    )
  }

  return (
    <div>
      <TipMin
        show={minTip && !disableMinTip}
        onButton={onMinTip} />
      {newInviteeJoin()}
      {newInviteeSuccess()}
      {newInitiatorSuccess()}
      {chooseAvailability()}
      {inviteParticipants()}
      {trendingTimes()}
      {tentativeTimes()}
      {confirmedTime()}
      {participants()}
      {changeAvailability()}
      {turnOnAuto()}
      {changeAuto()}
      {availabilityCalendar()}
      <TipInviteeMeetingSettings
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.meetingTitle}
        onClose={() => {
          logTap({ component: 'TipInviteeMeetingSettings', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipInviteeMeetingSettings', button: 'Great' })
          updateMeetingCardTips({ meetingTitle: true })
          onNextTip()
        }} />
      <TipInviteeMoreActions
        show={!minTip && showScreenTip?.meetingCard === MeetingCardTips.moreActions}
        onClose={() => {
          logTap({ component: 'TipInviteeMoreActions', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipInviteeMoreActions', button: 'Great' })
          updateMeetingCardTips({ moreActions: true })
          onNextTip()
        }} />
      <TipNewMeeting
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newMeeting}
        onClose={() => {
          logTap({ component: 'TipNewMeeting', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipNewMeeting', button: 'Great' })
          updateMeetingScreenTips({ newMeeting: true })
          onNextTip()
        }} />
      <TipProfile
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.profile}
        onClose={() => {
          logTap({ component: 'TipProfile', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipProfile', button: 'Great' })
          updateMeetingScreenTips({ profile: true })
          onNextTip()
        }} />
      <TipNewInvitations
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.newInvitations}
        onClose={() => {
          logTap({ component: 'TipNewInvitations', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipNewInvitations', button: 'Great' })
          updateMeetingScreenTips({ newInvitations: true })
          onNextTip()
        }} />
      <EndTips
        show={!minTip && showScreenTip?.meetingsScreen === MeetingsScreenTips.endTips}
        onClose={() => {
          logTap({ component: 'EndTips', button: 'Close' })
          onClose && onClose()}}
        onButtons={(button) => {
          if (button === 0) {
            logTap({ component: 'EndTips', button: 'Yes' })
            updateStoreTips && updateStoreTips({ meetingsScreen: {}, meetingCard: {} })
            onNextTip(true)
          } else {
            logTap({ component: 'EndTips', button: 'No' })
            updateMeetingScreenTips({ endTips: true })
            onClose && onClose()
          }
        }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onClose?: () => void;
  onButton?: () => void;
  onButtons?: (button: number) => void;
}

const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      onClick={onButton} />
  )
}

// const TipNewInitiatorSuccess: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
//   const text = `Congrats on setting up your first meeting!
//   If you've sent the invitation link, we'll let you know when your invitees have responded.
//   Meanwhile let's check out a few things.`

//   return (
//     <ScreenTipCard
//       show={show}
//       text={text}
//       onClose={onClose}
//       onButton={onButton} />
//   )
// }

const TipInviteeMeetingSettings: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
  const text = 'Tap a Meeting title to edit the settings and details.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}

const TipInviteeMoreActions: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
  const text = 'Tap the top of the card to see meeting actions.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}

const TipNewMeeting: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
  const text = 'Create a new Meeting.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}

const TipNewInvitations: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
  const text = 'You have new meeting invitations!'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}

const TipProfile: React.FC<ContainerProps> = ({ show, onClose, onButton }) => {
  const text = 'You can quickly access your profile by tapping your avatar.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      onClose={onClose}
      onButton={onButton} />
  )
}

const EndTips: React.FC<ContainerProps> = ({ show, onClose, onButtons }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  const text = 'You\'ve seen all the tips here.'
  const subtext = 'Would you like to see the tips for this screen again?'

  return (
    <EndTipsCard
      animatedProps={animatedProps}
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButtons={onButtons} />
  )
}
