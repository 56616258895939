import React, { useEffect, useState, CSSProperties } from 'react'
import {
  IonSpinner,
  IonContent, IonListHeader, IonActionSheet, IonPage, IonItemDivider, IonFooter, IonItem, IonLabel, IonText,
  isPlatform,
} from '@ionic/react'

import { compareTimes } from 'services/time'
import { Invitee, InviteeStatus, Participant } from 'types'

import ParticipantListItem from 'components/molecules/ParticipantListItem/ParticipantListItem'
import { DeleteParticipantInput } from 'services/api'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useInvitees } from 'context/InviteesContext/InviteesContext'

import ListItem from 'components/atoms/ListItem/ListItem'
import { people } from 'ionicons/icons'
import InviteeListItem from 'components/molecules/InviteeListItem/InviteeListItem'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Avatar } from '@material-ui/core'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import { useUser } from 'context/UserContext/UserContext'
import { withinDayRanges, withinTimeRanges } from 'components/organisms/ScheduleCalendar/utils'

const title = 'Meeting Participants'

const container: CSSProperties = {
  flex: 1,
  paddingTop: 10,
  minHeight: '100%',
  paddingBottom: 40,
}

const avatarStyle = {
  width: 36,
  height: 36,
  backgroundColor: '#92949C',
  marginRight: 15,
}

const titleStyle: CSSProperties = {
  fontSize: 12,
}

interface ComponentPorps {
  onAdd: () => void;
  onInvite: () => void;
  goBack: () => void;
}

const MeetingParticipants: React.FC<ComponentPorps> = ({ onAdd, onInvite, goBack }) => {
  const [showParticipantActions, setParticipantActions] = useState<Participant>()
  const [showInviteeActions, setInviteeActions] = useState<Invitee>()
  const [showRemovingParticipant, setShowRemovingParticipant] = useState('')
  const [showRemovingInvitee, setShowRemovingInvitee] = useState('')

  const { meeting } = useMeetings()
  const { meetingInvitees, deleteInvitee } = useInvitees()
  const { loading, participants, participant, deleteParticipant } = useParticipants()
  const { logEvent } = useAnalytics()
  const { timeSlots } = useTimeSlots()
  const { user } = useUser()
  // let participants: [Participant] | undefined

  // if (participantsData && participantsData.participants) {
  //   participants = participantsData.participants.slice().sort((a: Participant, b: Participant) =>
  //     compareTimes(a.createTime, b.createTime))
  // }

  const notResponded: Participant[] = []
  const responded = participants?.filter(participant => {
    // if (user?.id && participant.user === user.id && autoBook) {
    //   return true
    // }

    const selectedTime =
        timeSlots?.filter(timeSlot => timeSlot.user === participant.user)
          .filter(t => withinDayRanges(t.startTime, t.endTime, meeting?.dayRanges))
          .find(t => {
            if (!meeting?.timeRanges?.length) {
              // if meeting.timeRanges are not set then all time slots withinDayRanges are valid
              return true
            }

            if (withinTimeRanges(t.startTime, meeting?.timeRanges) || withinTimeRanges(t.endTime, meeting?.timeRanges)) {
              return true
            }
          })

    if (selectedTime) {
      return true
    }

    notResponded.push(participant)

    return false
  })

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'MeetingParticipants', meeting: meeting?.id },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'MeetingParticipants',
        meeting: meeting?.id,
      },
    })
  }

  async function removeParticipant (): Promise<void> {
    console.log('remove user: ', showParticipantActions)

    if (showParticipantActions && deleteParticipant) {
      setParticipantActions(undefined)
      setShowRemovingParticipant(showParticipantActions.user)

      const input: DeleteParticipantInput = {
        id: showParticipantActions.id,
      }

      await deleteParticipant(input)

      setTimeout(() => {
        setShowRemovingParticipant('')
      }, 1000)
    }
  }

  async function removeInvitee (): Promise<void> {
    console.log('remove invitee: ', showInviteeActions)

    if (showInviteeActions && deleteInvitee) {
      setInviteeActions(undefined)
      setShowRemovingInvitee(showInviteeActions.user)

      await deleteInvitee(showInviteeActions.id)

      setTimeout(() => {
        setShowRemovingInvitee('')
      }, 1000)
    }
  }

  // function renderAddParticipants (): JSX.Element {
  //   return (
  //     <ListItem
  //       label='Invite Participants'
  //       detail
  //       icon={{ name: addCircleOutline, slot: 'start', color: 'primary' }}
  //       onClick={() => onAdd()} />
  //   )
  // }

  // function renderInviteLink (): JSX.Element {
  //   return (
  //     <ListItem
  //       label='Invite Others via Link'
  //       detail
  //       icon={{ name: linkOutline, slot: 'start', color: 'primary' }}
  //       onClick={() => onInvite()} />
  //   )
  // }

  function renderParticipantCount (): JSX.Element | undefined {
    if (meeting && meeting?.participantsExpected) {
      return (
        <ListItem
          title='total expected participants (including you)'
          label={meeting.participantsExpected.toString()}
          detail
          style={{ marginTop: 10, marginBottom: 10 }}
          icon={{
            name: people,
            color: 'medium',
            slot: 'start',
          }}
          onClick={() => {
            logTap({ component: 'ListItem', button: 'ParticipantCount' })
            onInvite()
          }} />
      )
    }
  }

  function renderParticipants (): JSX.Element | undefined {
    if (participants?.length && participant) {
      const sorted = participants.slice().sort((a: Participant, b: Participant) =>
        compareTimes(a.createTime, b.createTime))

      const admin = sorted.findIndex(p => p.admin)

      if (admin > -1) {
        sorted.unshift(sorted.splice(admin, 1)[0])
      }

      return (
        <div>
          {sorted.map((p: Participant, i: number) => (
            <ParticipantListItem
              key={i}
              participant={p}
              participants={participants}
              lines={i < participants.length - 1}
              showRemoving={p.user === showRemovingParticipant}
              onSelect={(p) => {
                logTap({ component: 'ParticipantListItem', button: 'SelectParticipant' })
                console.log('selected')
                setParticipantActions(p)
              }} />
          ))}
        </div>

      )
    }
  }

  function renderResponded (): JSX.Element | undefined {
    if (responded?.length && participant) {
      const sorted = responded.slice().sort((a: Participant, b: Participant) =>
        compareTimes(a.createTime, b.createTime))

      const admin = sorted.findIndex(p => p.admin)

      if (admin > -1) {
        sorted.unshift(sorted.splice(admin, 1)[0])
      }

      return (
        <div>
          <IonItemDivider
            color='light'
            sticky>
            <IonText
              style={titleStyle}
              color='medium'>
              RESPONDED {responded.length}
            </IonText>
          </IonItemDivider>

          {sorted.map((p: Participant, i: number) => (
            <ParticipantListItem
              key={i}
              participant={p}
              participants={participants}
              lines={i < notResponded.length - 1}
              showRemoving={p.user === showRemovingParticipant}
              onSelect={(p) => {
                logTap({ component: 'ParticipantListItem', button: 'SelectParticipant' })
                console.log('selected')
                setParticipantActions(p)
              }} />
          ))}
        </div>
      )
    }
  }

  function renderNotResponded (): JSX.Element | undefined {
    if (notResponded?.length && participant) {
      const sorted = notResponded.slice().sort((a: Participant, b: Participant) =>
        compareTimes(a.createTime, b.createTime))

      const admin = sorted.findIndex(p => p.admin)

      if (admin > -1) {
        sorted.unshift(sorted.splice(admin, 1)[0])
      }

      return (
        <div>
          <IonItemDivider
            color='light'
            sticky>
            <IonText
              style={titleStyle}
              color='medium'>
              NOT RESPONDED {notResponded.length}
            </IonText>
          </IonItemDivider>
          {sorted.map((p: Participant, i: number) => (
            <ParticipantListItem
              key={i}
              participant={p}
              participants={participants}
              lines={i < notResponded.length - 1}
              showRemoving={p.user === showRemovingParticipant}
              onSelect={(p) => {
                logTap({ component: 'ParticipantListItem', button: 'SelectParticipant' })
                console.log('selected')
                setParticipantActions(p)
              }} />
          ))}
        </div>
      )
    }
  }

  function renderInvitees (): JSX.Element | undefined {
    if (meetingInvitees?.length && participants?.length) {
      const invitees = meetingInvitees.filter(invitee => {
        if (invitee.status === InviteeStatus.accepted || invitee.status === InviteeStatus.declined) {
          return false
        }

        if (participants.find(p => p.user === invitee.user)) {
          return false
        }

        return true
      })

      if (invitees.length) {
        const sorted = invitees.sort((a: Invitee, b: Invitee) =>
          compareTimes(b.createTime, a.createTime))

        return (
          <div>
            <IonItemDivider
              color='light'
              sticky>
              <IonText
                style={titleStyle}
                color='medium'>
                INVITED {invitees.length}
              </IonText>
            </IonItemDivider>
            {sorted.map((invitee: Invitee, i: number) => (
              <InviteeListItem
                key={i}
                invitee={invitee}
                participants={participants}
                lines={i < invitees.length - 1}
                showRemoving={invitee.user === showRemovingInvitee}
                onSelect={(invitee) => {
                  logTap({ component: 'InviteeListItem', button: 'SelectInvitee' })
                  console.log('selected')
                  setInviteeActions(invitee)
                }} />
            ))}
          </div>

        )
      }
    }
  }

  function renderDeclined (): JSX.Element | undefined {
    if (meetingInvitees?.length && participants?.length) {
      const invitees = meetingInvitees.filter(invitee => {
        if (invitee.status === InviteeStatus.declined) {
          if (participants.find(p => p.user === invitee.user)) {
            return false
          }

          return true
        }
      })

      if (invitees.length) {
        const sorted = invitees.sort((a: Invitee, b: Invitee) =>
          compareTimes(b.createTime, a.createTime))

        return (
          <div>
            <IonItemDivider
              color='light'
              sticky>
              <IonText
                style={titleStyle}
                color='medium'>
                DECLINED {invitees.length}
              </IonText>
            </IonItemDivider>
            {sorted.map((invitee: Invitee, i: number) => (
              <InviteeListItem
                key={i}
                invitee={invitee}
                participants={participants}
                lines={i < invitees.length - 1}
                showRemoving={invitee.user === showRemovingInvitee}
                onSelect={(invitee) => {
                  logTap({ component: 'InviteeListItem', button: 'SelectInvitee' })
                  console.log('selected')
                  setInviteeActions(invitee)
                }} />
            ))}
          </div>

        )
      }
    }
  }

  function renderExpectingMore (): JSX.Element | undefined {
    const invitees = meetingInvitees?.filter(invitee => {
      // when an invitee is marked accepted  go ahead and render it as an invitee
      // until it's added to participant list as there might be a delay between
      // being marked as accepted and added to participants list
      // so to hide that delay show accepted invitee. Once it's added to participant
      // list we fill filter it out below.
      if (invitee.status === InviteeStatus.declined) {
        return false
      }

      if (participants && participants.find(p => p.user === invitee.user)) {
        return false
      }

      return true
    })

    let expectingInvitees = 0

    if (participants?.length && meeting?.participantsExpected) {
      if (meeting?.participantsExpected > participants?.length) {
        expectingInvitees = meeting.participantsExpected - participants.length

        if (invitees?.length && invitees.length <= expectingInvitees) {
          expectingInvitees = expectingInvitees - invitees.length
        }
      }
    }

    if (expectingInvitees) {
      return (
        <div>
          <IonItemDivider
            color='light'
            sticky>
            <IonText
              style={titleStyle}
              color='medium'>
              INVITED VIA LINK
            </IonText>
          </IonItemDivider>
          <IonItem lines='full'>
            <Avatar
              slot='start'
              style={avatarStyle}>
              <IonText style={{ fontSize: 22 }}>
                {expectingInvitees}
              </IonText>
            </Avatar>
            <IonLabel>
              <p>Expecting {expectingInvitees} more</p>
              <p>to join via link</p>
            </IonLabel>
          </IonItem>
        </div>
      )
    }
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          fill='solid'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'InviteParticipants' })
            onAdd()
          }}>
          Invite Participants
        </FooterButton>
        <FooterButton
          fill='outline'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'InviteViaLink' })
            onInvite()
          }}>
          Invite to Meeting via Link
        </FooterButton>
      </IonFooter>
    )
  }

  function renderParticipantActions (): JSX.Element {
    return (
      <IonActionSheet
        isOpen={!!showParticipantActions}
        onDidDismiss={() => setParticipantActions(undefined)}
        buttons={[{
          text: 'Remove ' + (showParticipantActions ? showParticipantActions.name : 'Participant'),
          role: 'destructive',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'RemoveParticipant' })
            removeParticipant()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'CancelRemoveAction' })
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  function renderInviteeActions (): JSX.Element {
    return (
      <IonActionSheet
        isOpen={!!showInviteeActions}
        onDidDismiss={() => setInviteeActions(undefined)}
        buttons={[{
          text: 'Delete invitation for ' + (showInviteeActions ? showInviteeActions.name : 'Invitee'),
          role: 'destructive',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'DeleteInvite' })
            removeInvitee()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'CancelInviteAction' })
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  return (
    <IonPage>
      <ScreenHeader
        title={meeting?.title || title}
        onBack={goBack} />
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          <IonListHeader
            style={{ marginBottom: 10 }}
            mode='ios'>
            Participants
            {loading &&
              <IonSpinner name='dots' />}
          </IonListHeader>
          {renderParticipantCount()}
          {/* {renderAddParticipants()} */}
          {/* {renderInviteLink()} */}
          {/* {renderParticipants()} */}
          {renderResponded()}
          {renderNotResponded()}
          {renderInvitees()}
          {renderDeclined()}
          {renderExpectingMore()}
        </div>
      </IonContent>
      {renderFooter()}
      {renderParticipantActions()}
      {renderInviteeActions()}
    </IonPage>
  )
}

export default MeetingParticipants
