import queryString from 'query-string'
import axios from 'axios'
import moment from 'moment'

import { CalendarAccount, CalendarEvent, SubCalendar, ParticipantCalendar } from 'types'

const API_KEY = process.env.REACT_APP_GOOG_API_KEY
const CLIENT_ID = process.env.REACT_APP_GOOG_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_GOOG_CLIENT_SECRET
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
]
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly'

// https://github.com/google/google-api-javascript-client/blob/master/docs/reference.md
// https://developers.google.com/calendar/concepts
// https://developers.google.com/calendar/v3/reference
// https://developers.google.com/people/v1/getting-started

export function initGoogleApi (): Promise<void> {
  return new Promise((resolve) => {
    window.gapi.load('client', () => {
      window.gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => resolve())
    })
  })
}

export async function getAccessToken (tokens: {
  accessToken: string;
  refreshToken: string;
  expireTime: string;
}): Promise<string> {
  const { accessToken, refreshToken, expireTime } = tokens

  if (moment(expireTime).isAfter(moment())) {
    return accessToken
  }

  if (refreshToken) {
  /*eslint-disable */
  const req = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  }
 /* eslint-enable */

    const api = queryString.stringifyUrl({ url: 'https://oauth2.googleapis.com/token', query: req })

    const response = await axios.post(api, null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.data) {
      return response.data.access_token
    }
  }

  return ''
}
export type GetEventsInput = {
  account: CalendarAccount;
  calendars?: ParticipantCalendar[];
  range?: {
    startTime: string;
    endTime: string;
  };
}
export async function getEvents (args: GetEventsInput): Promise<CalendarEvent[]> {
  const { account, calendars, range } = args

  const accessToken = await getAccessToken(account.accessTokens)

  const timeMin = range ? range.startTime : moment().toISOString()
  const timeMax = range ? range.endTime : moment().add(7, 'days').toISOString()

  window.gapi.client.setToken({
    // eslint-disable-next-line
    access_token: accessToken,
  })

  console.log('GET EVENTS for userCalendar: ', account)
  console.log(`${timeMin} to ${timeMax}`)

  let calendarEvents: CalendarEvent[] = []

  if (window.gapi.client.calendar?.calendarList) {
    const listResponse = await window.gapi.client.calendar?.calendarList.list({
      showHidden: true,
    })

    if (listResponse) {
      const list = listResponse.result.items

      console.log('CAL LIST: ', list)
      console.log('CAL ACCOUNT: ', account)

      // const enabledCalendars = list.filter(cal => account.calendars.find(c => ((c.id === cal.id) && c.enabled)))
      const enabledCalendars = account.calendars
        .filter(c => {
          if (calendars?.length) {
            const calendar = calendars.find(cal => (cal.calendarId === c.calendarId && cal.account === account.id))

            if (calendar) {
              if (calendar.enabled) {
                return true
              }

              return false
            }
          }

          if (c.enabled) {
            return true
          }
        })
        .map(c => list.find(cal => cal.id === c.calendarId))

      console.log('ENABLED CALS: ', enabledCalendars)

      if (enabledCalendars?.length) {
        for (let i = 0; i < enabledCalendars.length; i++) {
          let backgroundColor = ''
          const foregroundColor = ''
          const calendar = enabledCalendars[i]

          if (calendar) {
            if (calendar?.backgroundColor) {
              // console.log('Found calendarInfo', calendar)
              backgroundColor = calendar.backgroundColor
            }

            const response = await window.gapi.client.calendar.events.list({
              calendarId: calendar.id,
              timeMin,
              timeMax,
              showDeleted: false,
              singleEvents: true,
              maxResults: 50,
              orderBy: 'startTime',
            })

            if (response) {
              const items = response.result.items

              if (items.length) {
                const events = items.map((event) => {
                  const start = event.start.dateTime || event.start.date
                  const end = event.end.dateTime || event.end.date

                  // console.log(`${start} - ${event.summary}`)

                  const calendarEvent: CalendarEvent = {
                    id: event.id,
                    summary: event.summary,
                    startTime: moment(start).toISOString(),
                    endTime: moment(end).toISOString(),
                  }

                  // if (start) calendarEvent.startTime = moment(start).toISOString()

                  // if (end) calendarEvent.endTime = moment(end).toISOString()

                  if (backgroundColor) calendarEvent.backgroundColor = backgroundColor

                  if (foregroundColor) calendarEvent.foregroundColor = foregroundColor

                  return calendarEvent
                })

                calendarEvents = calendarEvents.concat(events)
              }
            }
          }
        }
      }
    }
  }

  return calendarEvents
}

export async function getSubCalendars (args: {
  account: CalendarAccount; writable?: boolean;
}): Promise<SubCalendar[]> {
  const { account, writable } = args
  const accessToken = await getAccessToken(account.accessTokens)

  window.gapi.client.setToken({
    // eslint-disable-next-line
    access_token: accessToken,
  })

  console.log('GET Calendars for userCalendar: ', account)

  const calendarList = await window.gapi.client.calendar?.calendarList.list({
    showHidden: true,
  })

  if (calendarList) {
    // console.log('CALENDAR LIST: ', calendarList.result.items)

    const list = calendarList.result.items
    let selectedCalendars

    if (writable) {
      selectedCalendars = list.filter(cal => cal.accessRole === 'owner')
    } else {
      selectedCalendars = list
      // selectedCalendars = list.filter(cal => cal.selected)
    }

    if (selectedCalendars?.length) {
      return selectedCalendars.map(cal => {
        const { id, summary, backgroundColor, foregroundColor, primary, timeZone, hidden, selected } = cal

        return {
          id,
          name: summary,
          account: account.id,
          primary,
          backgroundColor,
          foregroundColor,
          timeZone,
          hidden,
          selected,
        }
      })
    }
  }

  return []
}

export async function getAccountInfo (calendar: CalendarAccount): Promise<{ photo: string} | undefined> {
  const accessToken = await getAccessToken(calendar.accessTokens)

  window.gapi.client.setToken({
    // eslint-disable-next-line
    access_token: accessToken,
  })

  console.log('GET account info for userCalendar: ', calendar)

  if (window.gapi.client.people?.people) {
    const profile = await window.gapi.client.people.people.get({
      resourceName: 'people/me',
      personFields: 'photos',
    })

    console.log('user profile ', profile.result)

    if (profile.result.photos?.length) {
      const photo = profile.result.photos.find(p => p.metadata.primary)

      if (photo) {
        return {
          photo: photo.url,
        }
      }
    }
  }
}
