import React, { CSSProperties, useEffect } from 'react'
import {
  IonContent, IonLabel, IonItem, IonSpinner, IonText, IonFooter, IonPage,
} from '@ionic/react'

import { AddCalendarInput, CalendarService } from 'services/api'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import AnimatedLightning from 'components/atoms/AnimatedLightning/AnimatedLightning'
import { encryptedAccessToken } from 'services/security/accessToken'
import { Avatar } from '@material-ui/core'
import { encryptedDeviceFingerPrint } from 'services/accounts'
import { EventName, EventData, useAnalytics } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Connect Calendars'

const iconBox: CSSProperties = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}

const iconBox2: CSSProperties = {
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}

const box2: CSSProperties = {
  padding: 20,
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardTitle: CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
}

const avatarStyle: CSSProperties = {
  width: 40,
  height: 40,
  backgroundColor: '#92949C',
}

const messageBox: CSSProperties = {
  marginTop: 10,
  alignItems: 'flex-start',
  textAlign: 'start',
}

const cardMessage: CSSProperties = {
  fontSize: 15,
}

// const messageBox: CSSProperties = {
//   padding: 20,
//   paddingTop: 0,
// }

interface ContainerProps {
  calendarId?: string;
  onDone: () => void;
  onAddCalendar: (authUrl: string) => void;
}

const CalendarSettingsDoneAddAnother: React.FC<ContainerProps> = ({ calendarId, onAddCalendar, onDone }) => {
  const { loading, calendars, addCalendar } = useCalendars()
  const { logEvent } = useAnalytics()

  console.log('ALL Calendars: ', calendars)

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'CalendarSettingsDoneAddAnother' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'CalendarSettingsDoneAddAnother',
      },
    })
  }

  async function addGoogleCalendar (): Promise<void> {
    const addToken = encryptedAccessToken() || ''
    const device = encryptedDeviceFingerPrint()

    const state = {
      token: addToken,
      device,
    }

    const input: AddCalendarInput = {
      service: CalendarService.Google,
      state: JSON.stringify(state),
    }
    const authUrl = await addCalendar(input)

    if (authUrl) {
      console.log('ADD CALENDAR RETURNED: ', authUrl)
      onAddCalendar(authUrl)
    }
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        <FooterButton
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'AddAnotherCalendar' })
            addGoogleCalendar()
          }}>
          Connect Another Google Calendar
        </FooterButton>
        <FooterButton
          fill='clear'
          onClick={() => {
            logTap({ component: 'FooterButton', button: 'Done' })
            onDone()
          }}>
          Done
        </FooterButton>
      </IonFooter>
    )
  }

  function renderCalendarAccount (): JSX.Element | undefined {
    if (calendars?.length) {
      const calendar = calendars.find(cal => cal.id === calendarId)

      if (calendar) {
        const { service, account, photo } = calendar

        return (
          <div
            style={iconBox}
            className='titleIconBox'>
            <Avatar
              src={photo}
              style={avatarStyle} />
            <IonLabel style={{ textAlign: 'center', marginTop: 10 }}>
              <h2>{account}</h2>
              <p>{service}</p>
            </IonLabel>
            <div style={iconBox2}>
              <AnimatedLightning
                loop={2} />
              <IonText color='medium'>
                Automagic Scheduling is all set using this calendar!
              </IonText>
            </div>
          </div>
        )
      }
    }
  }

  function renderLoading (): JSX.Element | undefined {
    if (loading) {
      return (
        <IonItem
          lines='full'
          style={{ textAlign: 'center' }}>
          <IonLabel color='secondary'>
            <IonSpinner
              name='dots' />
          </IonLabel>
        </IonItem>
      )
    }
  }

  function renderMessage (): JSX.Element {
    const text = 'You can connect multiple calendars and use them to better schedule your time in it\'s entirety, as well as schedule on each calendar individually.'

    return (
      <div style={box2}>
        <IonText style={cardTitle}>
          Connect More Calendars
        </IonText>
        <div style={messageBox}>
          <IonText style={cardMessage}>
            {text}
          </IonText>
        </div>
      </div>
    )
  }

  return (
    <IonPage>
      <ScreenHeader title={title} />
      <IonContent>
        {renderCalendarAccount()}
        {renderMessage()}
        {renderLoading()}
      </IonContent>
      {renderFooter()}
    </IonPage>
  )
}

export default CalendarSettingsDoneAddAnother
