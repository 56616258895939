import React, { CSSProperties } from 'react'
import moment from 'moment'
import {
  IonCardSubtitle, IonCardTitle, IonText,
} from '@ionic/react'
import { MeetingInvite } from 'types'
import { dayRangesToString, minutesToString, timeRangesToString } from 'services/time'
import ProfilePhoto from 'components/atoms/ProfilePhoto/ProfilePhoto'
import AnimatedMeeting from 'components/atoms/AnimatedMeeting/AnimatedMeeting'

const photoBox: CSSProperties = {
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const welcomeText: CSSProperties = {
  fontSize: 20,
  textAlign: 'center',
}

const messageBox: CSSProperties = {
  padding: 20,
  textAlign: 'center',
}

const avatarColor = '#92949C'

const avatarCircle: CSSProperties = {
  width: 30,
  height: 30,
  borderRadius: 15,
  border: '2px solid #d7d8da',
  backgroundColor: avatarColor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const expectingCircle: CSSProperties = {
  ...avatarCircle,
  backgroundColor: 'orange',
  marginLeft: -20,
  zIndex: 1,
}
const expectingBadge: CSSProperties = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 13,
}

interface ContainerProps {
  invite: MeetingInvite;
  tip?: string;
  onAccept: () => void
  onDecline: () => void
}

function getInitials (name: string): string {
  return name.split(' ').map((t: string) => t.charAt(0) && t.charAt(0).toUpperCase()).join('')
}

const FirstInviteCard: React.FC<ContainerProps> = ({ invite }) => {
  const { title, duration, dayRanges, timeRanges, name, photo, participants } = invite

  function renderPhoto (): JSX.Element | undefined {
    if (name) {
      const initials = getInitials(name)
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

      const photoStyle = {
        width: 80,
        height: 80,
        border: '3px solid white',
        boxShadow: prefersDark?.matches ? '' : '0px 4px 8px gray',
      }

      return (
        <div
          style={photoBox}
          className='cardIconBox'>
          <ProfilePhoto
            style={photoStyle}
            fontSize={32}
            initials={initials}
            photo={photo} />
          <IonText
            color='dark'
            style={welcomeText}>
            {name}
          </IonText>
        </div>
      )
    }
  }

  function renderWelcomeMessage (): JSX.Element | undefined {
    if (name) {
      const displayName = name.split(' ').find(t => t)

      return (
        <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, textAlign: 'center' }}>
          <IonText
            data-ci-id='welcome-message'
            color='medium'>
            {displayName} is inviting you to
          </IonText>
        </div>
      )
    }
  }

  function renderParticipants (): JSX.Element | undefined {
    if (participants > 1) {
      return (
        <div style={{ marginTop: 10, textAlign: 'center' }}>
          <IonText
            color='medium'
            style={{ fontSize: 14 }}>
            Participating
          </IonText>
          <div style={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
            {Array(...Array(participants - 1)).map((v, i) => {
              const style = {
                ...avatarCircle,
                marginLeft: i ? -20 : 0,
              }

              // show max of 10
              if (i < 10) {
                return (
                  <div
                    key={i}
                    style={style} />
                )
              }
            })}
            <div
              style={expectingCircle}>
              <IonText style={expectingBadge}>
                {participants}
              </IonText>
            </div>
          </div>
        </div>
      )
    }
  }

  function renderTimeRestrictions (): JSX.Element | undefined {
    if (timeRanges?.length) {
      return (
        <div>
          <div style={{ marginTop: 10 }}>
            <IonText
              color='medium'
              style={{ fontSize: 14 }}>
              Sometime Between
            </IonText>
          </div>
          <IonCardSubtitle
            color='dark'
            mode='ios'>{timeRangesToString(timeRanges)}
          </IonCardSubtitle>
        </div>
      )
    }
  }
  function renderMeetingDetails (): JSX.Element | undefined {
    if (name) {
      // if it's a single range
      let dayRangesTitle = 'Between The Days Of'

      if (dayRanges?.length === 1) {
        const range = dayRanges[0]

        // check if it's a single day
        if (moment(range.endTime).diff(moment(range.startTime), 'days') === 1) {
          dayRangesTitle = 'On'
        }
      }

      return (
        <div style={messageBox}>
          <IonCardTitle mode='ios'>
            {title}
          </IonCardTitle>
          <IonCardSubtitle
            mode='ios'>For {minutesToString(duration)}
          </IonCardSubtitle>
          <div style={{ marginTop: 10 }}>
            <IonText
              color='medium'
              style={{ fontSize: 14 }}>
              {dayRangesTitle}
            </IonText>
          </div>
          <IonCardSubtitle
            color='dark'
            mode='ios'>{dayRangesToString(dayRanges)}
          </IonCardSubtitle>
          {renderTimeRestrictions()}
          {renderParticipants()}
        </div>
      )
    }
  }

  function renderMoicaMessage (): JSX.Element | undefined {
    return (
      <div style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AnimatedMeeting
            loop
            style={{ width: 60, height: 60 }} />
        </div>
        <div style={{ marginTop: 10, paddingLeft: 40, paddingRight: 40 }}>
          <IonText color='medium'>
            Moica can automagically find a time that works for everyone
          </IonText>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderPhoto()}
      {renderWelcomeMessage()}
      {renderMeetingDetails()}
      {renderMoicaMessage()}
      {/* <IonCardContent>
        <IonRow>
          <IonCol>
            <IonButton
              expand='block'
              fill='solid'
              onClick={onAccept}>
              Accept
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              expand='block'
              fill='outline'
              color='danger'
              onClick={onDecline}>
              Decline
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent> */}
    </div>
  )
}

export default FirstInviteCard
