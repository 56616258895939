import React, { CSSProperties, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonModal, IonText,
} from '@ionic/react'
import { flashOutline, flashOffOutline, flash, warning, ban, peopleOutline } from 'ionicons/icons'
import { useSpring } from 'react-spring'

import { CalendarEvent, Meeting, Participant, TimeRange } from 'types'
import ActionCard from '../ActionCard/ActionCard'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import ListItem from 'components/atoms/ListItem/ListItem'
import { logoColor } from 'theme/styles'
import AnimatedLightning from 'components/atoms/AnimatedLightning/AnimatedLightning'
import moment from 'moment'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: CalendarEvent[];
}

const iconBox: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const messageBox: CSSProperties = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
}

const titleStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
}

const messageStyle: CSSProperties = {
  fontSize: 14,
}

export type SelectedOptions = {
  adjustedAutoTimes?: boolean
  selectedAutoTimes?: boolean
  calendarSettings?: boolean;
  disableAutoBook?: boolean;
  busyTimes?: boolean;
  addParticipants?: boolean;
}

interface ContainerProps {
  meeting: Meeting;
  adjustedAutoTimes?: TimeRange[];
  selectedAutoTimes?: TimeRange[];
  addParticipants?: boolean;
  inviteParticipants?: boolean;
  onSelectedOptions: (selectedOptions?: SelectedOptions) => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const ScheduleActionsModal: React.FC<ContainerProps> = ({
  meeting, adjustedAutoTimes,
  selectedAutoTimes, addParticipants, inviteParticipants, onSelectedOptions, onClose,
}) => {
  const [showCard, setShowCard] = useState(adjustedAutoTimes ? 'selectAutoTimes' : 'selectBusyTimes')
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>()

  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        component: 'ScheduleActionsModal',
        meeting: meeting?.id,
      },
    })
  }

  function getTimeLabel (ranges: TimeRange[]): string {
    let timeLabel = ''

    ranges.forEach((range, i) => {
      const start = moment(range.startTime).format('h:mm a')
      const end = moment(range.endTime).format('h:mm a')

      timeLabel += `${start} - ${end}`

      if (i < ranges.length - 1) {
        timeLabel += ', '
      }
    })

    return timeLabel
  }

  function renderAdjustedTimes (): JSX.Element | undefined {
    if (adjustedAutoTimes) {
      const timeLabel = getTimeLabel(adjustedAutoTimes)

      return (
        <ListItem
          label='Schedule between'
          subLabel={timeLabel}
          lines='inset'
          detail
          icon={{ name: flash, slot: 'start', style: { color: logoColor } }}
          onClick={() => {
            logTap({ button: 'ScheduleBetween' })
            setSelectedOptions({ adjustedAutoTimes: true })
            setShowCard('selectBusyTimes')
          }} />
      )
    }
  }

  function renderSelectedTimes (): JSX.Element | undefined {
    if (selectedAutoTimes) {
      const timeLabel = getTimeLabel(selectedAutoTimes)

      return (
        <ListItem
          label='Continue with selected times'
          subLabel={timeLabel}
          lines='inset'
          detail
          icon={{ name: flashOutline, slot: 'start', style: { color: logoColor } }}
          onClick={() => {
            logTap({ button: 'ContinuteWithSelected' })
            setSelectedOptions({ selectedAutoTimes: true })
            setShowCard('selectBusyTimes')
          }} />
      )
    }
  }

  function renderDisableAuto (): JSX.Element | undefined {
    return (
      <ListItem
        label='Disable for this meeting'
        subLabel='Select your availability manually'
        lines='none'
        detail
        icon={{ name: flashOffOutline, slot: 'start', color: 'medium' }}
        onClick={() => {
          logTap({ button: 'DisableAuto' })
          setShowCard('')
          onSelectedOptions({ disableAutoBook: true })
        }} />
    )
  }

  function renderBusyTimes (): JSX.Element | undefined {
    return (
      <ListItem
        label='Mark Busy Times'
        lines={addParticipants ? 'inset' : 'full'}
        detail
        icon={{ name: ban, slot: 'start', color: 'primary' }}
        onClick={() => {
          logTap({ button: 'MarkBusy' })
          onSelectedOptions({ ...selectedOptions, busyTimes: true })
          setShowCard('')
        }} />
    )
  }

  function renderAddParticipants (): JSX.Element | undefined {
    if (addParticipants) {
      return (
        <ListItem
          label='Add Participants'
          lines='full'
          detail
          icon={{ name: peopleOutline, slot: 'start', color: 'primary' }}
          onClick={() => {
            logTap({ button: 'AddParticipants' })
            onSelectedOptions({ ...selectedOptions, addParticipants: true })
            setShowCard('')
          }} />
      )
    }
  }

  function renderSchedule (): JSX.Element | undefined {
    if (!inviteParticipants) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'Schedule' })
              onSelectedOptions(selectedOptions)
              setShowCard('')
            }}>
            Schedule
          </FooterButton>
        </IonFooter>
      )
    }
  }

  function renderInviteParticipants (): JSX.Element | undefined {
    if (inviteParticipants) {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton
            onClick={() => {
              logTap({ button: 'InviteParticipants' })
              onSelectedOptions({ ...selectedOptions, addParticipants: true })
              setShowCard('')
            }}>
            Invite Participants
          </FooterButton>
        </IonFooter>
      )
    }
  }

  function renderHeader (message: string): JSX.Element | undefined {
    return (
      <div
        className='ion-text-start'>
        <div style={iconBox}>
          <AnimatedLightning
            style={{ width: 60, height: 60 }}
            loop={2} />
          <IonText
            style={titleStyle}>
            Automagic Scheduling is On!
          </IonText>
        </div>
        <div
          style={messageBox}>
          <IonText
            color='medium'
            style={messageStyle}>
            {message}
          </IonText>
        </div>
      </div>
    )
  }

  const autoTimesProps = useSpring({
    ...(showCard === 'selectAutoTimes' ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderAutoTimeOptions (): JSX.Element | undefined {
    const message = 'But your default schedule times don\'t cover the entire meeting time range. \n Select an option below'

    return (
      <ActionCard
        title={meeting.title}
        icon={warning}
        onClose={() => {
          logTap({ button: 'CloseAutoTimeOptions' })
          onClose && onClose()
        }}
        animatedProps={autoTimesProps}>
        {renderHeader(message)}
        {renderAdjustedTimes()}
        {renderSelectedTimes()}
        {renderDisableAuto()}
      </ActionCard>
    )
  }

  const busyTimesProps = useSpring({
    ...(showCard === 'selectBusyTimes' ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderBusyTimesOptions (): JSX.Element | undefined {
    const message = 'You can mark certain times busy before Moica will automagically schedule you'

    return (
      <ActionCard
        title={meeting.title}
        onClose={() => {
          logTap({ button: 'CloseRenderBusyTimes' })
          onClose && onClose()
        }}
        animatedProps={busyTimesProps}>
        {renderHeader(message)}
        {renderBusyTimes()}
        {renderAddParticipants()}
        {renderSchedule()}
        {renderInviteParticipants()}
      </ActionCard>
    )
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={() => {
        logTap({ button: 'GestureDismiss' })
        onClose && onClose()
      }}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderAutoTimeOptions()}
        {renderBusyTimesOptions()}
      </IonContent>
    </IonModal>
  )
}

export default ScheduleActionsModal
