import React, { useState, useEffect, useRef } from 'react'
import {
  IonHeader, IonToolbar, IonTitle,
  IonContent,
  IonButtons,
  IonSpinner,
  IonLabel,
  IonActionSheet,
  IonPage,
  IonListHeader,
  IonFooter,
  IonChip,
  IonIcon,
  IonText,
  IonItem,
  IonToggle,
  isPlatform,
} from '@ionic/react'

import 'theme/styles.css'

import { Meeting, TimeRange } from 'types'
import { Spring } from 'react-spring/renderprops'
import moment from 'moment'
import { useKeyboardState } from '@ionic/react-hooks/keyboard'

import { CreateInviteeInput, CreateMeetingInput, UpdateMeetingInput, UpdateParticipantInput } from 'services/api'
import MeetingDuration from '../../components/organisms/SelectMeetingDuration/SelectMeetingDuration'
import MeetingCalendar from '../../components/molecules/MeetingBookCalendar/MeetingBookCalendar'
import SelectMeetingCalendar from '../../components/organisms/SelectMeetingBookCalendar/SelectMeetingCalendar'
import MeetingFreeBusyCalendars from '../../components/molecules/MeetingFreeBusyCalendars/MeetingFreeBusyCalendars'
import SelectFreeBusyCalendars from '../../components/organisms/SelectFreeBusyCalendars/SelectFreeBusyCalendars'
import MeetingTimes from '../../components/molecules/MeetingTimes/MeetingTimes'
import SelectMeetingTimes from '../../components/organisms/SelectMeetingTimes/SelectMeetingTimes'
import AutoBookSettingsModal from 'components/organisms/SelectMeetingAutobookSchedule/AutoBookSettingsModal'
import SelectMeetingDays from 'components/organisms/SelectMeetingDays/SelectMeetingDays'
import MeetingDays from 'components/molecules/MeetingDays/MeetingDays'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import ListItemInput from 'components/atoms/ListItemInput/ListItemInput'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useCalendars, MeetingBookCalendar } from 'context/CalendarsContext/CalendarsContext'
import { usePreferences } from 'context/PreferencesContext/PreferencesContext'
import MeetingConference from 'components/molecules/MeetingConference/MeetingConference'
import EditMeetingConference from 'components/molecules/EditMeetingConference/EditMeetingConference'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import ScreenBackButton from 'components/atoms/ScreenBackButton/ScreenBackButton'
import { useTabs } from 'navigation/TabsContext'
import { MeetingSettingsTips } from 'types/componentTips'
import { ScreenTips } from './ScreenTips'
import { useTips } from 'context/TipsContext/TipsContext'
import { useScreens } from 'context/ScreensContext/ScreensContext'
import { MeetingSettings as StoreTips } from 'services/store/tips/types'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useStoreParticipants } from 'context/StoreParticipantsContext/StoreParticipantsContext'
import { SelectedOptions } from 'components/organisms/InviteeActionsModal/InviteeActionsModal'
import ScheduleActionsModal from 'components/organisms/ScheduleActionsModal/ScheduleActionsModal'
import { adjustTimeRangesForDay } from 'components/organisms/ScheduleCalendar/utils'
import { closeCircle, flash, flashOffOutline, warning } from 'ionicons/icons'
import { logoColor } from 'theme/styles'
import MeetingDescriptionModal from 'components/organisms/MeetingDescriptionModal/MeetingDescriptionModal'
import { useAnalytics, EventName } from 'context/AnalyticsContext/AnalyticsContext'
import { useUser } from 'context/UserContext/UserContext'
import { minutesToString, stringToMinutes } from 'services/time'

const container: CSSProperties = {
  flex: 1,
  minHeight: '100%',
  paddingBottom: 40,
}

const titleStyle = {
  fontSize: 12,
  marginLeft: isPlatform('ios') ? 20 : 16,
}

const messageBox: CSSProperties = {
  marginTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
  display: 'flex',
  alignItems: 'center',
}

const messageStyle = {
  fontSize: 14,
}

const warningChipStyle: CSSProperties = {
  marginTop: 8,
  marginLeft: 10,
  // border: `1px solid ${blue}`,
}

function defaultDayRanges (): TimeRange[] {
  const startTime = moment().startOf('day').toISOString()
  const endTime = moment(startTime).add(7, 'days').toISOString()

  // console.log('set default day ranges ', startTime, endTime)

  return [{
    startTime,
    endTime,
  }]
}

function defaultTimeLimitation (): TimeRange[] {
  return [
    {
      startTime: moment().startOf('day').add(9, 'hours').toISOString(),
      endTime: moment().startOf('day').add(17, 'hours').toISOString(),
    },
  ]
}

export enum Steps {
  'title' = 1,
  'duration',
  'dateRange',
  'timeRange',
  'description',
  'availability',
}

interface ContainerProps {
  meetingId?: string;
  tips?: boolean;
  step?: number;
  addParticipants?: string[];
  meetMeInitiator?: boolean;
  meetMeInvitee?: boolean;
  newUser?: boolean;
  goBack: () => void;
  onChooseAvailability: (id: string, startEdit?: boolean) => void;
  onAskConnectCalendar: (id: string) => void;
  onAddParticipants: (id: string) => void;
  onMeetingCalendarSettings: (id: string) => void;
}

const appUrl = process.env.REACT_APP_MEETINGS_APP + '/invitations/'

const MeetingSettings: React.FC<ContainerProps> = (
  {
    meetingId, step = 0, addParticipants, meetMeInitiator, meetMeInvitee, newUser,
    goBack, onAskConnectCalendar, onChooseAvailability, onAddParticipants, onMeetingCalendarSettings,
  }) => {
  const [meeting, setMeeting] = useState<Meeting>()
  const [bookCalendar, setBookCalendar] = useState<MeetingBookCalendar>()
  const [edited, setEdited] = useState(false)
  const [showStep, setShowStep] = useState(step)
  const [selectDays, setSelectDays] = useState(false)
  const [selectTimes, setSelectTimes] = useState(false)
  const [showActivity, setShowActivity] = useState('')
  const [showDeleteAction, setShowDeleteActions] = useState('')
  const [selectViewCalendars, setSelectViewCalendars] = useState(false)
  const [selectMeetingCalendar, setSelectMeetingCalendar] = useState(false)
  const [selectAutoTimeRanges, setSelectAutoTimeRanges] = useState(false)
  const [editConference, setEditConference] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [adjustedAutoTimes, setAudjustedAutoTimes] = useState<TimeRange[]>()
  const [showScheduleActionsModal, setShowScheduleActionsModal] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState<MeetingSettingsTips>(MeetingSettingsTips.noTip)
  const [showTips, setShowTips] = useState(!!step)
  const [autoEnabled, setAutoEnabled] = useState(false)

  const { isOpen } = useKeyboardState()

  const pageRef = useRef()

  const {
    meeting: originalMeeting,
    createMeeting,
    updateMeeting,
    deleteMeeting,
    exitMeeting,
  } = useMeetings()

  const { user } = useUser()
  const { participant, updateParticipant } = useParticipants()
  const { createInvitee } = useInvitees()
  const { calendars, getMeetingCalendar } = useCalendars()
  const { preferences } = usePreferences()
  const { showTabs, setShowTabs } = useTabs()
  const { storeTips, updateStoreTips } = useTips()
  const { screens } = useScreens()
  const { storeParticipants, updateStoreParticipants } = useStoreParticipants()
  const { logEvent } = useAnalytics()

  const componentStoreTips = storeTips?.meetingSettings
  const steps = !meetingId && (!componentStoreTips?.steps)

  useEffect(() => {
    console.log('meetingId: ', meetingId)

    if (!meetingId) {
      console.log('SET MEETING ')
      setMeeting({
        id: '',
        title: 'New Meeting',
        duration: '60',
        // timeRanges: defaultTimeLimitation(),
      })
    }

    setTimeout(() => {
      if (!showStep) {
        setShowStep(Steps.title)
        setShowScreenTip(MeetingSettingsTips.title)

        // if (steps) {
        //   setShowTips(true)
        // }
      }
    }, 1000)

    // disable tab bar to get max calendar view
    setShowTabs && setShowTabs(false)

    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'MeetingSettings', meeting: meeting?.id },
    })
  }, [])

  useEffect(() => {
    console.log('MEETING DATA: ', originalMeeting)
    console.log('Meeting:', meetingId, meeting)

    if (meetingId && !meeting && originalMeeting) {
      // console.log('SETTING MEETING: ', originalMeeting)

      const copyMeeting = Object.assign({}, originalMeeting)
      const { dayRanges, timeRanges } = copyMeeting

      copyMeeting.dayRanges = Array.isArray(dayRanges) ? dayRanges.map(r => {
        return {
          startTime: r.startTime,
          endTime: r.endTime,
        }
      }) : []

      copyMeeting.timeRanges = Array.isArray(timeRanges) ? timeRanges.map(r => {
        return {
          startTime: r.startTime,
          endTime: r.endTime,
        }
      }) : []

      console.log('SETTING MEETING: copyMeeting ', copyMeeting)
      setMeeting(copyMeeting)
    }
  }, [originalMeeting])

  async function getBookCalendar (): Promise<void> {
    if (calendars && participant && getMeetingCalendar) {
      const calendar = await getMeetingCalendar(participant)

      if (calendar) {
        setBookCalendar(calendar)

        if (calendar.autoBook) {
          setAutoEnabled(true)
        }

        meeting && checkAutoTimes(meeting, calendar)
      }
    }
  }
  useEffect(() => {
    getBookCalendar()

    if (participant?.readyToSchedule) {
      if (!participant.readyToSchedule.ready && storeParticipants && updateStoreParticipants) {
        if (!storeParticipants.notReadyToSchedule?.find(id => participant.id === id)) {
          updateStoreParticipants({
            notReadyToSchedule: storeParticipants.notReadyToSchedule
              ? [...storeParticipants.notReadyToSchedule, participant.id] : [participant.id],
          })
        }
      }
    }
  }, [calendars, participant])

  function logTap (button: string, component?: string): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        screen: 'MeetingSettings',
        button,
        component,
        user: user?.id,
        meeting: meeting?.id || 'new meeting',
      },
    })
  }

  function checkAutoTimes (meeting: Meeting, calendar: MeetingBookCalendar): void {
    if (calendar?.autoTimes && meeting?.timeRanges) {
      const { autoTimes } = calendar
      const { timeRanges } = meeting

      const dayStartTime = moment().startOf('day').toISOString()
      const autoRanges = adjustTimeRangesForDay(dayStartTime, autoTimes)
      const meetingRanges = adjustTimeRangesForDay(dayStartTime, timeRanges)
      // check if any of meetingRanges are outside the autoRanges
      const outsideAutoRange = meetingRanges.find(meetingRange => {
        const startRange = moment(meetingRange.startTime).valueOf()
        const endRange = moment(meetingRange.endTime).valueOf()

        return autoRanges.find(autoRange => {
          const startAuto = moment(autoRange.startTime).valueOf()
          const endAuto = moment(autoRange.endTime).valueOf()

          if (startRange >= startAuto && endRange <= endAuto) {
            return false
          }

          return true
        })
      })

      if (outsideAutoRange) {
        const updateAutoTimes = [
          {
            startTime: timeRanges[0].startTime,
            endTime: timeRanges[timeRanges.length - 1].endTime,
          },
        ]

        setAudjustedAutoTimes(updateAutoTimes)
        console.log('ADJUSTED AUTO TIMES: ', updateAutoTimes)
      } else {
        setAudjustedAutoTimes(undefined)
      }
    }
  }

  async function addNewParticipants (id: string): Promise<void> {
    if (id && addParticipants?.length) {
      const input: CreateInviteeInput = {
        meeting: id,
        inviteUsers: addParticipants,
        appUrl,
        meetMe: meetMeInitiator,
      }

      await createInvitee(input)
    }
  }

  // note pass the meeting as a param as we can't always assume the state will updated instantaneouly
  async function saveMeeting (updatedMeeting?: Meeting, selectedOptions?: SelectedOptions): Promise<void> {
    console.log('SAVE MEETING meeting, ', updatedMeeting)

    if (updatedMeeting) {
      setShowActivity('update')

      if (!meetingId) {
        const { title, duration, description, dayRanges, timeRanges, videoConference } = updatedMeeting

        const input: CreateMeetingInput = {
          title,
          duration,
          dayRanges: dayRanges?.length ? dayRanges : defaultDayRanges(),
          timeRanges,
          participantsExpected: 1,
          tentativeThreshold: 1,
        }

        if (description) {
          input.description = description
        }

        if (videoConference) {
          input.videoConference = {
            link: videoConference.link,
          }
        }

        console.log('createMeeting input: ', input)

        const created = await createMeeting(input)

        if (created?.id) {
          // check if we need to immediately add participants
          await addNewParticipants(created.id)

          setMeeting(undefined)

          if (!showTabs) { setShowTabs && setShowTabs(true) }

          if (!calendars?.length) {
            if (screens?.meetingSettings?.connectCalendar) {
              if (!storeTips?.tipsConnectCalendars?.skipped ||
                storeTips?.tipsConnectCalendars?.skipped < 2) {
                onAskConnectCalendar(created?.id)

                return
              }
            }

            onChooseAvailability(created?.id)

            // if (!storeTips?.tipsConnectCalendars?.skipped ||
            //   storeTips?.tipsConnectCalendars?.skipped < 2) {
            //   onAskConnectCalendar(created?.id)
            // } else {
            //   onChooseAvailability(created?.id)
            // }
          } else if (calendars?.length && onMeetingCalendarSettings) {
            onMeetingCalendarSettings(created?.id)
          } else if (onAddParticipants) {
            onAddParticipants(created?.id)
          } else if (goBack) {
            goBack()
          }
        }
      } else if (updatedMeeting?.id) {
        console.log('Update meeting: meeting', updatedMeeting)

        const { id, title, duration, description, dayRanges, timeRanges, videoConference } = updatedMeeting

        const update: UpdateMeetingInput = {
          id,
          title,
          duration,
          dayRanges,
          timeRanges,
        }

        if (description) {
          update.description = description
        }

        if (videoConference) {
          update.videoConference = {
            link: videoConference.link,
          }
        }

        const updated = await updateMeeting(update)

        console.log('Update meeting response: ', updated)

        if (participant && bookCalendar && updateParticipant) {
          const { calendar, autoTimes, autoDays, autoBook } = bookCalendar

          // remove __typename from autoTimes
          const input: UpdateParticipantInput = {
            id: participant.id,
            bookCalendar: {
              account: calendar.account,
              calendarId: calendar.id,
              timeRanges: Array.isArray(autoTimes) ? autoTimes.map(r => {
                return {
                  startTime: r.startTime,
                  endTime: r.endTime,
                }
              }) : [],
              autoDays,
            },
            autoBookEnabled: autoBook,
          }
          const updated = await updateParticipant(input)

          console.log('Update participant response: ', updated)
        }

        setMeeting(undefined)

        if (selectedOptions?.busyTimes) {
          onChooseAvailability(updatedMeeting.id, true)
        } else if (selectedOptions?.addParticipants) {
          onAddParticipants(updatedMeeting.id)
        } else if (goBack) {
          goBack()
        }
      }

      setShowActivity('')
    }
  }

  function updateTip (tip: StoreTips): void {
    if (updateStoreTips) {
      updateStoreTips({
        meetingSettings: {
          ...componentStoreTips, ...tip,
        },
      })
    }
  }

  async function onDeleteMeeting (): Promise<void> {
    if (meetingId) {
      if (showDeleteAction === 'delete') {
        console.log('Deleting meeting => ', meeting)
        setShowActivity('delete')
        await deleteMeeting(meetingId)
        goBack()
      } else if (showDeleteAction === 'exit') {
        console.log('Exit meeting => ', meeting)
        setShowActivity('delete')
        await exitMeeting(meetingId)
        goBack()
      } else {
        goBack()
      }
    }
  }

  // console.log('AUTO TIME RANGES: ', autoTimeRanges)

  function renderFooter (): JSX.Element | undefined {
    if (showActivity === 'update') {
      return (
        <IonFooter className='screenFooterButton'>
          <FooterButton>
            <IonSpinner name='dots' />
          </FooterButton>
        </IonFooter>
      )
    }

    if (!meetMeInvitee && !steps && edited && !isOpen) {
      const disabled = !validMeetingTimeRanges()

      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                {meetingId &&
                  <FooterButton
                    disabled={disabled}
                    onClick={() => {
                      logTap('UpdateMeeting', 'FooterButton')
                      saveMeeting(meeting)
                    }}>
                    Update Meeting
                  </FooterButton>}
                {!meetingId &&
                  <FooterButton
                    onClick={() => {
                      logTap('CreateMeeting', 'FooterButton')
                      saveMeeting(meeting)
                    }}>
                    Create Meeting
                  </FooterButton>}
                {edited &&
                  <FooterButton
                    fill='clear'
                    onClick={() => {
                      setMeeting(undefined)
                      logTap('Cancel', 'FooterButton')
                      goBack && goBack()
                    }}>
                    Cancel
                  </FooterButton>}
              </IonFooter>
            </div>}
        </Spring>
      )
    } else if (meetMeInvitee && !isOpen) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={{ ...props }}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  onClick={() => {
                    if (bookCalendar?.autoBook) {
                      setShowScheduleActionsModal(true)
                    } else {
                      saveMeeting(meeting)
                    }
                  }}>
                  Schedule
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }
  }

  function showNextStep (): void {
    setShowStep(showStep + 1)
    setShowScreenTip(showScreenTip + 1)
    // setShowTips(true)
  }

  function renderNext (): JSX.Element | undefined {
    if (!showActivity && !isOpen && (steps && edited && showStep)) {
      return (
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <IonFooter className='screenFooterButton'>
                <FooterButton
                  testId='next-setting-button'
                  onClick={() => {
                    if (showStep === Steps.description) {
                      console.log('NEXT: open ask connect calendars')
                      saveMeeting(meeting)
                      updateTip({ steps: true })
                    } else {
                      showNextStep()
                    }

                    logTap('Next', 'FooterButton')
                  }}>
                  Next
                </FooterButton>
              </IonFooter>
            </div>}
        </Spring>
      )
    }
  }

  function renderHeader (): JSX.Element {
    const title = meetingId ? 'Edit Meeting' : 'New Meeting'

    return (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            {!edited && !newUser &&
              <ScreenBackButton
                onClick={() => goBack && goBack()} />}
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
    )
  }

  function renderModals (): JSX.Element | undefined {
    if (meeting) {
      if (selectDays) {
        return (
          <SelectMeetingDays
            days={meeting?.dayRanges?.length ? meeting.dayRanges : []}
            doneButton={steps ? 'Next' : undefined}
            presentingElement={pageRef.current}
            onClose={() => {
              setSelectDays(false)
            }}
            onChangeDayRanges={(dayRanges) => {
              console.log('DAY RANGES: ', dayRanges)
              setMeeting({
                ...meeting,
                dayRanges,
              })
              setEdited(true)

              if (showStep === Steps.dateRange) {
                setShowStep(Steps.timeRange)
                // setShowTips(false)
                setShowScreenTip(MeetingSettingsTips.timeRange)
              }
            }} />
        )
      }

      if (selectTimes) {
        return (
          <SelectMeetingTimes
            timeRanges={meeting.timeRanges}
            duration={meeting.duration}
            tip
            doneButton={steps ? 'Next' : undefined}
            presentingElement={pageRef.current}
            onClose={() => {
              setSelectTimes(false)
            }}
            onUpdated={(timeRanges) => {
              console.log('Updated meeting time ranges: ', timeRanges)

              const updateMeeting = {
                ...meeting,
                timeRanges,
              }

              setMeeting(updateMeeting)
              setEdited(true)
              bookCalendar && checkAutoTimes(updateMeeting, bookCalendar)

              if (showStep === Steps.timeRange) {
                // the state won't update in time so we need to pass the meeting as a param
                saveMeeting(updateMeeting)
                updateTip({ steps: true })
              }
            }} />
        )
      }

      // if (askConnectCalendar) {
      //   return (
      //     <AskConnectCalendarCard
      //       presentingElement={pageRef.current}
      //       onClose={() => {
      //         setAskConnectCalendar(false)
      //       }}
      //       onConnectCalendar={() => {
      //         console.log('connect calendar')
      //       }}
      //       onChooseAvailability={() => {
      //         console.log('choose availability')
      //       }} />
      //   )
      // }

      // if (askConnectCalendar) {
      //   return (
      //     <AskConnectCalendarModal
      //       meeting={meeting}
      //       presentingElement={pageRef.current}
      //       onClose={() => {
      //         setAskConnectCalendar(false)
      //       }}
      //       onConnectCalendar={() => {
      //         setAskConnectCalendar(false)
      //         saveMeeting(true)
      //       }}
      //       onChooseAvailability={() => {
      //         setAskConnectCalendar(false)
      //         saveMeeting()
      //       }} />
      //   )
      // }

      if (selectMeetingCalendar && calendars && bookCalendar?.calendar) {
        return (
          <SelectMeetingCalendar
            calendars={calendars}
            selectedCalendar={bookCalendar.calendar}
            presentingElement={pageRef.current}
            onClose={() => setSelectMeetingCalendar(false)}
            onSelect={(selected) => {
              const updateBookCalendar = {
                ...bookCalendar,
                acalendar: selected,
              }

              setBookCalendar(updateBookCalendar)
              checkAutoTimes(meeting, updateBookCalendar)
              setSelectMeetingCalendar(false)
              setEdited(true)
            }} />
        )
      }

      if (selectViewCalendars && calendars?.length && participant) {
        return (
          <SelectFreeBusyCalendars
            participant={participant}
            accounts={calendars}
            presentingElement={pageRef.current}
            onClose={() => setSelectViewCalendars(false)}
            onUpdated={() => {
              setSelectViewCalendars(false)
              // refetchParticipant && refetchParticipant({ meeting: meeting.id })
              setEdited(true)
            }} />
        )
      }

      if (selectAutoTimeRanges && bookCalendar) {
        const { autoBook, autoTimes, autoDays } = bookCalendar

        return (
          <AutoBookSettingsModal
            enabled={autoBook || false}
            timeRanges={autoTimes}
            days={autoDays}
            timeZone={preferences?.timeZone}
            presentingElement={pageRef.current}
            onClose={() => setSelectAutoTimeRanges(false)}
            onUpdated={(ranges, days, enabled) => {
              const updateBookCalendar = {
                ...bookCalendar,
                autoDays: days,
                autoTimes: ranges,
                autoBook: enabled,
              }

              setBookCalendar(updateBookCalendar)
              checkAutoTimes(meeting, updateBookCalendar)
              setSelectAutoTimeRanges(false)
              setEdited(true)
            }} />
        )
      }

      if (showScheduleActionsModal && bookCalendar?.autoTimes) {
        const selectedAutoTimes = bookCalendar?.autoTimes

        return (
          <ScheduleActionsModal
            meeting={meeting}
            selectedAutoTimes={selectedAutoTimes}
            adjustedAutoTimes={adjustedAutoTimes}
            addParticipants={participant?.meetMe}
            presentingElement={pageRef.current}
            onSelectedOptions={(selectedOptions?: SelectedOptions) => {
              setShowScheduleActionsModal(false)
              saveMeeting(meeting, selectedOptions)
            }}
            onClose={() => {
              setShowScheduleActionsModal(false)
            }} />
        )
      }

      if (editDescription) {
        return (
          <MeetingDescriptionModal
            meetingDescription={meeting.description}
            onClose={() => setEditDescription(false)}
            onUpdated={(description) => {
              setMeeting({
                ...meeting,
                description,
              })
              setEditDescription(false)
              setEdited(true)
            }}
            presentingElement={pageRef.current} />
        )
      }

      if (editConference) {
        return (
          <EditMeetingConference
            videoConference={meeting.videoConference}
            onClose={() => setEditConference(false)}
            onDone={(video) => {
              setMeeting({
                ...meeting,
                videoConference: video,
              })
              setEditConference(false)
              setEdited(true)
            }}
            presentingElement={pageRef.current} />
        )
      }
    }
  }

  function saveTitle (value: string): void {
    if (meeting && value) {
      setEdited(true)
      setMeeting({
        ...meeting,
        title: value,
      })
    }
  }
  function renderTitle (): JSX.Element | undefined {
    if (meeting) {
      return (
        <ListItemInput
          testId='meeting-name'
          title='meeting title'
          placeholder='Tap here to enter title'
          value={meetingId ? meeting.title : ''}
          tip={steps && showTips && showStep === Steps.title}
          onFocus={() => {
            setShowTips(false)
          }}
          onChange={() => {
            setEdited(true)
            updateTip({ title: true })
          }}
          onBlur={(value) => {
            saveTitle(value)
          }}
          onDone={(value) => {
            saveTitle(value)
            showNextStep()
            logTap('Done', 'MeetingTitle')
          }} />
      )
    }
  }

  function renderDuration (): JSX.Element | undefined {
    if (meeting && (!steps || (showStep >= Steps.duration))) {
      return (
        <Spring
          from={(steps ? { opacity: 0 } : { opacity: 1 })}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <MeetingDuration
                duration={meeting.duration}
                tip={steps && showTips && showStep === Steps.duration}
                onOpen={() => {
                  updateTip({ duration: true })
                  logTap('Open', 'MeetingDuration')
                }}
                onCancel={() => {
                  logTap('Cancel', 'MeetingDuration')
                }}
                onChange={value => {
                  setMeeting({
                    ...meeting,
                    duration: value,
                  })
                  setEdited(true)

                  if (showStep === Steps.duration) {
                    setShowStep(Steps.dateRange)
                    setShowScreenTip(MeetingSettingsTips.dateRange)
                    // setShowTips(true)
                  }

                  logTap('Done', 'MeetingDuration')
                }} />
            </div>}
        </Spring>
      )
    }
  }

  function renderDays (): JSX.Element | undefined {
    if (meeting && (!steps || (showStep >= Steps.dateRange))) {
      return (
        <Spring
          from={(steps ? { opacity: 0 } : { opacity: 1 })}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <MeetingDays
                title='find a time on days'
                tip={steps && showTips && showStep === Steps.dateRange}
                dayRanges={meeting?.dayRanges?.length ? meeting.dayRanges : defaultDayRanges()}
                onClick={() => {
                  setSelectDays(true)
                  updateTip({ dateRange: true })
                  logTap('Open', 'MeetingDays')
                }} />
            </div>}
        </Spring>
      )
    }
  }

  function renderTimes (): JSX.Element | undefined {
    if (meeting && (!steps || (showStep >= Steps.timeRange)) &&
    meeting.timeRanges?.length) {
      return (
        <Spring
          from={(steps ? { opacity: 0 } : { opacity: 1 })}
          to={{ opacity: 1 }}>
          {props =>
            <div style={props}>
              <MeetingTimes
                title='find a time between'
                tip={steps && showTips && showStep === Steps.timeRange}
                timeRanges={meeting.timeRanges || defaultTimeLimitation()}
                onClick={() => {
                  setSelectTimes(true)
                  updateTip({ timeRange: true })
                  logTap('Open', 'MeetingTimes')
                }} />
            </div>}
        </Spring>
      )
    }
  }

  function validMeetingTimeRanges (): boolean {
    if (meeting?.duration && meeting.timeRanges?.length) {
      const meetingDuration = Number(stringToMinutes(meeting?.duration))

      if (meeting.timeRanges.find(timeSlot =>
        moment(timeSlot.endTime).diff(moment(timeSlot.startTime), 'minutes') < meetingDuration)) {
        return false
      }
    }

    return true
  }

  function renderMeetingTimesError (): JSX.Element | undefined {
    if (meeting && !validMeetingTimeRanges()) {
      const message = `The time ranges should be greater than meeting duration ${minutesToString(meeting.duration)}`

      return (
        <div style={messageBox}>
          <IonIcon
            icon={closeCircle}
            style={{ fontSize: 20, marginRight: 5 }}
            color='danger' />
          <IonText
            color='danger'
            style={messageStyle}>
            {message}
          </IonText>
        </div>
      )
    }
  }

  function renderDescription (): JSX.Element | undefined {
    if (meeting && (!steps || (showStep >= Steps.description))) {
      const description = meeting.description || 'Enter meeting description'

      return (
        <div style={{ paddingTop: 20 }}>
          <IonText
            color='medium'
            style={titleStyle}>
            MEETING DESCRIPTION
          </IonText>
          <IonItem
            lines='none'
            detail
            style={{ marginTop: 5 }}
            onClick={() => {
              setEditDescription(true)
              logTap('Open', 'MeetingDescription')
            }}>
            <IonLabel>
              <IonText
                data-ci-id='description-input'
                color={!meeting.description ? 'medium' : ''}
                style={{ whiteSpace: 'pre-wrap' }}
                className='meetingCardDescription'>
                {description}
              </IonText>
            </IonLabel>
          </IonItem>
        </div>
      )
    }
  }

  function renderVideoConference (): JSX.Element | undefined {
    if (meeting && meeting.videoConference) {
      return (
        <MeetingConference
          videoConference={meeting.videoConference}
          onClick={() => {
            setEditConference(true)
            logTap('Open', 'MeetingVideoConference')
          }} />
      )
    }
  }

  function renderAutoSwitch (): JSX.Element | undefined {
    // const label = timeZone ? `${timeZone} (${moment.tz(timeZone).format('z')})` : ''
    if (bookCalendar?.calendar) {
      return (
        <div style={{ marginTop: 40 }}>
          <IonItem
            lines='full'>
            <IonIcon
              icon={autoEnabled ? flash : flashOffOutline}
              style={{ color: autoEnabled ? logoColor : 'gray' }}
              slot='start' />
            <IonLabel>
              <h2>Automagic Scheduling </h2>
              {/* <p>{label} </p> */}
            </IonLabel>
            <IonToggle
              slot='end'
              checked={autoEnabled}
              onIonChange={e => {
                console.log('automatic scheduling ', e.detail.checked)

                if (bookCalendar) {
                  const updateBookCalendar: MeetingBookCalendar = {
                    ...bookCalendar,
                    autoBook: e.detail.checked,
                  }

                  setAutoEnabled(e.detail.checked)
                  setBookCalendar(updateBookCalendar)
                  setEdited(true)
                }

                logTap(e.detail.checked ? 'On' : 'Off', 'AutoBookSwitch')
              }} />
          </IonItem>
        </div>
      )
    }
  }

  function renderBookCalendar (): JSX.Element | undefined {
    if (bookCalendar?.calendar && autoEnabled) {
      return (
        <div>
          <IonListHeader mode='ios'>
            <IonLabel>
              Meeting Calendar
            </IonLabel>
          </IonListHeader>
          <MeetingCalendar
            calendar={bookCalendar.calendar}
            onClick={() => {
              logTap('Open', 'MeetingBookCalendar')
              setSelectMeetingCalendar(true)
            }} />
        </div>
      )
    }
  }

  function renderAutoBookTimes (): JSX.Element | undefined {
    if (bookCalendar?.autoTimes && autoEnabled) {
      return (
        <MeetingTimes
          title='automagic schedule times'
          timeRanges={bookCalendar.autoTimes}
          timeZone={preferences?.timeZone}
          disabled={!bookCalendar.autoBook}
          onClick={() => {
            setSelectAutoTimeRanges(true)
            logTap('Open', 'MeetingAutoTimeRanges')
          }} />
      )
    }
  }

  function renderWarning (): JSX.Element | undefined {
    if (adjustedAutoTimes && autoEnabled) {
      const message = 'Your Automagic scheduling preferences are not set to handle meetings for this time range.'

      return (
        <div>
          <div style={messageBox}>
            <IonIcon
              icon={warning}
              style={{ fontSize: 20, marginRight: 5 }}
              color='danger' />
            <IonText
              color='danger'
              style={messageStyle}>
              {message}
            </IonText>
          </div>
          <IonChip
            color='primary'
            style={warningChipStyle}
            onClick={() => {
              if (meeting && bookCalendar) {
                const update = {
                  ...bookCalendar,
                  autoTimes: adjustedAutoTimes,
                }

                setBookCalendar(update)
                checkAutoTimes(meeting, update)
                setEdited(true)
                logTap('ResetAutoTimes')
              }
            }}>
            <IonLabel>Set to cover meeting time range</IonLabel>
          </IonChip>
        </div>
      )
    }
  }

  function renderViewCalendars (): JSX.Element | undefined {
    if (calendars?.length && participant && autoEnabled) {
      return (
        <MeetingFreeBusyCalendars
          participant={participant}
          accounts={calendars}
          onClick={() => {
            setSelectViewCalendars(true)
            logTap('Open', 'MeetingFreeBusyCalendars')
          }} />
      )
    }
  }

  // function renderDelete (): JSX.Element | undefined {
  //   if (participant?.admin) {
  //     if (showActivity === 'delete') {
  //       return (
  //         <IonItem
  //           lines='full'
  //           style={{ marginTop: 40 }}>
  //           <IonLabel color='danger'>Deleting</IonLabel>
  //           <IonSpinner
  //             name='dots'
  //             color='danger' />
  //         </IonItem>
  //       )
  //     } else {
  //       return (
  //         <IonItem
  //           lines='full'
  //           style={{ marginTop: 40, textAlign: 'center' }}
  //           onClick={() => setShowDeleteActions('delete')}>
  //           <IonLabel color='danger'>Delete Meeting</IonLabel>
  //         </IonItem>
  //       )
  //     }
  //   }
  // }

  // function renderExit (): JSX.Element | undefined {
  //   if (participant && !participant.admin) {
  //     if (showActivity === 'delete') {
  //       return (
  //         <IonItem
  //           lines='full'
  //           style={{ marginTop: 40 }}>
  //           <IonLabel color='danger'>Exiting</IonLabel>
  //           <IonSpinner
  //             name='dots'
  //             color='danger' />
  //         </IonItem>
  //       )
  //     } else {
  //       return (
  //         <IonItem
  //           lines='full'
  //           style={{ marginTop: 40, textAlign: 'center' }}
  //           onClick={() => setShowDeleteActions('exit')}>
  //           <IonLabel color='danger'>Decline Meeting</IonLabel>
  //         </IonItem>
  //       )
  //     }
  //   }
  // }

  function renderDeleteActions (): JSX.Element | undefined {
    return (
      <IonActionSheet
        isOpen={showDeleteAction.length > 0}
        onDidDismiss={() => setShowDeleteActions('')}
        buttons={[{
          text: (showDeleteAction === 'delete' ? 'Delete' : 'Exit'),
          role: 'destructive',
          handler: () => {
            setShowDeleteActions('')
            onDeleteMeeting()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        }]} />
    )
  }

  function renderScreenTips (): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={steps && !showTips}
        showScreenTip={(steps && showTips) ? showScreenTip : MeetingSettingsTips.noTip}
        onClose={() => {
          setShowTips(false)
        }}
        onMinTip={() => {
          setShowTips(true)
        }}
        onButton={() => {
          setShowTips(false)
        }} />
    )
  }

  return (
    <IonPage
      ref={pageRef}>
      {renderHeader()}
      <IonContent>
        <div
          style={container}
          className='titleIconBox'>
          {renderTitle()}
          {renderDuration()}
          {renderDays()}
          {renderTimes()}
          {renderMeetingTimesError()}
          {renderDescription()}
          {renderVideoConference()}
          {renderAutoSwitch()}
          {renderBookCalendar()}
          {renderViewCalendars()}
          {renderAutoBookTimes()}
          {renderWarning()}
          {/* {renderDelete()} */}
          {/* {renderExit()} */}
          {/* {renderTitleTip()}
          {renderDurationTip()}
          {renderTimesTip()}
          {renderDaysTip()}
          {renderAvailabilityTip()} */}
        </div>
      </IonContent>
      {renderNext()}
      {renderFooter()}
      {renderScreenTips()}
      {renderDeleteActions()}
      {renderModals()}
    </IonPage>
  )
}

export default MeetingSettings
