import React, { CSSProperties, useEffect, useState } from 'react'
import {
  IonContent, IonPage,
  IonLabel, IonItem, IonText, IonIcon, IonActionSheet, IonButton, IonSpinner,
} from '@ionic/react'

import 'screens/Settings.css'
import { phonePortraitOutline, removeCircle } from 'ionicons/icons'

import { UserDevice } from 'types'
import { browserName, platformName } from 'services/platform'
import moment from 'moment'
import { useDevices } from 'context/DevicesContext/DevicesContext'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { useAnalytics, EventData, EventName } from 'context/AnalyticsContext/AnalyticsContext'

const title = 'Authorized Devices'

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

const iconStyle: CSSProperties = {
  fontSize: 50,
}

const iconBox: CSSProperties = {
  backgroundColor: prefersDark?.matches ? 'black' : '#f4f5f8',
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

interface ComponentProps {
  myDevice: string;
  goBack: () => void
}

const Devices: React.FC<ComponentProps> = ({ myDevice, goBack }) => {
  const [showDeviceActions, setShowDeviceActions] = useState<UserDevice>()
  const [showDeletingDevice, setShowDeletingDevice] = useState('')

  const { devices, deleteDevice } = useDevices()
  const { logEvent } = useAnalytics()

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Devices' },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Devices',
      },
    })
  }

  async function onDeleteDevice (): Promise<void> {
    console.log('deleteDevice: ', showDeviceActions)

    if (showDeviceActions) {
      const id = showDeviceActions.id

      setShowDeletingDevice(id)
      await deleteDevice(id)
      setTimeout(() => {
        setShowDeletingDevice('')
      }, 1000)
    }
  }

  function renderDevices (): JSX.Element | undefined {
    if (devices?.length) {
      return (
        <div style={{ marginTop: 20 }}>
          {devices.map((device, i) => {
            const browser = browserName(device.browser)
            const platform = platformName(device.browser)
            let ip = device.ip.split(':').pop()

            if (ip === '1') {
              ip = 'localhost'
            }

            const accessTime = moment(device.accessTime).format('MMM Do YYYY, h:mm a')
            const thisDevice = device.device === myDevice
            const deviceName = thisDevice ? 'This device' : `${browser} on ${platform}`
            const deleting = showDeletingDevice === device.id

            return (
              <IonItem
                key={i}
                lines='full'>
                <IonLabel>
                  <h2>{deviceName}</h2>
                  <p>{accessTime}</p>
                  <p>IP: {ip}</p>
                </IonLabel>
                {!thisDevice && !deleting &&
                  <IonButton
                    slot='end'
                    fill='clear'
                    onClick={() => setShowDeviceActions(device)}>
                    <IonIcon
                      slot='icon-only'
                      color='danger'
                      icon={removeCircle} />
                  </IonButton>}
                {deleting &&
                  <IonSpinner
                    slot='end'
                    name='dots' />}
              </IonItem>
            )
          })}
        </div>
      )
    }
  }

  return (
    <IonPage>
      <ScreenHeader
        title={title}
        onBack={goBack} />
      <IonContent>
        <div
          style={{ flex: 1, height: '100%' }}
          className='titleIconBox'>
          <div style={iconBox}>
            <IonIcon
              style={iconStyle}
              icon={phonePortraitOutline}
              color='secondary' />
          </div>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Devices and apps that have access to your account
            </IonText>
          </div>
          {renderDevices()}
        </div>
      </IonContent>
      <IonActionSheet
        isOpen={!!showDeviceActions}
        onDidDismiss={() => setShowDeviceActions(undefined)}
        buttons={[{
          text: 'Deactivate ' + (showDeviceActions ? (`${browserName(showDeviceActions.browser)} on ${platformName(showDeviceActions.browser)}`) : 'Contact'),
          role: 'destructive',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'DeactivateDevice' })
            onDeleteDevice()
          },
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            logTap({ component: 'ActionSheet', button: 'CancelDeactivateDevice' })
            console.log('Cancel clicked')
          },
        }]} />
    </IonPage>
  )
}

export default Devices
