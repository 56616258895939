import React from 'react'
import { useSpring } from 'react-spring'

import { ChooseAvailabilityTips } from 'types/componentTips'
import TipMinButton from 'components/atoms/TipMinButton/TipMinButton'

import { useTips } from 'context/TipsContext/TipsContext'
import { useAnalytics, EventData, EventName } from 'context/Analytics/AnalyticsContext'
import { ChooseAvailability } from 'services/store/tips/types'
import { useScreens } from 'context/ScreensContext/ScreensContext'
import ScreenTipCard from 'components/molecules/ScreenTipCard/ScreenTipCard'
import EndTipsCard from 'components/organisms/EndTipsCard/EndTipsCard'
import { trendingUp } from 'ionicons/icons'

interface ScreenTipsProps {
  minTip?: boolean
  showScreenTip?: ChooseAvailabilityTips
  onClose?: () => void
  onButton?: (restartTips?: boolean) => void
  onMinTip?: () => void
}

export const ScreenTips: React.FC<ScreenTipsProps> = ({ minTip, showScreenTip, onMinTip, onClose, onButton }) => {
  // console.log('ScreenTips showScreenTip', showScreenTip)

  const { storeTips, updateStoreTips } = useTips()
  const { screens } = useScreens()
  const { logEvent } = useAnalytics()

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  function updateTips (tip: ChooseAvailability): void {
    if (updateStoreTips) {
      updateStoreTips({
        chooseAvailability: {
          ...storeTips?.chooseAvailability, ...tip,
        },
      })
    }
  }

  return (
    <div>
      <TipMin
        show={minTip}
        onButton={() => {
          logTap({ component: 'TipMin', button: 'MinTip' })
          onMinTip && onMinTip()}} />
      <TipInitiatorSelectTimes
        show={!minTip && screens?.allScreens?.newInitiator && showScreenTip === ChooseAvailabilityTips.selectTimes}
        onClose={() => {
          logTap({ component: 'TipInitiatorSelectTimes', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipInitiatorSelectTimes', button: 'GotIt' })
          updateTips({ selectTimesInitiator: true })
          onButton && onButton()
        }} />
      <TipInviteeSelectTimes
        show={!minTip && !screens?.allScreens?.newInitiator && showScreenTip === ChooseAvailabilityTips.selectTimes}
        onClose={() => {
          logTap({ component: 'TipInviteeSelectTimes', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipInviteeSelectTimes', button: 'GotIt'})
          updateTips({ selectTimesInvitee: true })
          onButton && onButton()
        }} />
      <TipSelectTimesFirstOne
        show={!minTip && showScreenTip === ChooseAvailabilityTips.selectTimesFirst}
        onClose={() => {
          logTap({ component: 'TipSelectTimesFirstOne', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipSelectTimesFirstOne', button: 'GotIt'})
          updateTips({ selectTimesFirst: true })
          onButton && onButton()
        }} />
      <TipSelectTimesSecond
        show={!minTip && showScreenTip === ChooseAvailabilityTips.selectTimesSecond}
        onClose={() => {
          logTap({ component: 'TipSelectTimesSecond', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipSelectTimesSecond', button: 'GotIt'})
          updateTips({ selectTimesSecond: true })
          onButton && onButton()
        }} />
      <TipAutoTimes
        show={!minTip && showScreenTip === ChooseAvailabilityTips.autoTimes}
        onClose={() => {
          logTap({ component: 'TipAutoTimes', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipAutoTimes', button: 'GotIt'})
          updateTips({ autoTimes: true })
          onButton && onButton()
        }} />
      <TipOthersTimeChoices
        show={!minTip && showScreenTip === ChooseAvailabilityTips.othersTimes}
        onClose={() => {
          logTap({ component: 'TipOthersTimeChoices', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipOthersTimeChoices', button: 'GotIt'})
          updateTips({ othersTimes: true })
          onButton && onButton()
        }} />
      <TipTrendingTimes
        show={!minTip && showScreenTip === ChooseAvailabilityTips.trendingTimes}
        onClose={() => {
          logTap({ component: 'TipTrendingTimes', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipTrendingTimes', button: 'GotIt'})
          updateTips({ trendingTimes: true })
          onButton && onButton()
        }} />
      <TipTentativeTimes
        show={!minTip && showScreenTip === ChooseAvailabilityTips.tentativeTimes}
        onClose={() => {
          logTap({ component: 'TipTentativeTimes', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipTentativeTimes', button: 'GotIt'})
          updateTips({ tentativeTimes: true })
          onButton && onButton()
        }} />
      <TipTentativeTimesAuto
        show={!minTip && showScreenTip === ChooseAvailabilityTips.tentativeTimesAuto}
        onClose={() => {
          logTap({ component: 'TipTentativeTimesAuto', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipTentativeTimesAuto', button: 'GotIt'})
          updateTips({ tentativeTimes: true })
          onButton && onButton()
        }} />
      <TipInvitePeople
        show={!minTip && showScreenTip === ChooseAvailabilityTips.invitePeople}
        onClose={() => {
          logTap({ component: 'TipInvitePeople', button: 'Close' })
          onClose && onClose()}}
        onButton={() => {
          logTap({ component: 'TipInvitePeople', button: 'GotIt'})
          updateTips({ invitePeople: true })
          onButton && onButton()
        }} />
      <EndTips
        show={!minTip && showScreenTip === ChooseAvailabilityTips.endTips}
        onClose={() => {
          logTap({ component: '', button: 'Close' })
          onClose && onClose()}}
        onButtons={(button) => {
          if (button === 0) {
            logTap({ component: 'EndTips', button: 'Yes' })
            updateStoreTips && updateStoreTips({ chooseAvailability: {} })
            onButton && onButton(true)
          } else {
            logTap({ component: 'EndTips', button: 'No' })
            updateTips({ endTips: true })
            onButton && onButton()
          }
        }} />
    </div>
  )
}

interface ContainerProps {
  show?: boolean;
  onButton?: () => void;
  onClose?: () => void;
  onButtons?: (button: number) => void;
}

export const TipMin: React.FC<ContainerProps> = ({ show, onButton }) => {
  return (
    <TipMinButton
      show={show}
      bottom={60}
      onClick={onButton} />
  )
}

export const TipInitiatorSelectTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Tap anywhere on the calendar to select your availability within our given time range.'
  const subtext = 'Drag block edges to change the durations. Times outside the meeting\'s set time range are marked unavailable.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipInviteeSelectTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Tap anywhere on the calendar to select your preferred times within the given time range.'
  const subtext = 'Drag block edges to change the durations. Times outside the meeting\'s set time range are marked unavailable.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipSelectTimesFirstOne: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'No one has picked any times yet!'
  const subtext = 'Being the first just means you get your picks in earliest.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipSelectTimesSecond: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Others have selected some times they\'re available.'
  const subtext = 'Once you\'ve marked yours, Moica can go on to schedule the meeting.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

const TipAutoTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'You have automagic scheduling ON.'
  const subtext = 'Wonderful! Moica will use your real-time availability to work with everyone\'s choices and find a time.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipOthersTimeChoices: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Other\'s time choices are shown in light blue.'
  const subtext = 'Tapping them presents details and actions relevant to the time.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipTrendingTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Trending Time Slots'
  const subtext = `The earliest common times among the most number of participants are marked with an orange highlight and ${trendingUp} icon.`

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipTentativeTimes: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Tentative Time Slots'
  const subtext = 'When more than half of the participants can make a time, it\'s marked as Tentative.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipTentativeTimesAuto: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Trending Time Slots'
  const subtext = `The earliest common times among the most number of participants are marked with an orange highlight and ${trendingUp} icon.`

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

export const TipInvitePeople: React.FC<ContainerProps> = ({ show, onButton, onClose }) => {
  const text = 'Invite others to join your meeting.'
  const subtext = 'They can select their availability and Moica will find the best time for everyone.'

  return (
    <ScreenTipCard
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButton={onButton} />
  )
}

const EndTips: React.FC<ContainerProps> = ({ show, onButtons, onClose }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  const text = 'You\'ve seen all the tips here.'
  const subtext = 'Would you like to see the tips for this screen again?'

  return (
    <EndTipsCard
      animatedProps={animatedProps}
      show={show}
      text={text}
      subtext={subtext}
      onClose={onClose}
      onButtons={onButtons} />
  )
}
