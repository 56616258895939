import React, { CSSProperties, useState, useLayoutEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import {
  IonContent, IonFooter, IonModal, IonText, IonToast,
} from '@ionic/react'
import moment from 'moment'

import 'screens/Settings.css'
import 'theme/CalendarA.css'
import ModalHeader from 'components/molecules/ModalHeader/ModalHeader'

import TimeBar from 'components/organisms/CalendarTimeBar/TimeBar'
import { TimeSlot, TimeRange } from 'types'
import DayColumn from 'components/organisms/DayColumn/DayColumn'
import { adjustTimeRangesForDay, getTimeRanges } from 'components/organisms/ScheduleCalendar/utils'
import { compareTimes, minutesToString, stringToMinutes } from 'services/time'
import MeetingTimes from '../../molecules/MeetingTimes/MeetingTimes'
import { useCalendarView } from '../ScheduleCalendar/CalendarViewContext'
import { ScreenTips } from './ScreenTips'
import { SelectMeetingTimesTips } from 'types/componentTips'
import { useTips } from 'context/TipsContext/TipsContext'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { Spring } from 'react-spring/renderprops'
import { EventData, EventName, useAnalytics } from 'context/Analytics/AnalyticsContext'

const user = 'user'
const meeting = 'meeting'
const scale = 60
const maxSlots = 2

const defaultMeeting = {
  id: meeting,
  title: meeting,
  duration: '60',
  participants: [user],
}

const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}
const bottomBox: CSSProperties = {
  flex: 1,
  display: 'flex',
  overflow: 'hidden',
}

const messageBox: CSSProperties = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
}

const messageStyle = {
  fontSize: 14,
}

const footerContainer: CSSProperties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  backgroundColor: 'transparent',
}

let dayStartTime = ''

function initTimeSlots (timeRanges?: TimeRange[]): TimeSlot[] {
  dayStartTime = moment().add(1, 'day').startOf('day').toISOString()

  if (Array.isArray(timeRanges)) {
    const ranges = adjustTimeRangesForDay(dayStartTime, timeRanges)

    console.log('initTimeSlots: ', ranges)

    return ranges.map(range => {
      return {
        localId: uuidv1(),
        meeting,
        user,
        startTime: range.startTime,
        endTime: range.endTime,
        available: true,
      }
    })
  }

  return []
}

const title = 'Time Restrictions'

interface ContainerProps {
  showBar?: boolean;
  timeRanges?: TimeRange[];
  tip?: boolean;
  doneButton?: string;
  duration?: string;
  onClose: () => void;
  onUpdated: (timeRanges: TimeRange[]) => void;
  presentingElement: HTMLElement | undefined;
}

const SelectMeetingTimes: React.FC<ContainerProps> = ({ showBar, duration, timeRanges, tip, doneButton, onClose, onUpdated, presentingElement }) => {
  // const [edited, setEdited] = useState(false)
  const [showScreenTip, setShowScreenTip] = useState(SelectMeetingTimesTips.noTip)
  const [minTip, setMinTip] = useState(false)
  const [editSlot, setEditSlot] = useState<TimeSlot>()
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(initTimeSlots(timeRanges))
  const [deletedSlots, setDeletedSlots] = useState<TimeSlot[]>([])
  const [showToast, setShowToast] = useState('')
  const [disableScroll, setDisableScroll] = useState(false)

  const { timeLabelWidth = 60, calendarTopPadding = 20, timeSlotHeight = 50, timeSlotWidth = 90 } = useCalendarView()

  const { storeTips } = useTips()
  const { logEvent } = useAnalytics()

  if (duration) {
    defaultMeeting.duration = duration
  }

  const meetingDuration = duration ? Number(stringToMinutes(duration)) : 0

  const timeColumnContainer: CSSProperties = {
    width: timeLabelWidth + timeSlotWidth + 20,
    display: 'flex',
    position: 'relative',
    borderRight: '1px solid lightgray',
  }

  const columnBox: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: timeLabelWidth,
    right: 0,
    bottom: 0,
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
  }

  const calendarBox: CSSProperties = {
    flex: 1,
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'scroll',
    flexWrap: 'nowrap',
    paddingTop: calendarTopPadding,
  }

  useLayoutEffect(() => {
    let hour = '9'

    if (timeRanges?.length) {
      hour = moment(timeRanges[0].startTime).format('HH')
    }

    setTimeout(() => {
      const el = document.getElementById('selectTimesBox')

      if (el) {
        console.log('SCROLL TO HOUR: ', hour)

        el.scrollTop = timeSlotHeight * Number(hour)
      }
    }, 100)

    startTips()
  }, [])

  const componentStoreTips = storeTips?.selectMeetingTimes

  function startTips (): void {
    setTimeout(() => {
      setNextTip(SelectMeetingTimesTips.sequence)
    }, 1000)
  }

  function setNextTip (tip: SelectMeetingTimesTips): void {
    // console.log('Meeting screen setNextTip: ', tip)

    switch (tip) {
      // eslint-disable-next-line no-fallthrough
      case SelectMeetingTimesTips.sequence:
      case SelectMeetingTimesTips.selectTimes:
        if (!componentStoreTips?.selectTimes) {
          setShowScreenTip(SelectMeetingTimesTips.selectTimes)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case SelectMeetingTimesTips.noTip:
      default:
        // in the modal case reset the tips back to sequence
        setShowScreenTip(SelectMeetingTimesTips.selectTimes)
        setMinTip(true)
        break
    }
  }

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: { ...eventData },
    })
  }

  function updatedTimeRanges (): void {
    const sortedTimeSlots = timeSlots.slice().sort((a: TimeSlot, b: TimeSlot) => {
      const timeA = a.startTime
      const timeB = b.startTime

      return compareTimes(timeA, timeB)
    })

    if (duration && sortedTimeSlots.find(timeSlot =>
      moment(timeSlot.endTime).diff(moment(timeSlot.startTime), 'minutes') < meetingDuration,
    )) {
      setShowToast(`All time ranges should be greater than meeting duration ${minutesToString(duration)}`)
    } else {
      onUpdated(sortedTimeSlots.map(slot => {
        return {
          startTime: slot.startTime,
          endTime: slot.endTime,
        }
      }))
      onClose()
    }
  }

  function renderHeader (): JSX.Element {
    return (
      <ModalHeader
        title={title} />
    )
  }

  function onScroll (): void {
    const el = document.getElementById('selectTimesBox')

    if (el) {
      const timeBar = document.getElementById('selectTimesBar')

      if (timeBar) {
        timeBar.scrollTop = el.scrollTop
      }
    }
  }

  function onDragStart (fromTime: string): void {
    if (editSlot) {
      if (moment(fromTime).isAfter(moment(editSlot.startTime)) &&
      moment(fromTime).isBefore(moment(editSlot.endTime))) {
        setDisableScroll(true)
      }
    }
  }

  function onDragEnd (): void {
    setDisableScroll(false)
  }

  function onCreateTimeSlot (startTime: string, endTime: string): void {
    console.log(`onCreateTimeSlot: ${startTime} to ${endTime}`)

    if (timeSlots.length < maxSlots) {
      const slots = timeSlots.slice()
      const slot = {
        localId: uuidv1(),
        meeting,
        user,
        startTime,
        endTime,
        available: true,
      }

      slots.push(slot)
      setTimeSlots(slots)
      setEditSlot(slot)
      // setEdited(true)
    }
  }

  function onUpdateTimeSlot (timeSlot: TimeSlot): void {
    console.log('onUpdateTimeSlot: ', timeSlot)

    const slots = timeSlots.map(slot => {
      if (slot.localId === timeSlot.localId) {
        return {
          ...slot,
          startTime: timeSlot.startTime,
          endTime: timeSlot.endTime,
        }
      } else {
        return slot
      }
    })

    setTimeSlots(slots.filter(slot => {
      if (deletedSlots.find(s => s.localId === slot.localId)) {
        return false
      }

      return true
    }))
    // setEdited(true)
  }

  function onDeleteTimeSlot (timeSlot: TimeSlot): void {
    console.log('onDeleteTimeSlot: ', timeSlot)

    const slots = deletedSlots.slice()

    slots.push(timeSlot)
    setDeletedSlots(slots)

    setTimeSlots(timeSlots.filter(slot => {
      if (slot.localId === timeSlot.localId) {
        return false
      }

      return true
    }))
    // setEdited(true)
  }

  function renderTimesRanges (): JSX.Element | undefined {
    const sortedTimeSlots = timeSlots.slice().sort((a: TimeSlot, b: TimeSlot) => {
      const timeA = a.startTime
      const timeB = b.startTime

      return compareTimes(timeA, timeB)
    })

    const timeRanges = sortedTimeSlots.map(slot => {
      return {
        startTime: slot.startTime,
        endTime: slot.endTime,
      }
    })

    return (
      <MeetingTimes
        timeRanges={timeRanges} />
    )
  }

  function renderDayColumn (): JSX.Element {
    const calendarBoxStyle: CSSProperties = {
      ...calendarBox,
    }

    if (disableScroll) {
      calendarBoxStyle.overflowX = 'hidden'
      calendarBoxStyle.overflowY = 'hidden'
    }

    let ranges
    let slots: TimeSlot[] = []

    slots = timeSlots.slice()

    if (slots.length) {
      slots.push({
        meeting,
        user: 'disabled',
        startTime: dayStartTime,
        endTime: moment(dayStartTime).add(24, 'hours').toISOString(),
      })

      // console.log('SLOTS: ', slots)
      // console.log('EDIT SLOT: ', editSlot)

      ranges = getTimeRanges({ timeSlots: slots, editSlot })

      slots = timeSlots.slice()

      // console.log('RANGES: ', ranges)
      ranges.map(range => {
        if (range.participants?.length === 1) {
          slots.push({
            meeting,
            user: 'disabled',
            startTime: range.startTime,
            endTime: range.endTime,
            disabled: true,
          })
        }
      })
    }

    // console.log('TIME SLOTS: ', slots)

    return (
      <div style={timeColumnContainer}>
        <TimeBar
          id='selectTimesBar'
          timeRanges={showBar ? ranges?.filter(r => r.participants?.length === 2) : []} />
        <div style={columnBox}>
          <div
            id='selectTimesBox'
            style={calendarBoxStyle}
            onScroll={onScroll}>
            <DayColumn
              parentId='selectTimesBox'
              user={user}
              startDay={0}
              day={1}
              scale={scale}
              meeting={defaultMeeting}
              timeSlots={slots}
              calendarEvents={[]}
              participants={[]}
              showOpen
              editCalendar
              editSlot={editSlot}
              tip={(tip && slots.length === 0) ? 12 : 0}
              setEditSlot={(timeSlot) => {
                setEditSlot(timeSlot)
                setShowToast('')
                // if (!meetingDuration || !timeSlot ||
                //   moment(timeSlot.endTime).diff(moment(timeSlot.startTime), 'minutes') >= meetingDuration) {
                //   setShowToast('')
                // } else if (duration) {
                //   setShowToast(`Time range should be greater than meeting duration ${minutesToString(duration)}`)
                // }
              }}
              onEdit={() => {
                // console.log('onEdit')
              }}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onEditEnd={() => setEditSlot(undefined)}
              onCreateTimeSlot={(startTime, endTime) => {
                logTap({ component: 'DayColumn', button: 'CreateSlot' })
                onCreateTimeSlot(startTime, endTime)
              }}
              onUpdateTimeSlot={(timeSlot) => {
                logTap({ component: 'DayColumn', button: 'UpdateSlot' })
                onUpdateTimeSlot(timeSlot)
              }}
              onDeleteTimeSlot={(timeSlot) => {
                logTap({ component: 'DayColumn', button: 'DeleteSlot' })
                onDeleteTimeSlot(timeSlot)
              }}
              onInfo={() => {
                logTap({ component: 'DayColumn', button: 'Info' })
                console.log('onInfo')
              }} />
          </div>
        </div>
      </div>
    )
  }

  function renderScreenTips (): JSX.Element | undefined {
    return (
      <ScreenTips
        minTip={minTip}
        showScreenTip={showScreenTip}
        onClose={() => {
          setMinTip(true)
        }}
        onMinTip={() => {
          setMinTip(false)
          // setNextTip(showScreenTip + 1)
        }}
        onButton={() => {
          setMinTip(true)
          // setNextTip(showScreenTip + 1)
        }} />
    )
  }

  function renderFooter (): JSX.Element | undefined {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {props =>
          <div style={{ ...props, ...footerContainer }}>
            <IonFooter className='screenFooterButton'>
              <FooterButton
                testId='select-times-done'
                onClick={() => {
                  logTap({ component: 'FooterButton', button: doneButton || 'Done' })
                  updatedTimeRanges()
                }}>
                {doneButton || 'Done'}
              </FooterButton>
              {onClose &&
                <FooterButton
                  testId='select-times-cancel'
                  fill='clear'
                  onClick={() => {
                    logTap({ component: 'FooterButton', button: 'Cancel' })
                    onClose && onClose()
                  }}>
                  Cancel
                </FooterButton>}
            </IonFooter>
          </div>}
      </Spring>
    )
  }

  function renderToast (): JSX.Element | undefined {
    return (
      <IonToast
        isOpen={!!showToast}
        color='warning'
        onDidDismiss={() => setShowToast('')}
        message={showToast}
        position='bottom'
        duration={2000} />
    )
  }

  return (
    <IonModal
      isOpen
      presentingElement={presentingElement}
      onDidDismiss={onClose}>
      {renderHeader()}
      <IonContent
        scrollY={false}
        scrollX={false}>
        <div style={container}>
          <div style={messageBox}>
            <IonText
              color='medium'
              style={messageStyle}>
              Select up to two time ranges within which people can select their availability.
            </IonText>
          </div>
          {renderTimesRanges()}
          <div style={bottomBox}>
            {renderDayColumn()}
            {renderFooter()}
          </div>
        </div>
      </IonContent>
      {renderScreenTips()}
      {renderToast()}
    </IonModal>
  )
}

export default SelectMeetingTimes
