
import CryptoJS from 'crypto-js'
import { v1 as uuidv1 } from 'uuid'
import { getLocalStore } from 'services/store/configure'
import { addAccount, removeAccount, updateAccount } from 'services/store/accounts/actions'
import { RootState } from 'services/store'
import { Account } from 'services/store/accounts/types'

const key = process.env.REACT_APP_TOKEN_KEY || 'tokenKey123'

export let fingerprint: string
export let account: Account | undefined

export function generateDeviceFingerprint (): Promise<string> {
  return new Promise((resolve) => {
    fingerprint = uuidv1()

    const ciphertext = CryptoJS.AES.encrypt(fingerprint, key)

    account = {
      id: uuidv1(),
      device: ciphertext.toString(),
    }

    getLocalStore().dispatch(addAccount(account))

    console.log('SET DEVICE FINGERPRINT: ', fingerprint)
    sessionStorage.setItem('account', account.id)
    resolve(fingerprint)
  })
}

export function encryptedDeviceFingerPrint (): string | undefined {
  return account?.device
}

export function storeEncryptedDeviceFingerPrint (device: string): void {
  if (account) {
    getLocalStore().dispatch(updateAccount({
      ...account,
      device,
    }))
    account.device = device
  }
}

function loadAccount (accountId: string, accounts: Account[]): void {
  account = accounts.find(a => a.id === accountId)

  if (account && account.device) {
    const bytes = CryptoJS.AES.decrypt(account.device, key)

    fingerprint = bytes.toString(CryptoJS.enc.Utf8)

    console.log('LOAD DEVICE FINGERPRINT: ', fingerprint)
  }
}

export function findAccount (device: string): Account | undefined {
  const store = getLocalStore()
  const { accounts } = store.getState() as RootState

  return accounts.find(a => {
    const bytes = CryptoJS.AES.decrypt(a.device, key)

    const d = bytes.toString(CryptoJS.enc.Utf8)

    return d === device
  })
}

export function updateStoreAccount (update: Account): void {
  const store = getLocalStore()
  const { accounts } = store.getState() as RootState
  const storeAccount = accounts.find(a => a.id === update.id)

  account = {
    ...storeAccount,
    ...update,
  }

  if (storeAccount) {
    store.dispatch(updateAccount(account))
  }
}

// export async function initSession (id?: string): Promise<void> {
//   const accountId = sessionStorage.getItem('account')

//   const store = getLocalStore()
//   const { accounts } = store.getState() as RootState

//   console.log('LOCAL ACCOUNTS: ', accounts)

//   if (accountId) {
//     console.log('SESSION ACCOUNT ID: ', accountId)

//     if (accounts.find(a => a.id === accountId)) {
//       loadAccount(accountId, accounts)

//       return
//     }
//   }

//   // if session accountId is not set pick the recently added account
//   if (accounts?.length) {
//     if (id) {
//       account = accounts.find(a => a.id === id)
//     }

//     if (!account) {
//       account = accounts[0]
//     }

//     sessionStorage.setItem('account', account.id)
//     loadAccount(account.id, accounts)
//   } else {
//     await generateDeviceFingerprint()
//   }

//   await generateDeviceFingerprint()
// }

export async function initSession (id?: string): Promise<void> {
  const accountId = sessionStorage.getItem('account')

  const store = getLocalStore()
  const { accounts } = store.getState() as RootState

  console.log('LOCAL ACCOUNTS: ', accounts)

  if (id) {
    account = accounts.find(a => a.id === id)

    if (account) {
      sessionStorage.setItem('account', account.id)
      loadAccount(account.id, accounts)

      return
    }
  } else if (accountId) {
    console.log('SESSION ACCOUNT ID: ', accountId)

    if (accounts.find(a => a.id === accountId)) {
      loadAccount(accountId, accounts)

      return
    }
  }

  await generateDeviceFingerprint()
}

export function getAccounts (): Account[] | undefined {
  const store = getLocalStore()
  const { accounts } = store.getState() as RootState

  return accounts
}

export function logoutAccount (): void {
  if (account?.id) {
    getLocalStore().dispatch(removeAccount(account))
  }
}

export async function initDevice (): Promise<void> {
  await initSession()
}
