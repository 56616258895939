import React from 'react'

import 'screens/Settings.css'
import MeetingsProvider from 'context/MeetingsContext/MeetingsContext'
import ParticipantsProvider from 'context/ParticipantsContext/ParticipantsContext'
import MeetingTimeModal from './MeetingTimeModal'
import { MeetingTime } from 'types'
import TimeSlotsProvider from 'context/TimeSlotsContext/TimeSlotsContext'

interface ContainerProps {
  meeting: string;
  meetingTime: MeetingTime;
  onMore?: () => void;
  onClose: () => void;
  presentingElement: HTMLElement | undefined;
}

const MeetingTimeModalScreen: React.FC<ContainerProps> = ({ meeting, meetingTime, onMore, onClose, presentingElement }) => {
  console.log('MeetingTimes modal for meeting: ', meeting)
  console.log(meetingTime)

  return (
    <MeetingsProvider meeting={meeting}>
      <ParticipantsProvider meeting={meeting}>
        <TimeSlotsProvider meeting={meeting}>
          <MeetingTimeModal
            meetingTime={meetingTime}
            onMore={onMore}
            onClose={onClose}
            presentingElement={presentingElement} />
        </TimeSlotsProvider>
      </ParticipantsProvider>
    </MeetingsProvider>
  )
}

export default MeetingTimeModalScreen
