import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, IonSpinner, isPlatform, setupConfig } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

import {
  ApolloClient,
  ApolloProvider,
} from '@apollo/client'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from 'services/store/configure'

import ErrorPage from './screens/ErrorScreen/ErrorPage'

import 'react-dates/initialize'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import 'theme/variables.css'
import 'theme/styles.css'
import { initGoogleApi } from 'services/googleCalendar'
import { initDevice } from 'services/accounts'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import { AppRouter } from 'navigation/AppRouter'
import { initApollo } from 'services/apollo'

// console.log(process.env)

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line
  console.log = () => {}
}

// https:// hasura.io/blog/moving-from-apollo-boost-to-graphql-subscriptions-with-apollo-client-cc0373e0adb0/
// Create an http link:

console.log(navigator.userAgent)

const { store, persistor } = configureStore()
// let client: ApolloClient<Record<string, unknown>> | undefined

const App: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)
  const [apolloClient, setApolloClient] = useState<ApolloClient<Record<string, unknown>>>()

  // if running on desktop set the mode to ios
  if (isPlatform('desktop')) {
    setupConfig({
      mode: 'ios',
    })
  }

  function checkEnv (): boolean {
    if (process.env.REACT_APP_MEETINGS_APP &&
      process.env.REACT_APP_TOKEN_KEY &&
      process.env.REACT_APP_MEETINGS_SERVER &&
      process.env.REACT_APP_MEETINGS_WS) {
      return true
    }

    setShowError(true)

    return false
  }

  async function initApp (): Promise<void> {
    await initDevice()

    const client = await initApollo()

    setApolloClient(client)
    initGoogleApi()

    setLoading(false)
  }

  useEffect(() => {
    if (checkEnv()) {
      initApp()
    }
  }, [])

  async function resetApollo () : Promise<void> {
    const client = await initApollo()

    setApolloClient(client)
  }

  if (showError) {
    return (
      <IonApp className='appContainer'>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route
              path='/'
              component={ErrorPage}
              exact />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    )
  }

  if (loading) {
    return (
      <IonApp className='appContainer'>
        <ScreenCenter height='80%'>
          <img
            src='assets/icon/MOICA_logo.png'
            width='60'
            height='60'
            alt='' />
          <IonSpinner
            name='dots'
            color='medium' />
        </ScreenCenter>
      </IonApp>
    )
  }

  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <StoreProvider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}>
            <IonApp
              className='appContainer'>
              <AppRouter resetApollo={() => resetApollo()} />
            </IonApp>
          </PersistGate>
        </StoreProvider>
      </ApolloProvider>
    )
  }

  return null
}

export default App
