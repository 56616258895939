import React, { CSSProperties } from 'react'
import { IonText, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { TimeRange } from 'types'
import { ellipseOutline, checkmarkCircle, removeCircle } from 'ionicons/icons'
import moment from 'moment'

export type ItemIcon = {
  name: string;
  color: string;
  slot: string;
  activity?: boolean;
}

const timeRangeLabel: CSSProperties = {
  fontSize: 14,
}

const countStyle: CSSProperties = {
  fontSize: 14,
}

const iconStyle: CSSProperties = {
  marginRight: 20,
}

export interface TimeRangeListItemProps {
  timeRange: TimeRange;
  active?: boolean;
  busy?: boolean;
  tip?: boolean;
  name?: string;
  disableDay?: boolean;
  onSelect?: () => void;
  onDetails?: () => void;
}

const TimeRangeListItem: React.FC<TimeRangeListItemProps> = (
  { timeRange, active, busy, name, disableDay, onDetails }) => {
  const { startTime, endTime, participants } = timeRange
  const count = participants ? participants.length : 0
  const day = moment(startTime).format('ddd, MMM D')
  const start = moment(startTime).format('h:mm a')
  const end = moment(endTime).format('h:mm a')

  // Note: on android the list has a small padding at the bottom
  // and since we are using a slight shaded background for the select button
  // get rid of the bottom padding

  let countLabel = name || count.toString()

  if (active) {
    if (name) {
      countLabel = `You + ${name}`
    } else if (count > 1) {
      countLabel = `You + ${count - 1}`
    } else {
      countLabel = 'You'
    }
  }

  return (
    <IonItem
      lines='full'
      onClick={onDetails}>
      {!active &&
        <IonIcon
          icon={ellipseOutline}
          style={iconStyle}
          color='medium'
          slot='start' />}
      {active && !busy &&
        <IonIcon
          icon={checkmarkCircle}
          style={iconStyle}
          color='primary'
          slot='start' />}
      {active && busy &&
        <IonIcon
          icon={removeCircle}
          style={iconStyle}
          color='danger'
          slot='start' />}
      <IonLabel
        style={timeRangeLabel}>
        {!disableDay && day}
        <p>{start} to {end}</p>
      </IonLabel>
      <IonText
        color='medium'
        slot='end'
        style={countStyle}>
        {countLabel}
      </IonText>
    </IonItem>
  )
}

export default TimeRangeListItem
