import React, { CSSProperties } from 'react'
import { useSpring } from 'react-spring'

import NotifyCard from 'components/molecules/NotifyCard/NotifyCard'
import AnimatedCheckmark from 'components/atoms/AnimatedCheckmark/AnimatedCheckmark'
import { IonText } from '@ionic/react'
import AnimatedMeeting from 'components/atoms/AnimatedMeeting/AnimatedMeeting'
import AnimatedLightning from 'components/atoms/AnimatedLightning/AnimatedLightning'

const box: CSSProperties = {
  display: 'flex',
  marginLeft: -5,
  // border: '1px solid red',
}

const boxLeft: CSSProperties = {
  display: 'flex',
  // alignItems: 'center',
}

const boxRight: CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  marginLeft: 10,
  // border: '1px solid red',
}

interface ContainerProps {
  show?: boolean;
  message?: string;
  goBackTo?: string;
  onButton?: () => void;
}

export const ConfirmedTimeNotify: React.FC<ContainerProps> = ({ show, message, goBackTo, onButton }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  return (
    <NotifyCard
      animatedProps={animatedProps}
      cardContentStyle={{ paddingBottom: 5 }}
      className='screenTipCard'
      goBackTo={goBackTo}
      onButton={onButton}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedCheckmark delay={1000} />
          </div>}
        <div style={boxRight}>
          <IonText style={{ whiteSpace: 'pre-wrap' }}>
            Moica found a time that works for everyone, and you&apos;re now scheduled!
            To see more detail, tap on the confirmed (green) time. <br />
            {message}
          </IonText>
        </div>
      </div>
    </NotifyCard>

  )
}

export const DoneSelectingTimesNotify: React.FC<ContainerProps> = ({ show, message, goBackTo, onButton }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 60 } : { opacity: 0, bottom: -600 }),
  })

  return (
    <NotifyCard
      animatedProps={animatedProps}
      cardContentStyle={{ paddingBottom: 5 }}
      goBackTo={goBackTo}
      className='screenTipCard'
      onButton={onButton}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedMeeting
              color='white'
              style={{ width: 40, height: 40 }}
              loop />
          </div>}
        <div style={boxRight}>
          <IonText style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </IonText>
        </div>
      </div>
    </NotifyCard>

  )
}

export const SelectAllTimesNotify: React.FC<ContainerProps> = ({ show, onButton }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 60 } : { opacity: 0, bottom: -600 }),
  })

  const message = 'Please select all the times that you are available for this meeting.'

  return (
    <NotifyCard
      animatedProps={animatedProps}
      cardContentStyle={{ paddingBottom: 5 }}
      className='screenTipCard'
      onButton={onButton}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedMeeting
              color='white'
              style={{ width: 40, height: 40 }}
              loop />
          </div>}
        <div style={boxRight}>
          <IonText style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </IonText>
        </div>
      </div>
    </NotifyCard>
  )
}

export const AutoBookNotify: React.FC<ContainerProps> = ({ show, message, onButton }) => {
  const animatedProps = useSpring({
    ...(show ? { opacity: 1, bottom: 60 } : { opacity: 0, bottom: -600 }),
  })

  return (
    <NotifyCard
      animatedProps={animatedProps}
      cardContentStyle={{ paddingBottom: 5 }}
      className='screenTipCard'
      onButton={onButton}>
      <div style={box}>
        {show &&
          <div style={boxLeft}>
            <AnimatedLightning
              style={{ width: 60, height: 60 }}
              loop />
          </div>}
        <div style={boxRight}>
          <IonText style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </IonText>
        </div>
      </div>
    </NotifyCard>
  )
}
