import React, { useState, useEffect, useRef } from 'react'
import { IonPage, IonContent, IonSpinner, IonToolbar, IonLabel, IonSegment, IonSegmentButton, IonText, IonIcon } from '@ionic/react'

import { Invitee, InviteeStatus, MeetingInvite, TimeRange } from 'types'
import { CreateInviteeError, CreateInviteeInput, JoinMeetingInput, UpdateInviteeInput } from 'services/api'
import InviteCard from 'components/organisms/InviteCard/InviteCard'
import ScreenCenter from 'components/atoms/ScreenCenter/ScreenCenter'
import ScreenHeader from 'components/molecules/ScreenHeader/ScreenHeader'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useInvitees } from 'context/InviteesContext/InviteesContext'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import paths from 'navigation/paths'
import InviteeActionsModal, { SelectedOptions } from 'components/organisms/InviteeActionsModal/InviteeActionsModal'
import { useCalendars } from 'context/CalendarsContext/CalendarsContext'
import { useAnalytics, EventName, EventData } from 'context/AnalyticsContext/AnalyticsContext'

// import TipCard from 'components/organisms/TipCard'

const title = 'Invitations'

interface ComponentProps {
  invitation?: string;
  meetingId?: string;
  onChooseAvailability: (args: {meeting: string, connectCalendar?: boolean, autoBook?: boolean, inviteCount?: number, startEdit?: boolean}) => void;
  onMeetingCalendarSettings: (meeting: string) => void;
  onAddParticipants: (meeting: string) => void;
  onMeetingSettings: (meeting: string, meetMeInvitee?: boolean) => void;
  goBack: () => void
}

type InviteeActionsInfo = {
  invite: MeetingInvite;
  invitee: Invitee;
  selectAutoTimes?: boolean;
  adjustedAutoTimes?: TimeRange[];
  defaultAutoTimes?: TimeRange[];
  selectBusyTimes?: boolean;
}

const Invitations: React.FC<ComponentProps> = (
  { invitation, meetingId, onChooseAvailability, onMeetingCalendarSettings, onAddParticipants, onMeetingSettings, goBack }) => {
  const [showInvitation, setShowInvitation] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const [showActivityAccept, setShowActivityAccept] = useState('')
  const [showActivityDecline, setShowActivityDecline] = useState('')
  const [showInviteeActionsModal, setShowInviteeActionsModal] = useState(false)
  const [inviteeActionsInfo, setInviteeActionsInfo] = useState<InviteeActionsInfo>()

  const [showError, setShowError] = useState('')
  const [showSegment, setShowSegment] = useState('showNew')
  const [debounceInvite, setDebounceInvite] = useState('')
  const { logEvent } = useAnalytics()

  const pageRef = useRef()

  // Contexts
  const { loading, invitees, invitations, createInvitee, updateInvitee, refetch } = useInvitees()
  const { meetings, joinMeeting, exitMeeting } = useMeetings()
  const { defaultCalendar, autoDays } = useCalendars()
  // console.log('Invitations: ', invitations)
  // console.log('Invitees: ', invitees)

  useEffect(() => {
    logEvent({
      eventName: EventName.screenView,
      eventData: { screen: 'Invitations', meeting: meetingId },
    })
  }, [])

  function logTap (eventData: EventData): void {
    logEvent({
      eventName: EventName.buttonTap,
      eventData: {
        ...eventData,
        screen: 'Invitations',
        meeting: meetingId,
      },
    })
  }

  async function onCreateInvite (): Promise<void> {
    if (invitation) {
      setShowLoading(true)

      const input: CreateInviteeInput = {
        invitation,
        status: InviteeStatus.seen,
      }

      try {
        setDebounceInvite(invitation)

        const invitee = await createInvitee(input)

        if (invitee && invitee[0].id) {
          console.log('created invitee:  ', invitee)
          // if the user already has an invitee created for the same meeting the server will return that one
          // instead so set the invite link to the existing one
          setShowInvitation(invitee[0].id)
          setShowLoading(false)
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log('createInviteeMutationError: ')

          if (error.message === CreateInviteeError.inviteNotFound) {
            setShowError('Invite not found!')
          }

          if (error.message === CreateInviteeError.selfInvite) {
            goBack()
          }
        }
      }
    }
  }

  useEffect(() => {
    if (invitees) {
      if (invitation) {
        const invitee = invitees.find(i => i.invitation === invitation)

        if (!invitee) {
        // make sure we don't create it twice
          if (debounceInvite !== invitation) {
            onCreateInvite()
          }
        } else {
          // the invitee already exists
          setShowInvitation(invitee.id)
        }
      } else {
        // if there are no new invites but we have a declined invite then switch to declined tab
        if (invitees.find(invitee => invitee.status === InviteeStatus.declined)) {
          setShowSegment('showDeclined')
        }
        // else {
        //   const selected = invitees.filter(i =>
        //     i.status === InviteeStatus.invited || i.status === InviteeStatus.received || i.status === InviteeStatus.seen)

        //   // if there are no new invites left then go back
        //   if (selected.length === 0) {
        //     goBack()
        //   }
        // }
      }
    }
  }, [invitees])

  async function onAccept (args: {
    invitee: Invitee,
    selectedOptions?: SelectedOptions;
    }): Promise<void> {
    const { invitee, selectedOptions } = args

    if (invitee) {
      setShowActivityAccept(invitee.id)

      console.log('JOIN MEETING INVITATION: ', invitee)

      const input: JoinMeetingInput = {
        invitee: invitee.id,
      }

      if (selectedOptions) {
        if (selectedOptions.calendarSettings ||
          selectedOptions.busyTimes ||
          selectedOptions.addParticipants ||
          selectedOptions.meetingSettings) {
          input.notReadyToSchedule = true
        }

        if (defaultCalendar && inviteeActionsInfo) {
          if (selectedOptions.adjustedAutoTimes) {
            input.bookCalendar = {
              account: defaultCalendar.account,
              calendarId: defaultCalendar.id,
              timeRanges: inviteeActionsInfo.adjustedAutoTimes,
              autoDays,
            }
          }
        }

        if (selectedOptions.disableAutoBook) {
          input.disableAutoBook = true
        }
      }

      const meeting = await joinMeeting(input)

      if (meeting?.id) {
        setShowActivityAccept('')

        // // if the user opened an invite link then return to home
        // if (invitation) {
        //   goBack()
        // }
        const inviteCount = invitees?.filter(i =>
          i.status !== InviteeStatus.accepted && i.status !== InviteeStatus.declined).length

        console.log('remaining invite count: ', inviteCount)
        console.log('selected options: ', selectedOptions)

        if (selectedOptions?.busyTimes || selectedOptions?.disableAutoBook) {
          onChooseAvailability({
            meeting: invitee.meeting,
            autoBook: true,
            inviteCount,
            startEdit: true,
          })
        } else if (selectedOptions?.calendarSettings) {
          onMeetingCalendarSettings(invitee.meeting)
        } else if (selectedOptions?.addParticipants) {
          onAddParticipants(invitee.meeting)
        } else if (selectedOptions?.meetingSettings) {
          onMeetingSettings(invitee.meeting, invitee.meetMe)
        } else if (defaultCalendar) {
          // if automagic is ON
          // if there are no new invites left then go back
          // include the current invite as it's too soon that the current invite will change state
          if (inviteCount === 1) {
            goBack()
          }
        } else {
          // automagic is not ON so go to schedule times
          const connectCalendar = meetings ? meetings.length > 2 : false

          onChooseAvailability({
            meeting: invitee.meeting,
            connectCalendar,
            inviteCount,
            startEdit: true,
          })
        }
      }
    }
  }

  async function onDecline (invitee: Invitee): Promise<void> {
    if (invitee) {
      setShowActivityDecline(invitee.id)

      const input: UpdateInviteeInput = {
        id: invitee.id,
        status: InviteeStatus.declined,
      }

      const updated = await updateInvitee(input)

      if (updated?.id) {
        setShowActivityDecline('')

        // if the user opened an invite link then return to home
        if (invitation) {
          goBack()
        }
      }
    }
  }

  async function onExit (invitee: Invitee): Promise<void> {
    if (invitee) {
      setShowActivityDecline(invitee.id)

      if (invitation) {
        // if we are showing a specific invite and the user exits the meeting
        // then make sure we don't create the invitee again
        setDebounceInvite(invitation)
      }

      await exitMeeting(invitee.meeting)

      setShowActivityDecline('')

      if (refetch) {
        refetch()
      }

      // if the user opened an invite link then return to home
      if (invitation) {
        goBack()
      }
    }
  }

  function renderInvite (invitee: Invitee): JSX.Element | undefined {
    const invite = invitations?.find((i: MeetingInvite) => i.meeting === invitee.meeting)

    if (invite && invitee) {
      let showActivity = ''

      if (showActivityAccept === invitee.id) {
        showActivity = 'accept'
      } else if (showActivityDecline === invitee.id) {
        showActivity = 'decline'
      }

      return (
        <InviteCard
          key={invite.id}
          invite={invite}
          invitee={invitee}
          onAccept={() => {
            logTap({ component: 'InviteCard', button: 'AcceptInvitation' })
            onAccept({ invitee })
          }}
          onDecline={() => {
            logTap({ component: 'InviteCard', button: 'DeclineInvitation' })
            onDecline(invitee)
          }}
          onMeetingSettings={() => {
            logTap({ component: 'InviteCard', button: 'MeetingSettings' })
            onAccept({ invitee, selectedOptions: { meetingSettings: true } })
          }}
          onCalendarSettings={() => {
            logTap({ component: 'InviteCard', button: 'CalendarSettings' })
            onAccept({ invitee, selectedOptions: { calendarSettings: true } })
          }}
          onSelectAutoTimeOptions={(adjustedAutoTimes: TimeRange[], defaultAutoTimes?: TimeRange[]) => {
            logTap({ component: 'InviteCard', button: 'AutoTimeOptions' })
            setInviteeActionsInfo({
              invite,
              invitee,
              selectAutoTimes: true,
              defaultAutoTimes,
              adjustedAutoTimes,
            })
            setShowInviteeActionsModal(true)
          }}
          onSelectBusyTimes={() => {
            logTap({ component: 'InviteCard', button: 'BusyTimes' })
            setInviteeActionsInfo({
              invite,
              invitee,
              selectBusyTimes: true,
            })
            setShowInviteeActionsModal(true)
          }}
          onExit={() => {
            logTap({ component: 'InviteCard', button: 'Exit' })
            onExit(invitee)
          }}
          showActivity={showActivity} />
      )
    }
  }

  function renderInvitations (): JSX.Element | undefined {
    if (!invitation && invitees && invitations) {
      // only show invitations that have not been accepted
      let selected

      if (showSegment === 'showNew') {
        selected = invitees.filter(invitee =>
          invitee.status === InviteeStatus.invited || invitee.status === InviteeStatus.received || invitee.status === InviteeStatus.seen)
      } else {
        selected = invitees.filter(invitee => invitee.status === InviteeStatus.declined)
      }

      if (meetingId) {
        console.log('SHOW INVITATION FOR MEETING: ', meetingId)
        console.log(invitees)
        selected = invitees.filter(invitee => invitee.meeting === meetingId)
      }

      if (selected.length) {
        return (
          <div>
            {selected.map(invitee => {
              return renderInvite(invitee)
            })}
          </div>
        )
      } else {
        return (
          <ScreenCenter height='80%'>
            <IonIcon
              color='success'
              style={{ fontSize: 32, marginBottom: 10 }}
              icon={checkmarkCircleOutline} />
            no invitations
          </ScreenCenter>
        )
      }
    }
  }

  function renderSelectedInvite (): JSX.Element | undefined {
    if (showInvitation) {
      // Note that when the invitee is created showInvitation will be set to the id of the invitee
      // since the invitation link may be actually different if the invitee already exits
      const invitee = invitees?.find(i => i.id === showInvitation)

      if (invitee) {
        return renderInvite(invitee)
      }
    }
  }

  function renderSegments (): JSX.Element | undefined {
    if (!invitation && !meetingId) {
      return (
        <IonToolbar>
          <IonSegment
            value={showSegment}
            onIonChange={e => {
              logTap({ component: 'InviteStatus', button: showSegment })
              setShowSegment(e.detail.value || 'showNew')
            }}>
            <IonSegmentButton value='showNew'>
              <IonLabel>New</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='showDeclined'>
              <IonLabel>Declined</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      )
    }
  }

  function renderInviteeActionsModal (): JSX.Element | undefined {
    if (showInviteeActionsModal && inviteeActionsInfo) {
      const { invite, invitee, defaultAutoTimes, adjustedAutoTimes } = inviteeActionsInfo

      return (
        <InviteeActionsModal
          invite={invite}
          invitee={invitee}
          defaultAutoTimes={defaultAutoTimes}
          adjustedAutoTimes={adjustedAutoTimes}
          presentingElement={pageRef.current}
          onSelectedOptions={(selectedOptions?: SelectedOptions) => {
            logTap({ component: 'InviteeActionsModal', button: 'SelectedOptions' })
            setShowInviteeActionsModal(false)
            onAccept({ invitee, selectedOptions })
          }}
          onClose={() => {
            logTap({ component: 'InviteeActionsModal', button: 'CloseInviteeModal' })
            setShowInviteeActionsModal(false)
          }} />
      )
    }
  }

  if (showError) {
    return (
      <IonPage>
        <ScreenHeader
          title={title}
          goBackTo={paths.meetingsList} />
        <IonContent>
          <ScreenCenter height='80%'>
            <IonText style={{ fontSize: 32 }}>
              🙁 <br />
            </IonText>
            {showError}
          </ScreenCenter>
        </IonContent>
      </IonPage>
    )
  }

  if (loading || showLoading) {
    return (
      <IonPage>
        <ScreenHeader
          title={title}
          goBackTo={paths.meetingsList} />
        <IonContent>
          <ScreenCenter height='80%'>
            <IonSpinner name='dots' />
          </ScreenCenter>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage ref={pageRef}>
      <ScreenHeader
        title={title}
        onBack={goBack} />
      <IonContent>
        {renderSegments()}
        {renderSelectedInvite()}
        {renderInvitations()}
      </IonContent>
      {renderInviteeActionsModal()}
    </IonPage>
  )
}

export default Invitations
