import React, { useEffect, useState } from 'react'
import {
  IonBackdrop,
  IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonModal, IonSpinner, IonText,
} from '@ionic/react'
import moment from 'moment'

import './MeetingTimeModal.css'

import { CalendarEvent, MeetingTime, Participant, TimeRange, TimeSlot } from 'types'
import { useSpring } from 'react-spring'
import ActionCard from '../ActionCard/ActionCard'
import MeetingTimeItemDetails from '../MeetingTimeItemDetails/MeetingTimeItemDetails'
import { useMeetings } from 'context/MeetingsContext/MeetingsContext'
import { useParticipants } from 'context/ParticipantsContext/ParticipantsContext'
import { useUser } from 'context/UserContext/UserContext'
import { useTimeSlots } from 'context/TimeSlotsContext/TimeSlotsContext'
import MeetingTimeParticipants from '../MeetingTimeParticipants/MeetingTimeParticipants'
import FooterButton from 'components/atoms/FooterButton/FooterButton'
import { UpdateMeetingTimesInput, DeleteTimeSlotInput, CreateTimeSlotInput, UpdateTimeSlotInput } from 'services/api'
import { CSSProperties } from '@material-ui/styles'
import { ItemTitle } from 'components/atoms/ListItem/ListItem'
import { stringToMinutes } from 'services/time'
import { withinTimeRanges } from '../ScheduleCalendar/utils'
import { closeCircleOutline } from 'ionicons/icons'

type PopoverInfo = {
  participants?: Participant[];
  calendarEvents?: boolean;
}

const eventBox: CSSProperties = {
  paddingLeft: 20,
  overflow: 'hidden',
}

const eventItem: CSSProperties = {
  borderRadius: '6px',
  overflow: 'hidden',
  borderLeftStyle: 'solid',
  borderLeftWidth: '6px',
}

enum Activity {
  none,
  available,
  busy,
  confirm,
}

interface ContainerProps {
  selectedTime?: string;
  meetingTime?: MeetingTime;
  timeRange?: TimeRange;
  calendarEvents?: CalendarEvent[];
  autoRange?: boolean;
  onClose: () => void;
  onMore?: () => void;
  onEdit?: (timeSlot: TimeSlot) => void;
  onStartEditCalendar?: () => void;
  presentingElement: HTMLElement | HTMLDivElement | undefined | null
}

const MeetingTimeModal: React.FC<ContainerProps> = ({ selectedTime, meetingTime, timeRange, calendarEvents, autoRange, onMore, onClose, onStartEditCalendar }) => {
  const [showDetails, setShowDetails] = useState<PopoverInfo>()
  const { user } = useUser()
  const { meeting } = useMeetings()
  const { participants } = useParticipants()

  const [activity, setActivity] = useState(Activity.none)

  const { updateMeetingTimes } = useMeetings()
  const { timeSlots, createTimeSlot, updateTimeSlot, deleteTimeSlot } = useTimeSlots()

  const startTime = meetingTime?.startTime || timeRange?.startTime || ''
  const endTime = meetingTime?.endTime || timeRange?.endTime || ''
  // const status = meetingTime?.status

  const active = meetingTime?.participants ? !!meetingTime?.participants?.find(u => u === user?.id)
    : !!timeRange?.participants?.find(u => u === user?.id)

  // const tentative = status === 'tentative'
  // const trending = status === 'trending'

  // const [confirmed, setConfirmed] = useState(status === 'confirmed')

  function getDetails (): void {
    if (participants?.length) {
      if (meetingTime) {
        console.log('get details for meetingTime: ', meetingTime)

        const rangeParticipants = meetingTime.participants.map((u: string) => {
          return participants.find((p: Participant) => p.user === u)
        }).filter(p => p)

        if (rangeParticipants) {
          const info: PopoverInfo = {
            participants: rangeParticipants as Participant[],
          }

          setShowDetails(info)
        }
      } else if (timeRange?.participants) {
        console.log('get details for range: ', timeRange)

        const rangeParticipants = timeRange?.participants.map((u: string) => {
          return participants.find((p: Participant) => p.user === u)
        })

        if (rangeParticipants) {
          const info: PopoverInfo = {
            participants: rangeParticipants as Participant[],
          }

          setShowDetails(info)
        }
      } else if (calendarEvents?.length) {
        setShowDetails({ calendarEvents: true })
      }
    }
  }

  useEffect(() => {
    getDetails()
  }, [participants])

  async function onConfirm (confirm: boolean): Promise<void> {
    if (user?.id && meeting?.id) {
      let input: UpdateMeetingTimesInput

      setActivity(Activity.confirm)

      if (confirm) {
        const participants = meetingTime?.participants || timeRange?.participants || []

        input = {
          id: meeting.id,
          meetingTimes: [
            {
              startTime,
              endTime,
              participants,
              status: 'confirmed',
              updatedBy: user.id,
            },
          ],
        }
        await updateMeetingTimes(input)

        if (!active) {
          onAvailable()
        }
      } else {
        input = {
          id: meeting.id,
          meetingTimes: [],
        }
        await updateMeetingTimes(input)
      }

      setActivity(Activity.none)

      onClose()
      // onAction && onAction()
      // console.log('Creating time slots with server: ', input)
    }
  }

  let busy = false
  let autoCreated = false

  if (user?.id && meeting?.id && timeSlots?.length) {
    const startValue = moment(startTime).valueOf()
    const endValue = moment(endTime).valueOf()

    // find out if there are any blocked timeSlots for this time range
    const timeSlot = timeSlots.find(slot => {
      if (slot.user === user.id && slot.id) {
        const start = moment(slot.startTime).valueOf()
        const end = moment(slot.endTime).valueOf()

        // valid overlap

        if (start < endValue && end > startValue) {
          return true
        }
      }
    })

    if (timeSlot?.id) {
      if (!timeSlot.available) {
        busy = true
      }

      if (timeSlot.autoCreated) {
        autoCreated = true
      }
    }
  }

  async function onAvailable (): Promise<void> {
    if (user?.id && meeting?.id) {
      if (timeSlots) {
        const startValue = moment(startTime).valueOf()
        const endValue = moment(endTime).valueOf()

        // find out if there are any blocked timeSlots for this time range
        const timeSlot = timeSlots.find(slot => {
          if (slot.user === user.id && slot.id) {
            const start = moment(slot.startTime).valueOf()
            const end = moment(slot.endTime).valueOf()

            // valid overlap

            if (start < endValue && end > startValue) {
              return true
            }
          }
        })

        if (timeSlot?.id && !timeSlot.available) {
          const input: DeleteTimeSlotInput = {
            meeting: meeting.id,
            id: timeSlot.id,
          }

          console.log('Delete time slots with server: ', input)

          // console.log('Creating time slots with server: ', input)
          setActivity(Activity.available)
          await deleteTimeSlot(input)
          setActivity(Activity.none)
          onStartEditCalendar && onStartEditCalendar()
          onClose()

          return
        }
      }

      if (startTime && endTime) {
        // this should be in multiples of 15min
        const minutes = moment(selectedTime || startTime).minutes()
        const duration = Number(stringToMinutes(meeting.duration))

        // get closest startingMinutes in multiples of duration
        const startMinutes = Math.floor(minutes / duration) * duration

        const slotStartTime = moment(selectedTime || startTime)
          .startOf('hour')
          .add(startMinutes, 'minutes').toISOString()

        let slotEndTime = endTime

        slotEndTime = moment(slotStartTime).add(duration, 'minutes').toISOString()

        if (meeting.timeRanges?.length) {
          let withinRange = withinTimeRanges(slotEndTime, meeting.timeRanges)

          if (!withinRange) {
            withinRange = withinTimeRanges(slotStartTime, meeting.timeRanges)

            if (withinRange) {
              slotEndTime = withinRange.endTime
            } else {
              // both start and end times are outside range
              return
            }
          }
        }

        const input: CreateTimeSlotInput = {
          meeting: meeting.id,
          startTime: slotStartTime,
          endTime: slotEndTime,
          available: true,
        }

        console.log('Creating time slots with server: ', input)
        setActivity(Activity.available)
        await createTimeSlot(input)
        setActivity(Activity.none)
        onStartEditCalendar && onStartEditCalendar()
        onClose()
      } else if (calendarEvents?.length) {
        // sort calendar events with least duration first
        const sorted = calendarEvents.slice().sort((a: CalendarEvent, b: CalendarEvent) => {
          const durationA = moment(a.endTime).diff(moment(a.startTime), 'minutes')
          const durationB = moment(b.endTime).diff(moment(b.startTime), 'minutes')

          return durationA - durationB
        })

        console.log('SORTED CALENDARS: ', sorted)

        // use the calendar events start and end time
        const input: CreateTimeSlotInput = {
          meeting: meeting.id,
          startTime: sorted[0].startTime,
          endTime: sorted[0].endTime,
          available: true,
        }

        console.log('Creating time slots with server: ', input)
        setActivity(Activity.available)
        await createTimeSlot(input)
        setActivity(Activity.none)
        onStartEditCalendar && onStartEditCalendar()
        onClose()
      }
    }
  }

  async function onBusy (): Promise<void> {
    if (user?.id && meeting?.id) {
      const input: CreateTimeSlotInput = {
        meeting: meeting.id,
        startTime,
        endTime,
        available: false,
      }

      // console.log('Creating time slots with server: ', input)
      setActivity(Activity.busy)
      await createTimeSlot(input)
      setActivity(Activity.none)
      onStartEditCalendar && onStartEditCalendar()
      onClose()
    }
  }

  async function onNotAvailable (): Promise<void> {
    if (timeSlots && user?.id && meeting?.id) {
      const startValue = moment(startTime).valueOf()
      const endValue = moment(endTime).valueOf()

      // get all the user's timeSlots that are in this range
      // for now all slots that overlap this range.
      const slots = timeSlots.slice()

      setActivity(Activity.busy)

      // make a copy of the timeSlots else it could get updated while we are making changes
      await Promise.all(slots.map(async (slot) => {
        if (slot.user === user.id && slot.id) {
          const start = moment(slot.startTime).valueOf()
          const end = moment(slot.endTime).valueOf()

          // valid overlap

          if (start < endValue && end > startValue) {
            if (start < startValue) {
              const update: UpdateTimeSlotInput = {
                id: slot.id,
                startTime: slot.startTime,
                // endTime is set to the start of the deleted range
                endTime: startTime,
                available: true,
              }

              await updateTimeSlot(update)

              // this will create a hole so create a new timeSlot
              if (end > endValue) {
                const input: CreateTimeSlotInput = {
                  meeting: meeting.id,
                  // the new slot start the end of the deleted range
                  startTime: endTime,
                  endTime: slot.endTime,
                  available: true,
                }

                // console.log('Creating time slots with server: ', input)
                await createTimeSlot(input)
              }
            } else if (end > endValue) {
              const update: UpdateTimeSlotInput = {
                id: slot.id,
                startTime: endTime,
                // endTime is set to the start of the deleted range
                endTime: slot.endTime,
                available: true,
              }

              await updateTimeSlot(update)

              // this will create a hole so create a new timeSlot
              if (start < startValue) {
                const input: CreateTimeSlotInput = {
                  meeting: meeting.id,
                  // the new slot start the end of the deleted range
                  startTime: slot.startTime,
                  endTime: startTime,
                  available: true,
                }

                // console.log('Creating time slots with server: ', input)
                await createTimeSlot(input)
              }
            } else {
              // they are the same so just go ahead and delete the slot
              // the autoCreated time slots should have exact match

              if (slot.autoCreated) {
                // if autoCreated then block this time
                const update: UpdateTimeSlotInput = {
                  id: slot.id,
                  startTime: slot.startTime,
                  endTime: slot.endTime,
                  available: false,
                }

                await updateTimeSlot(update)
              } else {
                const input: DeleteTimeSlotInput = {
                  meeting: meeting.id,
                  id: slot.id,
                }

                // console.log('Delete time slots with server: ', input)

                await deleteTimeSlot(input)
              }
            }
          }
        }
      }))

      setActivity(Activity.none)
      onStartEditCalendar && onStartEditCalendar()
      onClose()
    }
  }

  // async function onEditTimeSlot (): Promise<void> {
  //   if (onEdit && user?.id && meeting?.id && timeSlots) {
  //     const startValue = moment(startTime).valueOf()
  //     const endValue = moment(endTime).valueOf()

  //     const timeSlot = timeSlots.find(slot => {
  //       if (slot.user === user.id && slot.id) {
  //         const start = moment(slot.startTime).valueOf()
  //         const end = moment(slot.endTime).valueOf()

  //         // valid overlap

  //         if (start < endValue && end > startValue) {
  //           return true
  //         }
  //       }
  //     })

  //     if (timeSlot?.id) {
  //       onEdit(timeSlot)
  //     }
  //   }
  // }

  function renderMeetingTimeDetails (): JSX.Element | undefined {
    if (user?.id && meeting && meetingTime) {
      return (
        <MeetingTimeItemDetails
          user={user?.id}
          meeting={meeting}
          meetingTime={meetingTime}
          onAction={onClose} />
      )
    }
  }

  function renderTimeRangeDetails (): JSX.Element | undefined {
    if (user?.id && meeting && timeRange) {
      return (
        <MeetingTimeItemDetails
          user={user.id}
          meeting={meeting}
          timeRange={timeRange}
          onAction={onClose} />
      )
    }
  }

  function renderCalendarEvents (): JSX.Element | undefined {
    if (calendarEvents?.length) {
      console.log('renderCalendar events: ', calendarEvents?.length)

      return (
        <div className='meetingCardItemBorderBottom'>
          <div style={{ textAlign: 'left', marginTop: 5, marginBottom: 5 }}>
            <ItemTitle title='your calendar events' />
          </div>
          {calendarEvents.map((event, i) => {
            const start = moment(event.startTime).format('h:mm A')
            const end = moment(event.endTime).format('h:mm A')

            const eventStyle: CSSProperties = {
              ...eventItem,
            }

            if (event.backgroundColor) {
              eventStyle.borderColor = event.backgroundColor
            }

            return (
              <div
                key={i}
                style={eventBox}>
                <IonItem
                  color='transparent'
                  style={eventStyle}
                  lines={(i < calendarEvents.length - 1) ? 'inset' : 'none'}>
                  <IonLabel
                    className='ion-text-wrap'>
                    <h2 style={{ fontWeight: 'bold' }}>{event.summary}</h2>
                    <p>{start} to {end}</p>
                  </IonLabel>
                </IonItem>
              </div>
            )
          })}
        </div>
      )
    }
  }

  function renderMeetingTimeParticipants (): JSX.Element | undefined {
    if (user?.id && showDetails?.participants) {
      return (
        <MeetingTimeParticipants
          user={user?.id}
          busy={busy}
          participants={showDetails.participants} />
      )
    }
  }

  function renderMoreInfoButton (): JSX.Element | undefined {
    if (onMore) {
      return (
        <IonItem
          mode='ios'
          detail
          color='transparent'
          lines='none'>
          {/* {active && onEdit && !autoCreated &&
            <IonIcon
              color='medium'
              style={{ marginRight: 5 }}
              icon={timeOutline} />} */}
          {/* {active && onEdit && !autoCreated &&
            <IonLabel
              color='medium'
              onClick={() => onEditTimeSlot()}>
              edit your time
            </IonLabel>} */}
          <IonText
            slot='end'
            color='medium'
            onClick={onMore}>
            more details
          </IonText>
        </IonItem>
      )
    }
  }

  function renderAvailableButton (): JSX.Element | undefined {
    if (busy) {
      return (
        <FooterButton
          disabled={!!activity}
          onClick={() => onAvailable()}>
          {activity !== Activity.available
            ? 'Mark Me Free'
            : <IonSpinner
                slot='end'
                name='dots' />}
        </FooterButton>
      )
    }

    if (!active) {
      if (autoRange) {
        if (calendarEvents?.length) {
          return (
            <FooterButton
              disabled={!!activity}
              onClick={() => onAvailable()}>
              {activity !== Activity.available
                ? 'Mark Me Free'
                : <IonSpinner
                    slot='end'
                    name='dots' />}
            </FooterButton>
          )
        }

        return (
          <FooterButton
            color='medium'
            disabled={!!activity}
            onClick={() => onBusy()}>
            {activity !== Activity.busy
              ? 'Mark Me Busy'
              : <IonSpinner
                  slot='end'
                  name='dots' />}
          </FooterButton>
        )
      } else {
        return (
          <FooterButton
            disabled={!!activity}
            onClick={() => onAvailable()}>
            {activity !== Activity.available
              ? 'Mark Me Available'
              : <IonSpinner
                  slot='end'
                  name='dots' />}
          </FooterButton>
        )
      }
    } else {
      if (autoCreated) {
        return (
          <FooterButton
            color='medium'
            disabled={!!activity}
            onClick={() => onBusy()}>
            {activity !== Activity.busy
              ? 'Mark Me Busy'
              : <IonSpinner
                slot='end'
                name='dots' />}
          </FooterButton>
        )
      }

      return (
        <FooterButton
          color='medium'
          disabled={!!activity}
          onClick={() => onNotAvailable()}>
          {activity !== Activity.busy
            ? 'Mark Me Unavailable'
            : <IonSpinner
                slot='end'
                name='dots' />}
        </FooterButton>
      )
    }
  }

  function renderManuallyConfirmButton (): JSX.Element | undefined {
    if (user?.id === meeting?.createdBy) {
      if (meetingTime?.status === 'confirmed') {
        return (
          <FooterButton
            fill='outline'
            color='danger'
            disabled={!!activity}
            onClick={() => onConfirm(false)}>
            {activity !== Activity.confirm &&
              <IonIcon
                icon={closeCircleOutline}
                style={{ marginRight: 5 }} />}
            {activity !== Activity.confirm
              ? 'Unconfirm This Time'
              : <IonSpinner
                slot='end'
                name='dots' />}
          </FooterButton>
        )
      } else {
        return (
          <FooterButton
            color='success'
            disabled={!!activity}
            onClick={() => onConfirm(true)}>
            {activity !== Activity.confirm
              ? 'Confirm This Time'
              : <IonSpinner
                slot='end'
                name='dots' />}
          </FooterButton>
        )
      }
    }

    if (active && meetingTime?.status === 'confirmed') {
      if (user?.id === meeting?.createdBy) {
        return (
          <FooterButton
            color='success'
            disabled={!!activity}
            onClick={() => onConfirm(true)}>

            {activity !== Activity.confirm
              ? 'Confirm This Time'
              : <IonSpinner
                slot='end'
                name='dots' />}
          </FooterButton>
        )
      }
    }
  }

  function renderActionButtons (): JSX.Element | undefined {
    return (
      <IonFooter className='screenFooterButton'>
        {renderAvailableButton()}
        {renderManuallyConfirmButton()}
      </IonFooter>
    )
  }

  const popoverProps = useSpring({
    ...(showDetails ? { opacity: 1, bottom: 0 } : { opacity: 0, bottom: -600 }),
  })

  function renderDetailsCard (): JSX.Element | undefined {
    if (meeting && showDetails) {
      return (
        <ActionCard
          title={meeting.title}
          icon=''
          onClose={onClose}
          animatedProps={popoverProps}>
          {renderCalendarEvents()}
          {renderMeetingTimeDetails()}
          {renderTimeRangeDetails()}
          {renderMeetingTimeParticipants()}
          {renderActionButtons()}
          {renderMoreInfoButton()}
        </ActionCard>
      )
    }
  }

  return (
    <IonModal
      isOpen
      backdropDismiss
      cssClass='meetingTimeModal'
      onDidDismiss={onClose}>
      <IonContent>
        <IonBackdrop
          tappable
          visible />
        {renderDetailsCard()}
      </IonContent>
    </IonModal>
  )
}

export default MeetingTimeModal
